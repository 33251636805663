import { Grid, Button } from "@mui/material";
import OnlineCoverSheetHeader from "./OnlineCoverSheetHeader";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import OnlineEditContent from "./OnlineEditContent";
import { useCallback, useEffect, useState } from "react";
import CoverSheetIMG from "./CoverSheetIMG";
import PrintSaveBtns from "../../../app/common/PrintSaveBtn";
import { PDF } from "../../../app/models/Pdf";
import { useAppSelector } from "../../../app/store/configureStore";
import { Answers } from "../../../app/models/Answers";
import LoadingSpinner from "../../../app/common/LoadingSpinner";
import agent from "../../../app/api/agent";
import createCoverSheetPDF from "../../../app/utils/pdfCoverSheet";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../../translation/translate";

export default function OnlineCoverSheet() {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();
  const navigate = useNavigate();
  const [pdf, setPdf] = useState<PDF[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [answers, setAnswers] = useState<Answers[]>([]);
  const defaultValues = {
    headerTitle: answers.find((answer: any) => answer.systemCodeId === 387)
      ?.answer,
    headerDate: answers.find((answer: any) => answer.systemCodeId === 388)
      ?.answer,
  };
  const [coverPicture, setCoverPicture] = useState(
    answers.find((answer: any) => answer.systemCodeId === 392)?.answer || ""
  );

  const fetchData = useCallback(async () => {
    try {
      if (user?.credentialName && user?.portfolioId) {
        const response = await agent.Answers.GetAnswers(
          user.credentialName,
          user.portfolioId,
          "CoverSheet"
        );

        const pdf = await agent.PDFViewer.GetPDFByType(
          user.credentialName,
          user.portfolioId,
          "CoverSheet"
        );

        setPdf(pdf);
        setAnswers(response);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [user?.credentialName, user?.portfolioId]);

  const handleAnswerChange = (systemCodeId: number, newValue: string) => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((answer) =>
        answer.systemCodeId === systemCodeId
          ? { ...answer, answer: newValue }
          : answer
      )
    );
  };

  const handleSave = async () => {
    setIsLoading(true);
    // This is just a sample function for demonstration
    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );
    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    navigate("/myprogresssummary");
    setIsLoading(false);
  };

  const handleSaveDraft = async () => {
    setIsLoading(true);
    // This is just a sample function for demonstration
    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );
    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    setIsLoading(false);
  };

  const handlePDF = async () => {
    createCoverSheetPDF({
      userOptions: pdf,
      coverPicture,
      user: [
        user?.firstName,
        user?.lastName,
        user?.credentialName,
        user?.cdaCouncilCandidateId,
      ],
    });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <Grid container p={2} m={1}>
      <Button href="/portfoliochecklist" style={{ textTransform: "initial" }}>
        <ArrowBackIosIcon />
        {getTranslation("BackToCheckList")}
      </Button>
      <Grid item container marginTop={2}>
        <OnlineCoverSheetHeader />
        <OnlineEditContent
          answersData={answers}
          onAnswerChange={handleAnswerChange}
          defaultValues={defaultValues}
        />
        <CoverSheetIMG
          answersData={answers}
          onAnswerChange={handleAnswerChange}
          coverPicture={coverPicture}
          setCoverPicture={setCoverPicture}
        />
      </Grid>
      <PrintSaveBtns
        btnColor={"success"}
        onSave={handleSave}
        onDraft={() => {
          handleSaveDraft();
          fetchData();
        }}
        onPreview={handlePDF}
        title={"Competency"}
      />
    </Grid>
  );
}
