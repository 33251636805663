import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { SummaryCompleteProps } from "../../app/models/SummaryComplete";
import PhilosophyStatementStatus from "./PhilosophyStatementStatus";
import HowYoungChildrenLearn from "./HowYoungChildrenLearn";
import MyRoleinCaring from "./MyRoleinCaring";
import ImportantAspects from "./ImportantAspects";
import PrintSaveBtns from "../../app/common/PrintSaveBtn";
import LoadingSpinner from "../../app/common/LoadingSpinner";
import { useState, useEffect, useCallback } from "react";
import agent from "../../app/api/agent";
import { Answers } from "../../app/models/Answers";
import { useAppSelector } from "../../app/store/configureStore";
import CongratsPopUp from "../../app/common/CongratsPopUp";
import createAndOpenPDF from "../../app/utils/pdfutils";
import { PDF } from "../../app/models/Pdf";
import { useTranslation } from "../../translation/translate";

export default function ThePhilosophyStatement() {
  const { getTranslation } = useTranslation();
  const { user } = useAppSelector((state) => state.account);
  const [summaryCompleteProps, setSummaryCompleteProps] =
    useState<SummaryCompleteProps>({
      title: getTranslation("TheProfessionalPhilosophyStatement"),
      percent: "",
      header: "",
      description: (
        <Typography component="span">
          {getTranslation("ThePhilosophyStatementDescription1")}

          <li>{getTranslation("ThePhilosophyStatementDescriptionList1")}</li>
          <li>{getTranslation("ThePhilosophyStatementDescriptionList2")}</li>
        </Typography>
      ),
    });

  const [answers, setAnswers] = useState<Answers[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [compComplete, setCompComplete] = useState<number>(0);
  const [popUp, setPopUp] = useState(false);
  const [showDenialModal, setShowDenialModal] = useState<boolean>(true);
  const [userProgress, setUserProgress] = useState<number>(0);
  const [pdf, setPdf] = useState<PDF[]>([]);

  const handlePopUp = () => {
    setPopUp((prev) => !prev);
  };

  const fetchData = useCallback(async () => {
    try {
      if (user?.credentialName && user?.portfolioId) {
        const response = await agent.Answers.GetAnswers(
          user.credentialName,
          user.portfolioId,
          "ProfessionalPhilosophy"
        );
        const compData = await agent.Progress.GetCompentencyResultsByType(
          user.portfolioId,
          "ProfessionalPhilosophy"
        );
        setUserProgress(compData.resourceProgress);
        const pdf = await agent.PDFViewer.GetPDFByType(
          user.credentialName,
          user.portfolioId,
          "ProfessionalPhilosophy"
        );
        setPdf(pdf);
        const compProgress = await agent.Progress.GetCompentencyResults(
          user.portfolioId
        );
        setCompComplete(compProgress[0].resourceProgress);

        setSummaryCompleteProps((prevSummaryCompleteProp) => ({
          ...prevSummaryCompleteProp,
          percent: compData.resourceProgress + "%",
        }));

        setAnswers(response);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [user?.credentialName, user?.portfolioId]);

  const handleComplete = () => {
    const resource = compComplete;
    resource < 90 ? setShowDenialModal(true) : setShowDenialModal(false);
  };

  useEffect(() => {
    fetchData();
    handleComplete();
  }, [fetchData, user, compComplete, userProgress]);

  const handleAnswerChange = (systemCodeId: number, newValue: string) => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((answer) =>
        answer.systemCodeId === systemCodeId
          ? { ...answer, answer: newValue }
          : answer
      )
    );
  };

  const handleSaveDraft = async () => {
    setIsLoading(true);
    // This is just a sample function for demonstration
    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );
    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    fetchData();
    setIsLoading(false);
  };

  const handleSave = async () => {
    setIsLoading(true);
    // This is just a sample function for demonstration
    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );
    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    fetchData();
    setIsLoading(false);
    handlePopUp();
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const handleClose = () => {
    setShowDenialModal(false);
  };
  const handlePDF = async () => {
    createAndOpenPDF({
      pdfData: pdf,
      title: "My Professional Philosophy Statement",
      user: [
        user?.firstName,
        user?.lastName,
        user?.credentialName,
        user?.cdaCouncilCandidateId,
      ],
    });
  };
  return (
    <Grid container p={2} mb={2}>
      <Dialog
        open={showDenialModal}
        onClose={(e) => {
          e === "backdropclick" && void "backdropClick";
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {getTranslation("ThePhilosophyStatement")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {getTranslation("ThePhilosophyStatementDescription")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button href="/myprogresssummary" onClick={handleClose} autoFocus>
            {getTranslation("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      <PhilosophyStatementStatus {...summaryCompleteProps} />
      <HowYoungChildrenLearn
        answersData={answers}
        onAnswerChange={handleAnswerChange}
      />
      <MyRoleinCaring
        answersData={answers}
        onAnswerChange={handleAnswerChange}
      />
      <ImportantAspects
        answersData={answers}
        onAnswerChange={handleAnswerChange}
      />
      <PrintSaveBtns
        btnColor={"myVerify"}
        onSave={handleSave}
        onDraft={handleSaveDraft}
        Progress={userProgress}
        onPreview={handlePDF}
        title={"Competency"}
      />
      <CongratsPopUp
        handlePopUp={handlePopUp}
        isPopUp={popUp}
        btnColor={"myVerify"}
        background={"myVerify.light"}
        description={getTranslation("TheProfessionalPhilosophyStatement")}
      />
    </Grid>
  );
}
