import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import agent from "../../../app/api/agent";
import LoadingSpinner from "../../../app/common/LoadingSpinner";
import { useState } from "react";
import { useTranslation } from "../../../translation/translate";

const textBorder = {
  border: "0.5px lightGray solid",
  p: 1,
  mr: 2,
  borderRadius: 1,
};

export default function CandidateSharedList({
  adminData,
  handleRemove,
  handleGrantPermission,
  otherUser,
}: any) {
  const { getTranslation } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [loadingFinalPDF, setLoadingFinalPDF] = useState(false);
  async function handleViewPortfolioButtonClick(
    portfoliId: any
  ): Promise<void> {
    setLoadingFinalPDF(true);
    try {
      // Generate the final PDF
      await agent.PDFViewer.GenerateFinalPDF(portfoliId, "Preview");

      // Get file info
      const data = await agent.FileUpload.GetFileInfo("PreviewPDF", portfoliId);

      // Get the file
      const response = await agent.FileUpload.GetFiles(data[0].id);

      // Create a blob from the file response
      const blob = new Blob([response], { type: "application/pdf" });

      // Create a URL for the blob
      const imageUrl = URL.createObjectURL(blob);

      // Open the PDF in a new tab
      window.open(imageUrl, "_blank");
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoadingFinalPDF(false);
    }
  }

  if (loadingFinalPDF) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <LoadingSpinner />
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      {adminData.map((item: any) => (
        <Grid item xs={12} mb={2} key={item.userId || item.id}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Grid item display="flex" flexDirection="row">
                  <Typography
                    fontSize={24}
                    fontWeight={700}
                    marginRight={5}
                    color="primary.main"
                  >
                    {adminData.indexOf(item) + 1}
                  </Typography>
                  <Typography fontSize={18} fontWeight={700} mt={0.8}>
                    {item.firstName} {item.lastName}
                  </Typography>
                </Grid>
                <Typography
                  fontSize={isMobile ? "small" : "large"}
                  alignSelf="flex-end"
                  color={
                    item.status === "Approved" ? "success.main" : "error.light"
                  }
                  m={2}
                >
                  {item.status === "Approved"
                    ? getTranslation("PermissionGranted")
                    : getTranslation("PermissionPending")}
                </Typography>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12} container p={2}>
                <Grid item xs={6}>
                  <Typography variant="overline" textTransform="initial">
                    {getTranslation("FirstName")}
                  </Typography>
                  <Typography sx={textBorder}>{item.firstName}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="overline" textTransform="initial">
                    {getTranslation("LastName")}
                  </Typography>
                  <Typography sx={textBorder}>{item.lastName}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="overline" textTransform="initial">
                    {getTranslation("Email_Local_Counseling_Agency")}
                  </Typography>
                  <Typography sx={textBorder}>{item.email}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="overline" textTransform="initial">
                    {getTranslation("Relationship")}
                  </Typography>
                  <Typography sx={textBorder}>{item.role}</Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
            <AccordionActions>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography>{item.permission}</Typography>
                <Grid item>
                  {item.status === "Pending" && (
                    <Button
                      sx={{ textTransform: "initial" }}
                      color="myVerify"
                      variant="contained"
                      onClick={() => {
                        handleGrantPermission(item.userId);
                      }}
                    >
                      {getTranslation("GrantPermission")}
                    </Button>
                  )}
                  {otherUser && (
                    <Button
                      sx={{ textTransform: "initial" }}
                      color="myVerify"
                      variant="contained"
                      onClick={() =>
                        handleViewPortfolioButtonClick(item.portfoliId)
                      }
                    >
                      View Portfolio
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "GrayText",
                      ":hover": { bgcolor: "error.light" },
                      textTransform: "initial",
                      ml: 2,
                    }}
                    onClick={() => handleRemove(item.userId, item.portfoliId)}
                  >
                    Remove Entry
                  </Button>
                </Grid>
              </Grid>
            </AccordionActions>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  );
}
