import { useState, useEffect, useCallback } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import agent from "../../app/api/agent";

interface ConfirmEmailProps {}

const ConfirmEmail: React.FC<ConfirmEmailProps> = () => {
  const [message, setMessage] = useState<string>("");
  const [email, setEmail] = useState<string | null>(null);
  const [token, setToken] = useState<string>("");

  const emailConfirm = useCallback(async () => {
    if (!email || !token) return;

    try {
      const emailToken = { emailAddress: email!, token };
      const m = await agent.Account.ConfirmEmail(emailToken);

      setMessage(m);
    } catch (error) {
      console.error(error);
    }
  }, [email, token]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setEmail(searchParams.get("email") ?? null);
    setToken(encodeURIComponent(searchParams.get("token") ?? ""));
  }, [emailConfirm]);

  useEffect(() => {
    emailConfirm();
  }, [emailConfirm]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh" }}
    >
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Typography variant="h4" align="center">
          {message}
        </Typography>
        <Typography variant="subtitle1" align="center">
          {message === "Your email has been confirmed" ? "Your email has been confirmed" : ""}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/account/login"
          fullWidth
        >
          Navigate to Login
        </Button>
      </Grid>
    </Grid>
  );
};

export default ConfirmEmail;
