import { FileUpload } from "@mui/icons-material";
import { Button, Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FileInfo } from "../../app/models/Fileinfo";
import {
  useAppSelector,
  RootState,
  useAppDispatch,
} from "../../app/store/configureStore";
import agent from "../../app/api/agent";
import {
  Anchor,
  Section,
  OpenDrawerPayload,
} from "../../app/models/FileUpload";
import { openDrawer } from "../FileUpload/drawerSlice";
import UploadedFiles from "../FileUpload/UploadedFiles";
import { FilesProps } from "../../app/models/Answers";
import { useTranslation } from "../../translation/translate";

const PolicyRelatedHomeVisitor: React.FC<FilesProps> = ({ RecallData }) => {
  const [data, setData] = useState<FileInfo[]>([]);
  const { isOpen } = useAppSelector((state: RootState) => state.drawer);

  const { user } = useAppSelector((state) => state.account);
  const dispatch = useAppDispatch();
  const { getTranslation } = useTranslation();

  const handleOpenDrawer = (anchor: Anchor, section: Section) => {
    const payload: OpenDrawerPayload = {
      anchor,
      section,
    };
    dispatch(openDrawer(payload));
  };

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const fetchData = useCallback(async () => {
    try {
      const data = await agent.FileUpload.GetFileInfo(
        "RCVI14",
        user?.portfolioId ?? 0
      );
      setData(data);
    } catch (error) {
      // Handle errors if necessary.
    }
  }, [user?.portfolioId]);

  useEffect(() => {
    fetchData();
    RecallData();
  }, [isOpen, fetchData]);

  function handleDeleteFile(): void {
    fetchData();
    RecallData();
  }

  return (
    <Grid container p={2} spacing={2}>
      <Grid item>
        <Typography fontSize={18} fontWeight={700}>
          {getTranslation("RCVIPolicyRelatedTitle")}
        </Typography>
      </Grid>
      <Grid item>
        <Typography>{getTranslation("RCVIPolicyRelatedDetails")}</Typography>
      </Grid>
      <Grid item xs={isMobile ? 12 : 6}>
        <Typography fontWeight={700}>
          {getTranslation("UploadPolicyDocuments")}
        </Typography>
        <Button
          variant="outlined"
          color="compVI"
          onClick={() => handleOpenDrawer("right", "RCVI14")}
        >
          <FileUpload /> {getTranslation("Upload")}
        </Button>
      </Grid>
      <Grid item xs={isMobile ? 12 : 6}>
        <Typography variant="body1" fontWeight="bold">
          {getTranslation("UploadedDocuments")}
        </Typography>
        <Typography variant="subtitle2">
          {data.length} {data.length === 1 ? "file " : "files "}
          {getTranslation("uploaded")}
        </Typography>
        {data.map((item) => (
          <UploadedFiles
            fileOrder={data.indexOf(item) + 1}
            key={item.id}
            fileInfo={item}
            onDeleteFile={() => handleDeleteFile()}
          />
        ))}
      </Grid>
    </Grid>
  );
};
export default PolicyRelatedHomeVisitor;
