import { Grid, Typography, TextField } from "@mui/material";
import { useAppSelector } from "../../app/store/configureStore";
import { useForm } from "react-hook-form";
import ReadMoreReadLess from "../../app/common/ReadMoreReadLess";
import { AnswersProps } from "../../app/models/Answers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const KeepingFamilies: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();

  const schema = yup.object().shape({
    textbox: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });
  const systemCodeId = user?.credentialTypeId === 4 ? 181 : 182;
  const defaultValues = {
    families: answersData.find((answer) => answer.systemCodeId === systemCodeId)
      ?.answer,
  };
  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const renderKeepingFamiliesInformedCompetence = () => {
    return (
      <>
        <li>{getTranslation("SIVKeepingFamiliesBulletList1")}</li>
        <li>{getTranslation("SIVKeepingFamiliesBulletList2")}</li>
        <li>{getTranslation("SIVKeepingFamiliesBulletList3")}</li>
        <li>{getTranslation("SIVKeepingFamiliesBulletList4")}</li>
      </>
    );
  };

  return (
    <Grid container p={2} m={1} bgcolor="#F3EBFC">
      <Grid item container>
        <Grid item xs={12}>
          <Typography fontWeight={700} mb={2}>
            {getTranslation("SIVKeepingFamiliesLine1")}
          </Typography>
          <Typography mb={2}>
            {user?.credentialTypeId === 4
              ? getTranslation("SIVKeepingFamiliesLine2")
              : getTranslation("SIVKeepingFamiliesLine3")}
          </Typography>
          <ReadMoreReadLess>
            <>{renderKeepingFamiliesInformedCompetence()}</>
          </ReadMoreReadLess>
        </Grid>
        <Grid item xs={12}>
          <TextField
            sx={{ backgroundColor: "white" }}
            label={getTranslation("SIVKeepingFamiliesLine4")}
            fullWidth
            multiline
            rows={2}
            {...register("families")}
            name="families"
            value={
              answersData.find((answer) => answer.systemCodeId === systemCodeId)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;

              newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.families?.message}
            helperText={errors.families?.message}
          />
          <Typography fontSize={13}>
            {defaultValues.families?.length}/500
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default KeepingFamilies;
