import * as yup from "yup";
import { useTranslation } from "../../translation/translate";

const RCIFileUploadValidation = (section: string) => {
  const { getTranslation } = useTranslation();
  let validationSchema = yup.object().shape({
    trainer: yup.string(),
  });

  if (section === "RCI1") {
    validationSchema = validationSchema.shape({
      trainer: yup.string().required(getTranslation("TrainerRequired")),
      certificateExpirationDate: yup
        .date()
        .typeError(getTranslation("ValidationInvalidDateFormat"))
        .required(getTranslation("ValidationDateRequired"))
        .test(
          "is-future-date",
          getTranslation("ValidationIsFutureDate"),
          (certificateExpirationDate: any) => {
            const sixMonths = new Date();
            sixMonths.setMonth(sixMonths.getMonth() + 6);
            return certificateExpirationDate > sixMonths;
          }
        ),
    });
  }

  return validationSchema;
};

export default RCIFileUploadValidation;
