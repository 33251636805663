import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { AnswersProps } from "../../app/models/Answers";

const schema = yup.object().shape({
  Goal1: yup
    .string()
    .required("Must give an answer")
    .max(1000, "Max characters reached"),
  Step1: yup
    .string()
    .required("Must give an answer")
    .max(1000, "Max characters reached"),
  Goal2: yup
    .string()
    .required("Must give an answer")
    .max(1000, "Max characters reached"),
  Step2: yup
    .string()
    .required("Must give an answer")
    .max(1000, "Max characters reached"),
  Goal3: yup
    .string()
    .required("Must give an answer")
    .max(1000, "Max characters reached"),
  Step3: yup
    .string()
    .required("Must give an answer")
    .max(1000, "Max characters reached"),
});

export default function EFormStepTwo({
  answersData,
  onAnswerChange,
}: AnswersProps) {
  const borderStyle = { border: "2px black solid", p: 1 };
  const titleStyle = { color: "myVerify.main", fontWeight: 700 };

  const defaultValues = {
    Goal1: answersData.find((answer) => answer.systemCodeId === 2189)?.answer,
    Step1: answersData.find((answer) => answer.systemCodeId === 2190)?.answer,
    Goal2: answersData.find((answer) => answer.systemCodeId === 2191)?.answer,
    Step2: answersData.find((answer) => answer.systemCodeId === 2192)?.answer,
    Goal3: answersData.find((answer) => answer.systemCodeId === 2193)?.answer,
    Step3: answersData.find((answer) => answer.systemCodeId === 2194)?.answer,
  };

  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });
  return (
    <Grid item container m={2} p={2}>
      <Grid item xs={12}>
        <Typography
          fontWeight={700}
          fontSize={20}
          fontStyle="italic"
          textAlign="center"
          color={"myVerify.main"}
        >
          Step 2: Set Goals, Plan Action Steps
        </Typography>
        <Typography>
          Now that you have listed your perceived strengths and areas for future
          growth, what goal or goals might you set for yourself? Is there a
          strength you're committed to strengthening even further? Is there an
          area you've identified that you are committed to improving? In the
          spaces below, list up to three professional goals you are committed to
          achieving. After writing each goal, discuss with your PD Specialist
          steps you might take to reach that goal.
        </Typography>
      </Grid>
      <Grid item container>
        <Grid item xs={6} sx={borderStyle}>
          <Typography sx={titleStyle}>Goal #1:</Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            {...register("Goal1")}
            value={
              answersData.find((answer) => answer.systemCodeId === 2189)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 2189;

              newValue.length <= 1000 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.Goal1?.message}
            helperText={errors.Goal1?.message}
          />
        </Grid>
        <Grid item xs={6} sx={borderStyle}>
          <Typography sx={titleStyle}>
            Steps I could take to reach Goal #1:
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            {...register("Step1")}
            value={
              answersData.find((answer) => answer.systemCodeId === 2190)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 2190;

              newValue.length <= 1000 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.Step1?.message}
            helperText={errors.Step1?.message}
          />
        </Grid>
        <Grid item xs={6} sx={borderStyle}>
          <Typography sx={titleStyle}>Goal #2:</Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            {...register("Goal2")}
            value={
              answersData.find((answer) => answer.systemCodeId === 2191)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 2191;

              newValue.length <= 1000 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.Goal2?.message}
            helperText={errors.Goal2?.message}
          />
        </Grid>
        <Grid item xs={6} sx={borderStyle}>
          <Typography sx={titleStyle}>
            Steps I could take to reach Goal #2:
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            {...register("Step2")}
            value={
              answersData.find((answer) => answer.systemCodeId === 2192)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 2192;

              newValue.length <= 1000 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.Step2?.message}
            helperText={errors.Step2?.message}
          />
        </Grid>
        <Grid item xs={6} sx={borderStyle}>
          <Typography sx={titleStyle}>Goal #3:</Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            {...register("Goal3")}
            value={
              answersData.find((answer) => answer.systemCodeId === 2193)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 2193;

              newValue.length <= 1000 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.Goal3?.message}
            helperText={errors.Goal3?.message}
          />
        </Grid>
        <Grid item xs={6} sx={borderStyle}>
          <Typography sx={titleStyle}>
            Steps I could take to reach Goal #3:
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            {...register("Step3")}
            value={
              answersData.find((answer) => answer.systemCodeId === 2194)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 2194;

              newValue.length <= 1000 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.Step3?.message}
            helperText={errors.Step3?.message}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
