import { useEffect, useState } from "react";
import CredentialForm from "./CredentialForm";
import { CredentialFormData } from "../../app/models/Credentials";
import agent from "../../app/api/agent";
import SubHeaderTwo from "../../app/layout/SubHeaderTwo";
import { Box } from "@mui/material";
import HorizontalStepper from "../../app/common/HorizontalStepper";
import LoadingSpinner from "../../app/common/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../translation/translate";

export default function ChooseCredential() {
  const [isLoading, setIsLoading] = useState(true);
  const { getTranslation } = useTranslation();

  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const steps = [
    getTranslation("CDABookStep1"),
    getTranslation("CDABookStep2"),
    getTranslation("CDABookStep3"),
  ];

  const fetchData = async () => {
    try {
      const d = await agent.Account.currentUser();
      if (d.welcomeComplete === true) navigate("/myprogresssummary");
      setData(d);
    } catch {
      console.error();
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, [navigate]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return data ? (
    <Box>
      <SubHeaderTwo title={getTranslation("GetStarted")} />
      <HorizontalStepper activeStep={1} steps={steps} />
      <CredentialForm preloadedValues={data as CredentialFormData} />
    </Box>
  ) : (
    <LoadingSpinner />
  );
}
