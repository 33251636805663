import React, { useEffect, useState } from "react";
import TranslationForm from "./translationForm";
import { Container, Typography } from "@mui/material";
import agent from "../app/api/agent";
import { TranslationItem } from "./translationType";

const TranslationFormEditor: React.FC = () => {
  const [allTranslations, setAllTranslations] = useState<TranslationItem[]>([]);

  const getData = async () => {
    try {
      const response = await agent.spanish.getTranslations();
      setAllTranslations(response);
    } catch (error) {}
  };

  const upsert = async (translationItem: TranslationItem) => {
    try {
      if (translationItem.id === 0) {
        // Add new entry
        const response = await agent.spanish.upsert(translationItem);
        setAllTranslations([...allTranslations, response]);
      } else {
        // Update existing entry
        const response = await agent.spanish.upsert(translationItem);
        const translations = allTranslations.map((item) =>
          item.id === translationItem.id ? response : item
        );
        setAllTranslations(translations);
      }
    } catch (error) {
      console.log("Error during upsert:", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      <Typography variant="h3" gutterBottom>
        Translation Editor
      </Typography>
      <TranslationForm
        key={`TranslationForm-${allTranslations.length}`}
        allTranslations={allTranslations}
        setAllTranslations={setAllTranslations}
        upsert={upsert}
      />
    </Container>
  );
};

export default TranslationFormEditor;
