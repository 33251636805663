import * as yup from "yup";
import { useTranslation } from "../../translation/translate";

export const CreateNewClassSchema = (getTranslation: (key: string) => string) =>
  yup.object().shape({
    name: yup.string().required(getTranslation("NameOfClassRequired")),
    drescription: yup
      .string()
      .required(getTranslation("ClassDescriptionRequired")),
    instructorId: yup.number(),
    startDate: yup.date().required(getTranslation("StartDateRequired")),
    endDate: yup.date().required(getTranslation("EndDateRequired")),
    active: yup.boolean(),
    createDate: yup.date(),
  });
  
export const useGetTranslation = () => {
  const { getTranslation } = useTranslation();
  return getTranslation;
};
