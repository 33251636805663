import { FileDownload } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { useAppSelector } from "../../app/store/configureStore";
import { useTranslation } from "../../translation/translate";

const CompleteVerification = () => {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();
  const HomeVistor = user?.credentialTypeId === 4;
  const downloadWorksheet = () => {
    const link = document.createElement("a");
    link.download = `CDAVerificationVisitReflectiveDialogWorksheet.pdf`;
    link.href = "./docs/CDAVerificationVisitReflectiveDialogWorksheet.pdf";
    link.click();
  };

  return (
    <Grid container bgcolor={"myVerify.light"} p={3}>
      <Grid item xs={12} mb={2}>
        <Typography fontWeight={700} fontSize={18}>
          {HomeVistor && "2."} {getTranslation("CompleteVerificationLine1")}
        </Typography>
        <Typography>{getTranslation("CompleteVerificationLine2")}</Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Button
          variant="outlined"
          color="myVerify"
          sx={{ textTransform: "initial" }}
          onClick={downloadWorksheet}
        >
          <FileDownload />
          {getTranslation("CompleteVerificationLine3")}
        </Button>
      </Grid>
    </Grid>
  );
};
export default CompleteVerification;
