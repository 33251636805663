import * as yup from "yup";
import "yup-phone";
import { useTranslation } from "../../translation/translate";

interface RCI3ValidationSchemaProps {
  languageSpecialization: boolean;
}

const RCI3ValidationSchema = ({
  languageSpecialization,
}: RCI3ValidationSchemaProps) => {
  const { getTranslation } = useTranslation();
  let schema = yup.object().shape({
    name: yup.string().max(60, getTranslation("ValidationNameMaxChar")),
    telephone: yup
      .string()
      .matches(/^(\d{10})?$/, getTranslation("ValidationPhone10Digit")),
    website: yup.string().url(getTranslation("ValidationInvalidWebsite")),
    stateLegal: yup.string().max(1000, getTranslation("ValidationMaximumCharacterReached")),
    responsibilityReporting: yup
      .string()
      .max(1000, getTranslation("ValidationMaximumCharacterReached")),
    stateLegalBilingual: yup.string(),
    responsibilityReportingBiligual: yup.string(),
  });

  const secondLanguageFields = {
    stateLegalBilingual: yup.string().max(1000),
    responsibilityReportingBiligual: yup.string().max(1000),
  };

  if (languageSpecialization) {
    schema = schema.shape(secondLanguageFields);
  }

  return schema;
};

export default RCI3ValidationSchema;
