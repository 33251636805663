import { Grid, TextField, Typography } from "@mui/material";
import { useAppSelector } from "../../app/store/configureStore";
import { AnswersProps } from "../../app/models/Answers";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const PhysicalDevelopment: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();

  const isHomeVisitor = user?.credentialTypeId === 4 ? 50 : 51;

  const defaultValues = {
    physical: answersData.find(
      (answer) => answer.systemCodeId === isHomeVisitor
    )?.answer,
    philosophy: answersData.find((answer) => answer.systemCodeId === 52)
      ?.answer,
  };
  const schema = yup.object().shape({
    physical: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    philosophy: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });
  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  return (
    <Grid sx={{ bgcolor: "#E9F6FC" }} pb={3}>
      <Grid item xs={12} px={3} pt={3}>
        <Typography variant="body1" fontWeight="bold" paragraph>
          {getTranslation("StatementIIPhysicalDevelopmentLine0")}
        </Typography>
        <Typography variant="body1" paragraph>
          {user?.credentialTypeId !== 4
            ? getTranslation("StatementIIPhysicalDevelopmentLine1")
            : getTranslation("StatementIIPhysicalDevelopmentQuestion1")}
        </Typography>
      </Grid>
      <Grid item xs={12} px={3} pt={3}>
        <TextField
          label={
            user?.credentialTypeId !== 4
              ? getTranslation("StatementIIPhysicalDevelopmentLine2")
              : getTranslation("StatementIIPhysicalDevelopmentLine3")
          }
          multiline
          rows={2}
          fullWidth
          {...register("physical")}
          name="physical"
          sx={{ bgcolor: "white" }}
          value={
            answersData.find((answer) => answer.systemCodeId === isHomeVisitor)
              ?.answer || ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = isHomeVisitor;

            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.physical?.message}
          helperText={errors.physical?.message}
        />
        <Typography fontSize={13}>
          {defaultValues.physical?.length}/500
        </Typography>
      </Grid>
      {user?.credentialTypeId !== 4 && (
        <>
          <Grid item xs={12} px={3} pt={3}>
            <Typography variant="body1" paragraph>
              {getTranslation("StatementIIPhysicalDevelopmentLine4")}
            </Typography>
          </Grid>
          <Grid item xs={12} px={3} pt={3}>
            <TextField
              label={getTranslation("StatementIIPhysicalDevelopmentLine5")}
              multiline
              rows={2}
              fullWidth
              {...register("philosophy")}
              name="philosophy"
              sx={{ bgcolor: "white" }}
              value={
                answersData.find((answer) => answer.systemCodeId === 52)
                  ?.answer || ""
              }
              onChange={(e) => {
                const newValue = e.target.value;
                const systemCodeId = 52;

                newValue.length <= 500 &&
                  onAnswerChange(systemCodeId, newValue);
              }}
              error={!!errors.philosophy?.message}
              helperText={errors.philosophy?.message}
            />
            <Typography fontSize={13}>
              {defaultValues.philosophy?.length}/500
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default PhysicalDevelopment;
