import { Grid, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { useEffect } from "react";

interface Props {
  barData: any;
}

export default function LanguageTypeData({ barData }: Props) {

  useEffect(() => {
  }, [barData]);

  return (
    <>
      <Grid item xs={12} mb={2}>
        <Grid item xs={12}>
          <Typography>
            Student Users by Setting and Language Specialization
          </Typography>
        </Grid>
        {barData && barData.length > 0 && (
          <BarChart
            dataset={barData}
            colors={["#d1c4e9", "#43a047"]}
            xAxis={[
              {
                scaleType: "band",
                dataKey: "credentialType",
              },
            ]}
            series={[
              {
                dataKey: "nonBilingual",
                label: "None",
              },
              {
                dataKey: "isBilingual",
                label: "Bilingual",
              },
            ]}
            slotProps={{
              legend: {
                direction: "row",
                position: { vertical: "bottom", horizontal: "middle" },
                padding: 0,
              },
            }}
            width={800}
            height={400}
          />
        )}
      </Grid>
      {/* 
      <Grid item xs={12} mb={2}>
        <Grid item xs={12}>
          <Typography>
            Student/Candidate Users by Setting and Language Version
          </Typography>
        </Grid>
        <BarChart
          colors={["#41257b", "#ffac33", "#d7a8df"]}
          xAxis={[
            {
              scaleType: "band",
              data: [
                "Family Child Care",
                "Preschool",
                "Home Visitor",
                "Preschool",
              ],
            },
          ]}
          series={[
            { data: [4, 5, 7, 8], label: "English" },
            { data: [7, 3, 2, 6], label: "Spanish" },
            { data: [9, 1, 4, 2], label: "Mandarin" },
          ]}
          slotProps={{
            legend: {
              direction: "row",
              position: { vertical: "bottom", horizontal: "middle" },
              padding: 0,
            },
          }}
          width={800}
          height={400}
        />
      </Grid> */}
    </>
  );
}
