import { Grid, TextField, Typography } from "@mui/material";
import ReadMoreReadLess from "../../app/common/ReadMoreReadLess";
import { useAppSelector } from "../../app/store/configureStore";
import { AnswersProps } from "../../app/models/Answers";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const PositiveSelf: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();
  const isHomeVisitor = user?.credentialTypeId === 4;
  const firstSystemCodeId = isHomeVisitor ? 174 : 175;

  const defaultValues = {
    firstTextBox: answersData.find(
      (answer) => answer.systemCodeId === firstSystemCodeId
    )?.answer,
  };

  const schema = yup.object().shape({
    firstTextBox: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });
  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const renderSelfConceptSocialEmotionalSkills = () => {
    return (
      <ul>
        <li>{getTranslation("StatementIIIPositiveSelfBulletList1")}</li>
        <li>{getTranslation("StatementIIIPositiveSelfBulletList2")}</li>
        <li>{getTranslation("StatementIIIPositiveSelfBulletList3")}</li>
        <li>{getTranslation("StatementIIIPositiveSelfBulletList4")}</li>
        <li>{getTranslation("StatementIIIPositiveSelfBulletList5")}</li>
        <li>{getTranslation("StatementIIIPositiveSelfBulletList6")}</li>
        <li>{getTranslation("StatementIIIPositiveSelfBulletList7")}</li>
      </ul>
    );
  };

  return (
    <>
      <Grid container bgcolor={"#FFF4F0"} pb={3}>
        <Grid item xs={12} px={3} pt={3}>
          <Typography variant="body1" fontWeight={"bold"} paragraph>
            {getTranslation("StatementIIIPositiveSelfLine1")}
          </Typography>
          <Typography variant="body1" paragraph>
            {user?.credentialTypeId === 4
              ? getTranslation("CSIIIQuestion1HomeVisitor")
              : getTranslation("CSIIIQuestion1")}
          </Typography>
          <ReadMoreReadLess>
            {renderSelfConceptSocialEmotionalSkills()}
          </ReadMoreReadLess>
        </Grid>
        <Grid item xs={12} px={3} pt={3}>
          <TextField
            label={
              user?.credentialTypeId !== 4
                ? getTranslation("StatementIIIPositiveSelfLine2")
                : getTranslation("StatementIIIPositiveSelfLine3")
            }
            multiline
            rows={2}
            fullWidth
            {...register("firstTextBox")}
            name="firstTextBox"
            sx={{ bgcolor: "white" }}
            value={
              answersData.find(
                (answer) => answer.systemCodeId === firstSystemCodeId
              )?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = firstSystemCodeId;

              newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.firstTextBox?.message}
            helperText={errors.firstTextBox?.message}
          />
          <Typography fontSize={13}>
            {defaultValues.firstTextBox?.length}/500
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default PositiveSelf;
