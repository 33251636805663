import {
  Checkbox,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormHelperText,
} from "@mui/material";
import { useAppSelector } from "../../../app/store/configureStore";
import util from "../../../app/utils/utils";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { RegistrationValidationAdminUpdateSchema } from "../../../app/validations/RegisterValidation";
import { useParams } from "react-router-dom";
import agent from "../../../app/api/agent";
import { User } from "../../../app/models/user";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";

export default function EditUser() {
  const { user } = useAppSelector((state) => state.account);
  const [updateUser, setUpdateUser] = useState<User>();
  const { userId } = useParams();
  const { CSIAdminRoles, CouncilAdminRoles, states, credentialTypes } = util;
  const candidateRoles =
    user?.role === "CSI Admin" ? CSIAdminRoles : CouncilAdminRoles;
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors, isValid },
    watch,
    setValue,
  } = useForm<User>({
    resolver: yupResolver(RegistrationValidationAdminUpdateSchema),
    mode: "onTouched",
  });
  const Candidate = "CDA Candidate";
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await agent.Users.GetUserById(Number(userId));
        setUpdateUser(data);
        reset(data);
      } catch (error) {
        // Handle errors if necessary.
      }
    };

    fetchData(); // Call fetchData inside useEffect
  }, [userId, reset]); // Include dependencies in useEffect

  const onSubmit = async (data: User) => {
    agent.Users.updateUser(data)
      .then(() => {
        navigate("/admin/manageusers");
      })
      .catch((error) => console.log(error));
  };

  return (
    <Container component="main" sx={{ padding: 4, marginTop: 2 }}>
      <form
        onSubmit={(e) => {
          e.preventDefault(); // Prevent default form submission
          handleSubmit(onSubmit)();
        }}
        noValidate
      >
        <Grid item>
          <Grid item xs={12}>
            <Typography fontSize={24} fontWeight={700}>
              Edit User
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography>User ID</Typography>
            <TextField
              fullWidth
              disabled
              variant="filled"
              defaultValue={updateUser?.id}
              {...register("id")}
            />
          </Grid>

          <Grid container mt={3} spacing={2}>
            <Grid item xs={12}>
              <Typography fontWeight={700}>Profile</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>User Role </Typography>
              <FormControl fullWidth>
                <Select
                  value={watch("role") || ""}
                  onChange={(e) => {
                    setValue("role", e.target.value);
                  }}
                >
                  {candidateRoles.map((candi) => (
                    <MenuItem key={candi.role} value={candi.role}>
                      {candi.role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <Typography>Council ID</Typography>
              <TextField
                defaultValue={updateUser?.cdaCouncilCandidateId}
                {...register("cdaCouncilCandidateId")}
              />
            </Grid>
          </Grid>
          <Grid container mt={2} spacing={2}>
            <Grid item>
              <Typography>First Name</Typography>
              <TextField
                {...register("firstName")}
                defaultValue={updateUser?.firstName}
                error={!!errors.firstName?.message}
                helperText={errors.firstName?.message}
              />
            </Grid>
            <Grid item>
              <Typography>Last Name</Typography>
              <TextField
                defaultValue={updateUser?.lastName}
                {...register("lastName")}
                error={!!errors.lastName?.message}
                helperText={errors.lastName?.message}
              />
            </Grid>
            <Grid item>
              <Typography>Email Address</Typography>
              <TextField
                defaultValue={updateUser?.email}
                {...register("email")}
                error={!!errors.email?.message}
                helperText={errors.email?.message}
              />
            </Grid>
            <Grid item>
              <Typography>Phone Number</Typography>
              <TextField
                defaultValue={updateUser?.phoneNumber}
                {...register("phoneNumber")}
                error={!!errors.phoneNumber?.message}
                helperText={errors.phoneNumber?.message}
              />
            </Grid>
          </Grid>
          {!watch("role")?.includes("Admin") && (
            <>
              <Grid container mt={2} spacing={2}>
                <Grid item>
                  <Typography>Home Zip Code</Typography>
                  <TextField
                    defaultValue={updateUser?.homeZipCode}
                    {...register("homeZipCode")}
                    error={!!errors.homeZipCode?.message}
                    helperText={errors.homeZipCode?.message}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography>Home State</Typography>
                  <FormControl fullWidth>
                    <Select
                      value={watch("homeState") || ""}
                      onChange={(e) => {
                        setValue("homeState", e.target.value);
                      }}
                    >
                      {states.map((abbr) => (
                        <MenuItem
                          key={abbr.abbreviation}
                          value={abbr.abbreviation}
                        >
                          {abbr.abbreviation}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.homeState && (
                      <FormHelperText>
                        {errors.homeState.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}
          {watch("role") === Candidate && (
            <>
              <Grid container mt={3} spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={700}>Setting</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>Credential Setting</Typography>
                  <FormControl fullWidth>
                    <Select
                      value={watch("credentialTypeId") || 0}
                      onChange={(e) => {
                        setValue("credentialTypeId", e.target.value as number);
                      }}
                    >
                      {credentialTypes.map((cred) => (
                        <MenuItem key={cred.id} value={cred.id}>
                          {cred.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container mt={3} spacing={2}>
                <Grid item xs={4}>
                  <FormControl fullWidth>
                    <Grid container direction="row" alignItems="center">
                      <Grid item>
                        <Typography>Language Specialization</Typography>
                      </Grid>
                      <Grid item>
                        <Checkbox
                          defaultChecked={
                            updateUser?.languageSpecialization || false
                          }
                          {...register("languageSpecialization")}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}
          <Grid container mt={3} spacing={2}>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <Typography>Is Active?</Typography>
                    <FormControl fullWidth>
                      <Select
                        value={watch("isActive") ? "true" : "false"} // Convert boolean to string
                        onChange={(e) => {
                          setValue("isActive", e.target.value === "true");
                        }}
                      >
                        <MenuItem value={"true"}>Active</MenuItem>
                        <MenuItem value={"false"}>Inactive</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
          </Grid>
          <LoadingButton
            loading={isSubmitting}
            disabled={!isValid}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Edit Account
          </LoadingButton>
        </Grid>
      </form>
    </Container>
  );
}
