import "rsuite/dist/rsuite.min.css";
import React from "react";
import { Sidenav, Nav, Divider } from "rsuite";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useMediaQuery, Theme } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { useAppSelector } from "../store/configureStore";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useTranslation } from "../../translation/translate";

interface SideNavigationProps {
  onNavigationItemClick: () => void; // Callback function to notify parent component
}

const SideNavigation: React.FC<SideNavigationProps> = ({
  onNavigationItemClick,
}) => {
  const { user } = useAppSelector((state) => state.account);
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { getTranslation } = useTranslation();

  const handleNavigationItemClick = (path: string): void => {
    navigate(path); // Navigate to the specified path
    if (isMobile) onNavigationItemClick(); // Call the callback function to close the menu
  };

  return (
    <Box sx={{ height: "150vh", display: "flex" }}>
      {user?.role !== "CSI Admin" &&
        user?.role !== "Council Admin" &&
        user?.role !== "Trainer" &&
        user?.role !== "Other Viewer" && (
          <Sidenav>
            <Sidenav.Body>
              <Nav className="side-navigation">
                <Nav.Item
                  eventKey="1"
                  onClick={() =>
                    handleNavigationItemClick("/myprogresssummary")
                  }
                >
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    {getTranslation("MyProgressSummary")}
                  </Typography>
                </Nav.Item>
                <Divider style={{ margin: 6 }} />
                <Nav.Item
                  eventKey="2"
                  onClick={() => handleNavigationItemClick("/cdaeducation")}
                >
                  {getTranslation("MyCDAEducation")}
                </Nav.Item>
                <Nav.Menu eventKey="3" title={getTranslation("CompetencyI")}>
                  <Nav.Item
                    eventKey="3-1"
                    onClick={() =>
                      handleNavigationItemClick(
                        "/competencyi/resourcecollection"
                      )
                    }
                  >
                    {getTranslation("ResourceCollectionI")}
                  </Nav.Item>

                  <Nav.Item
                    eventKey="3-2"
                    onClick={() =>
                      handleNavigationItemClick("/competencyi/statement")
                    }
                  >
                    {getTranslation("CompetencyIStatement")}
                  </Nav.Item>
                </Nav.Menu>
                <Nav.Menu eventKey="4" title={getTranslation("CompetencyII")}>
                  <Nav.Item
                    eventKey="4-1"
                    onClick={() =>
                      handleNavigationItemClick(
                        "/competencyii/resourcecollection"
                      )
                    }
                  >
                    {getTranslation("ResourceCollectionII")}
                  </Nav.Item>

                  <Nav.Item
                    eventKey="4-2"
                    onClick={() =>
                      handleNavigationItemClick("/competencyii/statement")
                    }
                  >
                    {getTranslation("CompetencyIIStatement")}
                  </Nav.Item>
                </Nav.Menu>
                <Nav.Menu eventKey="5" title={getTranslation("CompetencyIII")}>
                  <Nav.Item
                    eventKey="5-1"
                    onClick={() =>
                      handleNavigationItemClick(
                        "/competencyiii/resourcecollection"
                      )
                    }
                  >
                    {getTranslation("ResourceCollectionIII")}
                  </Nav.Item>

                  <Nav.Item
                    eventKey="5-2"
                    onClick={() =>
                      handleNavigationItemClick("/competencyiii/statement")
                    }
                  >
                    {getTranslation("CompetencyIIIStatement")}
                  </Nav.Item>
                </Nav.Menu>
                <Nav.Menu eventKey="6" title={getTranslation("CompetencyIV")}>
                  <Nav.Item
                    eventKey="6-1"
                    onClick={() =>
                      handleNavigationItemClick(
                        "/competencyiv/resourcecollection"
                      )
                    }
                  >
                    {getTranslation("ResourceCollectionIV")}
                  </Nav.Item>

                  <Nav.Item
                    eventKey="6-2"
                    onClick={() =>
                      handleNavigationItemClick("/competencyiv/statement")
                    }
                  >
                    {getTranslation("CompetencyIVStatement")}
                  </Nav.Item>
                </Nav.Menu>
                <Nav.Menu eventKey="7" title={getTranslation("CompetencyV")}>
                  <Nav.Item
                    eventKey="7-1"
                    onClick={() =>
                      handleNavigationItemClick(
                        "/competencyv/resourcecollection"
                      )
                    }
                  >
                    {getTranslation("ResourceCollectionV")}
                  </Nav.Item>

                  <Nav.Item
                    eventKey="7-2"
                    onClick={() =>
                      handleNavigationItemClick("/competencyv/statement")
                    }
                  >
                    {getTranslation("CompetencyVStatement")}
                  </Nav.Item>
                </Nav.Menu>
                <Nav.Menu eventKey="8" title={getTranslation("CompetencyVI")}>
                  <Nav.Item
                    eventKey="8-1"
                    onClick={() =>
                      handleNavigationItemClick(
                        "/competencyvi/resourcecollection"
                      )
                    }
                  >
                    {getTranslation("ResourceCollectionVI")}
                  </Nav.Item>

                  <Nav.Item
                    eventKey="8-2"
                    onClick={() =>
                      handleNavigationItemClick("/competencyvi/statement")
                    }
                  >
                    {getTranslation("CompetencyVIStatement")}
                  </Nav.Item>
                </Nav.Menu>
                <Nav.Item
                  eventKey="9"
                  onClick={() =>
                    handleNavigationItemClick("/familyquestionnairessummary")
                  }
                >
                  {getTranslation("FamilyQuestionnairesSummarySheetAndForms")}
                </Nav.Item>
                <Nav.Item
                  eventKey="10"
                  onClick={() =>
                    handleNavigationItemClick("/myverificationvisit")
                  }
                >
                  {getTranslation("GettingReadyForYourVerificationVisit")}
                </Nav.Item>
                <Nav.Item
                  eventKey="11"
                  onClick={() =>
                    handleNavigationItemClick("/philosophystatement")
                  }
                >
                  {getTranslation("TheProfessionalPhilosophyStatement")}
                </Nav.Item>
                <Nav.Item
                  eventKey="12"
                  onClick={() =>
                    handleNavigationItemClick("/portfoliochecklist")
                  }
                >
                  {getTranslation(
                    "MyCDAProfessionalPortfolioCoverSheetAndChecklist"
                  )}
                </Nav.Item>
                <Nav.Item
                  eventKey="13"
                  onClick={() =>
                    handleNavigationItemClick("/puttingitalltogether")
                  }
                >
                  {getTranslation("PuttingItAllTogether")}
                </Nav.Item>
                <Divider style={{ margin: 6 }} />
                <Nav.Item
                  eventKey="14"
                  onClick={() => handleNavigationItemClick("/downloadupload")}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <HelpIcon sx={{ mr: 1 }} />
                    {getTranslation("HowToUpload&Download")}
                  </Typography>
                </Nav.Item>
                <Nav.Item
                  eventKey="15"
                  onClick={() => handleNavigationItemClick("/myuploads")}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <CloudUploadIcon sx={{ mr: 1 }} />
                    {getTranslation("MyUploads")}
                  </Typography>
                </Nav.Item>
                <Nav.Item
                  eventKey="16"
                  onClick={() => handleNavigationItemClick("/resourcelibrary")}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <LocalLibraryIcon sx={{ mr: 1 }} />
                    {getTranslation("ResourceLibrary")}
                  </Typography>
                </Nav.Item>
                <Nav.Item
                  eventKey="17"
                  onClick={() => handleNavigationItemClick("/help/helpandfaqs")}
                >
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <HelpIcon sx={{ mr: "10px" }} />
                    {getTranslation("Help&FAQs")}
                  </Typography>
                </Nav.Item>
              </Nav>
            </Sidenav.Body>
          </Sidenav>
        )}
      {(user?.role === "CSI Admin" || user?.role === "Council Admin") && (
        <Sidenav>
          <Sidenav.Body>
            <Nav className="side-navigation">
              <Nav.Item
                eventKey="1"
                onClick={() => handleNavigationItemClick("/admin/dashboard")}
              >
                {getTranslation("Dashboard")}
              </Nav.Item>
              <Nav.Item
                eventKey="2"
                onClick={() => handleNavigationItemClick("/admin/reports")}
              >
                {getTranslation("Reports")}
              </Nav.Item>
              <Nav.Item
                eventKey="3"
                onClick={() => handleNavigationItemClick("/admin/manageusers")}
              >
                {getTranslation("ManageUsers")}
              </Nav.Item>
              <Nav.Item
                eventKey="4"
                onClick={() => handleNavigationItemClick("/trainer/classes")}
              >
                {getTranslation("ManageClasses")}
              </Nav.Item>
              <Nav.Item
                eventKey="5"
                onClick={() =>
                  handleNavigationItemClick("/admin/emailtemplate")
                }
              >
                {getTranslation("EmailTemplate")}
              </Nav.Item>
              <Nav.Item
                eventKey="6"
                onClick={() =>
                  handleNavigationItemClick("/admin/translation-editor")
                }
              >
                Spanish Translation
              </Nav.Item>
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      )}
      {user?.role === "Trainer" && (
        <Sidenav>
          <Sidenav.Body>
            <Nav className="side-navigation">
              <Nav.Item
                eventKey="1"
                onClick={() => handleNavigationItemClick("/trainer/dashboard")}
              >
                {getTranslation("Dashboard")}
              </Nav.Item>
              <Nav.Item
                eventKey="2"
                onClick={() => handleNavigationItemClick("/trainer/students")}
              >
                {getTranslation("ManageStudents")}
              </Nav.Item>
              <Nav.Item
                eventKey="3"
                onClick={() => handleNavigationItemClick("/trainer/classes")}
              >
                {getTranslation("ManageClasses")}
              </Nav.Item>
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      )}
      {user?.role === "Other Viewer" && (
        <Sidenav>
          <Sidenav.Body>
            <Nav className="side-navigation"></Nav>
          </Sidenav.Body>
        </Sidenav>
      )}
    </Box>
  );
};

export default SideNavigation;
