import React, { useState } from "react";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Typography, IconButton } from "@mui/material";
import { TranslationItem } from "./translationType";
import EditTranslationPopUp from "./editTranslationItemPopUp";
import { Edit } from "@mui/icons-material";

interface TranslationFormProps {
  allTranslations: TranslationItem[];
  setAllTranslations: (translations: TranslationItem[]) => void;
  upsert: (translationItem: TranslationItem) => void;
}

const TranslationForm: React.FC<TranslationFormProps> = ({
  allTranslations,
  setAllTranslations,
  upsert,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [translationItemToEdit, setTranslationItemToEdit] =
    useState<TranslationItem>();

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      editable: false,
    },
    {
      field: "placeholder",
      headerName: "Placeholder",
      flex: 2,
      renderCell: (params) => <div className="wrap-text">{params.value}</div>,
    },
    {
      field: "en",
      headerName: "English",
      flex: 2,
      renderCell: (params) => <div className="wrap-text">{params.value}</div>,
    },
    {
      field: "es",
      headerName: "Spanish",
      flex: 2,
      renderCell: (params) => <div className="wrap-text">{params.value}</div>,
    },
    {
      field: "_id",
      headerName: "Actions",
      width: 60,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            setOpenModal(true);
            setTranslationItemToEdit(params.row);
          }}
        >
          <Edit />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Typography variant="h5" gutterBottom style={{ marginTop: "20px" }}>
        Current Translations:
      </Typography>
      <DataGrid
        autoHeight
        getRowHeight={() => "auto"}
        rows={allTranslations}
        getRowId={(row) => row.id}
        columns={columns}
        sortModel={[{ field: "id", sort: "desc" }]}
        slots={{ toolbar: GridToolbar }}
        pageSizeOptions={[25, 100]}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />

      <EditTranslationPopUp
        key={`EditTranslationPopUp-${translationItemToEdit?.id}`}
        translationItemToEdit={translationItemToEdit!}
        upsert={upsert}
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
      />
    </>
  );
};

export default TranslationForm;
