import { Grid } from "@mui/material";
import ResourceIVStatus from "./ResourceIVStatus";
import { SummaryCompleteProps } from "../../app/models/SummaryComplete";

import PrintSaveBtns from "../../app/common/PrintSaveBtn";
import LoadingSpinner from "../../app/common/LoadingSpinner";
import agent from "../../app/api/agent";
import { useAppSelector } from "../../app/store/configureStore";
import { Answers } from "../../app/models/Answers";
import IncorporatingHome from "./SIVIncorporatingHome";
import FamilyQuestionnaire from "./SIVFamilyQuestionnaire";
import { useState, useEffect, useCallback } from "react";
import HappeningAtHome from "./SIVHappeningHome";
import KeepingFamilies from "./SIVKeepingFamilies";
import RealtionshipsFamilies from "./SIVRelationshipsFamilies";
import CongratsPopUp from "../../app/common/CongratsPopUp";
import BilingualPopUp from "../../app/common/BilingualPopUp";
import { PDF } from "../../app/models/Pdf";
import createAndOpenPDF from "../../app/utils/pdfutils";
import { useTranslation } from "../../translation/translate";

export default function CompetencyIVStatement() {
  const { user } = useAppSelector((state) => state.account);
  const [pdf, setPdf] = useState<PDF[]>([]);
  const { getTranslation } = useTranslation();
  const [summaryCompleteProps, setSummaryCompleteProps] =
    useState<SummaryCompleteProps>({
      title: getTranslation("CompetencyStatementIVTitle"),
      percent: "",
      header: getTranslation("CompetencyIVResourceCollectionHeader"),
      description: getTranslation("CompetencyStatementIVDescription"),
    });

  const [answers, setAnswers] = useState<Answers[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userProgress, setUserProgress] = useState(0);

  const [bilingualModal, setBilingualModal] = useState(true);
  const handleBilingual = () => {
    setBilingualModal(false);
  };

  const [popUp, setPopUp] = useState(false);
  const handlePopUp = () => {
    setPopUp((prev) => !prev);
  };

  const fetchData = useCallback(async () => {
    try {
      if (user?.credentialName && user?.portfolioId) {
        const response = await agent.Answers.GetAnswers(
          user.credentialName,
          user.portfolioId,
          "CSIV"
        );
        const compData = await agent.Progress.GetCompentencyResultsByType(
          user.portfolioId,
          "CSIV"
        );
        const pdf = await agent.PDFViewer.GetPDFByType(
          user.credentialName,
          user.portfolioId,
          "CSIV"
        );
        setPdf(pdf);
        setUserProgress(compData.statementProgress);
        setSummaryCompleteProps((prevSummaryCompleteProps) => ({
          ...prevSummaryCompleteProps,
          percent: compData.statementProgress + "%",
        }));

        setAnswers(response);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [user?.credentialName, user?.portfolioId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAnswerChange = (systemCodeId: number, newValue: string) => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((answer) =>
        answer.systemCodeId === systemCodeId
          ? { ...answer, answer: newValue }
          : answer
      )
    );
  };

  const handleSaveDraft = async () => {
    setIsLoading(true);
    // This is just a sample function for demonstration
    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );
    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    fetchData();
    setIsLoading(false);
  };
  const handleSave = async () => {
    setIsLoading(true);
    // This is just a sample function for demonstration
    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );
    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    fetchData();
    setIsLoading(false);
    handlePopUp();
  };

  const handlePDF = async () => {
    createAndOpenPDF({
      pdfData: pdf,
      title: getTranslation("CompetencyStatementIVTitle"),
      user: [
        user?.firstName,
        user?.lastName,
        user?.credentialName,
        user?.cdaCouncilCandidateId,
      ],
    });
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container p={2} mb={2}>
      {user?.languageSpecialization && (
        <BilingualPopUp
          isPopUp={bilingualModal}
          btnColor={"warning"}
          handlePopUp={handleBilingual}
        />
      )}
      <ResourceIVStatus {...summaryCompleteProps} />
      <RealtionshipsFamilies
        answersData={answers}
        onAnswerChange={handleAnswerChange}
      />
      <KeepingFamilies
        answersData={answers}
        onAnswerChange={handleAnswerChange}
      />
      <HappeningAtHome
        answersData={answers}
        onAnswerChange={handleAnswerChange}
      />
      <IncorporatingHome
        answersData={answers}
        onAnswerChange={handleAnswerChange}
      />
      <FamilyQuestionnaire
        answersData={answers}
        onAnswerChange={handleAnswerChange}
      />
      <PrintSaveBtns
        btnColor={"warning"}
        onSave={handleSave}
        onDraft={handleSaveDraft}
        onPreview={handlePDF}
        Progress={userProgress}
        {...summaryCompleteProps}
      />
      <CongratsPopUp
        handlePopUp={handlePopUp}
        isPopUp={popUp}
        btnColor={"warning"}
        background={"warning.light"}
        description={getTranslation("CompetencyStatementIVDescriptionPopUp")}
      />
    </Grid>
  );
}
