import { Typography, Grid, TextField, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useAppSelector } from "../../app/store/configureStore";
import { LoadingAnswersProps } from "../../app/models/Answers";
import RCI3ValidationSchema from "../../app/validations/RCI3Validations";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const Resource1StateChildAbuse: React.FC<LoadingAnswersProps> = ({
  answersData,
  onAnswerChange,
  onIsSubmittingChange,
  onIsValidChange,
}) => {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();

  const defaultValues = {
    name:
      answersData.find((answer) => answer.systemCodeId === 10)?.answer || "",
    telephone:
      answersData.find((answer) => answer.systemCodeId === 11)?.answer || "",
    website:
      answersData.find((answer) => answer.systemCodeId === 12)?.answer || "",
    stateLegal:
      answersData.find((answer) => answer.systemCodeId === 13)?.answer || "",
    responsibilityReporting:
      answersData.find((answer) => answer.systemCodeId === 14)?.answer || "",
    stateLegalBilingual:
      answersData.find((answer) => answer.systemCodeId === 20)?.answer || "",
    responsibilityReportingBiligual:
      answersData.find((answer) => answer.systemCodeId === 21)?.answer || "",
  };
  const validationSchema = RCI3ValidationSchema({
    languageSpecialization: !!user?.languageSpecialization,
  });

  const {
    register,
    formState: { isSubmitting, errors, isValid },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
  });

  // Update isSubmitting in the parent component when it changes
  onIsSubmittingChange(isSubmitting);

  // Update isLoading in the parent component when it changes (if applicable)
  onIsValidChange(isValid);

  const handleNumberInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const numericInput = input.replace(/\D/g, ""); // Remove non-numeric characters
    event.target.value = numericInput;
    const newValue = event.target.value;
    const systemCodeId = 11;
    onAnswerChange(systemCodeId, newValue);
  };

  return (
    <>
      <Grid item xs={12} px={3} pt={3}>
        <Typography variant="body1" fontWeight="bold" paragraph>
          {getTranslation("RCI3StateChildTitle")}
        </Typography>

        <Typography variant="body1" paragraph>
          {getTranslation("RCI3StateChildSubTitle")}
          {user?.languageSpecialization &&
            getTranslation("RCI3StateChildMustProvide")}
        </Typography>
        <Typography variant="body1" fontWeight="bold">
          {getTranslation("RCVIChildAbuseLine5")}
        </Typography>
      </Grid>
      <Box component="form">
        <Grid container spacing={2} px={3}>
          <Grid item sm={6} xs={12}>
            <TextField
              label={getTranslation("Name")}
              {...register("name")}
              value={
                answersData.find((answer) => answer.systemCodeId === 10)
                  ?.answer || ""
              }
              onChange={(e) => {
                const newValue = e.target.value;
                const systemCodeId = 10;

                onAnswerChange(systemCodeId, newValue);
              }}
              fullWidth
              error={!!errors.name?.message}
              helperText={errors.name?.message}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              label={getTranslation("Telephone")}
              {...register("telephone")}
              value={
                answersData.find((answer) => answer.systemCodeId === 11)
                  ?.answer || ""
              }
              onChange={handleNumberInput}
              fullWidth
              error={!!errors.telephone?.message}
              helperText={errors.telephone?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid item sm={6} xs={12}>
              <TextField
                label={getTranslation("Website")}
                {...register("website")}
                placeholder="https://www.website.com/"
                value={
                  answersData.find((answer) => answer.systemCodeId === 12)
                    ?.answer || ""
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  const systemCodeId = 12;

                  onAnswerChange(systemCodeId, newValue);
                }}
                fullWidth
                error={!!errors.website?.message}
                helperText={errors.website?.message}
              />
            </Grid>
          </Grid>
          <Grid item md={user?.languageSpecialization ? 6 : 12} sm={12}>
            <Typography variant="body1" fontWeight="bold" paragraph>
              {getTranslation("RCVIChildAbuseLine6")}
            </Typography>
            <Typography>{getTranslation("RCVIChildAbuseLine7")}</Typography>
            <TextField
              helperText={errors.stateLegal?.message}
              multiline
              rows={4}
              {...register("stateLegal")}
              value={
                answersData.find((answer) => answer.systemCodeId === 13)
                  ?.answer || ""
              }
              onChange={(e) => {
                const newValue = e.target.value;
                const systemCodeId = 13;
                if (newValue.length <= 1000) {
                  onAnswerChange(systemCodeId, newValue);
                }
              }}
              fullWidth
              error={!!errors.stateLegal?.message}
            />
            <Typography fontSize={13}>
              {defaultValues.stateLegal?.length}/1000
            </Typography>
          </Grid>
          {user?.languageSpecialization && (
            <Grid item md={6} sm={12}>
              <Typography variant="body1" fontWeight="bold" paragraph>
                ({getTranslation("RespondSecondLanguage")})
              </Typography>
              <Typography>{getTranslation("RCVIChildAbuseLine7")}</Typography>
              <TextField
                placeholder=""
                multiline
                rows={4}
                {...register("stateLegalBilingual")}
                value={
                  answersData.find((answer) => answer.systemCodeId === 20)
                    ?.answer || ""
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  const systemCodeId = 20;

                  if (newValue.length <= 1000) {
                    onAnswerChange(systemCodeId, newValue);
                  }
                }}
                fullWidth
                error={!!errors.stateLegalBilingual?.message}
                helperText={errors.stateLegalBilingual?.message}
              />
              <Typography fontSize={13}>
                {defaultValues.stateLegalBilingual?.length}/1000
              </Typography>
            </Grid>
          )}
          <Grid item md={user?.languageSpecialization ? 6 : 12} sm={12}>
            <Typography variant="body1" fontWeight="bold" paragraph>
              {getTranslation("ResponseiblityReporting")}
            </Typography>
            <Typography>{getTranslation("ResponseiblityReporting")}</Typography>
            <TextField
              placeholder={getTranslation("Whatisyourprogramspolicyexample")}
              multiline
              rows={4}
              {...register("responsibilityReporting")}
              value={
                answersData.find((answer) => answer.systemCodeId === 14)
                  ?.answer || ""
              }
              onChange={(e) => {
                const newValue = e.target.value;
                const systemCodeId = 14;

                if (newValue.length <= 1000) {
                  onAnswerChange(systemCodeId, newValue);
                }
              }}
              fullWidth
              error={!!errors.responsibilityReporting?.message}
              helperText={errors.responsibilityReporting?.message}
            />
            <Typography fontSize={13}>
              {defaultValues.responsibilityReporting?.length}/1000
            </Typography>
          </Grid>
          {user?.languageSpecialization && (
            <Grid item md={6} sm={12}>
              <Typography variant="body1" fontWeight="bold" paragraph>
                ({getTranslation("RespondSecondLanguage")})
              </Typography>
              <Typography>
                {getTranslation("ResponseiblityReporting")}
              </Typography>
              <TextField
                multiline
                rows={4}
                {...register("responsibilityReportingBiligual")}
                value={
                  answersData.find((answer) => answer.systemCodeId === 21)
                    ?.answer || ""
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  const systemCodeId = 21;

                  if (newValue.length <= 1000) {
                    onAnswerChange(systemCodeId, newValue);
                  }
                }}
                fullWidth
                error={!!errors.responsibilityReportingBiligual?.message}
                helperText={errors.responsibilityReportingBiligual?.message}
              />
              <Typography fontSize={13}>
                {defaultValues.responsibilityReportingBiligual?.length}/1000
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default Resource1StateChildAbuse;
