import { Grid, TextField, Theme, useMediaQuery } from "@mui/material";
import { useForm } from "react-hook-form";
import { ResourceCollectionIVAnswersProps } from "../../app/models/Answers";
import DraftAndSubmitBtns from "../../app/common/SaveDraftSubmitBtn";
import { yupResolver } from "@hookform/resolvers/yup";
import schemaRCIV from "../../app/validations/RC4Validations";
import { useTranslation } from "../../translation/translate";

const NonEnglishServices: React.FC<ResourceCollectionIVAnswersProps> = ({
  answersData,
  onAnswerChange,
  onSave,
  ResourceCollectionIVCodes,
}) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { getTranslation } = useTranslation();
  const gridStyle1 = {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
  };
  const textStyle1 = {
    mr: 2,
    mb: isMobile ? 2 : 0,
  };

  const defaultValues = {
    name: answersData.find(
      (answer) => answer.systemCodeId === ResourceCollectionIVCodes.nameCode
    )?.answer,
    address: answersData.find(
      (answer) => answer.systemCodeId === ResourceCollectionIVCodes.addressCode
    )?.answer,
    phone: answersData.find(
      (answer) =>
        answer.systemCodeId === ResourceCollectionIVCodes.telephoneCode
    )?.answer,
    email: answersData.find(
      (answer) => answer.systemCodeId === ResourceCollectionIVCodes.emailCode
    )?.answer,
    website: answersData.find(
      (answer) => answer.systemCodeId === ResourceCollectionIVCodes.websiteCode
    )?.answer,
  };

  const validationSchema = schemaRCIV.RCIVSchema();

  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
  });

  const handleNumberInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const numericInput = input.replace(/\D/g, ""); // Remove non-numeric characters
    event.target.value = numericInput;
    const newValue = event.target.value;
    const systemCodeId = ResourceCollectionIVCodes.telephoneCode;
    onAnswerChange(systemCodeId, newValue);
  };

  return (
    <Grid
      container
      display="flex"
      flexDirection={isMobile ? "column" : "row"}
      justifyContent="space-between"
      spacing={2}
    >
      <Grid item container xs={12} spacing={4}>
        <Grid item xs={12} md={12} sx={gridStyle1}>
          <TextField
            label={getTranslation("Name_Translation_Services_" + ResourceCollectionIVCodes.nameCode)} 
            {...register("name")}
            value={
              answersData.find(
                (answer) =>
                  answer.systemCodeId === ResourceCollectionIVCodes.nameCode
              )?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = ResourceCollectionIVCodes.nameCode;
              onAnswerChange(systemCodeId, newValue);
            }}
            fullWidth
            error={!!errors.name?.message}
            helperText={errors.name?.message}
          />
        </Grid>
        <Grid item xs={12} sx={gridStyle1}>
          <TextField
            sx={textStyle1}
            label={getTranslation("Address_Translation_Services_" + ResourceCollectionIVCodes.addressCode)}
            {...register("address")}
            value={
              answersData.find(
                (answer) =>
                  answer.systemCodeId === ResourceCollectionIVCodes.addressCode
              )?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = ResourceCollectionIVCodes.addressCode;
              onAnswerChange(systemCodeId, newValue);
            }}
            fullWidth
            error={!!errors.address?.message}
            helperText={errors.address?.message}
          />
          <TextField
            label={getTranslation("Telephone_Translation_Services_" + ResourceCollectionIVCodes.telephoneCode)}
            {...register("phone")}
            value={
              answersData.find(
                (answer) =>
                  answer.systemCodeId ===
                  ResourceCollectionIVCodes.telephoneCode
              )?.answer || ""
            }
            error={!!errors.phone?.message}
            helperText={errors.phone?.message}
            onChange={handleNumberInput}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sx={gridStyle1}>
          <TextField
            sx={textStyle1}
            label={getTranslation("Email_Translation_Services_" + ResourceCollectionIVCodes.emailCode)}
            {...register("email")}
            value={
              answersData.find(
                (answer) =>
                  answer.systemCodeId === ResourceCollectionIVCodes.emailCode
              )?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = ResourceCollectionIVCodes.emailCode;
              onAnswerChange(systemCodeId, newValue);
            }}
            fullWidth
            error={!!errors.email?.message}
            helperText={errors.email?.message}
          />

          <TextField
            label={getTranslation("Website_Translation_Services_" + ResourceCollectionIVCodes.websiteCode)}
            {...register("website")}
            placeholder="https://www.website.com/"
            value={
              answersData.find(
                (answer) =>
                  answer.systemCodeId === ResourceCollectionIVCodes.websiteCode
              )?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = ResourceCollectionIVCodes.websiteCode;
              onAnswerChange(systemCodeId, newValue);
            }}
            fullWidth
            error={!!errors.website?.message}
            helperText={errors.website?.message}
          />
        </Grid>
      </Grid>

      <DraftAndSubmitBtns onSave={onSave} />
    </Grid>
  );
};
export default NonEnglishServices;
