import { Alert, Box, Button, Grid, Typography, styled } from "@mui/material";
import HorizontalStepper from "../../app/common/HorizontalStepper";
import teacher from "../../assets/images/teacher-and-laptop.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import agent from "../../app/api/agent";
import { CredentialFormData } from "../../app/models/Credentials";
import LoadingSpinner from "../../app/common/LoadingSpinner";
import { useAppSelector } from "../../app/store/configureStore";
import { useTranslation } from "../../translation/translate";

export default function HowToVideo() {
  const [isLoading, setIsLoading] = useState(true);
  //const [videoEnded, setVideoEnded] = useState(false);
  const [data, setData] = useState<CredentialFormData | null>(null);
  const { getTranslation } = useTranslation();
  const { user } = useAppSelector((state) => state.account);

  const steps = [
    getTranslation("CDABookStep1"),
    getTranslation("CDABookStep2"),
    getTranslation("CDABookStep3"),
  ];

  const navigate = useNavigate();

  const handleClick = async () => {
    setIsLoading(true);

    try {
      const updatedData = { ...data, welcomeComplete: true };
      await agent.Welcome.UpdateWelcomeComplete(
        updatedData as CredentialFormData
      );
      navigate("/myprogresssummary");
    } catch {
      console.error();
      setIsLoading(false);
    }
  };
  // const handleVideoEnd = () => {
  //   setVideoEnded(true);
  // };
  const CustomAlert = styled(Alert)(({ theme }) => ({
    backgroundColor: "#7DA740",
    color: "white",
    "& .MuiAlert-icon": {
      color: "white", // Set the icon color to white
    },
  }));

  const fetchData = async () => {
    await agent.Account.currentUser().then((data) => {
      if (data?.welcomeComplete === true) return navigate("/myprogresssummary");
      setData(data);
    });
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, [navigate]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box>
      <CustomAlert severity="info">
        Your {data?.credentialName} credential selection (
        {data?.languageSpecialization ? "with" : "without"} a Bilingual
        Specialization) has been saved!
      </CustomAlert>
      <HorizontalStepper activeStep={2} steps={steps} />
      <Grid container>
        {/*  <Grid item xs={12} md={6} p={2} >
          <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <video width="100%" height="auto" controls onEnded={handleVideoEnd}>
              <source src={require('../../assets/videos/sample.mp4')} type="video/mp4" />
              Your browser does not support the video tag.
            </video>

          </Box>
            <Button
              disabled={!videoEnded}
              onClick={handleClick}
              variant="contained"
              sx={{ textTransform: "none", mt: 2 }}
            >
              Start my CDA Professional Portfolio
            </Button>
        </Grid> */}
        <Grid item xs={12} md={6} p={2}>
          <Typography mb={2}>{getTranslation("HowToVideoLine1")}</Typography>
          <img
            src={teacher}
            alt="teacher and laptop"
            style={{
              maxWidth: "80%",
              height: "auto",
            }}
          />
          <Button
            onClick={handleClick}
            variant="contained"
            sx={{ textTransform: "none", mt: 2 }}
          >
            {getTranslation("HowToVideoLine2")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
