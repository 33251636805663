import * as yup from "yup";

const ChangePasswordValidtionSchema = yup.object().shape({
  password: yup
    .string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)(?=.*[^\s]).*$/,
      'Password must have at least 1 uppercase, 1 lowercase, 1 number, 1 non-alphanumeric character'
    ),
  confirmPassword: yup.string().oneOf([yup.ref("password"), ""], "Passwords must match").required("Confirm Password is required."),
});

export default ChangePasswordValidtionSchema;
