import { ExpandCircleDown } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import EDUupload from "./EDUupload";
import { useTranslation } from "../../translation/translate";

export default function CdaEduUpload() {
  const { getTranslation } = useTranslation();
  return (
    <Grid item xs={12} mt={3}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandCircleDown sx={{ color: "myVerify.main" }} />}
        >
          <Typography fontWeight={700} fontSize={18}>
            {getTranslation("My_CDA_Education")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <EDUupload />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
