import { Box } from "@mui/material";
import Spinner from "../../assets/images/flow.gif";

export default function LoadingSpinner() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh" 
    >
      <img
        src={Spinner}
        alt="Loading......."
        style={{ maxWidth: "100%", height: "auto" }}
      />
    </Box>
  );
}
