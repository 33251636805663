import { Button, Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { BookForm } from "../../app/models/BookForm";
import NewBook from "./NewBook";
import BooksArr from "./BooksArr";
import agent from "../../app/api/agent";
import { useAppSelector } from "../../app/store/configureStore";
import CongratsPopUp from "../../app/common/CongratsPopUp";
import { FilesProps } from "../../app/models/Answers";
import { useTranslation } from "../../translation/translate";

const AddaBook: React.FC<FilesProps> = ({ RecallData }) => {
  const [bookData, setBookData] = useState<BookForm[]>([]);
  const { getTranslation } = useTranslation();

  const { user } = useAppSelector((state) => state.account);
  const [testArr, setTestArr] = useState<BookForm[]>([]);
  const [showDiv, setShowDiv] = useState(false);
  const [popUp, setPopUp] = useState(true);
  const [editID, setEditID] = useState<string>("");
  const [editTitle, setEditTitle] = useState<string>("");
  const [editAuthor, setEditAuthor] = useState<string>("");
  const [editPublisher, setEditPublisher] = useState<string>("");
  const [editCopyright, setEditCopyright] = useState<number>(0);
  const [editSummary, setEditSummary] = useState<string>("");
  const [editSummaryBL, setEditSummaryBL] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean | null>(null);

  const fetchData = useCallback(async () => {
    if (user && user.portfolioId) {
      const data = await agent.Books.GetBooks(user.portfolioId);
      setTestArr(data);
    }
  }, [user]);

  useEffect(() => {
    fetchData(); // Initial data fetch when the component mounts
  }, [user, fetchData, bookData]); // Fetch data when user changes

  const handleShowDiv = () => {
    setShowDiv((prev) => !prev);
  };

  const onSubmit: SubmitHandler<BookForm> = async (data) => {
    if (user && user.portfolioId) {
      await agent.Books.PostBooks(user?.portfolioId, data);
    }
    setBookData([...bookData, data]);
    setIsEdit(false);
    handleShowDiv();
    fetchData();
    RecallData();
  };

  const handleRemove = (id: any) => {
    const newList = testArr.filter((item) => item.id !== id);
    setTestArr(newList);
    agent.Books.DeleteBooks(id);
    RecallData();
  };

  const handleEdit = (id: any) => {
    setIsEdit(true);
    const newList = testArr.find((item) => item.id === id);
    if (showDiv === false) {
      const newList = testArr.filter((item) => item.id !== id);
      setTestArr(newList);
    }
    if (newList) {
      setEditID(newList.id);
      setEditTitle(newList.title);
      setEditAuthor(newList.author);
      setEditPublisher(newList.publisher);
      setEditCopyright(newList.copyright);
      setEditSummary(newList.summary);
      setEditSummaryBL(newList.summary2ndLanguage);
      handleShowDiv();
    }
    RecallData();
  };

  const counter = (id: any) => {
    const arr = testArr
      .map((item) => {
        return item.id;
      })
      .indexOf(id);
    return arr + 1;
  };

  const handleHideAdd = () => {
    if (testArr.length === 10) {
      return "none";
    } else {
      return "flex";
    }
  };
  const closeDiv = () => {
    fetchData();
    setShowDiv(false);
  };

  const handlePopUp = async () => {
    setPopUp((prev) => !prev);
  };

  const resetNewBook = () => {
    setEditTitle("");
    setEditAuthor("");
    setEditPublisher("");
    setEditCopyright(0);
    setEditSummary("");
    setEditSummaryBL("");
  };
  return (
    <Grid item xs={12}>
      <BooksArr
        handleRemove={handleRemove}
        counter={counter}
        handleEdit={handleEdit}
        bookData={testArr}
        setBookData={setBookData}
      />
      {testArr.length === 10 && (
        <CongratsPopUp
          handlePopUp={handlePopUp}
          isPopUp={popUp}
          btnColor={"comp3"}
          background={"comp3.light"}
          description={getTranslation("ResourceIIIAddNewBookLine1")}
        />
      )}
      {showDiv && (
        <NewBook
          onSubmit={onSubmit}
          showDiv={showDiv}
          closeDiv={closeDiv}
          isEdit={isEdit}
          editID={editID}
          editTitle={editTitle}
          setEditTitle={setEditTitle}
          editAuthor={editAuthor}
          setEditAuthor={setEditAuthor}
          editCopyright={editCopyright}
          setEditCopyright={setEditCopyright}
          editPublisher={editPublisher}
          setEditPublisher={setEditPublisher}
          editSummary={editSummary}
          setEditSummary={setEditSummary}
          editSummaryBL={editSummaryBL}
          setEditSummaryBL={setEditSummaryBL}
        />
      )}

      {!isEdit && (
        <Grid item xs={12} display={handleHideAdd} justifyContent="center">
          <Button
            color="inherit"
            onClick={() => {
              handleShowDiv();
              resetNewBook();
              setIsEdit(false);
            }}
          >
            {showDiv
              ? getTranslation("Cancel")
              : getTranslation("ResourceIIIAddNewBookLine2")}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default AddaBook;
