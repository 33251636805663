import { Grid, TextField, Typography } from "@mui/material";
import { AnswersProps } from "../../app/models/Answers";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const PositiveBehaviors: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const defaultValues = {
    firstTextBox: answersData.find((answer) => answer.systemCodeId === 176)
      ?.answer,
    secondTextBox: answersData.find((answer) => answer.systemCodeId === 177)
      ?.answer,
  };
  const { getTranslation } = useTranslation();
  const schema = yup.object().shape({
    firstTextBox: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    secondTextBox: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });
  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  return (
    <>
      <Grid container pb={3}>
        <Grid item xs={12} px={3} pt={3}>
          <Typography variant="body1" fontWeight={"bold"} paragraph>
            {getTranslation("StatementIIIPositiveBehaviorsLine1")}
          </Typography>
          <Typography variant="body1" paragraph>
            {getTranslation("StatementIIIPositiveBehaviorsLine2")}
          </Typography>
          <Typography variant="body1" paragraph>
            {getTranslation("StatementIIIPositiveBehaviorsLine3")}
          </Typography>
        </Grid>
        <Grid item xs={12} px={3} pt={3}>
          <TextField
            label={getTranslation("StatementIIIPositiveBehaviorsLine4")}
            multiline
            rows={2}
            fullWidth
            {...register("firstTextBox")}
            name="firstTextBox"
            sx={{ bgcolor: "white" }}
            value={
              answersData.find((answer) => answer.systemCodeId === 176)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 176;

              newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.firstTextBox?.message}
            helperText={errors.firstTextBox?.message}
          />
          <Typography fontSize={13}>
            {defaultValues.firstTextBox?.length}/500
          </Typography>
        </Grid>
        <Grid item xs={12} px={3} pt={3}>
          <Typography variant="body1" paragraph>
            {getTranslation("StatementIIIPositiveBehaviorsLine5")}
          </Typography>
        </Grid>
        <Grid item xs={12} px={3} pt={3}>
          <TextField
            label={getTranslation("StatementIIIPositiveBehaviorsLine6")}
            multiline
            rows={2}
            fullWidth
            {...register("secondTextBox")}
            name="secondTextBox"
            sx={{ bgcolor: "white" }}
            value={
              answersData.find((answer) => answer.systemCodeId === 177)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 177;

              newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.secondTextBox?.message}
            helperText={errors.secondTextBox?.message}
          />
          <Typography fontSize={13}>
            {defaultValues.secondTextBox?.length}/500
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default PositiveBehaviors;
