import * as yup from "yup";

const RegistrationValidationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  email: yup.string().email().required("Email is required."),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters.")
    .max(20, "Password less than 20 characters.")
    .required("Password is required.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)(?=.*[^\s]).*$/,
      "Password must have at least 1 uppercase, 1 lowercase, 1 number, 1 non-alphanumeric character."
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), ""], "Passwords must match.")
    .required("Confirm Password is required."),
  phoneNumber: yup.string().required("Phone Number is required."),
  homeZipCode: yup.string().required("Home Zip Code is required."),
  homeState: yup.string().required("Home State is required."),
  cdaCouncilCandidateId: yup.string().nullable(),
  role: yup.string().required("Role is required."),
});

const RegistrationValidationAdminAddSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  email: yup.string().email().required("Email is required."),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters.")
    .max(20, "Password less than 20 characters.")
    .required("Password is required.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)(?=.*[^\s]).*$/,
      "Password must have at least 1 uppercase, 1 lowercase, 1 number, 1 non-alphanumeric character."
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), ""], "Passwords must match.")
    .required("Confirm Password is required."),
  phoneNumber: yup.string().required("Phone Number is required."),
  cdaCouncilCandidateId: yup.string().nullable(),
  role: yup.string().required("Role is required."),
});

const RegistrationValidationAdminUpdateSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  email: yup.string().email().required("Email is required."),
  phoneNumber: yup.string().required("Phone Number is required."),
  cdaCouncilCandidateId: yup.string().nullable(),
  role: yup.string().required("Role is required."),
});

export {
  RegistrationValidationAdminAddSchema,
  RegistrationValidationSchema,
  RegistrationValidationAdminUpdateSchema,
};
