import { PrintSharp } from "@mui/icons-material";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import agent from "../../../app/api/agent";
import ProgressBarData from "./ProgressBarData";
import LanguageTypeData from "./LanguageTypeData";
import LoadingSpinner from "../../../app/common/LoadingSpinner";

export default function Dashboard() {
  const [newDateValue, setNewDateValue] = useState<Dayjs | null>();
  const [totalUsers, setTotalUsers] = useState<string | number>();
  const [isLoading, setIsLoading] = useState(true);
  const [barUserData, setBarUserData] = useState();
  const [typeUserData, setTypeUserData] = useState();
  const fetchData = async () => {
    try {
      const endDate = newDateValue?.toString() || dayjs().toString();
      const data = await agent.Admin.GetAllUsersWithProgress(endDate);
      const barData = await agent.Progress.GetUserProgress(endDate);
      const typeData = await agent.Progress.GetUserBiLingualProgress(endDate);

      const filteredData = data.filter(
        (user: { role: string }) => user.role === "CDA Candidate"
      );
      setTotalUsers(filteredData.length);
      setBarUserData(barData);
      setTypeUserData(typeData);
    } catch (error) {
      // Handle errors if necessary.
      console.error();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [newDateValue]);

  const handlePrintData = () => {
    window.print();
  };

  const handleApplyButtonClick = () => {
    setIsLoading(true);
    fetchData();
  };
  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container p={4}>
      <Grid item container>
        <Grid
          item
          xs={12}
          component="div"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography fontSize={24} fontWeight={700}>
            Dashboard
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => handlePrintData()}
          >
            <PrintSharp /> Print
          </Button>
        </Grid>
        <Grid item xs={6} md={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disableFuture
              label="Date"
              value={newDateValue}
              views={["year", "month", "day"]}
              onChange={(e) => setNewDateValue(e)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2} md={1} ml={2}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleApplyButtonClick}
          >
            Apply
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography>Total Student/Candidate: {totalUsers} </Typography>
        </Grid>
      </Grid>
      <Grid container p={2} spacing={5}>
        <ProgressBarData barData={barUserData} />
        <LanguageTypeData barData={typeUserData} />
      </Grid>
    </Grid>
  );
}
