import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useAppSelector } from "../../app/store/configureStore";
import EFormTable from "./EForm";
import { useCallback, useEffect, useState } from "react";
import { Answers } from "../../app/models/Answers";
import agent from "../../app/api/agent";
import LoadingSpinner from "../../app/common/LoadingSpinner";
import PrintSaveBtns from "../../app/common/PrintSaveBtn";
import { useTranslation } from "../../translation/translate";

export default function EducationEdoc() {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { user } = useAppSelector((state) => state.account);
  const [answers, setAnswers] = useState<Answers[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { getTranslation } = useTranslation();
  function toTitleCase(str: string) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const fetchData = useCallback(async () => {
    try {
      if (user?.credentialName && user?.portfolioId) {
        const response = await agent.Answers.GetAnswers(
          user.credentialName,
          user.portfolioId,
          "EducationSummary"
        );

        setAnswers(response);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [user?.credentialName, user?.portfolioId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAnswerChange = (systemCodeId: number, newValue: string) => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((answer) =>
        answer.systemCodeId === systemCodeId
          ? { ...answer, answer: newValue }
          : answer
      )
    );
  };

  const handleSaveDraft = async () => {
    setIsLoading(true);
    // This is just a sample function for demonstration
    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );
    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    fetchData();
    setIsLoading(false);
  };
  const handleSave = async () => {
    setIsLoading(true);
    // This is just a sample function for demonstration
    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );
    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    fetchData();
    setIsLoading(false);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container p={2} m={1} className="cavas1">
      <Button href="/cdaeducation" startIcon={<ArrowBackIosNewIcon />}>
        {getTranslation("SummaryofMyCDAEducationBackBtn")}
      </Button>
      <Card
        sx={{
          width: "100%",
          backgroundColor: "cdaEDU.light",
          boxShadow: "0 16px #7DA740",
          marginBottom: 4,
          marginTop: 2,
        }}
      >
        <CardContent>
          <Grid
            item
            xs={10}
            sm={10}
            md={11}
            display="block"
            flexDirection="column"
            paddingLeft={3}
          >
            <Typography variant="h6" fontWeight={700} marginBottom={1}>
              {getTranslation("OnlineForm")}
            </Typography>
            <Typography display={isMobile ? "none" : "show"} marginBottom={1}>
              <span style={{ fontWeight: 700 }}>
                {getTranslation("NotetoCandidate")}:{" "}
              </span>
              {getTranslation("MYCDAEducationDescriptionpart1")}{" "}
              <span style={{ fontStyle: "italic" }}>
                {getTranslation("MYCDAEducationDescriptionpart2")}
              </span>{" "}
              {getTranslation("MYCDAEducationDescriptionpart3")}
            </Typography>
          </Grid>
        </CardContent>
      </Card>
      <Grid item container p={2} spacing={2}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={18}>
            {getTranslation("MYCDAEducationTitle1")}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={toTitleCase(getTranslation("FirstName"))}
            defaultValue={user?.firstName}
            variant="filled"
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={getTranslation("LastName")}
            defaultValue={user?.lastName}
            variant="filled"
            disabled
            fullWidth
          />
        </Grid>
        <Grid item mt={2} xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            {getTranslation("MYCDAEducationTitle2")}
          </Typography>
          <Typography>{getTranslation("MYCDAEducationSubTitle")}</Typography>
        </Grid>
        <EFormTable answersData={answers} onAnswerChange={handleAnswerChange} />
        <PrintSaveBtns
          btnColor={"cdaEDU"}
          title={"OnlineForm"}
          onSave={handleSave}
          onDraft={handleSaveDraft}
        />
      </Grid>
    </Grid>
  );
}
