import { Grid, ImageList, ImageListItem, Typography } from "@mui/material";
import preschool from "../../assets/images/Preschool.jpg";
import infant from "../../assets/images/InfantToddler.jpg";
import family from "../../assets/images/Family-Child-Care.jpg";
import home from "../../assets/images/SP_Home-Visitor.jpg";
import { useAppSelector } from "../../app/store/configureStore";

export default function Books() {
  const images = [
    {
      img: infant,
      title: "Infant-Toddler Edition",
      spanishTitle: "Edición Infantil-Toddlers",
    },
    {
      img: preschool,
      title: "Preschool",
      spanishTitle: "Preescolar",
    },
    {
      img: family,
      title: "Family Child Care",
      spanishTitle: "Cuidado Infantil Familiar",
    },
    {
      img: home,
      title: "Home Visitor",
      spanishTitle: "Visitante Domiciliario",
    },
  ];
  const { user } = useAppSelector((state) => state.account);

  return (
    <ImageList sx={{ width: "100%", maxWidth: "350px" }} cols={2} gap={26}>
      {images.map((image) => {
        return (
          <Grid key={image.title}>
            <Typography>{image.title}</Typography>
            <ImageListItem key={image.title}>
              <img
                src={image.img}
                alt={user?.isSpanish ? image.spanishTitle : image.title}
                loading="lazy"
              />
            </ImageListItem>
          </Grid>
        );
      })}
    </ImageList>
  );
}
