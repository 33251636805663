import { Button, Grid, Modal, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../../app/common/LoadingSpinner";
import agent from "../../../app/api/agent";
import AddNewClassModal from "./addNewClassModal";
import { useAppSelector } from "../../../app/store/configureStore";
import ClassListTable from "./classTable";
import { useTranslation } from "../../../translation/translate";

export default function ManageClasses() {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();
  const [rows, setRows] = useState<any | []>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    if (user) {
      try {
        const getClass = await agent.TrainerClasses.GetTrainerClassList(
          user.id
        );
        setRows(getClass);
      } catch {
        console.error();
      }
      setIsLoading(false);
    }
  };

  const handleAddNewClass = async (data: any) => {
    if (user) {
      const classContent = {
        name: data.name,
        drescription: data?.drescription,
        instructorId: user.id,
        startDate: data?.startDate ?? new Date(),
        endDate: data.endDate,
        active: true,
        createDate: new Date(),
      };
      try {
        await agent.TrainerClasses.CreateNewClass(classContent);
      } catch {
        console.error();
      }
      fetchData();
    }
  };

  const handleRemoveClass = async (classId: number) => {
    if (user) {
      setIsLoading(true);
      const remove = {
        trainingClassId: classId,
        instructorId: user.id,
      };
      await agent.TrainerClasses.RemoveClass(remove);
    }
    fetchData();
  };

  useEffect(() => {
    fetchData();
    setIsLoading(false);
  }, []);

  const handleClick = () => {
    setIsOpen(false);
    setIsLoading(true);
    fetchData();
  };
  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container p={2} m={2}>
      <Grid item xs={12} display="flex" justifyContent="space-between">
        <Typography fontSize={24} fontWeight={700}>
          {getTranslation("ManageClasses")}
        </Typography>

        <Button
          variant="outlined"
          onClick={() => setIsOpen(true)}
          sx={{ marginTop: 2 }}
        >
          {getTranslation("AddClass")}
        </Button>
      </Grid>
      <Grid item xs={8} display="flex" justifyContent="space-between">
        <Typography fontSize={14}>
          {getTranslation("ManageClassesLine1")}

          <span style={{ fontWeight: 700 }}>
            {getTranslation("ManageClassesLine2")}
          </span>
        </Typography>
      </Grid>
      <ClassListTable rows={rows} handleRemoveClass={handleRemoveClass} />
      <Modal
        open={isOpen}
        onClose={handleClick}
        aria-labelledby="add-user-modal-title"
        aria-describedby="add-user-modal-description"
      >
        <AddNewClassModal
          setIsOpen={setIsOpen}
          handleOnSubmit={handleAddNewClass}
        />
      </Modal>
    </Grid>
  );
}
