import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import {
  Button,
  Divider,
  Grid,
  Popover,
  Modal,
  Typography,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect, useState } from "react";
import agent from "../../../app/api/agent";
import LoadingSpinner from "../../../app/common/LoadingSpinner";
import { useTranslation } from "../../../translation/translate";

export default function StudentTableData({
  handleRemoveUser,
  selectedRow,
  studentRowData,
  setSelectedRow,
}: any) {
  const [anchorEl, setAnchorEl] = useState<any | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<string[]>([]);
  const [noClassPopoverOpen, setNoClassPopoverOpen] = useState<boolean>(false);
  const [noClassAnchorEl, setNoClassAnchorEl] = useState<any | null>(null);
  const [loadingFinalPDF, setLoadingFinalPDF] = useState(false);
  const { getTranslation } = useTranslation();

  const columns: GridColDef[] = [
    { field: "portfoliId", headerName: "ID", width: 130 },
    { field: "firstName", headerName: getTranslation("FirstName"), width: 130 },
    { field: "lastName", headerName: getTranslation("LastName"), width: 130 },
    {
      field: "email",
      headerName: getTranslation("EmailOrUsername"),
      width: 170,
    },
    {
      field: "classNames",
      headerName: getTranslation("ClassAssigned"),
      width: 130,
      renderCell: (params) => {
        const classNames = params.value;
        if (classNames === "No Class Assigned") {
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  setNoClassAnchorEl(event.currentTarget);
                  setNoClassPopoverOpen(true);
                }}
              >
                <ClearIcon color="error" />
              </IconButton>
            </Box>
          );
        }
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                setModalContent(classNames ? classNames.split(",") : []);
                setIsModalOpen(true);
              }}
            >
              <CheckIcon color="primary" />
            </IconButton>
          </Box>
        );
      },
    },
    { field: "status", headerName: getTranslation("Status"), width: 130 },
  ];

  const handleCellClick = (e: React.MouseEvent<HTMLDivElement>, data: any) => {
    setSelectedRow(data);
    if (selectedRow !== "classNames") {
      setIsOpen(true);
      setAnchorEl(e.currentTarget);
    }
  };
  const handleProfileButtonClick = async (selectedRow: any) => {
    setLoadingFinalPDF(true);
    try {
      // Generate the final PDF
      await agent.PDFViewer.GenerateFinalPDF(selectedRow.portfoliId, "Preview");

      // Get file info
      const data = await agent.FileUpload.GetFileInfo(
        "PreviewPDF",
        selectedRow.portfoliId
      );

      // Get the file
      const response = await agent.FileUpload.GetFiles(data[0].id);

      // Create a blob from the file response
      const blob = new Blob([response], { type: "application/pdf" });

      // Create a URL for the blob
      const imageUrl = URL.createObjectURL(blob);

      // Open the PDF in a new tab
      window.open(imageUrl, "_blank");
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoadingFinalPDF(false);
    }
  };

  if (loadingFinalPDF) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <LoadingSpinner />
      </Grid>
    );
  }

  return (
    <Grid item container>
      <DataGrid
        slots={{ toolbar: GridToolbar }}
        rows={studentRowData}
        columns={columns}
        getRowId={(row) => row.portfoliId}
        autoHeight
        autoPageSize
        rowHeight={35}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[0, 10, 25, 50, 100]}
        onRowClick={(params, e: any) => handleCellClick(e, params.row)}
      />

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <Grid item display="flex" flexDirection="column">
          {selectedRow?.status === "Approved" && (
            <Button
              sx={{ textTransform: "initial" }}
              onClick={() => handleProfileButtonClick(selectedRow)}
            >
              {getTranslation("SeePortfolio")}
            </Button>
          )}
          <Divider />

          <Button
            sx={{ textTransform: "initial", color: "error.main" }}
            onClick={() => {
              handleRemoveUser(selectedRow.portfoliId);
              setIsOpen(false);
            }}
          >
            {getTranslation("DeleteStudent")}
          </Button>
        </Grid>
      </Popover>

      <Popover
        open={noClassPopoverOpen}
        anchorEl={noClassAnchorEl}
        onClose={() => setNoClassPopoverOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography>{getTranslation("NoClassAssigned")}</Typography>
        </Box>
      </Popover>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 4,
            boxShadow: 20,
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            gutterBottom
          >
            {getTranslation("ClassesAssigned")}
          </Typography>
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {modalContent.length > 0 ? (
              modalContent.map((className, index) => (
                <ListItem key={index}>
                  <ListItemText primary={`${index + 1}. ${className}`} />
                </ListItem>
              ))
            ) : (
              <ListItem>
                <ListItemText primary={getTranslation("NoClasses")} />
              </ListItem>
            )}
          </List>
          <Button
            onClick={() => setIsModalOpen(false)}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            {getTranslation("Close")}
          </Button>
        </Box>
      </Modal>
    </Grid>
  );
}
