import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import ReadMoreReadLess from "../../app/common/ReadMoreReadLess";
import { AnswersProps } from "../../app/models/Answers";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAppSelector } from "../../app/store/configureStore";
import { useTranslation } from "../../translation/translate";

const StatementMenuReflection: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const { getTranslation } = useTranslation();
  const { user } = useAppSelector((state) => state.account);
  const isInfantToddler = user?.credentialTypeId === 1;

  const [selectedOption, setSelectedOption] = useState<boolean | null>(null);

  const optionSystemCode = isInfantToddler ? 26 : 3600;
  const menuCommitmentSystemCode = isInfantToddler ? 27 : 3601;
  const strengthMenuSystemCode = isInfantToddler ? 28 : 3602;
  const improvMenuSystemCode = isInfantToddler ? 29 : 3603;
  const schema = yup.object().shape({
    menuCommitment: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    strengthsMenu: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    improveMenu: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });

  const defaultValues = {
    optionRadio: answersData.find(
      (answer) => answer.systemCodeId === optionSystemCode
    )?.answer,
    menuCommitment: answersData.find(
      (answer) => answer.systemCodeId === menuCommitmentSystemCode
    )?.answer,
    strengthsMenu: answersData.find(
      (answer) => answer.systemCodeId === strengthMenuSystemCode
    )?.answer,
    improveMenu: answersData.find(
      (answer) => answer.systemCodeId === improvMenuSystemCode
    )?.answer,
  };

  const {
    control,
    watch,
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const Option = watch("optionRadio");

  useEffect(() => {
    if (Option) {
      setSelectedOption(Option === "true");
    }
  }, [Option, selectedOption]);

  const seeMenuReflection = (
    <>
      <li>{getTranslation("CompetencyIStatementMenueReflectionLine15")}</li>
      <li>{getTranslation("CompetencyIStatementMenueReflectionLine16")}</li>
      <li>{getTranslation("CompetencyIStatementMenueReflectionLine17")}</li>
      <li>{getTranslation("CompetencyIStatementMenueReflectionLine18")}</li>
    </>
  );

  const seeMenuStrengths = (
    <>
      <li>{getTranslation("CompetencyIStatementMenueReflectionLine19")}</li>
      <li>{getTranslation("CompetencyIStatementMenueReflectionLine20")}</li>
      <li>{getTranslation("CompetencyIStatementMenueReflectionLine21")}</li>
      <li>{getTranslation("CompetencyIStatementMenueReflectionLine22")}</li>
    </>
  );

  const seeMenuImprovements = (
    <>
      <li>{getTranslation("CompetencyIStatementMenueReflectionLine23")}</li>
      <li>{getTranslation("CompetencyIStatementMenueReflectionLine24")}</li>
      <li>{getTranslation("CompetencyIStatementMenueReflectionLine25")}</li>
    </>
  );
  return (
    <Grid item xs={12} px={3} py={3} sx={{ bgcolor: "#EBFCF2" }}>
      <Typography variant="body1" fontWeight="bold" paragraph>
        CS I-a - {getTranslation("CompetencyIStatementMenueReflection")}
      </Typography>
      {isInfantToddler ? (
        <Typography variant="body1" paragraph>
          {getTranslation("CompetencyIStatementMenueReflectionLine1")}
        </Typography>
      ) : (
        <Typography variant="body1" paragraph>
          {getTranslation("CompetencyIStatementMenueReflectionLine2")}
        </Typography>
      )}
      <Box component="form">
        {isInfantToddler ? (
          <Typography variant="body1">
            {getTranslation("CompetencyIStatementMenueReflectionLine3")}
          </Typography>
        ) : (
          <Typography variant="body1">
            {getTranslation("CompetencyIStatementMenueReflectionLine4")}
          </Typography>
        )}
        <Controller
          name="optionRadio"
          control={control}
          render={({ field }) => (
            <RadioGroup
              name="optionRadio"
              value={field.value}
              onChange={(e) => {
                field.onChange(e);
                const newValue = e.target.value;
                const systemCodeId = optionSystemCode;

                onAnswerChange(systemCodeId, newValue);
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={getTranslation("Yes")}
                labelPlacement="end"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={getTranslation("No")}
                labelPlacement="end"
              />
            </RadioGroup>
          )}
        />

        {selectedOption === true && (
          <Box>
            <Grid item xs={12} pt={3}>
              {isInfantToddler ? (
                <Typography variant="body1" paragraph>
                  {getTranslation("CompetencyIStatementMenueReflectionLine5")}
                </Typography>
              ) : (
                <Typography variant="body1" paragraph>
                  {getTranslation("CompetencyIStatementMenueReflectionLine6")}
                </Typography>
              )}

              <ReadMoreReadLess>{seeMenuReflection}</ReadMoreReadLess>
            </Grid>
            <Grid item xs={12} py={3}>
              <TextField
                label={getTranslation(
                  "CompetencyIStatementMenueReflectionLine7"
                )}
                {...register("menuCommitment")}
                sx={{ bgcolor: "#FFF" }}
                value={
                  answersData.find(
                    (answer) => answer.systemCodeId === menuCommitmentSystemCode
                  )?.answer || ""
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  const systemCodeId = menuCommitmentSystemCode;
                  newValue.length <= 500 &&
                    onAnswerChange(systemCodeId, newValue);
                }}
                multiline
                rows={2}
                fullWidth
                error={!!errors.menuCommitment?.message}
                helperText={errors.menuCommitment?.message}
              />
              <Typography fontSize={13}>
                {defaultValues.menuCommitment?.length}/500
              </Typography>
            </Grid>
          </Box>
        )}
        {selectedOption === false && (
          <Box>
            <Grid item xs={12} pt={3}>
              <Typography variant="body1" paragraph>
                {isInfantToddler
                  ? getTranslation("CompetencyIStatementMenueReflectionLine8")
                  : getTranslation("CompetencyIStatementMenueReflectionLine9")}
              </Typography>
              <ReadMoreReadLess>{seeMenuStrengths}</ReadMoreReadLess>
            </Grid>
            <Grid item xs={12} py={3}>
              <TextField
                label={
                  isInfantToddler
                    ? getTranslation(
                        "CompetencyIStatementMenueReflectionLine10"
                      )
                    : getTranslation(
                        "CompetencyIStatementMenueReflectionLine11"
                      )
                }
                {...register("strengthsMenu")}
                sx={{ bgcolor: "#FFF" }}
                value={
                  answersData.find(
                    (answer) => answer.systemCodeId === strengthMenuSystemCode
                  )?.answer || ""
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  const systemCodeId = strengthMenuSystemCode;

                  newValue.length <= 500 &&
                    onAnswerChange(systemCodeId, newValue);
                }}
                multiline
                rows={2}
                fullWidth
                error={!!errors.strengthsMenu?.message}
                helperText={errors.strengthsMenu?.message}
              />
              <Typography fontSize={13}>
                {defaultValues.strengthsMenu?.length}/500
              </Typography>
            </Grid>
            <Grid item xs={12} pt={3}>
              <Typography variant="body1" paragraph>
                {isInfantToddler
                  ? getTranslation("CompetencyIStatementMenueReflectionLine12")
                  : getTranslation("CompetencyIStatementMenueReflectionLine13")}
              </Typography>
              {<ReadMoreReadLess>{seeMenuImprovements}</ReadMoreReadLess>}
            </Grid>
            <Grid item xs={12} py={3}>
              <TextField
                label={getTranslation(
                  "CompetencyIStatementMenueReflectionLine14"
                )}
                {...register("improveMenu")}
                name="improveMenu"
                sx={{ bgcolor: "#FFF" }}
                value={
                  answersData.find(
                    (answer) => answer.systemCodeId === improvMenuSystemCode
                  )?.answer || ""
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  const systemCodeId = improvMenuSystemCode;

                  newValue.length <= 500 &&
                    onAnswerChange(systemCodeId, newValue);
                }}
                multiline
                rows={2}
                fullWidth
                error={!!errors.improveMenu?.message}
                helperText={errors.improveMenu?.message}
              />
              <Typography fontSize={13}>
                {defaultValues.improveMenu?.length}/500
              </Typography>
            </Grid>
          </Box>
        )}
      </Box>
    </Grid>
  );
};
export default StatementMenuReflection;
