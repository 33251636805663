// src/redux/translationSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import agent from "../app/api/agent";
import { TranslationItem } from "./translationType";

interface TranslationState {
  translations: TranslationItem[] | null;
  loading: boolean;
  error: string | null;
}

const initialState: TranslationState = {
  translations: null,
  loading: false,
  error: null,
};

// Async thunk to fetch translations
export const fetchTranslations = createAsyncThunk<
  TranslationItem[], // Return type of the fulfilled action
  void, // Argument type of the thunk (none in this case)
  { rejectValue: string } // Type of the rejected action's payload
>("translations/fetchTranslations", async (_, thunkAPI) => {
  try {
    const response = await agent.spanish.getTranslations();

    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue("Failed to fetch translations");
  }
});

export const translationSlice = createSlice({
  name: "translations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTranslations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchTranslations.fulfilled,
        (state, action: PayloadAction<TranslationItem[]>) => {
          state.translations = action.payload;
          state.loading = false;
        }
      )
      .addCase(
        fetchTranslations.rejected,
        (state, action: PayloadAction<string | undefined>) => {
          state.error = action.payload || "Something went wrong";
          state.loading = false;
        }
      );
  },
});

export default translationSlice.reducer;
