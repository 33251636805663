import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "../../translation/translate";

export default function Prepare() {
  const { getTranslation } = useTranslation();
  return (
    <Grid item xs={12} mt={4} px={3} pt={3} sx={{ bgcolor: "#EEFCFB" }}>
      <Typography variant="body1" fontWeight="bold">
        1. {getTranslation("PuttingAllTogetherHeaderPrepareLine1")}
      </Typography>

      <ul>
        <li>
          <Typography variant="body1">
            {getTranslation("PuttingAllTogetherHeaderPrepareLine2")}
          </Typography>
        </li>
      </ul>
    </Grid>
  );
}
