import { Grid, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { useTranslation } from "../../../translation/translate";

interface Props {
  barData: any;
}

export default function StudentBarGraph({ barData }: Props) {
  const { getTranslation } = useTranslation();
  return (
    <Grid item xs={12}>
      <Grid item xs={12}>
        <Typography>{getTranslation("StudentGraphLine1")}</Typography>
      </Grid>
      {barData && barData.length > 0 && (
        <BarChart
          sx={{ width: "vw" }}
          dataset={barData}
          xAxis={[
            {
              scaleType: "band",
              dataKey: "credentialType",
            },
          ]}
          series={[
            {
              dataKey: "notStarted",
              label: getTranslation("NotStarted"),
            },
            {
              dataKey: "inProgress",
              label: getTranslation("InProgress"),
            },
            {
              dataKey: "completed",
              label: getTranslation("Completed"),
            },
          ]}
          colors={["#a6d4fa", "#2c387e", "#a5d6a7"]}
          slotProps={{
            legend: {
              direction: "row",
              position: { vertical: "bottom", horizontal: "middle" },
              padding: 0,
            },
          }}
          width={800}
          height={400}
        />
      )}
    </Grid>
  );
}
