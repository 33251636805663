import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import EmailForm from "./emailForm";
import { useEffect, useState } from "react";

import EmailTemplateArray from "./emailTemplateArray";
import agent from "../../../app/api/agent";
import { useAppSelector } from "../../../app/store/configureStore";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: "100%",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

export default function EmailTemplate() {
  const { user } = useAppSelector((state) => state.account);

  const [addNew, setAddNew] = useState<boolean>(false);
  const [emailTitles, setEmailTitles] = useState<any>();

  const [bodyHTML, setBodyHTML] = useState<any>(null);
  const [emailArr, setEmailArr] = useState<any>([]);
  const emailID = Math.floor(Math.random() * 1000);
  const emailOrder = emailArr.length + 1;

  const fetchEmailData = async () => {
    if (user) {
      try {
        const response = await agent.EmailTemplate.GetEmailTemplateByType();
        setEmailArr(response);
      } catch {
        console.error();
      }
    }
  };
  const handleAddNew = () => {
    setAddNew((prev) => !prev);
  };
  const resetEmailInputs = () => {
    setEmailTitles({
      title: "",
      subject: "",
      message: "",
    });
  };

  const onsubmit = async (data: any) => {
    const ConstructedObj = {
      title: data.title,
      subject: data.subject,
      message: bodyHTML,
    };
    handleAddNew();
    setEmailArr([...emailArr, ConstructedObj]);
    const postEmail = {
      id: data ? data.id : emailID,
      title: data.title,
      subject: data.subject,
      message: `${bodyHTML}`,
      order: emailOrder,
    };
    try {
      await agent.EmailTemplate.PostEmailTemplate(postEmail);
    } catch {
      console.error();
    }
    resetEmailInputs();
    fetchEmailData();
  };

  const removeEmail = async (emailId: number) => {
    await agent.EmailTemplate.DeleteEmailTemplate(emailId);
    fetchEmailData();
  };

  const editEmailTemplate = (data: any) => {
    handleAddNew();
    setEmailTitles({
      id: data.id,
      title: data.title,
      subject: data.subject,
      message: data.message,
    });
  };

  useEffect(() => {
    fetchEmailData();
  }, []);

  return (
    <Grid container p={3}>
      <Grid
        item
        xs={12}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Typography fontSize={24} fontWeight={700}>
          Emails
        </Typography>
        <Button variant="contained" size="small" onClick={handleAddNew}>
          Add New
        </Button>
      </Grid>
      <Modal open={addNew} onClose={handleAddNew}>
        <Box sx={style} overflow="scroll">
          <EmailForm
            onsubmit={onsubmit}
            handleAddNew={handleAddNew}
            setBodyHTML={setBodyHTML}
            bodyHTML={bodyHTML}
            emailTitles={emailTitles}
            setEmailTitles={setEmailTitles}
            resetEmailInputs={resetEmailInputs}
          />
        </Box>
      </Modal>
      <Grid item container xs={12} p={2} m={2} mt={3}>
        <EmailTemplateArray
          emailArr={emailArr}
          removeEmail={removeEmail}
          editEmailTemplate={editEmailTemplate}
          resetEmailInputs={resetEmailInputs}
        />
      </Grid>
    </Grid>
  );
}
