import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import agent from "../../../app/api/agent";
import { RegisterFormInputs } from "../../../app/models/RegisterForm";
import util from "../../../app/utils/utils";
import { RegistrationValidationAdminAddSchema } from "../../../app/validations/RegisterValidation";
import { useAppSelector } from "../../../app/store/configureStore";

interface AddUserModalProps {
  onClose: () => void;
}

const AddUserModal: React.FC<AddUserModalProps> = ({ onClose }) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors, isValid },
  } = useForm<RegisterFormInputs>({
    resolver: yupResolver(RegistrationValidationAdminAddSchema),
    mode: "onTouched",
  });

  const { CSIAdminRolesAdmin, CouncilAdminRolesAdmin } = util;
  const { user } = useAppSelector((state) => state.account);

  const candidateRoles =
    user?.role === "CSI Admin" ? CSIAdminRolesAdmin : CouncilAdminRolesAdmin;

  function handleApiErrors(errors: any) {
    if (errors) {
      errors.forEach((error: string) => {
        if (error.includes("Password")) {
          setError("password", { message: error });
        } else if (error.includes("Email")) {
          setError("email", { message: error });
        }
      });
    }
  }
  return (
    <Container component="main" maxWidth="sm" sx={{}}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          bgcolor: "rgba(255,255,255,1)",
          padding: "20px",
          paddingLeft: "10%",
          paddingRight: "10%",
          borderRadius: "8px",
          maxWidth: "lg",
        }}
      >
        <Typography variant="h6" id="add-user-modal-title" component="div">
          Add User
        </Typography>
        <Typography
          variant="body2"
          id="add-user-modal-description"
          component="div"
        >
          Fill out the form below to add a new user.
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit((data) =>
            agent.Account.register(data)
              .then(() => {
                onClose();
              })
              .catch((error) => handleApiErrors(error))
          )}
          sx={{ mt: 3 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                label="First Name"
                {...register("firstName")}
                fullWidth
                error={!!errors.firstName?.message}
                helperText={errors.firstName?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="family-name"
                label="Last Name"
                {...register("lastName")}
                fullWidth
                error={!!errors.lastName?.message}
                helperText={errors.lastName?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email Address"
                type="email"
                {...register("email")}
                fullWidth
                error={!!errors.email?.message}
                helperText={errors.email?.message}
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Password"
                type="password"
                {...register("password")}
                fullWidth
                error={!!errors.password?.message}
                helperText={errors.password?.message}
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Confirm Password"
                type="password"
                {...register("confirmPassword")}
                fullWidth
                error={!!errors.confirmPassword?.message}
                helperText={errors.confirmPassword?.message}
                autoComplete="confirm-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Phone Number"
                {...register("phoneNumber")}
                fullWidth
                error={!!errors.phoneNumber?.message}
                helperText={errors.phoneNumber?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="CDA Council Candidate ID"
                {...register("cdaCouncilCandidateId")}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth required error={!!errors.role?.message}>
                <InputLabel>Role</InputLabel>
                <Select {...register("role")} defaultValue="">
                  {candidateRoles.map((role) => (
                    <MenuItem key={role.role} value={role.role}>
                      {role.role}
                    </MenuItem>
                  ))}
                </Select>
                {errors.role && (
                  <FormHelperText>{errors.role.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <LoadingButton
            loading={isSubmitting}
            disabled={!isValid}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Create Account
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
};

export default AddUserModal;
