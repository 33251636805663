import { ExpandCircleDown } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Button,
} from "@mui/material";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import {
  Anchor,
  OpenDrawerPayload,
  Section,
} from "../../app/models/FileUpload";
import { openDrawer } from "../FileUpload/drawerSlice";
import { useCallback, useEffect, useState } from "react";
import agent from "../../app/api/agent";
import { FileInfo } from "../../app/models/Fileinfo";
import UploadedFiles from "../FileUpload/UploadedFiles";
import { useTranslation } from "../../translation/translate";

export default function ResourceVUploads() {
  const [data, setData] = useState<FileInfo[]>([]);
  const { isOpen } = useAppSelector((state) => state.drawer);
  const { user } = useAppSelector((state) => state.account);
  const dispatch = useAppDispatch();
  const { getTranslation } = useTranslation();

  const handleOpenDrawer = (anchor: Anchor, section: Section) => {
    const payload: OpenDrawerPayload = {
      anchor,
      section,
    };
    dispatch(openDrawer(payload));
  };

  const fetchData = useCallback(async () => {
    try {
      const data = await agent.FileUpload.GetFileInfo(
        "RCV",
        user?.portfolioId ?? 0
      );
      setData(data);
    } catch (error) {
      console.error();
    }
  }, [user?.portfolioId]);
  //1st group files
  const groupedFiles: Record<any, any[]> = {};
  data.forEach((item) => {
    const certType = item.certificateType;
    const itemFile = item;
    // If the property doesn't exist in the groupedFiles object, initialize it as an empty array.
    if (!groupedFiles[certType]) {
      groupedFiles[certType] = [];
    }
    // Push the file to the corresponding property group.
    groupedFiles[certType].push(itemFile);
  });

  //2nd Render grouped files
  const newArr = Object.entries(groupedFiles);
  const fileDataArray = () => {
    return newArr.map((innerArray) => {
      return innerArray[1].map((file: any) => (
        <UploadedFiles
          fileOrder={innerArray[1].indexOf(file) + 1}
          key={file.id}
          fileInfo={file}
          onDeleteFile={() => handleDeleteFile()}
        />
      ));
    });
  };

  useEffect(() => {
    fetchData();
  }, [isOpen, fetchData]);

  function handleDeleteFile(): void {
    fetchData();
  }
  return (
    <Grid item xs={12} mt={3}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandCircleDown sx={{ color: "myVerify.main" }} />}
        >
          <Typography fontWeight={700} fontSize={18}>
            {getTranslation("ResourceVUploadsLine1")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container item xs={12} p={2}>
            <Grid item xs={12} md={6} mb={2}>
              <Typography mb={1} fontWeight={700}>
                {getTranslation("UploadDocuments")}
              </Typography>

              <Grid item mt={2}>
                <Button
                  color="myVerify"
                  sx={{ textTransform: "none" }}
                  variant="outlined"
                  onClick={() => handleOpenDrawer("right", "RCV")}
                >
                  <FileUploadIcon />
                  {getTranslation("UploadForms")}
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid item>
                <Typography variant="body1" fontWeight="bold">
                  {getTranslation("UploadedDocuments")}
                </Typography>
                <Typography variant="subtitle2">
                  {data.length}{" "}
                  {data.length === 1
                    ? getTranslation("file")
                    : getTranslation("files")}
                  {"  "}
                  {getTranslation("uploaded")}
                </Typography>
                {fileDataArray()}
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
