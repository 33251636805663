import { ReactElement } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery, Theme } from "@mui/material";
import { useAppSelector } from "../store/configureStore";
import { useTranslation } from "../../translation/translate";

interface SubHeaderOneProps {
  toggleSideNavigation: () => void;
}

export default function SubHeaderOne({
  toggleSideNavigation,
}: SubHeaderOneProps): ReactElement {
  const handleClick = (): void => {
    toggleSideNavigation(); // Call the toggleSideNavigation function from props
  };

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { user } = useAppSelector((state) => state.account);
  const location = useLocation();
  const isWelcome = !location.pathname.includes("/welcome");
  const isAdmin = user?.role?.includes("Admin");
  const role = user?.role;
  const { getTranslation } = useTranslation();
  // const title = isAdmin
  //   ? "Admin Dashboard"
  //   : `My ${copyright} Professional Portfolio`;

  const title = () => {
    switch (role) {
      case isAdmin:
        return getTranslation("AdminDashboard");
      case "Trainer":
        return getTranslation("TrainerDashboard");
      case "Other Viewer":
        return getTranslation("ViewerDashboard");
      default:
        return getTranslation(`MyCDAProfessionalPortfolio`);
    }
  };

  const isSmallDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const isLargeDevice = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );

  return (
    <Box className="Rectangle">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mx: { xs: 2, md: 4 },
        }}
      >
        <Box sx={{ display: isSmallDevice ? "none" : "block" }}>
          <Typography variant="h6" fontWeight="bold">
            {title()}
          </Typography>
        </Box>
        <Box sx={{ display: isLargeDevice ? "none" : "block" }}>
          <Typography variant="body2" fontWeight="bold">
            {title()}
          </Typography>
        </Box>
        {isMobile && isWelcome && (
          <IconButton
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ marginRight: { xs: 1, md: 0 } }}
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
        )}
        {!isMobile && isWelcome && user?.role === "CDA Candidate" && (
          <Typography variant="h6" fontWeight="bold">
            {getTranslation("Setting")}:{" "}
            {user?.isSpanish
              ? user?.spanishCredentialName
              : user?.credentialName}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
