import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import stageOne from "../../assets/images/stageOne.png";
import stageTwo from "../../assets/images/stageTwo.png";
import stageThree from "../../assets/images/stageThree.png";
import stageFour from "../../assets/images/stageFour.png";
import { blue } from "@mui/material/colors";
import { useCallback, useEffect, useState } from "react";
import agent from "../../app/api/agent";
import { useAppSelector } from "../../app/store/configureStore";
import { CompetencyData } from "../../app/models/CompetencyData";
import LoadingSpinner from "../../app/common/LoadingSpinner";
import { useTranslation } from "../../translation/translate";
import React from "react";

const progressBar2 = blue[50];
const progressBar1 = blue[500];

function insertLineBreaksAtSpaces(text: string) {
  // Split the text by spaces
  const words = text.split(" ");

  // Map through the words and insert a line break after each one
  return words.map((word, index) => (
    <React.Fragment key={index}>
      {word}
      {index < words.length - 1 && <br />}
    </React.Fragment>
  ));
}

export default function CompetencyProgress() {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const { user } = useAppSelector((state) => state.account);
  const [compData, setCompData] = useState<CompetencyData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { getTranslation } = useTranslation();
  const fetchData = useCallback(async () => {
    if (user) {
      try {
        const portfolioId = await agent.Account.currentUser().then(
          (data) => data.portfolioId
        );
        const data = await agent.Progress.GetCompentencyResults(portfolioId);
        setCompData(data);
      } catch (error) {
        // Handle errors if necessary.
      }
      setIsLoading(false);
    }
  }, [user?.portfolioId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container spacing={3} p={1}>
      {/* Start of the Loop of the above object containing the progress results. */}
      {compData.map((comp: CompetencyData) => (
        <Grid key={comp.displayOrder} item xs={isMobile ? 12 : 4}>
          <Card>
            <CardContent sx={{ maxHeight: 58 }}>
              <Typography>{comp.title}</Typography>
            </CardContent>
            <CardContent sx={{ minHeight: isMobile ? 215 : 256 }}>
              <Grid
                item
                container
                spacing={isMobile ? 0 : 2}
                display="flex"
                justifyContent="center"
              >
                <Grid item xs={6} textAlign="center">
                  <Grid item>
                    <Box sx={{ position: "relative", display: "inline-flex" }}>
                      <CircularProgress
                        variant="determinate"
                        sx={{ color: progressBar2, position: "absolute" }}
                        value={100}
                        size={145}
                      />
                      <CircularProgress
                        variant="determinate"
                        sx={{ color: progressBar1 }}
                        value={comp.resourceProgress}
                        size={145}
                      />
                      <Box
                        sx={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Grid item color="text.secondary">
                          {(comp.resourceProgress === 100 && (
                            <img alt="" src={stageFour} width={60} />
                          )) ||
                            (comp.resourceProgress >= 67 && (
                              <img
                                src={stageThree}
                                alt=""
                                style={{
                                  maxWidth: 80,
                                  rotate: "z 40deg",
                                  maxHeight: 60,
                                }}
                              />
                            )) ||
                            (comp.resourceProgress >= 34 && (
                              <img
                                alt=""
                                src={stageTwo}
                                style={{ width: 80, marginBottom: 6 }}
                              />
                            )) || <img alt="" src={stageOne} width={70} />}

                          <Typography fontWeight={700} fontSize={18}>
                            {comp.resourceProgress}% <br />{" "}
                            <span
                              style={{ fontSize: ".7rem", fontWeight: 500 }}
                            >
                              {getTranslation("Complete").toLocaleUpperCase()}
                            </span>
                          </Typography>
                        </Grid>
                      </Box>
                    </Box>
                  </Grid>
                  {(comp.title.includes("Competency") ||
                    comp.title.includes("Competencia")) && (
                    <Grid item>
                      <Typography>
                        {insertLineBreaksAtSpaces(getTranslation("ResourceCollection"))}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                {(comp.title.includes("Competency") ||
                  comp.title.includes("Competencia")) && (
                  <Grid item xs={6} textAlign="center">
                    <Grid item>
                      <Box
                        sx={{ position: "relative", display: "inline-flex" }}
                      >
                        <CircularProgress
                          variant="determinate"
                          sx={{ color: progressBar2, position: "absolute" }}
                          value={100}
                          size={145}
                        />
                        <CircularProgress
                          variant="determinate"
                          sx={{ color: progressBar1 }}
                          value={comp.statementProgress}
                          size={145}
                        />
                        <Box
                          sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Grid item color="text.secondary">
                            {(comp.statementProgress === 100 && (
                              <img alt="" src={stageFour} width={60} />
                            )) ||
                              (comp.statementProgress >= 67 && (
                                <img
                                  alt=""
                                  src={stageThree}
                                  style={{
                                    maxWidth: 80,
                                    rotate: "z 40deg",
                                    maxHeight: 60,
                                  }}
                                />
                              )) ||
                              (comp.statementProgress >= 34 && (
                                <img
                                  alt=""
                                  src={stageTwo}
                                  style={{ width: 80, marginBottom: 6 }}
                                />
                              )) || <img alt="" src={stageOne} width={70} />}

                            <Typography fontWeight={700} fontSize={18}>
                              {comp.statementProgress}%<br />{" "}
                              <span
                                style={{ fontSize: ".7rem", fontWeight: 500 }}
                              >
                                {getTranslation("Complete").toLocaleUpperCase()}
                              </span>
                            </Typography>
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Typography>
                        {insertLineBreaksAtSpaces(getTranslation("ReflectiveStatement"))}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
