import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { ReadMoreReadLessProps } from "../models/ReadMoreReadLess";
import { useTranslation } from "../../translation/translate";

const ReadMoreReadLess: React.FC<ReadMoreReadLessProps> = ({ children }) => {
  const [isReadmore, setReadMore] = useState(false);

  const toggleBtn = () => {
    setReadMore((prevState) => !prevState);
  };
  const { getTranslation } = useTranslation();

  return (
    <Grid display="flex" flexDirection="column" alignItems="flex-start">
      <Typography
        variant="body2"
        color="secondary"
        onClick={toggleBtn}
        style={{
          cursor: "pointer",
          // Add underline style
        }}
      >
        {isReadmore ? getTranslation("HideIdeas") : getTranslation("SeeIdeas")}
      </Typography>
      <Typography
        variant="body1"
        style={{
          opacity: isReadmore ? 1 : 0,
          transition: "opacity 1.5s",
        }}
      >
        {isReadmore ? children : null}
      </Typography>
    </Grid>
  );
};

export default ReadMoreReadLess;
