import {
  Box,
  Card,
  CardContent,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import image from "../../assets/images/symbol-icon-status-progress-summary.png";
import { useTranslation } from "../../translation/translate";

export default function PuttingItAllTogether() {
  const { getTranslation } = useTranslation();
  const title = getTranslation("PuttingItAllTogether");
  const description = getTranslation("PuttingAllTogetherHeaderDescription");
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <Card
      sx={{
        width: "100%",
        backgroundColor: "#EEFCFB",
        boxShadow: " 0 16px #3E7787",
      }}
    >
      <CardContent>
        <Grid container mt={1}>
          <Grid item xs={2} sm={2} md={1} sx={{ textAlign: "center" }}>
            <Grid item sx={{ textAlign: "center" }}>
              <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                <img src={image} alt="status" style={{ width: "70%" }} />
              </Box>
            </Grid>
          </Grid>
          <Grid
            item
            xs={10}
            sm={10}
            md={11}
            display="block"
            flexDirection="column"
            paddingLeft={3}
          >
            <Typography variant="h6" fontWeight={700} mb={1}>
              {title}
            </Typography>
            <Typography display={isMobile ? "none" : "show"} mb={1}>
              {description}
            </Typography>
          </Grid>

          {/* None Mobile */}
        </Grid>
      </CardContent>
    </Card>
  );
}
