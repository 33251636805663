import {
  Card,
  CardContent,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "../../translation/translate";

export default function ChecklistHeader() {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const { getTranslation } = useTranslation();

  return (
    <Card
      sx={{
        width: "100%",
        backgroundColor: "myVerify.light",
        boxShadow: "0 16px #3E7787",
        marginBottom: 4,
      }}
    >
      <CardContent>
        <Grid
          item
          xs={10}
          sm={10}
          md={11}
          display="block"
          flexDirection="column"
          paddingLeft={3}
        >
          <Typography variant="h6" fontWeight={700} marginBottom={1}>
            {getTranslation("OnlineForm")}
          </Typography>
          <Typography display={isMobile ? "none" : "show"} marginBottom={1}>
            {getTranslation("CheckListDescriptionPart1")}{" "}
            <span style={{ fontStyle: "italic" }}>
              {getTranslation("MYCDAEducationDescriptionpart2")}
            </span>{" "}
            {getTranslation("CheckListDescriptionPart2")}
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
}
