import { Box, Button, Grid, Typography } from "@mui/material";
import SubHeaderTwo from "../../app/layout/SubHeaderTwo";
import HorizontalStepper from "../../app/common/HorizontalStepper";
import Books from "./Books";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import agent from "../../app/api/agent";
import { useTranslation } from "../../translation/translate";

export default function CDABook() {
  const { getTranslation } = useTranslation();
  const steps = [
    getTranslation("CDABookStep1"),
    getTranslation("CDABookStep2"),
    getTranslation("CDABookStep3"),
  ];
  const CDAStandards = getTranslation("CDABookStandard");
  const purchase = getTranslation("CDABookPurchase");
  const notpurchased = getTranslation("CDABookNotPurchased");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const data = await agent.Account.currentUser();
      if (data.welcomeComplete === true) navigate("/myprogresssummary");
    };
    fetchData();
  }, []);

  const handleClick = () => {
    navigate("/welcome/credential");
  };

  return (
    <Box>
      <SubHeaderTwo title={getTranslation("GetStarted")} />
      <Grid container>
        <HorizontalStepper activeStep={0} steps={steps} />
        <Grid item xs={12} md={7} sx={{ order: { xs: 2, md: 1 } }}>
          <Typography sx={{ fontWeight: "bold" }}>{CDAStandards}</Typography>
          <Typography sx={{ fontWeight: "bold" }} mt={2}>
            {purchase}
          </Typography>
          <Typography>{notpurchased}</Typography>

          <Button
            href="https://yourcouncil.org/cfprssa/ecssashop.show_category?p_category_id=CDA_COMP_BOOKS"
            target="_blank"
            variant="contained"
            color="secondary"
            sx={{ textTransform: "none", mb: 3, mt: 4 }}
          >
            {getTranslation("CDABookButton1")}
          </Button>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Button
                  onClick={handleClick}
                  variant="contained"
                  sx={{ textTransform: "none", mb: 3 }}
                >
                  {getTranslation("CDABookButton2")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: "flex",
            justifyContent: "center",
            order: { xs: 1, md: 2 },
          }}
        >
          <Books />
        </Grid>
      </Grid>
    </Box>
  );
}
