import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Anchor, OpenDrawerPayload, Section } from "../../app/models/FileUpload";

interface DrawerState {
  top: boolean;
  left: boolean;
  bottom: boolean;
  right: boolean;
  isOpen: boolean;
  section: Section ;
}

const initialState: DrawerState = {
  top: false,
  left: false,
  bottom: false,
  right: false,
  isOpen: false,
  section: "",
};

const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    openDrawer: (state, action: PayloadAction<OpenDrawerPayload>) => {
      const { anchor, section } = action.payload;
      state[anchor] = true;
      state.isOpen = true;
      state.section = section;
    },
    closeDrawer: (state, action: PayloadAction<Anchor>) => {
      state[action.payload] = false;
      state.isOpen = false;
    },
  },
});

export const { openDrawer, closeDrawer } = drawerSlice.actions;
export default drawerSlice.reducer;
