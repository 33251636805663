import jsPDF from "jspdf";
import { PDFProps } from "../models/Pdf";
import logogIMG from "../../assets/images/OfficialLogo2.png"

const createAndOpenPDF = ({ pdfData, title, user}: PDFProps ): jsPDF => { 
  
  // Create a new jsPDF instance.
  const doc = new jsPDF();
  //get page size
  const pageHeight = doc.internal.pageSize.getHeight()
  // Calculate double-spacing (line height factor) for 12-point font.
  const lineHeightFactor = 2;
  doc.setLineHeightFactor(lineHeightFactor);

  //doc margins
  let yOffset = 35;
  const marginBottom = pageHeight -20;

  // Create an object to store answers grouped by title.
  const groupedAnswers: Record<string, string[]> = {};

  pdfData.forEach((item) => {
    const itemTitle = item.title;
    const itemQuestion = item.value;
    const itemAnswer = item.answer;

    // If the title doesn't exist in the groupedAnswers object, initialize it as an empty array.
    if (!groupedAnswers[itemTitle]) {
      groupedAnswers[itemTitle] = [];
    }

    // Push the answer to the corresponding title group.
    groupedAnswers[itemTitle].push(itemQuestion)
    groupedAnswers[itemTitle].push(itemAnswer);
  });



  // header details
  const addHeader = () => {
     doc.setFont("times", "bold");
     doc.setFontSize(16);
     doc.addImage(logogIMG, 'JPG', 8,2, 30,25)
     doc.text(` ${title}`, 40,10);
     doc.setFontSize(12);
     doc.setFont("times", "normal");
     doc.text(` ${"Candidate: " + user[1]+ ", "+ user[0]}`, 40,17);
     doc.text(` ${ "Setting: " + user[2]}`, 145,10);
     doc.text(` ${ "Candidate ID Number: " + user[3]}`, 145, 17);
  }
   
  //footer
  const addFooters = (doc: any) => {     
    const pageCount = doc.internal.getNumberOfPages()

      const todate = new Date().toLocaleDateString()
      doc.setFontSize(12)
      doc.setFont("times", "normal")
     
      for (let i =1; i <=  pageCount; i++){
        doc.setPage(i)
        addHeader();
        doc.text('Page' + String(i) + '/' + pageCount, doc.internal.pageSize.width / 1.1 , 287,{ align: "right"})
        doc.text(10, 287, "Date: " + todate)
      }
  } 


  


  // Iterate through grouped Questions and Answers and add them to the PDF. BODY
  for (const [groupTitle, groupAnswers] of Object.entries(groupedAnswers)) {

    //addHeader();
    //Title
    doc.setFont("times", "bold");
    doc.setFontSize(12)
    doc.text(`${groupTitle}`, 10, yOffset);
    
    let groupYOffset = yOffset + 3; // Create a new variable for this group's yOffset
    groupYOffset += 5;
    
    
    
    // setting font for QA
    doc.setFont("times", "normal");
    doc.setFontSize(12)
    
    for (let i = 0; i< groupAnswers.length; i++){

      const questions = doc.splitTextToSize(groupAnswers[i], 180); // Split the Questions into lines based on a maximum line width (e.g., 100).
      
      if(i % 2 ==0){
        let arrYOffset = questions.length * 5;

        doc.text(questions, 12, groupYOffset +5)
        groupYOffset += 15; // Add 5 for spacing between lines
        groupYOffset += arrYOffset;
      } else{
        const answers = doc.splitTextToSize(groupAnswers[i] ?? 'Response not yet entered', 180) // Split the Answers into lines based on a maximum line width (e.g., 100).
        answers.forEach((line: any) =>{

        //content-answer div 
      
        doc.text(line, 12, groupYOffset -5 )
        groupYOffset += 5; // Add 5 for spacing between lines
        groupYOffset += 5 ;

        if (groupYOffset >= marginBottom +10){
          doc.cellAddPage()
          groupYOffset -= ( line.length >= 2 ? marginBottom - 15: marginBottom -15);
        }
        })
      }
    }

    //lined border
    doc.line(8,yOffset-6,201,yOffset-6)

  
    
    groupYOffset += 10; // Add 5 for spacing between paragraphs (adjust as needed)
    yOffset = groupYOffset +10 ; // Update the yOffset for the next group
     
    //adding more pages 
    if(yOffset > marginBottom -50 ){
     doc.addPage();

     yOffset=35
    }
  }

  addFooters(doc);

  
  

  
    
  // Generate a data URL for the PDF.
  const pdfDataUri = doc.output('datauristring'); // Specify the filename here

  // Open the PDF in a new tab.
  const newTab = window.open();


  if (newTab) {
    newTab.document.write(`
      <html>
      <head>
        <title>${title}.pdf</title>
      </head>
      <body>
        <embed width='100%' height='100%' src='${pdfDataUri}' type='application/pdf'>
        
      </body>
      </html>
    `);
  } else {
    // Handle the case where the new tab couldn't be opened.
    alert('Popup blocker prevented opening a new tab.');
  }

  return doc;
};

export default createAndOpenPDF;