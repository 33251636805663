import { Grid } from "@mui/material";
import Resource1Status from "./Resource1Status";
import StatementSafeEnvironment from "./StatementSafeEnvironment";
import StatementMenuReflection from "./StatementMenuReflection";
import StatementClassroomDesign from "./StatementClassrommDesign";
import StatementWeeklyPlan from "./StatementWeeklyPlan";
import { useAppSelector } from "../../app/store/configureStore";

import StatementFoodReflection from "./StatementFoodReflection";
import StatementHomeVisitingReflection from "./StatementHomeVisitingReflection";
import StatementChildAbuse from "./StatementChildAbuse";
import { SummaryCompleteProps } from "../../app/models/SummaryComplete";
import { useCallback, useEffect, useState } from "react";
import { Answers } from "../../app/models/Answers";
import agent from "../../app/api/agent";
import LoadingSpinner from "../../app/common/LoadingSpinner";
import PrintSaveBtns from "../../app/common/PrintSaveBtn";
import CongratsPopUp from "../../app/common/CongratsPopUp";
import { PDF } from "../../app/models/Pdf";
import createAndOpenPDF from "../../app/utils/pdfutils";
import BilingualPopUp from "../../app/common/BilingualPopUp";
import { useTranslation } from "../../translation/translate";

export default function Statement() {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();
  const [answers, setAnswers] = useState<Answers[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pdf, setPdf] = useState<PDF[]>([]);
  const [summaryCompleteProps, setSummaryCompleteProps] =
    useState<SummaryCompleteProps>({
      title: getTranslation("MyCompetencyIStatement"),
      percent: "",
      header: getTranslation("ResourceCollectionITitle"),
      description: getTranslation("ResourceCollectionIDetail"),
    });

  const [bilingualModal, setBilingualModal] = useState(true);
  const handleBilingual = () => {
    setBilingualModal(false);
  };

  const [userProgress, setUserProgress] = useState(0);
  const [popUp, setPopUp] = useState(false);

  const handlePopUp = () => {
    setPopUp((prev) => !prev);
  };

  const fetchData = useCallback(async () => {
    try {
      if (user?.credentialName && user?.portfolioId) {
        const response = await agent.Answers.GetAnswers(
          user.credentialName,
          user.portfolioId,
          "CSI"
        );

        const compData = await agent.Progress.GetCompentencyResultsByType(
          user.portfolioId,
          "CSI"
        );
        const pdf = await agent.PDFViewer.GetPDFByType(
          user.credentialName,
          user.portfolioId,
          "CSI"
        );
        setUserProgress(compData.statementProgress);
        setPdf(pdf);
        setSummaryCompleteProps((prevSummaryCompleteProps) => ({
          ...prevSummaryCompleteProps,
          percent: compData.statementProgress + "%",
        }));
        setAnswers(response);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [user?.credentialName, user?.portfolioId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAnswerChange = (systemCodeId: number, newValue: string) => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((answer) =>
        answer.systemCodeId === systemCodeId
          ? { ...answer, answer: newValue }
          : answer
      )
    );
  };

  const handleSaveDraft = async () => {
    setIsLoading(true);
    // This is just a sample function for demonstration
    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );
    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    fetchData();
    setIsLoading(false);
  };

  const handleSave = async () => {
    setIsLoading(true);
    // This is just a sample function for demonstration
    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );
    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    fetchData();
    setIsLoading(false);
    handlePopUp();
  };

  const handlePDF = async () => {
    createAndOpenPDF({
      pdfData: pdf,
      title: getTranslation("MyCompetencyIStatement"),
      user: [
        user?.firstName,
        user?.lastName,
        user?.credentialName,
        user?.cdaCouncilCandidateId,
      ],
    });
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container p={2} mb={2}>
      {user?.languageSpecialization && (
        <BilingualPopUp
          isPopUp={bilingualModal}
          btnColor={"success"}
          handlePopUp={handleBilingual}
        />
      )}
      <Resource1Status {...summaryCompleteProps} />
      <StatementSafeEnvironment
        answersData={answers}
        onAnswerChange={handleAnswerChange}
      />
      {user?.credentialTypeId === 4 ? null : (
        <>
          <StatementMenuReflection
            answersData={answers}
            onAnswerChange={handleAnswerChange}
          />
          <StatementClassroomDesign
            answersData={answers}
            onAnswerChange={handleAnswerChange}
          />
          <StatementWeeklyPlan
            answersData={answers}
            onAnswerChange={handleAnswerChange}
          />
        </>
      )}
      {user?.credentialTypeId === 4 ? (
        <>
          <StatementFoodReflection
            answersData={answers}
            onAnswerChange={handleAnswerChange}
          />
          <StatementHomeVisitingReflection
            answersData={answers}
            onAnswerChange={handleAnswerChange}
          />
          <StatementChildAbuse
            answersData={answers}
            onAnswerChange={handleAnswerChange}
          />
        </>
      ) : null}
      <PrintSaveBtns
        btnColor={"success"}
        onSave={handleSave}
        onDraft={handleSaveDraft}
        onPreview={handlePDF}
        Progress={userProgress}
        {...summaryCompleteProps}
      />
      <CongratsPopUp
        handlePopUp={handlePopUp}
        isPopUp={popUp}
        btnColor={"success"}
        background={"#EBFCF2"}
        description={getTranslation("ResourceCollectionIPopUp")}
      />
    </Grid>
  );
}
