import { Box, Grid, Typography } from "@mui/material";
import { useAppSelector } from "../../app/store/configureStore";
import { useState } from "react";
import agent from "../../app/api/agent";
import { LoadingButton } from "@mui/lab";
import React from "react";
import { useTranslation } from "../../translation/translate";

export default function SubmitIt() {
  const { user } = useAppSelector((state) => state.account);
  const [loadingFinalPDF, setLoadingFinalPDF] = useState(false);
  const [loadingPreviewPDF, setLoadingPreviewPDF] = useState(false);
  const { getTranslation } = useTranslation();

  const handleScholarships = () => {
    const url = "https://www.cdacouncil.org/en/find-cda-scholarships/";
    window.open(url, "_blank");
  };

  const handlePreviewPDF = async () => {
    setLoadingPreviewPDF(true);
    try {
      await agent.PDFViewer.GenerateFinalPDF(user?.portfolioId ?? 0, "Preview");
      const data = await agent.FileUpload.GetFileInfo(
        "PreviewPDF",
        user?.portfolioId ?? 0
      );

      // Get the file
      const response = await agent.FileUpload.GetFiles(data[0].id);

      // Create a blob from the file response
      const blob = new Blob([response], { type: "application/pdf" });

      // Create a URL for the blob
      const imageUrl = URL.createObjectURL(blob);

      // Open the PDF in a new tab
      window.open(imageUrl, "_blank");
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setLoadingPreviewPDF(false);
    }
  };

  return (
    <Grid item xs={12} mt={4} px={3} pt={3} sx={{ bgcolor: "#EEFCFB" }}>
      <Typography variant="body1" fontWeight="bold">
        3. {getTranslation("PuttingAllTogetherHeaderSubmitLine1")}
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            {getTranslation("PuttingAllTogetherHeaderSubmitLine2")}
            <br />
            {getTranslation("PuttingAllTogetherHeaderSubmitLine3")}
          </Typography>
        </li>

        <LoadingButton
          variant="outlined"
          color="secondary"
          onClick={handlePreviewPDF}
          loading={loadingPreviewPDF}
          style={{ textTransform: "initial", marginBottom: "15px" }}
        >
          {getTranslation("PuttingAllTogetherHeaderSubmitLine4")}
        </LoadingButton>
        <li>
          <Typography variant="body1">
            {getTranslation("PuttingAllTogetherHeaderSubmitLine5")}

            <br />
            {getTranslation("PuttingAllTogetherHeaderSubmitLine6")}
          </Typography>
        </li>
        <Box mt={2}>
          <LoadingButton
            variant="outlined"
            color="secondary"
            onClick={handleScholarships}
            loading={loadingFinalPDF}
            style={{ textTransform: "initial", marginBottom: "15px" }}
          >
            {getTranslation("PuttingAllTogetherHeaderSubmitLine7")}
          </LoadingButton>
        </Box>
      </ul>
    </Grid>
  );
}
