import { Grid } from "@mui/material";
import ResourceLibraryStatus from "./ResourceLibraryStatus";
import ResourceCollectionInfo from "./PortfolioForms";
import CDAFunctionalAreas from "./CDAInformation";

export default function ResourceLibraryCollection() {
  return (
    <Grid container p={1} m={1}>
      <ResourceLibraryStatus />
      <ResourceCollectionInfo />
      <CDAFunctionalAreas />
    </Grid>
  );
}
