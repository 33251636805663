import { Grid, TextField, Typography } from "@mui/material";
import ReadMoreReadLess from "../../app/common/ReadMoreReadLess";
import { AnswersProps } from "../../app/models/Answers";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const StatementHomeVisitingReflection: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const defaultValues = {
    homeNeeds: answersData.find((answer) => answer.systemCodeId === 22)?.answer,
    homeDisablility: answersData.find((answer) => answer.systemCodeId === 23)
      ?.answer,
  };

  const { getTranslation } = useTranslation();

  const schema = yup.object().shape({
    homeNeeds: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    homeDisablility: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });
  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const renderWeeklyPlanReflectionCompetence = () => {
    return (
      <>
        <li>{getTranslation("WeeklyPlanReflectionCompetenceBullet1")}</li>
        <li>{getTranslation("WeeklyPlanReflectionCompetenceBullet2")}</li>
        <li>{getTranslation("WeeklyPlanReflectionCompetenceBullet3")}</li>
      </>
    );
  };

  const renderChildAbuseNeglectPolicyReflectionCompetence = () => {
    return (
      <>
        <li>{getTranslation("WeeklyPlanReflectionCompetenceBullet4")}</li>
        <li>{getTranslation("WeeklyPlanReflectionCompetenceBullet5")}</li>
        <li>{getTranslation("WeeklyPlanReflectionCompetenceBullet6")}</li>
      </>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} px={3} pt={3}>
        <Typography variant="body1" fontWeight="bold" paragraph>
          {getTranslation("WeeklyPlanReflectionCompetenceTitle")}
        </Typography>
      </Grid>
      <Grid item xs={12} pt={3} px={3}>
        <Typography variant="body1" paragraph>
          {getTranslation("WeeklyPlanReflectionCompetenceQ1")}
        </Typography>
        <ReadMoreReadLess>
          <>{renderWeeklyPlanReflectionCompetence()}</>
        </ReadMoreReadLess>
      </Grid>
      <Grid item xs={12} pt={3} px={3}>
        <TextField
          label={getTranslation("WeeklyPlanReflectionCompetenceLabel1")}
          multiline
          rows={2}
          fullWidth
          {...register("homeNeeds")}
          name="homeNeeds"
          sx={{ bgcolor: "#FFF" }}
          value={
            answersData.find((answer) => answer.systemCodeId === 22)?.answer ||
            ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = 22;

            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.homeNeeds?.message}
          helperText={errors.homeNeeds?.message}
        />
        <Typography fontSize={13}>
          {defaultValues.homeNeeds?.length}/500
        </Typography>
      </Grid>
      <Grid item xs={12} pt={3} px={3}>
        <Typography variant="body1" paragraph>
          {getTranslation("WeeklyPlanReflectionCompetenceQ2")}
        </Typography>
        <ReadMoreReadLess>
          <>{renderChildAbuseNeglectPolicyReflectionCompetence()}</>
        </ReadMoreReadLess>
      </Grid>
      <Grid item xs={12} py={3} px={3}>
        <TextField
          label={getTranslation("WeeklyPlanReflectionCompetenceLabel2")}
          multiline
          rows={2}
          fullWidth
          {...register("homeDisablility")}
          name="homeDisablility"
          sx={{ bgcolor: "#FFF" }}
          value={
            answersData.find((answer) => answer.systemCodeId === 23)?.answer ||
            ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = 23;

            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.homeDisablility?.message}
          helperText={errors.homeDisablility?.message}
        />
        <Typography fontSize={13}>
          {defaultValues.homeDisablility?.length}/500
        </Typography>
      </Grid>
    </Grid>
  );
};
export default StatementHomeVisitingReflection;
