import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useAppSelector } from "../../app/store/configureStore";
import { useForm } from "react-hook-form";
import ExpandCircleDown from "@mui/icons-material/ExpandCircleDown";
import DraftAndSubmitBtns from "../../app/common/SaveDraftSubmitBtn";
import { ResourceCollectionIVAnswersProps } from "../../app/models/Answers";
import { yupResolver } from "@hookform/resolvers/yup";
import schemaRCIV from "../../app/validations/RC4Validations";
import { useTranslation } from "../../translation/translate";

const AgencyServices: React.FC<ResourceCollectionIVAnswersProps> = ({
  answersData,
  onAnswerChange,
  onSave,
  ResourceCollectionIVCodes,
}) => {
  const { user } = useAppSelector((state) => state.account);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const gridStyle1 = {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
  };
  const textStyle1 = {
    mr: 2,
    mb: isMobile ? 2 : 0,
  };

  const validationSchema = schemaRCIV.RC4ValidationSchema({
    languageSpecialization: !!user?.languageSpecialization,
  });
  const defaultValues = {
    name: answersData.find(
      (answer) => answer.systemCodeId === ResourceCollectionIVCodes.nameCode
    )?.answer,
    address: answersData.find(
      (answer) => answer.systemCodeId === ResourceCollectionIVCodes.addressCode
    )?.answer,
    phone: answersData.find(
      (answer) =>
        answer.systemCodeId === ResourceCollectionIVCodes.telephoneCode
    )?.answer,
    email: answersData.find(
      (answer) => answer.systemCodeId === ResourceCollectionIVCodes.emailCode
    )?.answer,
    website: answersData.find(
      (answer) => answer.systemCodeId === ResourceCollectionIVCodes.websiteCode
    )?.answer,
    description: answersData.find(
      (answer) =>
        answer.systemCodeId === ResourceCollectionIVCodes.agencyDetailsCode
    )?.answer,

    description2ngLanguage: answersData.find(
      (answer) =>
        answer.systemCodeId ===
        ResourceCollectionIVCodes.agencyDetailsBilingualCode
    )?.answer,
  };
  const handleNumberInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const numericInput = input.replace(/\D/g, ""); // Remove non-numeric characters
    event.target.value = numericInput;
    const newValue = event.target.value;
    const systemCodeId = ResourceCollectionIVCodes.telephoneCode;
    onAnswerChange(systemCodeId, newValue);
  };
  const { getTranslation } = useTranslation();

  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
  });

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandCircleDown color="warning" />}>
        <Typography fontWeight={700}>
          {getTranslation("AgencyServicesLine1")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item container xs={12} spacing={4}>
            <Grid item xs={12} md={12} sx={gridStyle1}>
              <TextField
                label={getTranslation("Name_Agencies_Serving_Children_" + ResourceCollectionIVCodes.nameCode)}
                {...register("name")}
                value={
                  answersData.find(
                    (answer) =>
                      answer.systemCodeId === ResourceCollectionIVCodes.nameCode
                  )?.answer || ""
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  const systemCodeId = ResourceCollectionIVCodes.nameCode;
                  onAnswerChange(systemCodeId, newValue);
                }}
                fullWidth
                error={!!errors.name?.message}
                helperText={errors.name?.message}
              />
            </Grid>
            <Grid item xs={12} sx={gridStyle1}>
              <TextField
                sx={textStyle1}
                label={getTranslation("Address_Agencies_Serving_Children_" + ResourceCollectionIVCodes.addressCode)}
                {...register("address")}
                value={
                  answersData.find(
                    (answer) =>
                      answer.systemCodeId ===
                      ResourceCollectionIVCodes.addressCode
                  )?.answer || ""
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  const systemCodeId = ResourceCollectionIVCodes.addressCode;
                  onAnswerChange(systemCodeId, newValue);
                }}
                fullWidth
                error={!!errors.address?.message}
                helperText={errors.address?.message}
              />
              <TextField
                label={getTranslation("Telephone_Agencies_Serving_Children_" + ResourceCollectionIVCodes.telephoneCode)}
                {...register("phone")}
                value={
                  answersData.find(
                    (answer) =>
                      answer.systemCodeId ===
                      ResourceCollectionIVCodes.telephoneCode
                  )?.answer || ""
                }
                onChange={handleNumberInput}
                fullWidth
                error={!!errors.phone?.message}
                helperText={errors.phone?.message}
              />
            </Grid>
            <Grid item xs={12} sx={gridStyle1}>
              <TextField
                sx={textStyle1}
                label={getTranslation("Email_Agencies_Serving_Children_" + ResourceCollectionIVCodes.emailCode)}
                {...register("email")}
                value={
                  answersData.find(
                    (answer) =>
                      answer.systemCodeId ===
                      ResourceCollectionIVCodes.emailCode
                  )?.answer || ""
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  const systemCodeId = ResourceCollectionIVCodes.emailCode;
                  onAnswerChange(systemCodeId, newValue);
                }}
                fullWidth
                error={!!errors.email?.message}
                helperText={errors.email?.message}
              />

              <TextField
                label={getTranslation("Website_Agencies_Serving_Children_" + ResourceCollectionIVCodes.websiteCode)}
                {...register("website")}
                placeholder="https://www.website.com/"
                value={
                  answersData.find(
                    (answer) =>
                      answer.systemCodeId ===
                      ResourceCollectionIVCodes.websiteCode
                  )?.answer || ""
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  const systemCodeId = ResourceCollectionIVCodes.websiteCode;
                  onAnswerChange(systemCodeId, newValue);
                }}
                fullWidth
                error={!!errors.website?.message}
                helperText={errors.website?.message}
              />
            </Grid>
            <Grid item xs={12} sx={gridStyle1}>
              <Grid item xs={12} mr={isMobile ? 0 : 2}>
                <TextField
                  label={getTranslation("Description_Agencies_Serving_Children_" + ResourceCollectionIVCodes.agencyDetailsCode)}
                  {...register("description")}
                  value={
                    answersData.find(
                      (answer) =>
                        answer.systemCodeId ===
                        ResourceCollectionIVCodes.agencyDetailsCode
                    )?.answer || ""
                  }
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const systemCodeId =
                      ResourceCollectionIVCodes.agencyDetailsCode;
                    newValue.length <= 1000 &&
                      onAnswerChange(systemCodeId, newValue);
                  }}
                  fullWidth
                  multiline
                  rows={2}
                />
                <Typography fontSize={13}>
                  {defaultValues.description?.length}/1000
                </Typography>
              </Grid>
              {user?.languageSpecialization && (
                <Grid item xs={12}>
                  <TextField
                    label={getTranslation("Description (in your second language)_Agencies_Serving_Children_" + ResourceCollectionIVCodes.agencyDetailsBilingualCode)}
                    {...register("description2ngLanguage")}
                    value={
                      answersData.find(
                        (answer) =>
                          answer.systemCodeId ===
                          ResourceCollectionIVCodes.agencyDetailsBilingualCode
                      )?.answer || ""
                    }
                    onChange={(e) => {
                      const newValue = e.target.value;
                      const systemCodeId =
                        ResourceCollectionIVCodes.agencyDetailsBilingualCode;
                      newValue.length <= 1000 &&
                        onAnswerChange(systemCodeId, newValue);
                    }}
                    fullWidth
                    multiline
                    rows={2}
                  />
                  <Typography fontSize={13}>
                    {defaultValues.description2ngLanguage?.length}/1000
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>

          <DraftAndSubmitBtns onSave={onSave} />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
export default AgencyServices;
