import {
  Button,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";

import { useCallback, useEffect, useState } from "react";
import { FileInfo } from "../../app/models/Fileinfo";
import UploadedFiles from "../FileUpload/UploadedFiles";
import { useForm } from "react-hook-form";
import {
  Anchor,
  OpenDrawerPayload,
  Section,
} from "../../app/models/FileUpload";
import { openDrawer } from "../FileUpload/drawerSlice";
import agent from "../../app/api/agent";
import { yupResolver } from "@hookform/resolvers/yup";
import RC4WebValidationSchema from "../../app/validations/RC4WebValdations";
import { ResourceIVInfo } from "../../app/models/RCIVResources";
import { useTranslation } from "../../translation/translate";

export default function AddMoreWebsites({
  isEdit,
  websiteID,
  onSubmit,
  websiteCount,
  descriptionValue,
  setDescriptionValue,
  descriptionBLValue,
  setDescriptionBLValue,
  websiteValue,
  setWebsiteValue,
}: any) {
  const { user } = useAppSelector((state) => state.account);
  const [data, setData] = useState<FileInfo[]>([]);
  const { getTranslation } = useTranslation();

  const validationSchema = RC4WebValidationSchema({
    languageSpecialization: !!user?.languageSpecialization,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResourceIVInfo>({
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
  });

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  let section: any = "RCIV4-" + (websiteCount + 1);

  const dispatch = useAppDispatch();

  const handleOpenDrawer = (anchor: Anchor, section: Section) => {
    const payload: OpenDrawerPayload = {
      anchor,
      section,
    };
    dispatch(openDrawer(payload));
  };

  const fetchData = useCallback(async () => {
    try {
      const data = await agent.FileUpload.GetFileInfo(
        "RCIV4",
        user?.portfolioId ?? 0
      );
      const filterByTypeEndingWithOne = true; // Set to true to filter by types ending with "1"

      const filteredData = data.filter((item: { certificateType: string }) => {
        if (filterByTypeEndingWithOne) {
          return item.certificateType.endsWith(websiteCount + 1);
        } else {
          return true; // Include all records if filterByTypeEndingWithOne is false
        }
      });
      setData(filteredData);
    } catch (error) {
      // Handle errors if necessary.
    }
  }, [user?.portfolioId, websiteCount]);

  useEffect(() => {
    fetchData();
  }, [onSubmit]);

  function handleDeleteFile(): void {
    fetchData();
  }

  return (
    <Grid item container bgcolor={"white"} p={2} m={1} ml={3} display={"block"}>
      <Grid item container spacing={3} xs={12}>
        <Grid id="firstcontainer" item container spacing={3} xs={12}>
          <Grid item xs={12} md={user?.languageSpecialization ? 12 : 8}>
            {isEdit && (
              <TextField
                style={{ display: "none" }}
                value={websiteID}
                {...register("id")}
              />
            )}
            <TextField
              label={getTranslation("RCIV-4Websites_Website")}
              {...register("website")}
              defaultValue={websiteValue}
              onChange={(e) => {
                const newValue = e.target.value;
                setWebsiteValue(newValue);
              }}
              fullWidth
              placeholder="https://www.website.com/"
              error={!!errors.website?.message}
              helperText={
                errors.website?.message ? errors.website.message.toString() : ""
              }
            />
          </Grid>
          <Grid item xs={12} md={user?.languageSpecialization ? 6 : 12}>
            <TextField
              label={getTranslation("RCIV-4Websites_Description")}
              {...register("description")}
              defaultValue={descriptionValue}
              onChange={(e) => {
                const newValue = e.target.value;
                newValue.length <= 1000 && setDescriptionValue(newValue);
              }}
              multiline
              rows={4}
              fullWidth
              error={!!errors.description?.message}
              helperText={
                errors.description?.message
                  ? errors.description.message.toString()
                  : ""
              }
            />
            <Typography fontSize={13}>
              {descriptionValue.length}/1000
            </Typography>
          </Grid>
          {user?.languageSpecialization && (
            <Grid item xs={12} md={6}>
              <TextField
                label={getTranslation(
                  "RCIV-4Websites_Descripción (segundo idioma)"
                )}
                {...register("description2ndLanguage")}
                defaultValue={descriptionBLValue}
                onChange={(e) => {
                  const newValue = e.target.value;
                  newValue.length <= 1000 && setDescriptionBLValue(newValue);
                }}
                fullWidth
                multiline
                rows={4}
                error={!!errors.description2ndLanguage?.message}
                helperText={
                  errors.description2ndLanguage?.message
                    ? errors.description2ndLanguage.message.toString()
                    : ""
                }
              />
              <Typography fontSize={13}>
                {descriptionBLValue.length}/1000
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid id="docUpload" item container mt={3} mb={3} xs={12}>
        <Grid item xs={isMobile ? 12 : 6} mb={2}>
          <Typography>{getTranslation("UploadDocuments")}</Typography>
          <Button
            startIcon={<FileUploadIcon />}
            variant="outlined"
            color="warning"
            sx={{
              textTransform: "none",
            }}
            onClick={() => handleOpenDrawer("right", section)}
          >
            {getTranslation("UploadArticle")}
          </Button>
        </Grid>
        <Grid item xs={6} display={isMobile ? "none" : "show"}>
          <Typography variant="body1" fontWeight="bold">
            {getTranslation("UploadedDocuments")}
          </Typography>
          <Typography variant="subtitle2">
            {data.length}{" "}
            {data.length === 1
              ? getTranslation("file")
              : getTranslation("files")}
            {getTranslation("Uploaded")}
          </Typography>
          {data.map((item) => (
            <UploadedFiles
              fileOrder={data.indexOf(item) + 1}
              key={item.id}
              fileInfo={item}
              onDeleteFile={() => handleDeleteFile()}
            />
          ))}
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12} mr={2}>
          <Button
            onClick={handleSubmit(onSubmit)}
            color="warning"
            variant="contained"
            sx={{
              marginRight: 3,
              marginBottom: isMobile ? 2 : 0,
              textTransform: "initial",
            }}
          >
            {getTranslation("Save")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
