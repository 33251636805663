import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import status from "../../assets/images/resource-library.png";
import { useTranslation } from "../../translation/translate";
export default function ResourceLibraryStatus() {
  const { getTranslation } = useTranslation();
  return (
    <>
      <Card
        sx={{
          width: "100%",
          backgroundColor: "rcLibrary.light",
          boxShadow: " 0 16px #7B9F03",
          marginBottom: 2,
        }}
      >
        <CardContent>
          <Grid container mt={1}>
            <Grid item xs={2} sm={2} md={1} sx={{ textAlign: "center" }}>
              <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                <img src={status} alt="status" style={{ width: "70%" }} />
              </Box>
            </Grid>
            <Grid
              item
              xs={10}
              sm={10}
              md={11}
              display="block"
              flexDirection="column"
              paddingLeft={3}
            >
              <Typography variant="h6" fontWeight={700} marginBottom={1}>
                {getTranslation("ResourceLibrary")}
              </Typography>
              <Typography marginBottom={1}>
                {getTranslation("ResourceLibraryStatusLine1")}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
