import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { useAppSelector } from "../../app/store/configureStore";
import ExpandCircleDown from "@mui/icons-material/ExpandCircleDown";
import NonEnglishServices from "./NonEnglishServices";
import { ResourceIVForm } from "../../app/models/Answers";
import { useTranslation } from "../../translation/translate";

export default function TranslationServices(props: any) {
  const { onSave, answersData, onAnswerChange } = props;
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();

  const ResourceCollectionIVCodes: ResourceIVForm = {
    nameCode: 200,
    addressCode: 201,
    telephoneCode: 202,
    emailCode: 203,
    websiteCode: 204,
    agencyDetailsCode: 319,
    agencyDetailsBilingualCode: 322,
  };

  const ResourceCollectionSignLanguageVCodes: ResourceIVForm = {
    nameCode: 210,
    addressCode: 211,
    telephoneCode: 212,
    emailCode: 213,
    websiteCode: 214,
    agencyDetailsCode: 321,
    agencyDetailsBilingualCode: 320,
  };

  return (
    <Grid container mb={2} p={1} ml={1} bgcolor={"warning.light"}>
      <Grid item>
        <Typography fontWeight={700}>
          {getTranslation("RCIVTranslationServicsLine1")}
        </Typography>
      </Grid>
      <Grid item mb={3}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandCircleDown color="warning" />}>
            <Typography fontWeight={700}>
              {getTranslation("RCIVTranslationServicsLine2")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item>
              <NonEnglishServices
                onSave={onSave}
                answersData={answersData}
                onAnswerChange={onAnswerChange}
                ResourceCollectionIVCodes={ResourceCollectionIVCodes}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandCircleDown color="warning" />}>
            <Typography fontWeight={700}>
              {getTranslation("RCIVTranslationServicsLine3")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item>
              <NonEnglishServices
                onSave={onSave}
                answersData={answersData}
                onAnswerChange={onAnswerChange}
                ResourceCollectionIVCodes={ResourceCollectionSignLanguageVCodes}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}
