import { Grid, Theme, useMediaQuery } from "@mui/material";
import { SummaryCompleteProps } from "../../app/models/SummaryComplete";
import MyProgressStatus from "./MyProgressStatus";
import ProgressGuide from "./ProgressGuide";
import CompetencyProgress from "./CompetencyProgress";

export default function MyProgressSummary() {
  const summaryCompleteProps: SummaryCompleteProps = {
    title: "My Progress Summary",
    percent: "0%",
    header: "",
    description: `Here you can see your progress on each of the major portfolio sections. Each competency section has two subsections: a resource collection and a reflective statement. You may complete those sections in any order, but for each competency, it is best to complete the resource collection before the reflective statement.`,
  };
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <Grid container p={2} mb={2}>
      <MyProgressStatus {...summaryCompleteProps} />
      {isMobile ? null : <ProgressGuide />}
      <CompetencyProgress />
    </Grid>
  );
}
