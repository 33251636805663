import { Button, Grid } from "@mui/material";
import ChecklistHeader from "./ChecklistHeader";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ChecklistForm from "./ChecklistForm";
import { useCallback, useEffect, useState } from "react";
import { Answers } from "../../app/models/Answers";
import { useAppSelector } from "../../app/store/configureStore";
import agent from "../../app/api/agent";
import LoadingSpinner from "../../app/common/LoadingSpinner";
import PrintSaveBtns from "../../app/common/PrintSaveBtn";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../translation/translate";

export default function ProfessionalChecklist() {
  const { user } = useAppSelector((state) => state.account);
  const [answers, setAnswers] = useState<Answers[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const { getTranslation } = useTranslation();

  const fetchData = useCallback(async () => {
    try {
      if (user?.credentialName && user?.portfolioId) {
        const response = await agent.Answers.GetAnswers(
          user.credentialName,
          user.portfolioId,
          "PortfolioChecklist"
        );

        setAnswers(response);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [user?.credentialName, user?.portfolioId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAnswerChange = (systemCodeId: number, newValue: string) => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((answer) =>
        answer.systemCodeId === systemCodeId
          ? { ...answer, answer: newValue }
          : answer
      )
    );
  };

  const handleSaveDraft = async () => {
    setIsLoading(true);
    // This is just a sample function for demonstration
    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );
    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    navigate("/portfoliochecklist");
    setIsLoading(false);
  };
  const handleSave = async () => {
    setIsLoading(true);
    // This is just a sample function for demonstration
    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );
    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    navigate("/myprogresssummary");
    setIsLoading(false);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container p={2} m={1}>
      <Grid item mb={1}>
        <Button
          color="myVerify"
          sx={{ textTransform: "initial" }}
          startIcon={<ArrowBackIosIcon />}
          href="/portfoliochecklist"
        >
          {getTranslation("BackToCheckList")}
        </Button>
      </Grid>
      <Grid item>
        <ChecklistHeader />
        <ChecklistForm
          answersData={answers}
          onAnswerChange={handleAnswerChange}
        />
        <PrintSaveBtns
          btnColor={"myVerify"}
          title={"OnlineForm"}
          onSave={handleSave}
          onDraft={handleSaveDraft}
        />
      </Grid>
    </Grid>
  );
}
