import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'

const createUserStateReport = ({title, report, pdfData}: any): jsPDF=> { 
    // Create a new jsPDF instance.
    const doc = new jsPDF();
  
    // Calculate double-spacing (line height factor) for 12-point font.
    const lineHeightFactor = 2;
    doc.setLineHeightFactor(lineHeightFactor);
    //get page size
    const pageWidth = doc.internal.pageSize.getWidth()
    const pageHeight = doc.internal.pageSize.getHeight()
    //doc margins
    let yOffset = 35;
    let marginBottom = doc.internal.pageSize.height -50;
    const answers = doc.splitTextToSize(report, 200, 'center')
    doc.setFontSize(16)
    doc.setFont('times','bold')
    doc.text(answers, 15,20)
    yOffset +=10

    

    autoTable(doc, {
      theme: 'grid',
      margin: {top: 30},
      headStyles: {fillColor: 255, textColor: 0, lineWidth: .1 },
      body: pdfData,
      columns: [
          {header: 'State', dataKey: 'state'},
          {header: 'Total', dataKey: 'count'}, 
      ],
    })
    


      // Generate a data URL for the PDF.
  const pdfDataUri = doc.output('datauristring'); // Specify the filename here

  // Open the PDF in a new tab.
  const newTab = window.open();


  if (newTab) {
    newTab.document.write(`
      <html>
      <head>
        <title>${title}.pdf</title>
      </head>
      <body>
        <embed width='100%' height='100%' src='${pdfDataUri}' type='application/pdf'>
        
      </body>
      </html>
    `);
  } else {
    // Handle the case where the new tab couldn't be opened.
    alert('Popup blocker prevented opening a new tab.');
  }

    return doc;

}
export default createUserStateReport;
