import { Button, Grid, Typography } from "@mui/material";
import logo from "../../assets/images/LandingLogo.png";
import { useNavigate } from "react-router-dom";

export default function LandingInfo() {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/account/login");
  };

  return (
    <Grid item container xs={12}>
      <Grid item md={12} bgcolor="#FBFBFB" height={85} alignContent="center">
        <Typography fontWeight={700} fontSize={24} textAlign="center" mt={3}>
          Seeking the Child Development Associate Credential® (CDA)?
        </Typography>
      </Grid>
      <Grid item container spacing={4} mt={2} marginLeft={2} p={3} width="95%">
        <Grid item md={8}>
          <Typography color="#73A05E" fontSize={18} fontWeight={700}>
            Need help with your Professional Portfolio?
          </Typography>
          <Typography>
            We have just the tool for you -{" "}
            <span style={{ fontWeight: 700 }}>and it's free!</span>
          </Typography>
          <Typography>
            With our new CDA® Professional Portfolio online tool, you can
            complete your portfolio at your own pace and on your own schedule.
            This tool takes you step-by-step through the requirements for each
            section, and it's full of handy writing tips and resources to help
            you along the way.
          </Typography>
          <Typography>
            It covers any credential setting - infant-toddler, preschool,
            family, or home visitor - and allows for bilingual specialization.
            And it will soon be available in Spanish.
          </Typography>
          <Typography>
            Assemble your resource collection items. Write your reflective
            statements. Keep track of what you've completed and what you have
            left to do on your own personal dashboard. Allow your trainer,
            mentor, employer, or Professional Development Specialist access to
            review your progress or completed portfolio. Create a pdf of your
            personal professional Portfolio to print or share.
          </Typography>
        </Grid>
        <Grid item md={4}>
          <img src={logo} width={416} alt="Person filling out a portfolio" />
        </Grid>
      </Grid>
      <Grid
        item
        bgcolor="#F2F6E6"
        width="95%"
        minHeight={196}
        paddingLeft={10}
        paddingRight={10}
        margin={2}
        borderRadius={8}
      >
        <Typography mt={4} fontSize={20}>
          <span style={{ color: "#7B9F03", fontWeight: 700 }}>Trainers - </span>
          create a class, use as teaching tool, and, with your students'
          permission, track your students' Professional Portfolio progress
          online!
        </Typography>
        <Typography fontSize={20}>
          <span style={{ color: "#7B9F03", fontWeight: 700 }}>
            Council Professional Development Specialists -{" "}
          </span>
          with your candidates' permission, see their portfolio progress and/or
          review their final Professional Portfolio online!
        </Typography>
      </Grid>
      <Grid item xs={12} textAlign="center" mt={2}>
        <Typography fontSize={20} fontWeight={700}>
          To get started, create your CDA Professional Portfolio account:
        </Typography>

        <Button onClick={handleLogin} variant="contained" color="primary">
          Create Account or Sign In
        </Button>
      </Grid>
    </Grid>
  );
}
