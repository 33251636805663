import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../store/configureStore";

interface Props {
  role?: string;
}

export default function RequireAuth({ role }: any) {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.account);
  const location = useLocation();
  const isRoot = location.pathname === "/";

  useEffect(() => {
    if (!user) {
      // Trigger the navigation using the Navigate component
      return navigate("/", { state: { from: location } });
    }

    if (role && !role.includes(user.role)) {
      return navigate("/unauthorized", { state: { error: location } });
    }
    if (user && isRoot && user.welcomeComplete === true) {
      // Trigger the navigation using the Navigate component
      return navigate("/myprogresssummary");
    }

    if (user && isRoot) {
      // Trigger the navigation using the Navigate component
      return navigate("/welcome");
    }
    if (user && !isRoot) {
      // Trigger the navigation using the Navigate component
      return navigate(location.pathname);
    }
  }, [user]);

  if (!user) {
    // Return null if user is null
    return null;
  }

  // Render child components if user is not null
  return <Outlet />;
}
