import ResourceIIIStatus from "./ResourceIIIStatus";
import { SummaryCompleteProps } from "../../app/models/SummaryComplete";
import { Grid } from "@mui/material";
import ChildrenBookDevelopement from "./ResourceIIIChildrenBooks";
import AddaBook from "./ResourceIIIAddNewBook";
import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../app/store/configureStore";
import agent from "../../app/api/agent";
import LoadingSpinner from "../../app/common/LoadingSpinner";
import { useTranslation } from "../../translation/translate";

export default function CompetencyIIIResourceCollection() {
  const { getTranslation } = useTranslation();
  const [summaryCompleteProps, setSummaryCompleteProps] =
    useState<SummaryCompleteProps>({
      title: getTranslation("CompetencyIIIResourceCollectionTitle"),
      percent: "",
      header: getTranslation("CompetencyIIIResourceCollectionHeader"),
      description: getTranslation("CompetencyIIIResourceCollectionDescription"),
    });

  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAppSelector((state) => state.account);

  const fetchData = useCallback(async () => {
    try {
      if (user?.portfolioId) {
        const compData = await agent.Progress.GetCompentencyResultsByType(
          user.portfolioId,
          "RCIII"
        );
        setSummaryCompleteProps((prevSummaryCompleteProps) => ({
          ...prevSummaryCompleteProps,
          percent: compData.resourceProgress + "%",
        }));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [user?.portfolioId]);

  const RecallData = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Grid container p={2} mb={2}>
        <ResourceIIIStatus {...summaryCompleteProps} />
        <ChildrenBookDevelopement />
        <AddaBook RecallData={RecallData} />
      </Grid>
    </>
  );
}
