import { ExpandCircleDown } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
  Link,
} from "@mui/material";
import { useTranslation } from "../../translation/translate";

export default function HelpSection() {
  const { getTranslation } = useTranslation();
  return (
    <Grid container mt={2} pt={3}>
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandCircleDown sx={{ color: "comp3.main" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontSize={20} fontWeight={700}>
              {getTranslation("Help")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontSize: "18px",
              lineHeight: "30px",
              fontFamily: "Lato Regular",
              bgcolor: "comp3.light",
            }}
          >
            <Typography>
              {getTranslation("HelpSectionLine1")}{" "}
              <Link href="mailto:support@yourcdaportfolio.org" type="email">
                support@yourcdaportfolio.org
              </Link>
            </Typography>
            <Typography mt={3}>
              {getTranslation("HelpSectionLine2")}{" "}
              <span style={{ color: "#CC581F", fontStyle: "italic" }}>
                {getTranslation("HelpSectionLine3")}
              </span>{" "}
              {getTranslation("HelpSectionLine4")}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}
