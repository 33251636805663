import { Typography, Grid, TextField } from "@mui/material";
import { useAppSelector } from "../../../app/store/configureStore";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../../translation/translate";
import { get } from "http";

export default function OnlineEditContent({
  answersData,
  onAnswerChange,
  defaultValues,
}: any) {
  const { user } = useAppSelector((state) => state.account);

  const { getTranslation } = useTranslation();

  const todaysDate = new Date().toLocaleDateString("default", {
    dateStyle: "long",
  });

  const schema = yup.object().shape({
    headerTitle: yup
      .string()
      .required(getTranslation("ValidationCoverSHeetTitle")),
    headerDate: yup.string(),
  });

  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  return (
    <Grid container p={2}>
      <Grid item xs={12}>
        <Typography fontSize={26} fontWeight={700}>
          {getTranslation("EditContent")}
        </Typography>
      </Grid>
      <Grid className="candidate" item container spacing={2}>
        <Grid item xs={12} marginTop={2}>
          <Typography fontWeight={700}>
            {getTranslation("MYCDAEducationTitle1")}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={getTranslation("FirstName")}
            defaultValue={user?.firstName}
            variant="filled"
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={getTranslation("LastName")}
            defaultValue={user?.lastName}
            variant="filled"
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label={getTranslation("CandidateIDNumber")}
            defaultValue={user?.cdaCouncilCandidateId}
            variant="filled"
            disabled
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid className="header" item container spacing={2}>
        <Grid item xs={12} marginTop={3}>
          <Typography fontWeight={700}>{getTranslation("Header")}</Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            label="Title"
            {...register("headerTitle")}
            placeholder={getTranslation("MyProfessionalPortfolio")}
            fullWidth
            value={
              answersData.find((answer: any) => answer.systemCodeId === 387)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 387;
              newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.headerTitle?.message}
            helperText={
              errors.headerTitle?.message &&
              errors.headerTitle.message.toString()
            }
          />
        </Grid>
        <Grid item md={4} xs={5}>
          <TextField
            {...register("headerDate")}
            value={todaysDate}
            variant="filled"
            disabled
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
