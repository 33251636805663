import { Grid, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { AnswersProps } from "../../app/models/Answers";
import { useTranslation } from "../../translation/translate";
import { get } from "http";

const EFormTable: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const thisDate = new Date();
  const { getTranslation } = useTranslation();

  const SubjectAreaData = [
    {
      id: 1,
      subject: getTranslation("EducationSummary327"),
      initial: "",
      registerInitial: "initial1",
    },
    {
      id: 2,
      subject: getTranslation("EducationSummary328"),
      initial: "",
      registerInitial: "initial2",
    },
    {
      id: 3,
      subject: getTranslation("EducationSummary329"),
      initial: "",
      registerInitial: "initial3",
    },
    {
      id: 4,
      subject: getTranslation("EducationSummary330"),
      initial: "",
      registerInitial: "initial4",
    },
    {
      id: 5,
      subject: getTranslation("EducationSummary331"),
      initial: "",
      registerInitial: "initial5",
    },
    {
      id: 6,
      subject: getTranslation("EducationSummary332"),
      initial: "",
      registerInitial: "initial6",
    },
    {
      id: 7,
      subject: getTranslation("EducationSummary333"),
      initial: "",
      registerInitial: "initial7",
    },
    {
      id: 8,
      subject: getTranslation("EducationSummary334"),
      initial: "",
      registerInitial: "initial8",
    },
  ];

  const defaultValues = {
    initial1:
      answersData.find((answer) => answer.systemCodeId === 327)?.answer || "",
    initial2:
      answersData.find((answer) => answer.systemCodeId === 328)?.answer || "",
    initial3:
      answersData.find((answer) => answer.systemCodeId === 329)?.answer || "",
    initial4:
      answersData.find((answer) => answer.systemCodeId === 330)?.answer || "",
    initial5:
      answersData.find((answer) => answer.systemCodeId === 331)?.answer || "",
    initial6:
      answersData.find((answer) => answer.systemCodeId === 332)?.answer || "",
    initial7:
      answersData.find((answer) => answer.systemCodeId === 333)?.answer || "",
    initial8:
      answersData.find((answer) => answer.systemCodeId === 334)?.answer || "",
    signature:
      answersData.find((answer) => answer.systemCodeId === 335)?.answer || "",
    date:
      answersData.find((answer) => answer.systemCodeId === 336)?.answer || "",
  };

  const { register } = useForm({
    defaultValues,
    mode: "onTouched",
  });

  return (
    <Grid container p={2}>
      <Grid
        item
        bgcolor="cdaEDU.main"
        color="white"
        p={0.5}
        mt={2}
        mb={2}
        xs={12}
        display="flex"
        justifyContent="space-between"
      >
        <Typography>{getTranslation("CDASubjectAreas")}</Typography>
        <Typography>{getTranslation("PleaseInitialBelow")}</Typography>
      </Grid>
      {SubjectAreaData.map((subj: any, index: number) => (
        <Grid
          key={subj.id}
          item
          container
          bgcolor={index % 2 !== 0 ? "#FBFDF5" : "white"}
          xs={12}
          display="flex"
          justifyContent="space-between"
          alignContent="center"
          m={1}
        >
          <Typography>{subj.subject}</Typography>
          <Grid item xs={12} md={1}>
            <TextField
              placeholder={getTranslation("initials")}
              inputProps={{
                maxLength: 3,
              }}
              {...register(subj.registerInitial)}
              onChange={(e) => {
                const newValue = e.target.value;
                const systemCodeId = 326 + subj.id;

                onAnswerChange(systemCodeId, newValue);
              }}
            />
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12} m={3}>
        <Typography>{getTranslation("MYCDAEducationAttest")}</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          label={getTranslation("EducationSummary335")}
          {...register("signature")}
          inputProps={{ maxLength: 70 }}
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = 335;

            onAnswerChange(systemCodeId, newValue);
            const systemCodeIdDate = 336;

            /*  Adds signature date once the form is signed */
            onAnswerChange(systemCodeIdDate, thisDate.toDateString());
          }}
          InputProps={{
            inputProps: {
              style: {
                textAlign: "center",
                fontSize: 20,
                fontFamily: "Cedarville Cursive, cursive",
              },
            },
          }}
          fullWidth
        />
        <Typography fontSize={11}>
          {getTranslation("DISCLAIMER")}
        </Typography>
      </Grid>
      <Grid item xs={6} textAlign="center">
        <TextField
          disabled
          variant="filled"
          label={getTranslation("EducationSummary336")}
          {...register("date")}
        />
      </Grid>
    </Grid>
  );
};

export default EFormTable;
