import { Box, Grid, Typography } from "@mui/material";
import logo from "../../assets/images/CDALogo.png";

export default function LandingHeader() {
  return (
    <Grid item container>
      <Grid item xs={12} m={1} mb={2}>
        <img src={logo} width={185} />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box sx={{ flex: 1, width: "33.33%", height: 30 }} className="gold">
            <Typography fontSize={18} ml={1}>
              CDA Portfolio
            </Typography>
          </Box>
          <Box
            sx={{ flex: 1, width: "33.33%", height: 30 }}
            className="green"
          ></Box>
          <Box
            sx={{ flex: 1, width: "33.33%", height: 30 }}
            className="blue"
          ></Box>
        </Box>
      </Grid>
    </Grid>
  );
}
