import { Grid } from "@mui/material";
import Footer from "../../app/layout/Footer";
import LandingHeader from "./LandingHeader";
import LandingInfo from "./LandingInfo";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import agent from "../../app/api/agent";
import LoadingSpinner from "../../app/common/LoadingSpinner";

export default function Landing() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await agent.Account.currentUser();
      const admin = data.role === "CSI Admin" || data.role === "Council Admin";
      const trainer = data.role === "Trainer";
      const otherViewer = data.role === "Other Viewer";
      if (data && admin) {
        navigate("/admin/dashboard");
        setIsLoading(false);
      } else if (data && trainer) {
        navigate("/trainer/dashboard");
        setIsLoading(false);
      } else if (data && otherViewer) {
        navigate("/trainer/manageusers");
        setIsLoading(false);
      } else {
        navigate("/myprogresssummary");
        setIsLoading(false);
      }
    };
    fetchData();
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <Grid container p={1}>
      <LandingHeader />
      <LandingInfo />

      <Grid item xs={12}>
        <Footer />
      </Grid>
    </Grid>
  );
}
