import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    compV: Palette['primary'];
    comp3: Palette['primary'];
    compVI: Palette['primary'];
    cdaEDU: Palette['primary'];
    myVerify: Palette['primary'];
    rcLibrary: Palette['primary'];
  }

  interface PaletteOptions {
    compV?: PaletteOptions['primary'];
    comp3?: PaletteOptions['primary'];
    compVI?: PaletteOptions['primary'];
    cdaEDU?: PaletteOptions['primary'];
    myVerify?: PaletteOptions['primary'];
    rcLibrary?: PaletteOptions['primary'];
  }
}

// Update the Button's color options to include an ochre option
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    compV: true;
    comp3: true;
    compVI: true;
    cdaEDU: true;
    myVerify: true;
    rcLibrary: true;
  }
  
  interface ButtonStyleOverrides {
    compV: true;
    comp3: true;
    compVI: true;
    myVerify: true;
    rcLibrary: true;
  }
}


const theme = createTheme({
  typography: {
    fontFamily: ['Lato', 'sans-serif'].join(','),

    fontSize: 15,
  },
 

  palette: {
    success: {
      main: "#049C8C",
      light: "#EBFCF2" 
    },
    secondary: {
      main: "#3e7787",
    },
    primary: {
      main: '#7B9F03', 
    },
    error: {
      main: "#db2424"
    },
    warning: {
      main: "#5E2F82",
      light: "#F3EBFC"

    },
    compV: {
      main: "#AD2E6F",
      light: "#FFEBF5"
    },
    comp3:{
      main: "#CC581F",
      light: "#FFF4F0"
    },
    compVI: {
      main: "#C32627",
      light: "#FFEFEF",
    },
    cdaEDU: {
      main: "#7DA740",
      light: "#F6FBEB"
    },
    myVerify: {
      main: "#3E7787",
      light: "#EEFCFB"
    },
    rcLibrary: {
      main: "#7B9F03",
      light: "#E5F7CA"
    },
    
 
  
   
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "column",
          minHeight: "95vh",
        },
      },
    },
    
    MuiButton: {
      styleOverrides: {
        outlinedSuccess: {
          backgroundColor: "white",
          color: "#049C8C", 
          "&:hover": {
            backgroundColor: "white",
          },
        },
        outlinedInfo: {
          backgroundColor: "white",
          color: "#1A82A7", 
          "&:hover": {
            backgroundColor: "white",
          },
        },
        textInfo: {
          backgroundColor: "#E9F6FC",
          color: "#1A82A7", 
          "&:hover": {
            backgroundColor: "#E9F6FC",
          },
        },
        outlinedSecondary: {
          backgroundColor: "white",
          color: "#3E7787", 
          "&:hover": {
            backgroundColor: "white",
          },
        },

        outlinedWarning: {
          backgroundColor: "white",
          color: "#5E2F82", 
          "&:hover": {
            backgroundColor: "white",
          },
        },
        containedWarning: {
          backgroundColor: "#5E2F82",
          color: "white", 
          "&:hover": {
            backgroundColor: "#5E2F82",
          },
        },
        textWarning: {
          color: "#5E2F82", 
         
        },
        contained: {
          color: "white", 
        },
        
          outlined:  {
            backgroundColor: "white",
            "&:hover": {
              backgroundColor: "white"
            }
          },
       
       
     
        root: {
          fontFamily: ['Lato', 'sans-serif'].join(','),
           fontSize: 15,
        },
      },
    },
  },

});

export default theme;
