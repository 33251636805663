import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandCircleDown from "@mui/icons-material/ExpandCircleDown";
import { Grid } from "@mui/material";
import { useAppSelector } from "../../app/store/configureStore";
import ResourceCollectionIIForm from "./ResourceCollectionIIForm";
import { ResourceCollectionIIFormObject } from "../../app/models/Answers";
import { useTranslation } from "../../translation/translate";

export default function LanguageAndLiteracy(props: any) {
  const { onSave, answersData, onAnswerChange } = props;
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();
  const ResourceCollectionIICodes: ResourceCollectionIIFormObject = {
    titleCode: 75,
    titleBilingualCode: 76,
    ageGroupCode: 77,
    ageGroupBiligualCode: 78,
    intendedGoalsCode: 79,
    intendedGoalsBiligualCode: 80,
    materialsCode: 81,
    materialsBiligualCode: 82,
    processCode: 83,
    processBiligualCode: 84,
    developmentCode: 85,
    developmentBiligualCode: 86,
  };

  return (
    <Grid item xs={12} px={3} pt={3}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandCircleDown className="blue-expand-icon" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="body1" fontWeight="bold">
            {user?.credentialTypeId === 4
              ? getTranslation("RCIILearningExperienceAccordion3A")
              : getTranslation("RCIILearningExperienceAccordion3B")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ResourceCollectionIIForm
            onSave={onSave}
            answersData={answersData}
            onAnswerChange={onAnswerChange}
            ResourceCollectionIICodes={ResourceCollectionIICodes}
          />
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
