import {
  Box,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { useState } from "react";
import HelpSupport from "./HelpSupport";

const Footer = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box sx={{ flex: 1, width: "33.33%" }} className="blue"></Box>
        <Box sx={{ flex: 1, width: "33.33%" }} className="green"></Box>
        <Box sx={{ flex: 1, width: "33.33%" }} className="gold"></Box>
      </Box>

      <Grid
        item
        container
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        xs={12}
      >
        <Typography color="#575f65" fontSize={isMobile ? 10 : 14} mt={2}>
          Powered by Care Solutions, Inc. {new Date().getFullYear()} All Right
          Reserved
        </Typography>

        <IconButton onClick={handlePopover}>
          <HelpIcon sx={{ fontSize: { xs: "medium", md: "large" } }} />
        </IconButton>
        <HelpSupport
          openPopover={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
        />
      </Grid>
    </Grid>
  );
};

export default Footer;
