import { Button, Grid } from "@mui/material";
import VerifyEformHeader from "./EFormHeader";
import EFormStepOne from "./EFormStep1";
import EFormStepTwo from "./EFormStep2";
import EFormStepThree from "./EFormStep3";
import { useCallback, useEffect, useState } from "react";
import { Answers } from "../../app/models/Answers";
import agent from "../../app/api/agent";
import { useAppSelector } from "../../app/store/configureStore";
import LoadingSpinner from "../../app/common/LoadingSpinner";
import CongratsPopUp from "../../app/common/CongratsPopUp";

export default function VerificationEForm() {
  const [answers, setAnswers] = useState<Answers[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAppSelector((state) => state.account);
  const [popUp, setPopUp] = useState(false);
  const handlePopUp = () => {
    setPopUp((prev) => !prev);
  };
  const fetchData = useCallback(async () => {
    try {
      if (user?.credentialName && user?.portfolioId) {
        const response = await agent.Answers.GetAnswers(
          user.credentialName,
          user.portfolioId,
          "myVerify"
        );
        setAnswers(response);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [user?.credentialName, user?.portfolioId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAnswerChange = (systemCodeId: number, newValue: string) => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((answer) =>
        answer.systemCodeId === systemCodeId
          ? { ...answer, answer: newValue }
          : answer
      )
    );
  };

  const handleSave = async () => {
    setIsLoading(true);
    // This is just a sample function for demonstration
    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );
    try {
      await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    } catch {
      console.error();
    }
    setIsLoading(false);
    handlePopUp();
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container p={2} m={2}>
      <VerifyEformHeader />
      <EFormStepOne answersData={answers} onAnswerChange={handleAnswerChange} />
      <EFormStepTwo answersData={answers} onAnswerChange={handleAnswerChange} />
      <EFormStepThree
        answersData={answers}
        onAnswerChange={handleAnswerChange}
      />
      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          style={{ margin: 2 }}
          color="myVerify"
          onClick={handleSave}
        >
          Submit form
        </Button>
      </Grid>
      <CongratsPopUp
        handlePopUp={handlePopUp}
        isPopUp={popUp}
        description={"You have completed your Online Dialogue Worksheet!"}
        btnColor={"myVerify"}
        background={"myVerify.light"}
      />
    </Grid>
  );
}
