import React, { useCallback, useEffect, useState } from "react";
import { Grid, Theme, useMediaQuery } from "@mui/material";
import Resource1Status from "./Resource1Status";
import Resource1FirstAid from "./Resource1FirstAid";
import Resource1MenuFeedingSchedule from "./Resource1MenuFeedingSchedule";
import Resource1WeeklyPlan from "./Resource1WeeklyPlan";
import { useAppSelector } from "../../app/store/configureStore";
import Resource1StateChildAbuse from "./Resource1StateChildAbuse";
import { SummaryCompleteProps } from "../../app/models/SummaryComplete";
import agent from "../../app/api/agent";
import LoadingSpinner from "../../app/common/LoadingSpinner";
import { Answers } from "../../app/models/Answers";
import PrintSaveBtns from "../../app/common/PrintSaveBtn";
import CongratsPopUp from "../../app/common/CongratsPopUp";
import { useTranslation } from "../../translation/translate";

const CompetencyIResourceCollection: React.FC = () => {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();
  const [summaryCompleteProps, setSummaryCompleteProps] =
    useState<SummaryCompleteProps>({
      title: getTranslation("ResourceCollectionIHeader"),
      percent: "",
      header: getTranslation("ResourceCollectionITitle"),
      description: getTranslation("ResourceCollectionIDescription"),
    });

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const [answers, setAnswers] = useState<Answers[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [popUp, setPopUp] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [userProgress, setUserProgress] = useState(0);

  const handlePopUp = () => {
    setPopUp((prev) => !prev);
  };

  const fetchData = useCallback(async () => {
    try {
      if (user?.credentialName && user?.portfolioId) {
        const response = await agent.Answers.GetAnswers(
          user.credentialName,
          user.portfolioId,
          "RCI"
        );
        const compData = await agent.Progress.GetCompentencyResultsByType(
          user.portfolioId,
          "RCI"
        );
        setUserProgress(compData.resourceProgress);

        setSummaryCompleteProps((prevSummaryCompleteProps) => ({
          ...prevSummaryCompleteProps,
          percent: compData.resourceProgress + "%",
        }));
        setAnswers(response);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [user?.credentialName, user?.portfolioId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAnswerChange = (systemCodeId: number, newValue: string) => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((answer) =>
        answer.systemCodeId === systemCodeId
          ? { ...answer, answer: newValue }
          : answer
      )
    );
  };

  const handleSaveDraft = async () => {
    setIsLoading(true);
    // This is just a sample function for demonstration
    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );
    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    fetchData();
    setIsLoading(false);
  };
  const handleSave = async () => {
    setIsLoading(true);
    // This is just a sample function for demonstration
    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );
    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    fetchData();
    setIsLoading(false);
    handlePopUp();
  };

  const handleIsSubmittingChange = (newIsSubmitting: boolean) => {
    setIsSubmitting(newIsSubmitting);
  };

  const handleIsValidChange = (newIsValid: boolean) => {
    setIsValid(newIsValid);
  };

  const RecallData = () => {
    fetchData();
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container p={isMobile ? 0 : 2} mb={2}>
      <Resource1Status {...summaryCompleteProps} />
      <Resource1FirstAid
        answersData={answers}
        RecallData={RecallData}
        onAnswerChange={handleAnswerChange}
      />
      {user?.credentialTypeId !== 4 && (
        <Resource1MenuFeedingSchedule RecallData={RecallData} />
      )}
      <Resource1WeeklyPlan RecallData={RecallData} />
      {user?.credentialTypeId === 4 && (
        <Resource1StateChildAbuse
          answersData={answers}
          onAnswerChange={handleAnswerChange}
          onIsSubmittingChange={handleIsSubmittingChange}
          onIsValidChange={handleIsValidChange}
        />
      )}
      <PrintSaveBtns
        btnColor={"success"}
        onSave={handleSave}
        onDraft={handleSaveDraft}
        isSubmitting={isSubmitting}
        isValid={isValid}
        Progress={userProgress}
        {...summaryCompleteProps}
      />
      <CongratsPopUp
        handlePopUp={handlePopUp}
        isPopUp={popUp}
        btnColor={"success"}
        background={"#EBFCF2"}
        description={getTranslation("CompletedRCI")}
      />
    </Grid>
  );
};

export default CompetencyIResourceCollection;
