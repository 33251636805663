import "./App.css";
import { Box, Container, Grid } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/configureStore";
import { useEffect, useState } from "react";
import { fetchCurrentUser } from "../../features/account/accountSlice";
import Header from "./Header";
import SideNavigation from "./SideNavigation";
import SubHeaderOne from "./SubHeaderOne";
import Footer from "./Footer";
import { useMediaQuery, Theme } from "@mui/material";
import FileUpload from "../../features/FileUpload/FileUpload";
import { fetchTranslations } from "../../translation/translationSlice";

export default function App() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.account);
  const location = useLocation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const [showSideNavigation, setShowSideNavigation] = useState(!isMobile);

  useEffect(() => {
    const initApp = async (): Promise<void> => {
      try {
        await dispatch(fetchCurrentUser());
        await dispatch(fetchTranslations());
      } catch (error) {
        console.error();
      }
    };

    initApp();
  }, [dispatch]);

  const toggleSideNavigation = () => {
    setShowSideNavigation((prevShowSideNavigation) => !prevShowSideNavigation);
  };

  const handleNavigationItemClick = (): void => {
    setShowSideNavigation(false);
  };

  const shouldDisplaySideNavigation = !location.pathname.includes("/welcome");

  return (
    <>
      <Container maxWidth="xl">
        {user && (
          <Box>
            <Header />
            <SubHeaderOne toggleSideNavigation={toggleSideNavigation} />
          </Box>
        )}
        <Box sx={{ position: "relative" }}></Box>
        <Box sx={{ display: "flex" }}>
          {user && shouldDisplaySideNavigation && (
            <Box
              sx={{
                width: isMobile ? "100%" : "20%",
                display: showSideNavigation ? "block" : "none",
              }}
            >
              <FileUpload anchor="right" />
              <SideNavigation
                onNavigationItemClick={handleNavigationItemClick}
              />
            </Box>
          )}
          <Grid
            container
            sx={{
              flex: 1,
              display: isMobile && showSideNavigation ? "none" : "block",
            }}
          >
            <Outlet />
          </Grid>
        </Box>
        {user && <Footer />}
      </Container>
    </>
  );
}
