import { Grid } from "@mui/material";
import VerificationVisitStatus from "./VerificationVisitStatus";
import { SummaryCompleteProps } from "../../app/models/SummaryComplete";
import CompleteVerification from "./CompleteVerification";
import { useState } from "react";
import { useAppSelector } from "../../app/store/configureStore";
import HomeVisitorAssessor from "./homeVisitorAssessor";
import { useTranslation } from "../../translation/translate";

export default function VerificationVisitWorksheet() {
  const { getTranslation } = useTranslation();
  const { user } = useAppSelector((state) => state.account);
  const HomeVisitorSetting = user?.credentialTypeId === 4;
  const description = HomeVisitorSetting
    ? getTranslation("VerificationVisitWorksheetLine1")
    : getTranslation("VerificationVisitWorksheetLine2");
  const [summaryCompleteProps] = useState<SummaryCompleteProps>({
    title: getTranslation("GettingReadyForYourVerificationVisit"),
    percent: "",
    header: "",
    description: description,
  });

  return (
    <Grid container p={2} mb={2}>
      <VerificationVisitStatus {...summaryCompleteProps} />
      {HomeVisitorSetting && <HomeVisitorAssessor />}
      <CompleteVerification />
    </Grid>
  );
}
