import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import GetAppIcon from "@mui/icons-material/GetApp";
import Typography from "@mui/material/Typography";
import { FileInfo } from "../../app/models/Fileinfo";
import { useEffect, useState } from "react";
import agent from "../../app/api/agent";
import Tooltip from "@mui/material/Tooltip";
import { Divider, Menu, MenuItem } from "@mui/material";

const UploadedFiles: React.FC<{
  fileInfo: FileInfo;
  onDeleteFile: () => void;
  fileOrder: number;
}> = ({ fileInfo, onDeleteFile, fileOrder }) => {
  const primaryColor = "#049C8C";

  //modal confirmation
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // Function to convert bytes to megabytes
  function bytesToMegabytes(bytes: number) {
    const megabytes = bytes / (1024 * 1024);
    return megabytes.toFixed(2); // This will round the result to two decimal places.
  }

  // Function to detect the user's timezone
  const detectTimezone = () => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return userTimezone;
  };

  // Function to convert UTC time to the user's local time
  function convertUtcToLocal(
    utcTime: string | number | Date,
    userTimezone: string
  ) {
    const utcDate = new Date(utcTime + "Z"); //z enforces ISO UTC Date
    const localDate = new Date(
      utcDate.toLocaleString("en-US", { timeZone: userTimezone })
    );
    return localDate;
  }

  // ...

  const userTimezone = detectTimezone(); // Assuming you have a function to detect the user's timezone

  // Convert the UTC time to the user's local time
  const localDate = convertUtcToLocal(fileInfo.uploadDate, userTimezone);

  // Format the upload date using Intl.DateTimeFormat
  const formattedUploadDate = new Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZone: userTimezone,
  }).format(new Date(localDate));

  const fileSizeInMB = bytesToMegabytes(fileInfo.fileSize);

  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const [isDeleted, setIsDeleted] = useState(false);

  // Function to handle the download click event
  const handleDownloadClick = async () => {
    try {
      const response = await agent.FileUpload.GetFiles(fileInfo.id);

      // Create a blob URL for the file and initiate download
      const blob = new Blob([response], {
        type: "application/octet-stream",
      });

      const url = URL.createObjectURL(blob);

      setDownloadUrl(url);
    } catch (error) {
      // Handle error here, if needed
      console.error(error);
    }
  };

  const handleDelete = async () => {
    try {
      await agent.FileUpload.DeleteFile(fileInfo.id);
      setIsDeleted(true); // Set the state to indicate that the file is deleted
      onDeleteFile();
      setAnchorEl(null);
    } catch (error) {
      // Handle error here, if needed
      console.error(error);
    }
  };

  // Effect to initiate download when the downloadUrl state changes
  useEffect(() => {
    if (downloadUrl) {
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = fileInfo.filename;
      a.click();
      setDownloadUrl(null);
    }
  }, [downloadUrl, fileInfo.filename]);

  if (isDeleted) {
    return null;
  }

  return (
    <Box
      sx={{
        minWidth: 275,
        boxShadow: "none",
        borderBottom: `1px solid #ccc`,
        backgroundColor: "white",
      }}
    >
      <Box sx={{ padding: "8px" }}>
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ fontWeight: "bold" }}>
            {fileInfo.certificateType.replace(/\d/g, "").trim()} {fileOrder}
          </Typography>
          <Box display="flex" alignItems="center">
            <Tooltip title={`Download ${fileInfo.filename}`}>
              <GetAppIcon
                sx={{ marginRight: 1, color: primaryColor, cursor: "pointer" }}
                onClick={handleDownloadClick}
              />
            </Tooltip>
            <Tooltip title={`Delete ${fileInfo.filename}`}>
              <DeleteIcon
                sx={{ color: primaryColor, cursor: "pointer" }}
                onClick={(e: any) => handleClick(e)}
              />
            </Tooltip>
          </Box>
        </Box>
        <Typography variant="subtitle2">
          {formattedUploadDate} • {fileSizeInMB} MB
        </Typography>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="deleteBtn"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>Are you sure you want to delete?</MenuItem>
        <Divider />
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>Cancel</MenuItem>
      </Menu>
    </Box>
  );
};

export default UploadedFiles;
