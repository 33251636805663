import Copyright from "../../app/common/Copyright";
import {
  Container,
  CssBaseline,
  Box,
  Typography,
  Link,
} from "@mui/material";
import { useLocation } from "react-router-dom";

export default function Results() {
  const location = useLocation();

  const { confirm } = location.state || {};
  let message = "";
  let mlink;

  if (confirm === "reset") {
    message =
      "Forgot password Email Sent.";
    mlink = "Please check your email and follow instructions";
  } else {
    message =
      "Reset password confirmation";
    mlink = (
      <Typography variant="body2" mt={2}>
        Your password has been reset,{" "}
        <Link href="/account/login" variant="body2">
          {"Click here to log in"}
        </Link>
      </Typography>
    );
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          {message}
        </Typography>
        {mlink}
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
