import { ExpandCircleDown } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import AddForm1 from "./AddForm1";
import AddForm2 from "./AddForm2";
import { useAppSelector } from "../../app/store/configureStore";
import { useTranslation } from "../../translation/translate";

export default function AdditionalForms() {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();

  return (
    <Grid item xs={12} mt={3}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandCircleDown sx={{ color: "myVerify.main" }} />}
        >
          <Typography fontWeight={700} fontSize={18}>
            {getTranslation("AdditionalForms")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AddForm1 />
          {user?.credentialTypeId === 4 && <AddForm2 />}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
