import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Grid,
  Modal,
  Popover,
  Typography,
  Divider,
} from "@mui/material";
import agent from "../../../app/api/agent";
import dayjs from "dayjs";
import AddStudentToClassRoster from "./addToClassRoster";
import { useAppSelector } from "../../../app/store/configureStore";
import LoadingSpinner from "../../../app/common/LoadingSpinner";
import { useTranslation } from "../../../translation/translate";

export default function ClassStudentData() {
  const { user } = useAppSelector((state) => state.account);
  const [anchorEl, setAnchorEl] = useState<any | null>(null);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [rows, setRows] = useState<[]>([]);
  const [searchStudents, setSearchStudents] = useState<any>([]);
  const thisClass = JSON.parse(localStorage.getItem("ClassData") || "[]");
  const startDate = dayjs(thisClass.startDate).format("MM/DD/YYYY");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isPopover, setIsPopover] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const { getTranslation } = useTranslation();

  const columns: GridColDef[] = [
    //{ field: "portfolioId", headerName: "ID", sortable: false },
    {
      field: "cdaCouncilCandidateId",
      headerName: getTranslation("CandidateId"),
      headerAlign: "left",
      flex: 1,
      sortable: false,
    },
    {
      field: "firstName",
      headerName: getTranslation("FirstName"),
      type: "string",
      flex: 1,
      sortable: false,
    },
    {
      field: "lastName",
      headerName: getTranslation("LastName"),
      type: "string",
      flex: 1,
      sortable: false,
    },
    {
      field: "emailUsername",
      headerName: getTranslation("EmailUsername"),
      type: "string",
      flex: 1.5,
      sortable: false,
    },
    {
      field: "languageSpecialization",
      headerName: getTranslation("LanguageSpecialization"),
      headerAlign: "left",
      type: "string",
      flex: 1.5,
      sortable: false,
    },
    {
      field: "languagePreference",
      headerName: getTranslation("Language"),
      type: "string",
      flex: 1,
      sortable: false,
    },
    {
      field: "setting",
      headerName: getTranslation("Setting"),
      type: "string",
      flex: 1,
      sortable: false,
    },
  ];

  const fetchData = async () => {
    if (user) {
      try {
        const classList = await agent.TrainerClasses.GetClassRoster(
          thisClass.id
        );
        setRows(classList);
        const search = await agent.SharedAccess.GetSharedListAccessUserId(
          user.id
        );
        const thisStudent = search.filter(
          (item: any) => item.status === "Approved"
        );
        setSearchStudents(thisStudent);
      } catch {
        console.error();
      }
      setIsLoading(false);
    }
  };

  const handleAddStudent = async (studentProfileId: number) => {
    setIsLoading(true);
    const addStudent = {
      portfolioId: studentProfileId,
      trainingClassId: thisClass.id,
    };

    try {
      await agent.TrainerClasses.AddStudentToClass(addStudent);
    } catch {
      console.error();
    }
    fetchData();
  };

  const RemoveFromClass = async (studentProfileId: number) => {
    if (user) {
      setIsLoading(true);
      const remove = {
        portfolioId: studentProfileId,
        trainingClassId: thisClass.id,
      };
      await agent.TrainerClasses.RemoveFromClassRoster(remove);
      fetchData();
    }
  };
  const handleProfileButtonClick = async (selectedRow: any) => {
    setIsLoading(true);
    try {
      // Generate the final PDF
      await agent.PDFViewer.GenerateFinalPDF(
        selectedRow.portfolioId,
        "Preview"
      );

      // Get file info
      const data = await agent.FileUpload.GetFileInfo(
        "PreviewPDF",
        selectedRow.portfolioId
      );

      // Get the file
      const response = await agent.FileUpload.GetFiles(data[0].id);

      // Create a blob from the file response
      const blob = new Blob([response], { type: "application/pdf" });

      // Create a URL for the blob
      const imageUrl = URL.createObjectURL(blob);

      // Open the PDF in a new tab
      window.open(imageUrl, "_blank");
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <Grid container p={2} m={2}>
      <Grid item xs={12}>
        <Grid
          item
          xs={12}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography fontWeight={700} fontSize={20}>
            {thisClass.name} {getTranslation("Roster")}
          </Typography>
          <Button variant="outlined" onClick={() => setIsOpen(true)}>
            {getTranslation("AddStudent")}
          </Button>
        </Grid>
        <Typography>
          {getTranslation("NumberofStudents")} {rows.length ?? 0}{" "}
          {getTranslation("Students")}
        </Typography>
        <Typography>
          {getTranslation("ClassStartDate")} {startDate}{" "}
        </Typography>
      </Grid>
      <Grid item xs={12} width={900}>
        <DataGrid
          slots={{ toolbar: GridToolbar }}
          rows={rows}
          columns={columns}
          autoHeight
          disableColumnMenu
          rowHeight={35}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          editMode="row"
          onCellClick={(params, e) => {
            setSelectedRow(params.row);
            setAnchorEl(e.currentTarget);
            setIsPopover(true);
          }}
        />
      </Grid>
      <Popover
        open={isPopover}
        anchorEl={anchorEl}
        onClose={() => setIsPopover(false)}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <Box display="flex" flexDirection="column">
          <Button
            sx={{ textTransform: "initial", color: "black" }}
            onClick={() => handleProfileButtonClick(selectedRow)}
          >
            {getTranslation("SeePortfolio")}
          </Button>
          <Divider />
          <Button
            sx={{ textTransform: "initial", color: "error.main" }}
            onClick={() => {
              {
                RemoveFromClass(selectedRow.portfolioId);
                setIsPopover(false);
              }
            }}
          >
            {getTranslation("DeleteStudent")}
          </Button>
        </Box>
      </Popover>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="add-user-modal-title"
        aria-describedby="add-user-modal-description"
      >
        <AddStudentToClassRoster
          setIsOpen={setIsOpen}
          handleOnSubmit={handleAddStudent}
          searchStudents={searchStudents}
        />
      </Modal>
    </Grid>
  );
}
