import {  Grid } from "@mui/material";
import logo from "../../assets/images/CDALogo.png";
import ProfilePicture from "./ProfilePicture";
import { useAppSelector } from "../store/configureStore";

const Header = () => {
  const { user } = useAppSelector((state) => state.account);
  return (
    <Grid
      container
      sx={{
        mt: 2,
        mb: 2,
      }}
    >
      <Grid
          item
          xs={4}
          md={2}
    
        >
          <img src={logo} alt="CDA LOGO" style={{ width: "80%" }} />
        </Grid>
      {user && (
        <Grid
          item
          xs={8}
          sm={6}
          md={4}
          mt={2}
          sx={{ display: "flex", justifyContent: "flex-end", ml: "auto" }}
        >
          <ProfilePicture />
        </Grid>
      )}
    </Grid>
  );
};

export default Header;
