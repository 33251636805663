import { ExpandCircleDown } from "@mui/icons-material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "../../translation/translate";

export default function CDAFunctionalAreas() {
  const { getTranslation } = useTranslation();
  const downloadCredentialProcess = () => {
    const link = document.createElement("a");
    link.download = `CDACredentialProcessOverview.pdf`;
    link.href = "../docs/CDA-Process-Overview_eng-spa_042022.pdf";
    link.click();
  };
  const downloadPreschoolStandards = () => {
    const link = document.createElement("a");
    link.download = `PreschoolCompetencyStandards.pdf`;
    link.href = "../docs/CDA_FunctionalAreasPreschoolStandards.pdf";
    link.click();
  };
  const downloadHVCredential = () => {
    const link = document.createElement("a");
    link.download = `HomeVisitorCDACredential.pdf`;
    link.href = "../docs/HV-Infographic_final_110819.pdf";
    link.click();
  };
  const downloadHomeVisitorBook = () => {
    const link = document.createElement("a");
    link.download = `HomeVisitorCompetencyStandards.pdf`;
    link.href = "../docs/HV_CS_book_At-A-Glance.pdf";
    link.click();
  };
  const downloadInfatToddlerBook = () => {
    const link = document.createElement("a");
    link.download = `InfantToddlerCompetencyStandards.pdf`;
    link.href = "../docs/Infant-Toddler_CS_book_At-A-Glance.pdf";
    link.click();
  };
  const downloadFamilyChildCareBook = () => {
    const link = document.createElement("a");
    link.download = `FamilyChildCareCompetencyStandards.pdf`;
    link.href = "../docs/FamilyChildCare-CS-book_At-A-Glance.pdf";
    link.click();
  };
  return (
    <Grid item xs={12} mt={2}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandCircleDown sx={{ color: "rcLibrary.main" }} />}
        >
          <Typography fontSize={18} fontWeight={700}>
            {getTranslation("CDAInformationLine1")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item container p={2} spacing={5}>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>
                {getTranslation("CDAInformationLine2")}
              </Typography>
              <Button
                onClick={downloadCredentialProcess}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadOverview")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>
                {getTranslation("CDAInformationLine3")}
              </Typography>
              <Button
                onClick={downloadHVCredential}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadInformation")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>
                {getTranslation("CDAInformationLine4")}
              </Typography>
              <Button
                onClick={downloadPreschoolStandards}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("CDAInformationLine5")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>
                {getTranslation("CDAInformationLine6")}
              </Typography>
              <Button
                onClick={downloadHomeVisitorBook}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("CDAInformationLine7")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>
                {getTranslation("CDAInformationLine8")}
              </Typography>
              <Button
                onClick={downloadFamilyChildCareBook}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("CDAInformationLine9")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>
                {getTranslation("CDAInformationLine10")}
              </Typography>
              <Button
                onClick={downloadInfatToddlerBook}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("CDAInformationLine11")}
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
