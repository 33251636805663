import { Button, Grid, Theme, Tooltip, useMediaQuery } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { useTranslation } from "../../translation/translate";

export default function PrintSaveBtns(props: any) {
  const {
    btnColor,
    onSave,
    title,
    Progress,
    onDraft,
    onPreview,
    isSubmitting,
    isValid,
  } = props;

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const pattern =
    /Competency|CDA Education|Philosophy Statement|Reflective Dialogue Worksheet|Eform|Portfolio Checklist|OnlineForm|/; //FOrms that does not need validation goes here. Do not remove logic unless you understand it

  const [isDisabled, setIsDisabled] = useState<boolean>();
  const { getTranslation } = useTranslation();

  useEffect(() => {
    if (Progress >= 100) {
      setIsDisabled(false);
    } else {
      if (Progress < 100) {
        setIsDisabled(true);
      }
    }
  }, [isSubmitting, isValid]);

  return (
    <Grid
      container
      display="flex"
      flexDirection="row"
      justifyContent={"space-between"}
      alignItems="center"
      p={3}
    >
      {title.includes("Competency") && (
        <Grid item>
          <Tooltip title={getTranslation("MustSaveDraftFirst")}>
            <Button
              variant="outlined"
              startIcon={<PrintIcon />}
              color={btnColor}
              sx={{ textTransform: "none" }}
              onClick={onPreview}
            >
              {getTranslation("ButtonPreviewPrint")}
            </Button>
          </Tooltip>
        </Grid>
      )}

      <Grid item display="flex" flexDirection={"row"}>
        <LoadingButton
          variant="outlined"
          color={btnColor}
          sx={{
            textTransform: "none",
            mr: 2,
          }}
          onClick={onDraft}
          loading={isSubmitting}
          disabled={pattern.test(title) ? false : !isValid}
        >
          {getTranslation("ButtonSaveDraft")}
        </LoadingButton>

        <LoadingButton
          variant="contained"
          sx={{ textTransform: "none" }}
          color={btnColor}
          onClick={onSave}
          loading={isSubmitting}
          disabled={isDisabled}
        >
          {getTranslation("ButtonSave&Submit")}
        </LoadingButton>
      </Grid>
    </Grid>
  );
}
