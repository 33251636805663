import { DownloadSharp } from "@mui/icons-material";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useState, useCallback, useEffect } from "react";
import agent from "../../../app/api/agent";
import createUserProgressReport from "../../../app/utils/adminUtils/pdfReports";
import createUserBilingualPDF from "../../../app/utils/adminUtils/pdfBilingual";
import createUserDetailedReport from "../../../app/utils/adminUtils/pdfDetailed";
import createUserStateReport from "../../../app/utils/adminUtils/pdfUserState";
import { useAppSelector } from "../../../app/store/configureStore";
import LoadingSpinner from "../../../app/common/LoadingSpinner";

export default function Reports() {
  const { user } = useAppSelector((state) => state.account);
  let SampleReportData = [
    {
      id: 1,
      title: "User Progress",
      report: `Student/Candidate Users by Setting and Progress`,
    },
    {
      id: 2,
      title: "Users by State",
      report: `Student/Candidate Users by State`,
    },
    {
      id: 3,
      title: "Language Specialization",
      report: `Student/Candidate Users by Setting, Language Specialization, and Language Version`,
    },
    // {
    //   id: 4,
    //   title: "Language Version",
    //   report: "CDAVerificationVisitReflectiveDialogWorksheet.pdf",
    // },
  ];

  if (user?.role === "CSI Admin") {
    SampleReportData.push({
      id: 5,
      title: "Detailed Report",
      report: "Detailed Student/Candidate User Report for CSI only",
    });
  }
  const [newDateValue, setNewDateValue] = useState<Dayjs | null>();

  const [progressData, setProgressData] = useState();
  const [bilingualData, setBilingualData] = useState();
  const [userStateData, setUserStateData] = useState();
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      const endDate = newDateValue?.toString() || dayjs().toString();
      const response = await agent.Progress.GetUserProgress(endDate);
      setProgressData(response);

      const response2 = await agent.Progress.GetCandidateUserbyState(endDate);
      setUserStateData(response2);

      const response3 = await agent.Progress.GetUserBiLingualProgress(endDate);
      setBilingualData(response3);

      const response4 = await agent.Admin.GetAllUsersWithProgress(
        dayjs().toString()
      );
      setUserData(response4);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, [newDateValue]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleApply = () => {
    fetchData();
  };

  const handlePDF = async ({ title, report, id }: any) => {
    if (id === 1) {
      createUserProgressReport({
        pdfData: progressData,
        title: title,
        report: report,
      });
    } else {
      if (id === 2) {
        createUserStateReport({
          pdfData: userStateData,
          title: title,
          report: report,
        });
      } else {
        if (id === 3) {
          createUserBilingualPDF({
            pdfData: bilingualData,
            title: title,
            report: report,
          });
        } else {
          if (id === 5) {
            createUserDetailedReport({
              pdfData: userData,
              title: title,
              report: report,
            });
          }
        }
      }
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container p={4}>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <Typography fontSize={24} fontWeight={700}>
            Reports
          </Typography>
        </Grid>
        <Grid item xs={4} md={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disableFuture
              label="Date"
              value={newDateValue}
              views={["year", "month", "day"]}
              onChange={(e: any) => {
                setNewDateValue(e);
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2} m={1}>
          <Button
            onClick={() => handleApply()}
            variant="contained"
            color="secondary"
          >
            Apply
          </Button>
        </Grid>
      </Grid>
      {SampleReportData.map((item: any) => (
        <Grid item md={4} xs={12} mt={4} key={item.id}>
          <Card sx={{ height: 190, width: 320 }}>
            <CardContent>
              <Typography fontSize={18} fontWeight={700}>
                {item.title}
              </Typography>
            </CardContent>
            <CardActions sx={{ marginLeft: 9 }}>
              <Button
                variant="outlined"
                color="secondary"
                sx={{ textTransform: "initial" }}
                endIcon={<DownloadSharp />}
                onClick={() => {
                  handlePDF(item);
                }}
              >
                Download
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
