import React, { useState } from "react";
import { Grid, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import ReadMoreReadLess from "../../app/common/ReadMoreReadLess";
import { useAppSelector } from "../../app/store/configureStore";
import { AnswersProps } from "../../app/models/Answers";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "../../translation/translate";

const StatementSafeEnvironment: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const defaultValues = {
    safeEnv: answersData.find((answer) => answer.systemCodeId === 15)?.answer,
    healthEnv: answersData.find((answer) => answer.systemCodeId === 16)?.answer,
    learningEnv: answersData.find((answer) => answer.systemCodeId === 17)
      ?.answer,
  };

  const { getTranslation } = useTranslation();

  const schema = yup.object().shape({
    safeEnv: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    healthEnv: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    learningEnv: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });
  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const { user } = useAppSelector((state) => state.account);
  const renderSafetyContent = () => {
    if (user?.credentialTypeId !== 4) {
      return (
        <>
          <li>{getTranslation("CompetencyIStatementSafeEnvironmentLine2")}</li>
          <li>{getTranslation("CompetencyIStatementSafeEnvironmentLine3")}</li>
          <li>{getTranslation("CompetencyIStatementSafeEnvironmentLine4")}</li>
          <li>{getTranslation("CompetencyIStatementSafeEnvironmentLine5")}</li>
        </>
      );
    } else {
      return (
        <>
          <li>{getTranslation("CompetencyIStatementSafeEnvironmentLine6")}</li>
          <li>{getTranslation("CompetencyIStatementSafeEnvironmentLine7")}</li>
          <li>{getTranslation("CompetencyIStatementSafeEnvironmentLine8")}</li>
          <li>{getTranslation("CompetencyIStatementSafeEnvironmentLine9")}</li>
          <li>{getTranslation("CompetencyIStatementSafeEnvironmentLine10")}</li>
        </>
      );
    }
  };

  const renderHealthContent = () => {
    if (user?.credentialTypeId !== 4) {
      return (
        <>
          <li>
            {getTranslation("CompetencyIStatementHealthyEnvironmentLine4")}
          </li>
          <li>
            {getTranslation("CompetencyIStatementHealthyEnvironmentLine5")}
          </li>
          <li>
            {getTranslation("CompetencyIStatementHealthyEnvironmentLine6")}
          </li>
          <li>
            {getTranslation("CompetencyIStatementHealthyEnvironmentLine7")}
          </li>
        </>
      );
    } else {
      return (
        <>
          <li>
            {getTranslation("CompetencyIStatementHealthyEnvironmentLine8")}
          </li>
          <li>
            {getTranslation("CompetencyIStatementHealthyEnvironmentLine9")}
          </li>
          <li>
            {getTranslation("CompetencyIStatementHealthyEnvironmentLine10")}
          </li>
          <li>
            {getTranslation("CompetencyIStatementHealthyEnvironmentLine11")}
          </li>
          <li>
            {getTranslation("CompetencyIStatementHealthyEnvironmentLine12")}
          </li>
        </>
      );
    }
  };

  const renderLearningContent = () => {
    if (user?.credentialTypeId !== 4) {
      return (
        <>
          <li>
            {getTranslation("CompetencyIStatementLearningEnvironmentline2")}
          </li>
          <li>
            {getTranslation("CompetencyIStatementLearningEnvironmentline3")}
          </li>
          <li>
            {getTranslation("CompetencyIStatementLearningEnvironmentline4")}
          </li>
          <li>
            {getTranslation("CompetencyIStatementLearningEnvironmentline5")}
          </li>
        </>
      );
    } else {
      return (
        <>
          <li>
            {getTranslation("CompetencyIStatementLearningEnvironmentline6")}
          </li>
          <li>
            {getTranslation("CompetencyIStatementLearningEnvironmentline7")}
          </li>
          <li>
            {getTranslation("CompetencyIStatementLearningEnvironmentline8")}
          </li>
          <li>
            {getTranslation("CompetencyIStatementLearningEnvironmentline9")}
          </li>
          <li>
            {getTranslation("CompetencyIStatementLearningEnvironmentline10")}
          </li>
        </>
      );
    }
  };

  return (
    <>
      <Grid item xs={12} px={3} pt={3}>
        <Typography variant="body1" fontWeight="bold" paragraph>
          {getTranslation("CompetencyIStatementSafeEnvironment")}
        </Typography>
        <Typography variant="body1" paragraph>
          {user?.credentialTypeId !== 4
            ? getTranslation("CompetencyIStatementSafeEnvironmentLine1")
            : getTranslation("CompetencyIStatementSafeEnvironmentLine1A")}
        </Typography>
        <ReadMoreReadLess>{renderSafetyContent()}</ReadMoreReadLess>
      </Grid>
      <Grid item xs={12} px={3} pt={3}>
        <TextField
          label={getTranslation("CompetencyIStatementSafeEnvironmentLine11")}
          multiline
          rows={2}
          fullWidth
          {...register("safeEnv")}
          name="safeEnv"
          value={
            answersData.find((answer) => answer.systemCodeId === 15)?.answer ||
            ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = 15;

            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.safeEnv?.message}
          helperText={errors.safeEnv?.message}
        />
        <Typography fontSize={13}>
          {defaultValues.safeEnv?.length}/500
        </Typography>
      </Grid>
      <Grid item xs={12} px={3} pt={3}>
        <Typography variant="body1" fontWeight="bold" paragraph>
          {getTranslation("CompetencyIStatementHealthyEnvironment")}
        </Typography>
        <Typography variant="body1" paragraph>
          {user?.credentialTypeId !== 4
            ? getTranslation("CompetencyIStatementHealthyEnvironmentLine1")
            : getTranslation("CompetencyIStatementHealthyEnvironmentLine2")}
        </Typography>
        <ReadMoreReadLess>{renderHealthContent()}</ReadMoreReadLess>
      </Grid>
      <Grid item xs={12} px={3} pt={3}>
        <TextField
          label={getTranslation("CompetencyIStatementHealthyEnvironmentLine3")}
          multiline
          rows={2}
          fullWidth
          {...register("healthEnv")}
          name="healthEnv"
          value={
            answersData.find((answer) => answer.systemCodeId === 16)?.answer ||
            ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = 16;

            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.healthEnv?.message}
          helperText={errors.healthEnv?.message}
        />
        <Typography fontSize={13}>
          {defaultValues.healthEnv?.length}/500
        </Typography>
      </Grid>
      <Grid item xs={12} px={3} pt={3}>
        <Typography fontWeight={700} paragraph>
          {getTranslation("CompetencyIStatementLearningEnvironment")}
        </Typography>
        <Typography>
          {user?.credentialTypeId === 4
            ? getTranslation("CompetencyIStatementLearningEnvironmentline1")
            : getTranslation("CompetencyIStatementLearningEnvironmentline1A")}
        </Typography>
        <ReadMoreReadLess>{renderLearningContent()}</ReadMoreReadLess>
      </Grid>
      <Grid item xs={12} px={3} py={3}>
        <TextField
          label={getTranslation(
            "CompetencyIStatementLearningEnvironmentLine11"
          )}
          multiline
          rows={2}
          fullWidth
          {...register("learningEnv")}
          name="learningEnv"
          value={
            answersData.find((answer) => answer.systemCodeId === 17)?.answer ||
            ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = 17;

            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.learningEnv?.message}
          helperText={errors.learningEnv?.message}
        />
        <Typography fontSize={13}>
          {defaultValues.learningEnv?.length}/500
        </Typography>
      </Grid>
    </>
  );
};
export default StatementSafeEnvironment;
