import { Grid, TextField, Typography } from "@mui/material";
import ReadMoreReadLess from "../../app/common/ReadMoreReadLess";
import { useAppSelector } from "../../app/store/configureStore";
import { useForm } from "react-hook-form";
import { AnswersProps } from "../../app/models/Answers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const HappeningAtHome: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();

  const schema = yup.object().shape({
    textbox: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });

  const systemCodeId = user?.credentialTypeId === 4 ? 184 : 183;
  const defaultValues = {
    happening: answersData.find(
      (answer) => answer.systemCodeId === systemCodeId
    )?.answer,
  };
  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const renderHappeningAtHomeCompetence = () => {
    return (
      <>
        <li>{getTranslation("SIVHappeningHomeBulletList1")}</li>
        <li>{getTranslation("SIVHappeningHomeBulletList2")}</li>
        <li>{getTranslation("SIVHappeningHomeBulletList3")}</li>
        <li>{getTranslation("SIVHappeningHomeBulletList4")}</li>
      </>
    );
  };

  return (
    <Grid container p={2} m={1}>
      <Grid item container>
        <Grid item xs={12}>
          <Typography fontWeight={700} mb={2}>
            {getTranslation("SIVHappeningHomeLine1")}
          </Typography>
          <Typography mb={2}>
            {user?.credentialTypeId === 4
              ? getTranslation("SIVHappeningHomeLine2")
              : getTranslation("SIVHappeningHomeLine3")}
          </Typography>
          <ReadMoreReadLess>
            <>{renderHappeningAtHomeCompetence()}</>
          </ReadMoreReadLess>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={getTranslation("SIVHappeningHomeLine4")}
            fullWidth
            multiline
            rows={2}
            {...register("happening")}
            name="happening"
            value={
              answersData.find((answer) => answer.systemCodeId === systemCodeId)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;

              newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.happening?.message}
            helperText={errors.happening?.message}
          />
          <Typography fontSize={13}>
            {defaultValues.happening?.length}/500
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default HappeningAtHome;
