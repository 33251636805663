import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Email } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import Copyright from "../../app/common/Copyright";
import { ResetPasswordFormInputs } from "../../app/models/ResetPassword";
import ResetPasswordValidationSchema from "../../app/validations/ResetPasswordValidation";
import logo from "../../assets/images/CDALogo.png";
import agent from "../../app/api/agent";


export default function ResetPassword() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm<ResetPasswordFormInputs>({
    resolver: yupResolver(ResetPasswordValidationSchema),
    mode: "onTouched",
  });


  const onSubmit = async (data: ResetPasswordFormInputs) => {
    await agent.Account.forgotPassword(data).then(() => {
      navigate("/account/resetconfirmation", { state: { confirm: "reset" } });
    });
  };
  

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="CDA LOGO" style={{ width: "60%" }} />
        
        <Typography  variant="body1" mt={2}>
          Enter your email address below and a email will be sent with instructions on how to reset your password.
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Email"
            margin="normal"
            fullWidth
            InputProps={{
              startAdornment: <Email />,
            }}
            {...register("email")}
            error={!!errors.email?.message}
            helperText={errors.email?.message}
            onBlur={handleSubmit(onSubmit)}
            autoComplete="username"
            inputProps={{ autoComplete: "username" }}
          />
          <LoadingButton
            loading={isSubmitting}
            disabled={!isValid}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </LoadingButton>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
