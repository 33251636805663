import {
  Box,
  Card,
  CardContent,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import status from "../../assets/images/rc6-icon-status.png";
import { SummaryCompleteProps } from "../../app/models/SummaryComplete";
import { useTranslation } from "../../translation/translate";

const ResourceVIStatus: React.FC<SummaryCompleteProps> = (props) => {
  const { title, percent, description, header } = props;

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { getTranslation } = useTranslation();

  return (
    <>
      <Card
        sx={{
          width: "100%",
          backgroundColor: "compVI.light",
          boxShadow: "0 16px #C32627",
          marginBottom: 4,
        }}
      >
        <CardContent>
          <Grid container mt={1}>
            <Grid item xs={2} sm={2} md={1} sx={{ textAlign: "center" }}>
              <Grid item sx={{ textAlign: "center" }}>
                <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                  <img src={status} alt="status" style={{ width: "70%" }} />
                </Box>
                <Typography variant="h6" fontWeight={700}>
                  {percent}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {getTranslation("Complete")}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={10}
              sm={10}
              md={11}
              display="block"
              flexDirection="column"
              paddingLeft={3}
            >
              <Typography variant="body2" fontWeight={700} marginBottom={1}>
                {header}
              </Typography>
              <Typography variant="h6" fontWeight={700} marginBottom={1}>
                {title}
              </Typography>
              <Typography display={isMobile ? "none" : "show"} marginBottom={1}>
                {description}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};
export default ResourceVIStatus;
