import { Grid, TextField, Typography } from "@mui/material";
import { AnswersProps } from "../../app/models/Answers";
import { useForm } from "react-hook-form";
import ReadMoreReadLess from "../../app/common/ReadMoreReadLess";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const StatementFoodReflection: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const { getTranslation } = useTranslation();
  const schema = yup.object().shape({
    foodPhil: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    foodTeach: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });

  const defaultValues = {
    foodPhil: answersData.find((answer) => answer.systemCodeId === 18)?.answer,
    foodTeach: answersData.find((answer) => answer.systemCodeId === 19)?.answer,
  };
  const {
    formState: { errors },
    register,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });
  const renderFoodHandlingReflectionCompetence = () => {
    return (
      <>
        <li>{getTranslation("FoodHandlingReflectionLine1")}</li>
        <li>{getTranslation("FoodHandlingReflectionLine2")}</li>
        <li>{getTranslation("SIVHappeningHomeBulletList3")}</li>
        <li>{getTranslation("FoodHandlingReflectionLine4")}</li>
      </>
    );
  };

  const renderExamplePreparedFoodCompetence = () => {
    return (
      <>
        <li>{getTranslation("FoodHandlingReflectionLine6")}</li>
        <li>{getTranslation("FoodHandlingReflectionLine7")}</li>
      </>
    );
  };

  return (
    <Grid container sx={{ bgcolor: "#EBFCF2" }}>
      <Grid item xs={12} px={3} pt={3}>
        <Typography variant="body1" fontWeight="bold" paragraph>
          {getTranslation("FoodHandlingReflectionTitle")}
        </Typography>
        <Typography variant="body1">
          {getTranslation("FoodHandlingReflectionDetail")}
        </Typography>
      </Grid>
      <Grid item xs={12} pt={3} px={3}>
        <Typography variant="body1" paragraph>
          {getTranslation("StatementFoodReflectionQuestion1")}
        </Typography>
        <ReadMoreReadLess>
          <>{renderFoodHandlingReflectionCompetence()}</>
        </ReadMoreReadLess>
      </Grid>
      <Grid item xs={12} pt={3} px={3}>
        <TextField
          label={getTranslation("FoodHandlingReflectionHelper1")}
          multiline
          rows={2}
          fullWidth
          {...register("foodPhil")}
          name="foodPhil"
          sx={{ bgcolor: "#FFF" }}
          value={
            answersData.find((answer) => answer.systemCodeId === 18)?.answer ||
            ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = 18;

            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.foodPhil?.message}
          helperText={errors.foodPhil?.message}
        />
        <Typography fontSize={13}>
          {defaultValues.foodPhil?.length}/500
        </Typography>
      </Grid>
      <Grid item xs={12} pt={3} px={3}>
        <Typography variant="body1" paragraph>
          {getTranslation("FoodHandlingReflectionQ1")}
        </Typography>
        <ReadMoreReadLess>
          {renderExamplePreparedFoodCompetence()}
        </ReadMoreReadLess>
      </Grid>
      <Grid item xs={12} py={3} px={3}>
        <TextField
          label={getTranslation("FoodHandlingReflectionHelper2")}
          multiline
          rows={2}
          fullWidth
          {...register("foodTeach")}
          name="foodTeach"
          sx={{ bgcolor: "#FFF" }}
          value={
            answersData.find((answer) => answer.systemCodeId === 19)?.answer ||
            ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = 19;

            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.foodTeach?.message}
          helperText={errors.foodTeach?.message}
        />
        <Typography fontSize={13}>
          {defaultValues.foodTeach?.length}/500
        </Typography>
      </Grid>
    </Grid>
  );
};
export default StatementFoodReflection;
