import { Grid, Typography } from "@mui/material";
import stageOne from "../../assets/images/stageOne.png";
import stageTwo from "../../assets/images/stageTwo.png";
import stageThree from "../../assets/images/stageThree.png";
import stageFour from "../../assets/images/stageFour.png";
import { useTranslation } from "../../translation/translate";

export default function ProgressGuide() {
  const { getTranslation } = useTranslation();

  return (
    <Grid container p={1} mb={2} spacing={4}>
      <Grid
        item
        container
        display="flex"
        flexDirection="row"
        alignContent="center"
        justifyContent="space-evenly"
      >
        <Grid item textAlign="center" xs={1} mt={1}>
          <img src={stageOne} width="98%" alt="" />
          <Typography mt={1}>0-33% {getTranslation("Complete")}</Typography>
        </Grid>
        <Grid item textAlign="center" xs={1} mt={2.5}>
          <img src={stageTwo} width={"100%"} alt="" />
          <Typography mt={3}>34-66% {getTranslation("Complete")}</Typography>
        </Grid>
        <Grid item textAlign="center" xs={1}>
          <img
            src={stageThree}
            style={{
              maxWidth: 100,
              rotate: "z 40deg",
              maxHeight: 75,
            }}
            alt=""
          />
          <Typography>67-99% {getTranslation("Complete")}</Typography>
        </Grid>
        <Grid item textAlign="center" xs={1}>
          <img src={stageFour} width={"100%"} alt="" />
          <Typography mt={0.3}>100% {getTranslation("Complete")}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
