import React from "react";
import { Grid, TextField, Typography } from "@mui/material";
import ReadMoreReadLess from "../../app/common/ReadMoreReadLess";
import { useAppSelector } from "../../app/store/configureStore";
import { AnswersProps } from "../../app/models/Answers";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const CompetenceList: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const { getTranslation } = useTranslation();
  const schema = yup.object().shape({
    physical: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    cognitive: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    communitcation: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    creative: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });
  const { user } = useAppSelector((state) => state.account);

  const isHomeVisitor = user?.credentialTypeId === 4;
  const physicalSystemCodeId = isHomeVisitor ? 46 : 42;
  const cognitiveSystemCodeId = isHomeVisitor ? 47 : 43;
  const communitcationSystemCodeId = isHomeVisitor ? 48 : 44;
  const creativeSystemCodeId = isHomeVisitor ? 49 : 45;

  const defaultValues = {
    physical: answersData.find(
      (answer) => answer.systemCodeId === physicalSystemCodeId
    )?.answer,
    cognitive: answersData.find(
      (answer) => answer.systemCodeId === cognitiveSystemCodeId
    )?.answer,
    communitcation: answersData.find(
      (answer) => answer.systemCodeId === communitcationSystemCodeId
    )?.answer,
    creative: answersData.find(
      (answer) => answer.systemCodeId === creativeSystemCodeId
    )?.answer,
  };
  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const renderPhysicalCompetence = () => {
    return (
      <>
        <li>{getTranslation("StatementIICompetenceBulletList1")}</li>
        <li>{getTranslation("StatementIICompetenceBulletList2")}</li>
        <li>{getTranslation("StatementIICompetenceBulletList3")}</li>
        <li>{getTranslation("StatementIICompetenceBulletList4")}</li>
      </>
    );
  };
  const renderIntellectualCompetence = () => {
    return (
      <>
        <li>{getTranslation("StatementIICompetenceBulletList5")}</li>
        <li>{getTranslation("StatementIICompetenceBulletList6")}</li>
        <li>{getTranslation("StatementIICompetenceBulletList7")}</li>
        <li>{getTranslation("StatementIICompetenceBulletList8")}</li>
      </>
    );
  };
  const renderCommunicationCompetence = () => {
    return (
      <>
        <li>{getTranslation("StatementIICompetenceBulletList9")}</li>
        <li>{getTranslation("StatementIICompetenceBulletList10")}</li>
        <li>{getTranslation("StatementIICompetenceBulletList11")}</li>
        <li>{getTranslation("StatementIICompetenceBulletList12")}</li>
      </>
    );
  };
  const renderCreativeCompetence = () => {
    return (
      <>
        <li>{getTranslation("StatementIICompetenceBulletList13")}</li>
        <li>{getTranslation("StatementIICompetenceBulletList14")}</li>
        <li>{getTranslation("StatementIICompetenceBulletList15")}</li>
      </>
    );
  };

  return (
    <Grid container pb={3}>
      <Grid item xs={12} px={3} pt={3}>
        <Typography variant="body1" fontWeight="bold" paragraph>
          {getTranslation("PhysicalCompetence")}
        </Typography>
        <Typography variant="body1" paragraph>
          {user?.credentialTypeId !== 4
            ? getTranslation("StatementIICompetenceQuestion1")
            : getTranslation("StatementIICompetenceQuestion1A")}
        </Typography>
        <Grid xs item>
          <ReadMoreReadLess>{renderPhysicalCompetence()}</ReadMoreReadLess>
        </Grid>
      </Grid>
      <Grid item xs={12} px={3} pt={3}>
        <TextField
          label={
            user?.credentialTypeId !== 4
              ? getTranslation("StatementIICompetenceLine1")
              : getTranslation("StatementIICompetenceLine2")
          }
          multiline
          rows={2}
          {...register("physical")}
          name="physical"
          fullWidth
          value={
            answersData.find(
              (answer) => answer.systemCodeId === physicalSystemCodeId
            )?.answer || ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = physicalSystemCodeId;

            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.physical?.message}
          helperText={errors.physical?.message}
        />
        <Typography fontSize={13}>
          {defaultValues.physical?.length}/500
        </Typography>
      </Grid>

      <Grid item xs={12} px={3} pt={3}>
        <Typography variant="body1" fontWeight="bold" paragraph>
          {getTranslation("CognitiveCompetence")}
        </Typography>
        <Typography variant="body1" paragraph>
          {user?.credentialTypeId !== 4
            ? getTranslation("QuestionCognitiveCompetence")
            : getTranslation("QuestionCognitiveCompetence1")}
        </Typography>
        <ReadMoreReadLess>{renderIntellectualCompetence()}</ReadMoreReadLess>
      </Grid>
      <Grid item xs={12} px={3} pt={3}>
        <TextField
          label={
            user?.credentialTypeId !== 4
              ? getTranslation("StatementIICompetenceLine3")
              : getTranslation("StatementIICompetenceLine4")
          }
          multiline
          rows={2}
          fullWidth
          {...register("cognitive")}
          name="cognitive"
          value={
            answersData.find(
              (answer) => answer.systemCodeId === cognitiveSystemCodeId
            )?.answer || ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = cognitiveSystemCodeId;

            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.cognitive?.message}
          helperText={errors.cognitive?.message}
        />
        <Typography fontSize={13}>
          {defaultValues.cognitive?.length}/500
        </Typography>
      </Grid>

      <Grid item xs={12} px={3} pt={3}>
        <Typography variant="body1" fontWeight="bold" paragraph>
          {getTranslation("CreativeCompetence")}
        </Typography>
        <Typography variant="body1" paragraph>
          {user?.credentialTypeId !== 4
            ? getTranslation("QuestionHelpChildrenCreative")
            : getTranslation("QuestionHelpChildrenCreative1A")}
        </Typography>
        <ReadMoreReadLess>
          <>{renderCreativeCompetence()}</>
        </ReadMoreReadLess>
      </Grid>
      <Grid item xs={12} px={3} pt={3}>
        <TextField
          label={
            user?.credentialTypeId !== 4
              ? getTranslation("StatementIICompetenceLine5")
              : getTranslation("StatementIICompetenceLine6")
          }
          multiline
          rows={2}
          fullWidth
          {...register("creative")}
          name="creative"
          value={
            answersData.find(
              (answer) => answer.systemCodeId === creativeSystemCodeId
            )?.answer || ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = creativeSystemCodeId;

            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.creative?.message}
          helperText={errors.creative?.message}
        />
        <Typography fontSize={13}>
          {defaultValues.creative?.length}/500
        </Typography>
      </Grid>

      <Grid item xs={12} px={3} pt={3}>
        <Typography variant="body1" fontWeight="bold" paragraph>
          {getTranslation("CommunicationCompetence")}
        </Typography>
        <Typography variant="body1" paragraph>
          {user?.credentialTypeId !== 4
            ? getTranslation("QuestionCommunicationCompetence")
            : getTranslation("StatementIICompetenceQuestion3")}
        </Typography>
        <ReadMoreReadLess>{renderCommunicationCompetence()}</ReadMoreReadLess>
      </Grid>
      <Grid item xs={12} px={3} pt={3}>
        <TextField
          label={
            user?.credentialTypeId !== 4
              ? getTranslation("StatementIICompetenceLine7")
              : getTranslation("StatementIICompetenceLine8")
          }
          multiline
          rows={2}
          fullWidth
          {...register("communitcation")}
          name="communitcation"
          value={
            answersData.find(
              (answer) => answer.systemCodeId === communitcationSystemCodeId
            )?.answer || ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = communitcationSystemCodeId;

            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.communitcation?.message}
          helperText={errors.communitcation?.message}
        />
        <Typography fontSize={13}>
          {defaultValues.communitcation?.length}/500
        </Typography>
      </Grid>
    </Grid>
  );
};

export default CompetenceList;
