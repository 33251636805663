import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import { useForm } from "react-hook-form";
import util from "../../../app/utils/utils";
import { AdminSharedAccType } from "../../../app/models/AdminSharedAcc";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import CandidateSharedList from "./CandidateSharedList";
import { useAppSelector } from "../../../app/store/configureStore";
import { useTranslation } from "../../../translation/translate";
import { FormValidationSAcc } from "../../../app/validations/FormValidationSA";

export default function SharedAccessComp({
  adminData,
  onSubmit,
  addNew,
  setAddNew,
  handleRemove,
  listOfAdmin,
  fecthListData,
  otherUser,
  handleGrantPermission,
}: any) {
  const { user } = useAppSelector((state) => state.account);
  const { SharedAccessCredential } = util;
  const counter = adminData.length + 1;
  const [roleType, setRoleType] = useState<string>("");
  const [adminOption, setAdminOption] = useState<AdminSharedAccType | []>([]);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { getTranslation } = useTranslation();
  const {
    register,
    resetField,
    formState: { errors },
  } = useForm<AdminSharedAccType>({
    mode: "onSubmit",
    resolver: yupResolver(FormValidationSAcc(getTranslation)),
  });

  const gridStyle = {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    justifyContent: "space-evenly",
  };

  const handleSelectType = (type: string) => {
    fecthListData(type);
  };

  const handleSubmitUser = (data: any) => {
    setAdminOption(data);
  };

  return (
    <Grid container bgcolor="myVerify.light" p={2} m={2}>
      <Grid item xs={12}>
        <Typography fontSize={20} fontWeight={600}>
          {getTranslation("SharedAccess")}
        </Typography>
        <Typography>
          {otherUser
            ? getTranslation("SharedAccessLine1")
            : getTranslation("SharedAccessLine2")}
        </Typography>
      </Grid>

      <CandidateSharedList
        adminData={adminData}
        handleRemove={handleRemove}
        handleGrantPermission={handleGrantPermission}
        otherUser={otherUser}
      />

      {user?.role === "CDA Candidate" && addNew && (
        <Grid item xs={12}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography color="#3E7787" fontSize={24} fontWeight={700}>
                {counter}
              </Typography>
              <Typography ml={5} mt={1}>
                {getTranslation("SharedAccessLine3")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12} sx={gridStyle}>
                <FormControl sx={{ width: isMobile ? "100%" : 300 }} required>
                  <InputLabel>{getTranslation("Relationship")}</InputLabel>
                  <Select
                    label="Relationship"
                    {...register("role")}
                    error={!!errors.role?.message}
                    value={roleType}
                  >
                    {SharedAccessCredential.map((role) => (
                      <MenuItem
                        key={role.role}
                        onClick={() => {
                          setRoleType(role.role);
                          // setUserType(role.role);
                          handleSelectType(role.role);
                        }}
                        value={role.role}
                      >
                        {role.role}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText sx={{ color: "error.main" }}>
                    {errors.role?.message}
                  </FormHelperText>
                </FormControl>

                <Autocomplete
                  id="auto-select"
                  sx={{
                    width: isMobile ? "100%" : "50%",
                    mt: isMobile ? 2 : 0,
                  }}
                  options={listOfAdmin}
                  onChange={(props, option: any) => {
                    props.preventDefault();
                    handleSubmitUser(option);
                  }}
                  getOptionLabel={(option) =>
                    option.firstName +
                    " " +
                    option.lastName +
                    " " +
                    option.email
                  }
                  autoSelect
                  renderInput={(params) => (
                    <TextField
                      {...register("firstName")}
                      key={params.id}
                      {...params}
                      label={
                        roleType
                          ? `Registered ${roleType}`
                          : getTranslation("SharedAccessLine4")
                      }
                      required
                      error={!!errors.firstName?.message}
                      helperText={errors.firstName?.message}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                mt={2}
                textAlign={isMobile ? "center" : "right"}
              >
                <Button
                  fullWidth={isMobile ? true : false}
                  onClick={() => {
                    onSubmit(adminOption);
                    setAddNew(false);
                    resetField("firstName");
                    resetField("role");
                  }}
                  disabled={
                    errors.firstName || JSON.stringify(adminOption) === "[]"
                      ? true
                      : false
                  }
                  type="submit"
                  variant="contained"
                  color="secondary"
                  sx={{ textTransform: "initial", mr: isMobile ? 0 : 2 }}
                >
                  {getTranslation("GrantPermission")}
                </Button>
                <Button
                  fullWidth={isMobile ? true : false}
                  onClick={() => {
                    setAddNew(false);
                    resetField("firstName");
                    resetField("role");
                  }}
                  type="submit"
                  variant="outlined"
                  color="secondary"
                  sx={{ textTransform: "initial", mt: isMobile ? 2 : 0 }}
                >
                  {getTranslation("Cancel")}
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}

      {counter <= 4 && !otherUser && (
        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            startIcon={<AddIcon />}
            onClick={() => {
              setAddNew(true);
            }}
            sx={{ display: addNew ? "none" : "flex" }}
          >
            {getTranslation("AddNew")}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}