import {
  Box,
  Button,
  Grid,
  Modal,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

import confetti from "../../assets/images/confetti.png";
import { CloseOutlined } from "@mui/icons-material";
import { useTranslation } from "../../translation/translate";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function CongratsPopUp(props: any) {
  const { handlePopUp, isPopUp, description, btnColor, background } = props;
  const { getTranslation } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const handleClose = (event: any, reason: any) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
  };

  return (
    <Modal
      open={isPopUp}
      onClose={() => handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box width={isMobile ? "100%" : 600} sx={style}>
        <Grid container>
          <Grid
            item
            container
            xs={12}
            bgcolor={background}
            justifyContent="space-between"
          >
            <Typography fontSize={20} fontWeight={700} m={2}>
              {getTranslation("Congratulations")}
            </Typography>
            <Button onClick={handlePopUp} endIcon={<CloseOutlined />}></Button>
          </Grid>
          <Grid item container spacing={2} m={1} xs={12}>
            <Grid item xs={4}>
              <img src={confetti} width="100%" alt="confetti" />
            </Grid>
            <Grid
              item
              xs={8}
              display="flex"
              justifyContent="flex-start"
              alignSelf="center"
            >
              <Typography fontWeight={700} flexWrap="wrap">
                {description}
              </Typography>
            </Grid>
            <Grid item xs={12} m={2} display="flex" justifyContent="flex-end">
              <Button
                href={"/myprogresssummary"}
                onClick={handlePopUp}
                variant="contained"
                sx={{
                  textTransform: "initial",
                }}
                color={btnColor}
              >
                {getTranslation("Dismiss")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
