import { ExpandCircleDown } from "@mui/icons-material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "../../translation/translate";

export default function ResourceCollectionInfo() {
  const { getTranslation } = useTranslation();
  const downloadDialogueWorksheet = () => {
    const link = document.createElement("a");
    link.download = `CDAVerificationVisitReflectiveDialogWorksheet.pdf`;
    link.href = "../docs/CDAVerificationVisitReflectiveDialogWorksheet.pdf";
    link.click();
  };

  const downloadQuestionnaire = () => {
    const link = document.createElement("a");
    link.download = `FamilyQuestionnaire.pdf`;
    link.href = "../docs/Family_Questionnaire.pdf";
    link.click();
  };
  const downloadQuestionnaireSpanish = () => {
    const link = document.createElement("a");
    link.download = `FamilyQuestionnaireSpanish.pdf`;
    link.href = "../docs/HV_Family Questionnaire_Spanish.pdf";
    link.click();
  };
  const downloadQuestionnaireEnglish = () => {
    const link = document.createElement("a");
    link.download = `FamilyQuestionnaireEnglish.pdf`;
    link.href = "../docs/HV_Family Questionnaire_English.pdf";
    link.click();
  };
  const downloadQuestionnaireSummary = () => {
    const link = document.createElement("a");
    link.download = `FamilyQuestionnairesSummary.pdf`;
    link.href = "../docs/FamilyQuestionnaireSummary.pdf";
    link.click();
  };
  const downloadProfessionalChecklist = () => {
    const link = document.createElement("a");
    link.download = `MyCDAProfessionalPortfolioChecklist.pdf`;
    link.href = "../docs/MyCDAProfessionalPortfolioForm.pdf";
    link.click();
  };
  const downloadProfessionalHVChecklist = () => {
    const link = document.createElement("a");
    link.download = `MyCDAProfessionalPortfolioChecklist.pdf`;
    link.href = "../docs/HV_CS_book_My CDA Professional Portfolio.pdf";
    link.click();
  };
  const downloadParentObservation = () => {
    const link = document.createElement("a");
    link.download = `ParentObservationPermissonForm.pdf`;
    link.href = "../docs/HV_ParentObservationPermission.pdf";
    link.click();
  };
  const downloadCDAEdu = () => {
    const link = document.createElement("a");
    link.download = `SummaryofMyCDAEducation.pdf`;
    link.href = "../docs/SummaryofMyCDAEducationForm.pdf";
    link.click();
  };
  const downloadHVQuestionnaireSummary = () => {
    const link = document.createElement("a");
    link.download = `HomeVisitorFamilyQuestionnaireSummarySheet.pdf`;
    link.href = "../docs/HV_CS_book_FamilyQuestionnairesSummarySheet.pdf";
    link.click();
  };
  const downloadHVSummaryEDU = () => {
    const link = document.createElement("a");
    link.download = `HomeVisitorSummaryofMyCDAEducation.pdf`;
    link.href = "../docs/HV_CS_book_SummaryofMyCDAEducation.pdf";
    link.click();
  };
  const downloadHVAssessor = () => {
    const link = document.createElement("a");
    link.download = `HomeVisitorCDAAssessorRecommendationForm.pdf`;
    link.href = "./docs/HomeVisitorCDAAssessorRecommendationForm.pdf";
    link.click();
  };

  return (
    <Grid item xs={12} mt={2}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandCircleDown sx={{ color: "rcLibrary.main" }} />}
        >
          <Typography fontSize={18} fontWeight={700}>
            {getTranslation("PortfolioFormsLine1")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item container p={2} spacing={5}>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>
                {getTranslation("PortfolioFormsLine2")}
              </Typography>
              <Button
                onClick={downloadDialogueWorksheet}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadWorksheet")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>
                {getTranslation("FamilyQuestionnaire")}
              </Typography>
              <Button
                onClick={downloadQuestionnaire}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadWorksheet")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>
                {getTranslation("PortfolioFormsLine3")}
              </Typography>
              <Button
                onClick={downloadQuestionnaireEnglish}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadWorksheet")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>
                {getTranslation("PortfolioFormsLine4")}
              </Typography>
              <Button
                onClick={downloadQuestionnaireSpanish}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadWorksheet")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>
                {getTranslation("PortfolioFormsLine5")}
              </Typography>
              <Button
                onClick={downloadQuestionnaireSummary}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadWorksheet")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>
                {getTranslation("PortfolioFormsLine6")}
              </Typography>
              <Button
                onClick={downloadHVQuestionnaireSummary}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadWorksheet")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>
                {getTranslation("PortfolioFormsLine7")}
              </Typography>
              <Button
                onClick={downloadProfessionalChecklist}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadDocument")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>
                {getTranslation("PortfolioFormsLine8")}
              </Typography>
              <Button
                onClick={downloadProfessionalHVChecklist}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadDocument")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>
                {getTranslation("PortfolioFormsLine9")}
              </Typography>
              <Button
                onClick={downloadParentObservation}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadDocument")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>
                {getTranslation("PortfolioFormsLine10")}
              </Typography>
              <Button
                onClick={downloadCDAEdu}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadSummary")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>
                {getTranslation("PortfolioFormsLine11")}
              </Typography>
              <Button
                onClick={downloadHVSummaryEDU}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadSummary")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography mb={2}>
                {getTranslation("PortfolioFormsLine12")}
              </Typography>
              <Button
                onClick={downloadHVAssessor}
                variant="outlined"
                color="rcLibrary"
                startIcon={<FileDownloadIcon />}
                sx={{ textTransform: "none" }}
              >
                {getTranslation("DownloadWorksheet")}
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
