import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useForm } from "react-hook-form";
import ExpandCircleDown from "@mui/icons-material/ExpandCircleDown";
import DraftAndSubmitBtns from "../../app/common/SaveDraftSubmitBtn";
import { AnswersProps } from "../../app/models/Answers";
import { yupResolver } from "@hookform/resolvers/yup";
import schemaRCIV from "../../app/validations/RC4Validations";
import { useTranslation } from "../../translation/translate";

const LocalCounseling: React.FC<AnswersProps & { onSave: () => void }> = ({
  answersData,
  onAnswerChange,
  onSave,
}) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const { getTranslation } = useTranslation();
  const gridStyle1 = {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
  };
  function toTitleCase(str: string) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const textStyle1 = {
    mr: isMobile ? 0 : 2,
    mb: isMobile ? 2 : 0,
  };
  const defaultValues = {
    name: answersData.find((answer) => answer.systemCodeId === 190)?.answer,
    address: answersData.find((answer) => answer.systemCodeId === 191)?.answer,
    phone: answersData.find((answer) => answer.systemCodeId === 192)?.answer,
    email: answersData.find((answer) => answer.systemCodeId === 193)?.answer,
    website: answersData.find((answer) => answer.systemCodeId === 194)?.answer,
  };
  const validationSchema = schemaRCIV.RCIVSchema();
  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
    mode: "onTouched",
  });

  const handleNumberInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const numericInput = input.replace(/\D/g, ""); // Remove non-numeric characters
    event.target.value = numericInput;
    const newValue = event.target.value;
    const systemCodeId = 192;
    onAnswerChange(systemCodeId, newValue);
  };

  return (
    <Grid item mb={2} p={2}>
      <Grid item xs={12} mb={2}>
        <Typography fontWeight={700}>
          {getTranslation("RCIVLocalCounselingLine1")}
        </Typography>
      </Grid>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandCircleDown color="warning" />}>
          <Typography fontWeight={700}>
            {toTitleCase(getTranslation("RCIVLocalCounselingLine3"))}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            justifyContent="space-between"
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography>
                {getTranslation("RCIVLocalCounselingLine3")}
              </Typography>
            </Grid>
            <Grid item container xs={12} spacing={4}>
              <Grid item xs={12} md={12}>
                <TextField
                  label={getTranslation("NAME_Local_Counseling_Agency")}
                  {...register("name")}
                  value={
                    answersData.find((answer) => answer.systemCodeId === 190)
                      ?.answer || ""
                  }
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const systemCodeId = 190;
                    onAnswerChange(systemCodeId, newValue);
                  }}
                  fullWidth
                  error={!!errors.name?.message}
                  helperText={errors.name?.message}
                />
              </Grid>
              <Grid item xs={12} sx={gridStyle1}>
                <TextField
                  sx={textStyle1}
                  label={getTranslation("Address_Local_Counseling_Agency")}
                  {...register("address")}
                  value={
                    answersData.find((answer) => answer.systemCodeId === 191)
                      ?.answer || ""
                  }
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const systemCodeId = 191;
                    onAnswerChange(systemCodeId, newValue);
                  }}
                  fullWidth
                  error={!!errors.address?.message}
                  helperText={errors.address?.message}
                />
                <TextField
                  label={getTranslation("Telephone_Local_Counseling_Agency")}
                  {...register("phone")}
                  value={
                    answersData.find((answer) => answer.systemCodeId === 192)
                      ?.answer || ""
                  }
                  onChange={handleNumberInput}
                  fullWidth
                  error={!!errors.phone?.message}
                  helperText={errors.phone?.message}
                />
              </Grid>
              <Grid item xs={12} sx={gridStyle1}>
                <TextField
                  sx={textStyle1}
                  label={getTranslation("Email_Local_Counseling_Agency")}
                  {...register("email")}
                  value={
                    answersData.find((answer) => answer.systemCodeId === 193)
                      ?.answer || ""
                  }
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const systemCodeId = 193;
                    onAnswerChange(systemCodeId, newValue);
                  }}
                  fullWidth
                  error={!!errors.email?.message}
                  helperText={errors.email?.message}
                />
                <TextField
                  label={getTranslation("Website_Local_Counseling_Agency")}
                  {...register("website")}
                  placeholder="https://www.website.com/"
                  value={
                    answersData.find((answer) => answer.systemCodeId === 194)
                      ?.answer || ""
                  }
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const systemCodeId = 194;
                    onAnswerChange(systemCodeId, newValue);
                  }}
                  fullWidth
                  error={!!errors.website?.message}
                  helperText={errors.website?.message}
                />
              </Grid>
            </Grid>

            <DraftAndSubmitBtns btnColor={"info"} onSave={onSave} />
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};
export default LocalCounseling;
