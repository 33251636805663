import { useEffect } from "react";
import { TranslationItem } from "./translationType";
import { useAppDispatch, useAppSelector } from "../app/store/configureStore";
import { fetchTranslations } from "./translationSlice";

export function useTranslation() {
  const dispatch = useAppDispatch();

  // Select translations from Redux store
  const { translations } = useAppSelector(
    (state) => state.translations
  );

  useEffect(() => {
    if (!translations) {
      dispatch(fetchTranslations());
    }
  }, [dispatch, translations]);

  const { user } = useAppSelector((state) => state.account);

  const getLanguage = () => {
    if (user?.isSpanish) {
      return "es";
    }
    return "en";
  };

  const getTranslation = (key: string): string => {
    if (!translations) return "";

    const matchingTranslation = translations.find((x) => x.placeholder === key);

    if (matchingTranslation) {
      const langKey = getLanguage() as keyof TranslationItem;

      return matchingTranslation[langKey]?.toString() || "";
    }
    return "";
  };

  return { getTranslation };
}
