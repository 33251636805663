import { FileUpload, FileDownload } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import {
  Anchor,
  Section,
  OpenDrawerPayload,
} from "../../app/models/FileUpload";
import { FileInfo } from "../../app/models/Fileinfo";
import {
  useAppSelector,
  RootState,
  useAppDispatch,
} from "../../app/store/configureStore";
import UploadedFiles from "../FileUpload/UploadedFiles";
import { openDrawer } from "../FileUpload/drawerSlice";
import agent from "../../app/api/agent";
import LoadingSpinner from "../../app/common/LoadingSpinner";
import { FilesProps } from "../../app/models/Answers";
import { useTranslation } from "../../translation/translate";

function toTitleCase(str: string) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

const Instructions: React.FC<FilesProps> = ({ RecallData }) => {
  const [data, setData] = useState<FileInfo[]>([]);
  const { isOpen } = useAppSelector((state: RootState) => state.drawer);
  const [isLoading, setIsLoading] = useState(true);
  const { getTranslation } = useTranslation();

  const { user } = useAppSelector((state) => state.account);

  const dispatch = useAppDispatch();

  const handleOpenDrawer = (anchor: Anchor, section: Section) => {
    const payload: OpenDrawerPayload = {
      anchor,
      section,
    };
    dispatch(openDrawer(payload));
  };

  const fetchData = useCallback(async () => {
    try {
      const data = await agent.FileUpload.GetFileInfoByCertType(
        "Questionnaires Summary",
        user?.portfolioId ?? 0
      );
      setData(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      // Handle errors if necessary.
    }
  }, [user?.portfolioId]);

  useEffect(() => {
    fetchData();
  }, [isOpen, fetchData]);

  function handleDeleteFile(): void {
    fetchData();
    RecallData();
  }

  const downloadQuestionnaireSpanish = () => {
    const link = document.createElement("a");
    link.download = `FamilyQuestionnaireSpanish.pdf`;
    link.href = "../docs/HV_Family Questionnaire_Spanish.pdf";
    link.click();
  };
  const downloadEForm = () => {
    if (user?.credentialTypeId === 4) {
      const link = document.createElement("a");
      link.download = `FamilyQuestionnaireEnglish.pdf`;
      link.href = "../docs/HV_Family Questionnaire_English.pdf";
      link.click();
    } else {
      const link = document.createElement("a");
      link.download = `FamilyQuestionnaireSummarySheet.pdf`;
      link.href = "./docs/FamilyQuestionnaireSummary.pdf";
      link.click();
    }
  };
  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <Grid container p={2} spacing={2} m={1} bgcolor="myVerify.light">
      <Grid item xs={12} md={6} container className="divone">
        <Grid item xs={12}>
          <Typography fontSize={18} fontWeight={700}>
            {getTranslation("InstructionsLine1")}
          </Typography>
          <Typography>{getTranslation("InstructionsLine2")}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography component="div">
            <ul>
              <li>{getTranslation("InstructionsLine3")}</li>
              <li>{getTranslation("InstructionsLine4")}</li>
              <li>{getTranslation("InstructionsLine5")}</li>
            </ul>
          </Typography>
        </Grid>
        <Grid item mt={2} xs={12}>
          <Button
            href="/familyquestionnairessummary/EForm"
            variant="outlined"
            sx={{
              textTransform: "none",
            }}
            color="myVerify"
          >
            {getTranslation("InstructionsLine6")}
          </Button>
          <Typography variant="body1" fontWeight="bold" pt={2}>
            {toTitleCase(getTranslation("DownloadForm"))}
          </Typography>
          {user?.credentialTypeId === 4 && (
            <Button
              onClick={() => downloadQuestionnaireSpanish()}
              variant="outlined"
              startIcon={<FileDownload />}
              sx={{
                textTransform: "none",
                marginBottom: 2,
              }}
              color="myVerify"
            >
              {getTranslation("InstructionsLine8")}
            </Button>
          )}
          <Button
            onClick={() => downloadEForm()}
            variant="outlined"
            startIcon={<FileDownload />}
            sx={{
              textTransform: "none",
              marginBottom: 2,
            }}
            color="myVerify"
          >
            {getTranslation("InstructionsLine9")}
          </Button>
          <Typography fontWeight={700}>{getTranslation("Upload")}</Typography>
          <Button
            variant="outlined"
            startIcon={<FileUpload />}
            sx={{
              textTransform: "none",
            }}
            color="myVerify"
            onClick={() => handleOpenDrawer("right", "SummarySheet")}
          >
            {getTranslation("InstructionsLine10")}
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="body1" fontWeight="bold">
          {getTranslation("UploadedDocuments")}
        </Typography>
        <Typography variant="subtitle2">
          {data.length}{" "}
          {data.length === 1 ? getTranslation("file") : getTranslation("files")}
          {"  "}
          {getTranslation("uploaded")}
        </Typography>
        {data.map((item) => (
          <UploadedFiles
            fileOrder={data.indexOf(item) + 1}
            key={item.id}
            fileInfo={item}
            onDeleteFile={() => handleDeleteFile()}
          />
        ))}
      </Grid>
    </Grid>
  );
};
export default Instructions;
