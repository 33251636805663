import {
  Box,
  Button,
  Grid,
  Modal,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { useTranslation } from "../../translation/translate";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

export default function BilingualPopUp(props: any) {
  const { handlePopUp, isPopUp, btnColor } = props;
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { getTranslation } = useTranslation();
  return (
    <Modal
      open={isPopUp}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box width={isMobile ? "100%" : 600} sx={style}>
        <Grid container p={2} spacing={3}>
          <Grid item>
            <Typography fontWeight={700} fontSize={20}>
              {getTranslation("BilingualSpecializationReminder")}
            </Typography>
          </Grid>
          <Grid
            item
            display="flex"
            justifyContent="flex-start"
            alignSelf="center"
          >
            <Typography flexWrap="wrap">
              {getTranslation("BilingualSpecializationPopUp")}
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Button
              onClick={handlePopUp}
              variant="contained"
              sx={{
                textTransform: "initial",
              }}
              color={btnColor}
            >
              {getTranslation("Dismiss")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
