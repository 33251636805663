import jsPDF from "jspdf";

const createCoverSheetPDF = ({
  user,
  userOptions,
  coverPicture,
}: any): jsPDF => {
  // Create a new jsPDF instance.
  const doc = new jsPDF();

  // Calculate double-spacing (line height factor) for 12-point font.
  const lineHeightFactor = 2;
  doc.setLineHeightFactor(lineHeightFactor);

  //doc margins
  let marginBottom = doc.internal.pageSize.height - 50;

  const title = userOptions[0].answer;
  const date = new Date().toLocaleDateString("default", {
    dateStyle: "long",
  });

  // header/footer details
  doc.setFontSize(20);

  if (coverPicture) {
    doc.addImage(coverPicture, "JPG", 60, 65, 90, 90);
  }
  // Split text into lines based on a maximum line width (e.g., 100).
  const userHeader = doc.splitTextToSize(title, 150);
  const headerHeight = coverPicture ? 180 : 120;
  doc.text(userHeader, 110, headerHeight, { align: "center" });

  // doc.addImage(coverPicture, 'PNG', 8,2, 20,20)
  doc.setFontSize(16);
  doc.text(`${user[1] + ", " + user[0]}`, 140, marginBottom - 17);
  doc.text(`${"Candidate ID: " + user[3]}`, 140, marginBottom - 10);
  doc.text(date, 140, marginBottom - 3);

  // Generate a data URL for the PDF.
  const pdfDataUri = doc.output("datauristring"); // Specify the filename here

  // Open the PDF in a new tab.
  const newTab = window.open();

  if (newTab) {
    newTab.document.write(`
      <html>
      <head>
        <title>${title}.pdf</title>
      </head>
      <body>
        <embed width='100%' height='100%' src='${pdfDataUri}' type='application/pdf'>
        
      </body>
      </html>
    `);
  } else {
    // Handle the case where the new tab couldn't be opened.
    alert("Popup blocker prevented opening a new tab.");
  }

  return doc;
};
export default createCoverSheetPDF;
