import { Grid } from "@mui/material";
import HelpHeader from "./HelpHeader";
import HelpSection from "./HelpSection";
import FaqSection from "./FaqSection";

export default function HelpAndFaqs() {
  return (
    <>
      <Grid container p={2} mb={2}>
        <HelpHeader />
        <HelpSection />
        <FaqSection />
      </Grid>
    </>
  );
}
