import { Grid, TextField, Typography } from "@mui/material";
import { useAppSelector } from "../../app/store/configureStore";
import ReadMoreReadLess from "../../app/common/ReadMoreReadLess";
import { useForm } from "react-hook-form";
import { AnswersProps } from "../../app/models/Answers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const IndicatorsProfessionalism: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();

  const defaultValues = {
    professionalism: answersData.find((answer) => answer.systemCodeId === 255)
      ?.answer,
  };

  const schema = yup.object().shape({
    professionalism: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });
  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const renderIndicatorsofProfessionalismCompetence = () => {
    return (
      <>
        <li>{getTranslation("SVIIndicatorsLine1")}</li>
      </>
    );
  };

  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={12}>
        <Typography fontSize={18} fontWeight={700}>
          {getTranslation("SVIIndicatorsLine0")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{getTranslation("SVIIndicatorsLine2")}</Typography>
        {user?.credentialTypeId === 4 ? (
          <></>
        ) : (
          <ReadMoreReadLess>
            <>{renderIndicatorsofProfessionalismCompetence()}</>
          </ReadMoreReadLess>
        )}
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={getTranslation("SVIIndicatorsLine3")}
          {...register("professionalism")}
          name="professionalism"
          fullWidth
          multiline
          rows={2}
          value={
            answersData.find((answer) => answer.systemCodeId === 255)?.answer ||
            ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = 255;

            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.professionalism?.message}
          helperText={errors.professionalism?.message}
        />
        <Typography fontSize={13}>
          {defaultValues.professionalism?.length}/500
        </Typography>
      </Grid>
    </Grid>
  );
};
export default IndicatorsProfessionalism;
