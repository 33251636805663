import { Grid, Typography } from "@mui/material";
import { useAppSelector } from "../../app/store/configureStore";
import AgencyServices from "./AgencyServices";
import { ResourceIVForm } from "../../app/models/Answers";
import { useTranslation } from "../../translation/translate";

export default function AgenciesServingChild(props: any) {
  const { onSave, answersData, onAnswerChange } = props;
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();

  const RCIVCommunityAgencyCodes: ResourceIVForm = {
    nameCode: 220,
    addressCode: 221,
    telephoneCode: 222,
    emailCode: 223,
    websiteCode: 224,
    agencyDetailsCode: 319,
    agencyDetailsBilingualCode: 322,
  };

  const RCIV2ndCommunityAgencyCodes: ResourceIVForm = {
    nameCode: 230,
    addressCode: 231,
    telephoneCode: 232,
    emailCode: 233,
    websiteCode: 234,
    agencyDetailsCode: 321,
    agencyDetailsBilingualCode: 320,
  };

  return (
    <Grid item mb={2} p={2}>
      <Typography fontWeight={700}>
        {getTranslation("RCIVAgenciesServingChildLine1")}
      </Typography>

      <Grid item>
        <Typography display={user?.languageSpecialization ? "show" : "none"}>
          {getTranslation("RCIVAgenciesServingChildLine2")}
        </Typography>
      </Grid>
      <Grid item mb={2}>
        <Typography>
          {getTranslation("RCIVAgenciesServingChildLine3")}
        </Typography>
      </Grid>
      <Grid item mb={2}>
        <AgencyServices
          onSave={onSave}
          answersData={answersData}
          onAnswerChange={onAnswerChange}
          ResourceCollectionIVCodes={RCIVCommunityAgencyCodes}
        />
      </Grid>

      <Grid item>
        <AgencyServices
          onSave={onSave}
          answersData={answersData}
          onAnswerChange={onAnswerChange}
          ResourceCollectionIVCodes={RCIV2ndCommunityAgencyCodes}
        />
      </Grid>
    </Grid>
  );
}
