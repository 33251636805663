import { Grid, Typography } from "@mui/material";
import ResourceVStatus from "./ResourceVStatus";
import { SummaryCompleteProps } from "../../app/models/SummaryComplete";
import UploadDocumentsRSV from "./RSVUploadDocs";
import { useState, useEffect, useCallback } from "react";
import agent from "../../app/api/agent";
import LoadingSpinner from "../../app/common/LoadingSpinner";
import { useAppSelector } from "../../app/store/configureStore";
import CongratsPopUp from "../../app/common/CongratsPopUp";
import { useTranslation } from "../../translation/translate";

export default function CompetencyVResourceCollection() {
  const { user } = useAppSelector((state) => state.account);
  const [isLoading, setIsLoading] = useState(true);
  const { getTranslation } = useTranslation();

  const description =
    user && user?.credentialTypeId === 4 ? (
      <Typography component="span">
        <ul>
          {getTranslation("CompetencyVResourceCollectionBulletList1")}
          <ol>{getTranslation("CompetencyVResourceCollectionBulletList11")}</ol>
          <ol>{getTranslation("CompetencyVResourceCollectionBulletList12")}</ol>
          <ol>{getTranslation("CompetencyVResourceCollectionBulletList13")}</ol>
        </ul>
      </Typography>
    ) : (
      <Typography component="span">
        <ul>
          {getTranslation("CompetencyVResourceCollectionBulletList2")}
          <ol>{getTranslation("CompetencyVResourceCollectionBulletList21")}</ol>
          <ol>{getTranslation("CompetencyVResourceCollectionBulletList22")}</ol>
          <ol>{getTranslation("CompetencyVResourceCollectionBulletList23")}</ol>
        </ul>
      </Typography>
    );

  const [summaryCompleteProps, setSummaryCompleteProps] =
    useState<SummaryCompleteProps>({
      title: getTranslation("CompetencyVResourceCollectionTitle"),
      percent: "",
      header: getTranslation("CompetencyVResourceCollectionHeader"),
      description: description,
    });

  const [userProgress, setUserProgress] = useState(0);
  const [popUp, setPopUp] = useState(false);
  const handlePopUp = () => {
    setPopUp(false);
  };

  const fetchData = useCallback(async () => {
    try {
      if (user?.credentialName && user?.portfolioId) {
        const compData = await agent.Progress.GetCompentencyResultsByType(
          user.portfolioId,
          "RCV"
        );

        setUserProgress(compData.resourceProgress);
        setSummaryCompleteProps((prevSummaryCompleteProps) => ({
          ...prevSummaryCompleteProps,
          percent: compData.resourceProgress + "%",
        }));

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [user?.credentialName, user?.portfolioId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const RecallData = () => {
    fetchData();
  };

  const handleSave = async () => {
    console.log("handleSave");
    setIsLoading(true);
    fetchData();
    setIsLoading(false);
    if (userProgress >= 100) {
      setPopUp(true);
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container p={2} mb={2}>
      <ResourceVStatus {...summaryCompleteProps} />
      <UploadDocumentsRSV
        onSave={handleSave}
        userProgress={userProgress}
        RecallData={RecallData}
      />
      <CongratsPopUp
        handlePopUp={handlePopUp}
        isPopUp={popUp}
        btnColor={"compV"}
        background={"compV.light"}
        description={getTranslation("CompetencyStatementVDescriptionPopUp")}
      />
    </Grid>
  );
}
