import { Button, Grid, Typography, Modal } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import agent from "../../../app/api/agent";
import AddUserModal from "./AddUserModal";
import LoadingSpinner from "../../../app/common/LoadingSpinner";
import dayjs from "dayjs";

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "firstName", headerName: "First name", width: 130 },
  { field: "lastName", headerName: "Last name", width: 130 },
  { field: "email", headerName: "Email/ Username", width: 170 },
  {
    field: "isActive",
    headerName: "Status",
    width: 130,
    renderCell: (params) => (params.value ? "Active" : "Inactive"),
  },
  { field: "state", headerName: "State", width: 130 },
  { field: "role", headerName: "Role", width: 130 },
  {
    field: "cdaCouncilCandidateId",
    headerName: "CDA Council Candidate ID",
    width: 200,
  },
  { field: "progress", headerName: "Progress", width: 130 },
];

export default function ManageUsers() {
  const [rows, setRows] = useState<any>([]);
  const [editDisabled, setEditDisabled] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isAddUserModalOpen, setAddUserModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      const data = await agent.Admin.GetAllUsersWithProgress(
        dayjs().toString()
      );
      setRows(data);
    } catch (error) {
      // Handle errors if necessary.
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClick = (e: any) => {
    setSelectedRow(e.row.id);
  };

  const handleSelectionModelChange = (selectionModel: string | any[]) => {
    if (selectionModel.length > 1) {
      const newSelectionModel = [selectionModel[selectionModel.length - 1]];
      setSelectedRow(newSelectionModel[0]);
    } else {
      setSelectedRow(selectionModel[0]);
    }
    if (selectionModel.length > 0) {
      setEditDisabled(false);
    } else {
      setEditDisabled(true);
    }
  };

  const handleAddUserClick = () => {
    setAddUserModalOpen(true);
  };

  const handleAddUserModalClose = () => {
    setAddUserModalOpen(false);
    setIsLoading(true);
    fetchData();
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container spacing={3} p={3}>
      <Grid item xs={6}>
        <Typography variant="h5" fontWeight="bold" paragraph>
          Manage Users
        </Typography>
      </Grid>
      <Grid item xs={6} sx={{ justifyContent: "flex-end" }}>
        <Typography
          variant="h5"
          fontWeight="bold"
          paragraph
          sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleAddUserClick}
            sx={{ textTransform: "initial" }}
          >
            Add User
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            href={`/admin/edituser/${selectedRow}`}
            sx={{ textTransform: "initial" }}
            disabled={editDisabled}
          >
            Edit User
          </Button>
        </Typography>
      </Grid>

      <Modal
        open={isAddUserModalOpen}
        onClose={handleAddUserModalClose}
        aria-labelledby="add-user-modal-title"
        aria-describedby="add-user-modal-description"
      >
        <div>
          <AddUserModal onClose={handleAddUserModalClose} />
        </div>
      </Modal>

      <Grid item container>
        <DataGrid
          slots={{ toolbar: GridToolbar }}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          editMode="row"
          onCellClick={(e) => handleClick(e)}
          checkboxSelection
          rowSelectionModel={selectedRow ? [selectedRow] : []}
          onRowSelectionModelChange={(newSelection) =>
            handleSelectionModelChange(newSelection)
          }
        />
      </Grid>
    </Grid>
  );
}
