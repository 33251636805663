import { FileUpload } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import {
  Anchor,
  Section,
  OpenDrawerPayload,
} from "../../app/models/FileUpload";
import { openDrawer } from "../FileUpload/drawerSlice";
import agent from "../../app/api/agent";
import { useEffect, useState } from "react";
import { FileInfo } from "../../app/models/Fileinfo";
import UploadedFiles from "../FileUpload/UploadedFiles";
import { FilesProps } from "../../app/models/Answers";
import { useTranslation } from "../../translation/translate";

const Resource1WeeklyPlan: React.FC<FilesProps> = ({ RecallData }) => {
  const { user } = useAppSelector((state) => state.account);

  const [data, setData] = useState<FileInfo[]>([]);
  const { isOpen } = useAppSelector((state) => state.drawer);
  const section = user?.credentialTypeId === 4 ? "RCI24" : "RCI3";
  const dispatch = useAppDispatch();
  const { getTranslation } = useTranslation();

  const handleOpenDrawer = (anchor: Anchor, section: Section) => {
    const payload: OpenDrawerPayload = {
      anchor,
      section,
    };
    dispatch(openDrawer(payload));
  };

  const fetchData = async () => {
    try {
      const data = await agent.FileUpload.GetFileInfo(
        section,
        user?.portfolioId ?? 0
      ); // the 4 represents Credential Type Id
      setData(data);
    } catch (error) {
      // Handle errors if necessary.
    }
  };

  useEffect(() => {
    fetchData();
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      RecallData();
    }
  }, [isOpen]);

  function handleDeleteFile(): void {
    RecallData();
    fetchData();
  }

  return (
    <Grid
      item
      container
      p={2}
      bgcolor={user?.credentialTypeId === 4 ? "#EBFCF2" : "#FFFFFF"}
    >
      <Grid item xs={12}>
        <Typography variant="body1" fontWeight="bold" paragraph>
          {user?.credentialTypeId === 4
            ? "RC I-2 " + getTranslation("ResourceCollectionISection3Line1A")
            : "RC I-3 " + getTranslation("ResourceCollectionISection3Line1B")}
        </Typography>
        {user?.credentialTypeId === 4 ? (
          <Typography component="div">
            <Typography>
              {getTranslation("ResourceCollectionISection3Line2")}
            </Typography>
            <ul>
              <li>{getTranslation("ResourceCollectionISection3Line3")}</li>
              <li>{getTranslation("ResourceCollectionISection3Line4")}</li>
            </ul>
          </Typography>
        ) : (
          <>
            <Typography fontWeight={700}>
              {getTranslation("ResourceCollectionISection3Line5")}
            </Typography>
            <Typography component="div">
              <ul>
                <li>{getTranslation("ResourceCollectionISection3Line6")}</li>
                <li> {getTranslation("ResourceCollectionISection3Line7")}</li>
                <li>{getTranslation("ResourceCollectionISection3Line8")}</li>
                <li>{getTranslation("ResourceCollectionISection3Line9")}</li>
              </ul>
            </Typography>
          </>
        )}
        <Typography variant="body1">
          {user?.languageSpecialization &&
            getTranslation("ResourceCollectionISection3Line10")}
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="body1" fontWeight="bold">
          {getTranslation("ResourceCollectionISection3Line11")}
        </Typography>
        <Button
          variant="outlined"
          startIcon={<FileUpload />}
          sx={{
            textTransform: "none",
            marginTop: "10px",
          }}
          color="success"
          onClick={() => handleOpenDrawer("right", section)}
        >
          {getTranslation("ResourceCollectionISection3Line11")}
        </Button>
      </Grid>
      <Grid item p={2} xs={12} md={6}>
        <Typography variant="body1" fontWeight="bold">
          {getTranslation("UploadedDocuments")}
        </Typography>
        <Typography variant="subtitle2">
          {data.length}{" "}
          {data.length === 1 ? getTranslation("file") : getTranslation("files")}{" "}
          {getTranslation("uploaded")}
        </Typography>
        {data.map((item) => (
          <UploadedFiles
            fileOrder={data.indexOf(item) + 1}
            key={item.id}
            fileInfo={item}
            onDeleteFile={() => handleDeleteFile()}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default Resource1WeeklyPlan;
