import * as yup from "yup";
import { useTranslation } from "../../translation/translate";
interface BookValidationSchemaProps {
  languageSpecialization: boolean;
}

const BookValidationSchema = ({
  languageSpecialization,
}: BookValidationSchemaProps) => {
  const { getTranslation } = useTranslation();
  let schema = yup.object().shape({
    title: yup
      .string()
      .max(100, getTranslation("ValidationTitleMax"))
      .required(getTranslation("ValidationTitleRequired")),
    author: yup
      .string()
      .max(100, getTranslation("ValidationAuthorMaxRequired"))
      .required(getTranslation("ValidationAuthorRequired")),
    publisher: yup
      .string()
      .max(100, getTranslation("ValidationPublisherMaxRequired"))
      .required(getTranslation("ValidationPublisherRequired")),
    copyright: yup
      .number()
      .typeError(getTranslation("ValidationProvidedValidYearCopyright"))
      .required(getTranslation("ValidationCopyrightRequired"))
      .max(new Date().getFullYear())
      .min(1000, getTranslation("ValidationRequireYearFormat")),
    summary: yup
      .string()
      .max(1000, getTranslation("ValidationMaximumCharacterReached"))
      .required(getTranslation("ValidationDescriptionRequired")),
  });

  const secondLanguageFields = {
    summary2ndLanguage: yup
      .string()
      .max(1000, getTranslation("ValidationMaximumCharacterReached"))
      .required(getTranslation("SummaryInSecondLanguage")),
  };

  if (languageSpecialization) {
    schema = schema.shape(secondLanguageFields);
  }

  return schema;
};

export default BookValidationSchema;
