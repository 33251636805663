import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import image from "../../assets/images/symbol-icon-status-help.png";
import { useTranslation } from "../../translation/translate";

export default function HelpHeader() {
  const { getTranslation } = useTranslation();

  return (
    <>
      <Card
        sx={{
          width: "100%",
          backgroundColor: "#FFF4F0",
          boxShadow: " 0 16px #CC581F",
        }}
      >
        <CardContent>
          <Grid container mt={1}>
            <Grid item xs={2} sm={2} md={1} sx={{ textAlign: "center" }}>
              <Grid item sx={{ textAlign: "center" }}>
                <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                  <img src={image} alt="status" style={{ width: "70%" }} />
                </Box>
                <Typography
                  variant="h6"
                  fontWeight={700}
                  sx={{
                    font: "Lato Bold",
                    fontSize: "24px",
                    lineHeight: "29px",
                    marginBottom: "37px",
                  }}
                ></Typography>
              </Grid>
            </Grid>
            <Grid
              item
              xs={10}
              sm={10}
              md={11}
              display="block"
              flexDirection="column"
              paddingLeft={3}
            >
              <Typography variant="h6" fontWeight={700} marginBottom={1}>
                {getTranslation("Help&FAQs")}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
