import { FileUpload } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import UploadedFiles from "../FileUpload/UploadedFiles";
import { useCallback, useEffect, useState } from "react";
import { FileInfo } from "../../app/models/Fileinfo";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../app/store/configureStore";
import {
  Anchor,
  OpenDrawerPayload,
  Section,
} from "../../app/models/FileUpload";
import { openDrawer } from "../FileUpload/drawerSlice";
import agent from "../../app/api/agent";
import { useTranslation } from "../../translation/translate";

export default function Resource1pt3() {
  const [data, setData] = useState<FileInfo[]>([]);
  const { isOpen } = useAppSelector((state: RootState) => state.drawer);
  const { user } = useAppSelector((state) => state.account);
  const section = user?.credentialTypeId === 4 ? "RCI24" : "RCI3"; //The 4 is for Credential type Do not Change unless you unsdertsand what is happening. Christopher Waters
  const dispatch = useAppDispatch();
  const { getTranslation } = useTranslation();

  const handleOpenDrawer = (anchor: Anchor, section: Section) => {
    const payload: OpenDrawerPayload = {
      anchor,
      section,
    };
    dispatch(openDrawer(payload));
  };

  const fetchData = useCallback(async () => {
    try {
      const data = await agent.FileUpload.GetFileInfo(
        section,
        user?.portfolioId ?? 0
      );
      setData(data);
    } catch (error) {
      console.error();
    }
  }, [user?.portfolioId]);

  useEffect(() => {
    fetchData();
  }, [isOpen, fetchData]);

  function handleDeleteFile(): void {
    fetchData();
  }
  return (
    <Grid container item xs={12} p={2} mt={2}>
      <Grid item xs={12} md={6} mb={2}>
        <Typography fontWeight={700} mb={2}>
          {getTranslation("Resource1part3Line1")}
        </Typography>
        <Typography fontWeight={700}>
          {getTranslation("Resource1part3Line2")}
        </Typography>

        <Button
          variant="outlined"
          startIcon={<FileUpload />}
          sx={{
            textTransform: "none",
            marginTop: "10px",
          }}
          color="success"
          onClick={() => handleOpenDrawer("right", section)}
        >
          {getTranslation("ResourceCollectionISection3Line11")}
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body1" fontWeight="bold">
          {getTranslation("UploadedDocuments")}
        </Typography>
        <Typography variant="subtitle2">
          {data.length}{" "}
          {data.length === 1 ? getTranslation("file") : getTranslation("files")}
          {"  "}
          {getTranslation("uploaded")}
        </Typography>
        {data.map((item) => (
          <UploadedFiles
            fileOrder={data.indexOf(item) + 1}
            key={item.id}
            fileInfo={item}
            onDeleteFile={() => handleDeleteFile()}
          />
        ))}
      </Grid>
    </Grid>
  );
}
