import { Grid } from "@mui/material";
import { SummaryCompleteProps } from "../../app/models/SummaryComplete";
import ResourceVIStatus from "./ResourceVIStatus";
import { useAppSelector } from "../../app/store/configureStore";
import PolicyRelatedHomeVisitor from "./RCVIPolicyRelated";
import ChildhoodAssociation from "./RCVIChildhoodAssociations";
import MentalHealthServices from "./RCVIMentalHealth";
import StateAgencyRegulating from "./RCVIStateAgencyRegulating";
import ChildAbuseReporting from "./RCVIChildAbuse";
import { useState, useEffect, useCallback } from "react";
import agent from "../../app/api/agent";
import LoadingSpinner from "../../app/common/LoadingSpinner";
import { Answers } from "../../app/models/Answers";
import CongratsPopUp from "../../app/common/CongratsPopUp";
import { useTranslation } from "../../translation/translate";

export default function CompetencyVIResourceCollection() {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();

  const [summaryCompleteProps, setSummaryCompleteProps] =
    useState<SummaryCompleteProps>({
      title: getTranslation("CompetencyVIResourceCollectionTitle"),
      percent: "",
      header: getTranslation("CompetencyVIResourceCollectionHeader"),
      description: getTranslation("CompetencyVIResourceCollectionDescription"),
    });
  const [answers, setAnswers] = useState<Answers[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [popUp, setPopUp] = useState(false);
  const handlePopUp = () => {
    setPopUp((prev) => !prev);
  };

  const fetchData = useCallback(async () => {
    try {
      if (user?.credentialName && user?.portfolioId) {
        const response = await agent.Answers.GetAnswers(
          user.credentialName,
          user.portfolioId,
          "RCVI"
        );

        const compData = await agent.Progress.GetCompentencyResultsByType(
          user.portfolioId,
          "RCVI"
        );
        setSummaryCompleteProps((prevSummaryCompleteProps) => ({
          ...prevSummaryCompleteProps,
          percent: compData.resourceProgress + "%",
        }));
        setAnswers(response);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [user?.credentialName, user?.portfolioId]);

  useEffect(() => {
    fetchData();
  }, [user, fetchData]);

  const handleAnswerChange = (systemCodeId: number, newValue: string) => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((answer) =>
        answer.systemCodeId === systemCodeId
          ? { ...answer, answer: newValue }
          : answer
      )
    );
  };
  const RecallData = () => {
    fetchData();
  };

  const handleSaveDraft = async () => {
    setIsLoading(true);

    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );

    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);

    fetchData();

    setIsLoading(false);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }
  return (
    <Grid container p={2} mb={2}>
      <ResourceVIStatus {...summaryCompleteProps} />
      {user?.credentialTypeId === 4 ? (
        <>
          <PolicyRelatedHomeVisitor RecallData={RecallData} />
          <ChildhoodAssociation RecallData={RecallData} />
          <MentalHealthServices RecallData={RecallData} />
        </>
      ) : (
        <>
          <StateAgencyRegulating
            answersData={answers}
            onAnswerChange={handleAnswerChange}
            onSave={handleSaveDraft}
          />
          <ChildhoodAssociation RecallData={RecallData} />
          <ChildAbuseReporting
            answersData={answers}
            onAnswerChange={handleAnswerChange}
            onSave={handleSaveDraft}
          />
        </>
      )}
      <CongratsPopUp
        handlePopUp={handlePopUp}
        isPopUp={popUp}
        btnColor={"compVI"}
        background={"compVI.light"}
        description={getTranslation("CompResourceVIPopup")}
      />
    </Grid>
  );
}
