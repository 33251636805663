import { Button, Grid, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTranslation } from "../../translation/translate";
export default function VerifyEformHeader() {
  const { getTranslation } = useTranslation();
  return (
    <Grid item container>
      <Grid item>
        <Button
          href="/myverificationvisit"
          startIcon={<ArrowBackIosIcon />}
          sx={{ textTransform: "initial" }}
        >
          Back to "Getting Ready for Your Verification Visit"
        </Button>
      </Grid>
      <Grid item xs={12} mt={2} p={2}>
        <Typography fontWeight={700} fontSize={24} textAlign="center">
          CDA Verification Visit® Reflective Dialogue Worksheet
        </Typography>
        <Typography>
          <span style={{ fontWeight: 700 }}>Note to Candidate:</span> The last
          step of the CDA Verification Visit process is the reflective dialogue,
          the culminating activity designed to support your ongoing reflection
          about your professional practices. Please know that the dialogue you
          will have with your CDA Professional Development (PD) Specialist™ will
          be kept confidential between the two of you, will not be scored and
          has no bearing on the award of your CDA® Credential. You will retain
          this worksheet after the dialogue - no one else will see this
          worksheet unless you choose to share it. Therefore, feel free to
          reflect honestly and candidly about your professional strengths and
          areas for growth. There are no “right” or “wrong” answers in a
          reflective dialogue - only your commitment to your own professional
          growth and the goals you will set for yourself.
        </Typography>
      </Grid>
    </Grid>
  );
}
