import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { StepperProps } from "../models/steps";
import styled from "@emotion/styled";

const BoldStepLabel = styled(StepLabel)`
  && .MuiStepLabel-label {
    font-weight: bold;
  }
`;

export default function HorizontalStepper({ activeStep, steps }: StepperProps) {
  return (
    <Box sx={{ width: "60%", margin: "20px auto" }}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        sx={{
          "& .MuiStepIcon-root.Mui-active": {
            color: "#3e7787"
          },
          "& .MuiStepIcon-root.Mui-completed": {
            color: "#3e7787"
          },
        }}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <BoldStepLabel sx={{ display: { xs: "none", md: "flex" } }}>
              {label}
            </BoldStepLabel>
            <BoldStepLabel sx={{ display: { xs: "flex", md: "none" } }}>
              {" "}
            </BoldStepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
