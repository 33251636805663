import { Grid } from "@mui/material";
import EducationStatus from "./EducationStatus";
import { SummaryCompleteProps } from "../../app/models/SummaryComplete";
import SummaryEducationForm from "./SummaryEduForm";
import { RootState, useAppSelector } from "../../app/store/configureStore";
import { useState, useCallback, useEffect } from "react";
import agent from "../../app/api/agent";
import LoadingSpinner from "../../app/common/LoadingSpinner";
import { Answers } from "../../app/models/Answers";

export default function Cdaeducation() {
  const { user } = useAppSelector((state) => state.account);
  const { isOpen } = useAppSelector((state: RootState) => state.drawer);
  const [summaryCompleteProps, setSummaryCompleteProps] =
    useState<SummaryCompleteProps>({
      title: "My CDA® Education",
      percent: "",
      header: "",
      description: `We are delighted that you are taking or have completed your 120 hours of CDA coursework. Once you have completed your coursework, upload your certificates or transcript on this screen. You may upload multiple documents as needed. Then complete the Summary of My CDA® Education form.`,
    });

  const [userProgress, setUserProgress] = useState(0);
  const [answers, setAnswers] = useState<Answers[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      if (user?.credentialName && user?.portfolioId) {
        const response = await agent.Answers.GetAnswers(
          user.credentialName,
          user.portfolioId,
          "EducationSummary"
        );
        setAnswers(response);

        const compData = await agent.Progress.GetCompentencyResultsByType(
          user.portfolioId,
          "EducationSummary"
        );
        setUserProgress(compData.resourceProgress);
        setSummaryCompleteProps((prevSummaryCompleteProps) => ({
          ...prevSummaryCompleteProps,
          percent: compData.resourceProgress + "%",
        }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [user?.credentialName, user?.portfolioId, isOpen]);

  useEffect(() => {
    fetchData();
  }, [fetchData, isOpen, userProgress]);

  const handleAnswerChange = async (systemCodeId: number, newValue: string) => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((answer) =>
        answer.systemCodeId === systemCodeId
          ? { ...answer, answer: newValue }
          : answer
      )
    );
    await agent.Answers.InsertorUpdateAnswers(
      answers
        .map((answer) =>
          answer.systemCodeId === systemCodeId
            ? { ...answer, answer: newValue.toString() }
            : answer
        )
        .filter((answer) => answer.id !== 0 || answer.answer !== "")
    );
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container p={2} mb={2}>
      <EducationStatus {...summaryCompleteProps} />
      <SummaryEducationForm
        answersData={answers}
        onAnswerChange={handleAnswerChange}
      />
    </Grid>
  );
}
