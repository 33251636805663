import { Grid } from "@mui/material";
import { SummaryCompleteProps } from "../../app/models/SummaryComplete";
import ResourceVIStatus from "./ResourceVIStatus";
import MaintainingCommitment from "./SVIMaintainCommitment";
import EarlyChildhood from "./SVIEarlyChildhood";
import IndicatorsProfessionalism from "./SVIIndicators";
import PrintSaveBtns from "../../app/common/PrintSaveBtn";
import { useAppSelector } from "../../app/store/configureStore";
import { useState, useEffect, useCallback } from "react";
import agent from "../../app/api/agent";
import LoadingSpinner from "../../app/common/LoadingSpinner";
import { Answers } from "../../app/models/Answers";
import CongratsPopUp from "../../app/common/CongratsPopUp";
import BilingualPopUp from "../../app/common/BilingualPopUp";
import { PDF } from "../../app/models/Pdf";
import createAndOpenPDF from "../../app/utils/pdfutils";
import { useTranslation } from "../../translation/translate";

export default function CompetencyVIStatement() {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();
  const [pdf, setPdf] = useState<PDF[]>([]);
  const [summaryCompleteProps, setSummaryCompleteProps] =
    useState<SummaryCompleteProps>({
      title: getTranslation("CompetencyVIStatementTitle"),
      percent: "",
      header: getTranslation("CompetencyVIStatementHeader"),
      description: getTranslation("CompetencyVIStatementDescription"),
    });

  const [answers, setAnswers] = useState<Answers[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userProgress, setUserProgress] = useState(0);

  const [bilingualModal, setBilingualModal] = useState(true);
  const handleBilingual = () => {
    setBilingualModal(false);
  };

  const [popUp, setPopUp] = useState(false);
  const handlePopUp = () => {
    setPopUp((prev) => !prev);
  };

  const fetchData = useCallback(async () => {
    try {
      if (user?.credentialName && user?.portfolioId) {
        const response = await agent.Answers.GetAnswers(
          user.credentialName,
          user.portfolioId,
          "CSVI"
        );
        const compData = await agent.Progress.GetCompentencyResultsByType(
          user.portfolioId,
          "CSVI"
        );
        const pdf = await agent.PDFViewer.GetPDFByType(
          user.credentialName,
          user.portfolioId,
          "CSVI"
        );
        setPdf(pdf);
        setUserProgress(compData.statementProgress);

        setSummaryCompleteProps((prevSummaryCompleteProps) => ({
          ...prevSummaryCompleteProps,
          percent: compData.statementProgress + "%",
        }));

        setAnswers(response);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [user?.credentialName, user?.portfolioId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAnswerChange = (systemCodeId: number, newValue: string) => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((answer) =>
        answer.systemCodeId === systemCodeId
          ? { ...answer, answer: newValue }
          : answer
      )
    );
  };

  const handleSaveDraft = async () => {
    setIsLoading(true);
    // This is just a sample function for demonstration
    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );
    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    fetchData();
    setIsLoading(false);
  };

  const handleSave = async () => {
    setIsLoading(true);
    // This is just a sample function for demonstration
    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );
    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    fetchData();
    setIsLoading(false);
    handlePopUp();
  };

  const handlePDF = async () => {
    createAndOpenPDF({
      pdfData: pdf,
      title: getTranslation("CompetencyVIStatementTitle"),
      user: [
        user?.firstName,
        user?.lastName,
        user?.credentialName,
        user?.cdaCouncilCandidateId,
      ],
    });
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container p={2} mb={2}>
      {user?.languageSpecialization && (
        <BilingualPopUp
          isPopUp={bilingualModal}
          btnColor={"compVI"}
          handlePopUp={handleBilingual}
        />
      )}
      <ResourceVIStatus {...summaryCompleteProps} />
      <MaintainingCommitment
        answersData={answers}
        onAnswerChange={handleAnswerChange}
      />
      <EarlyChildhood
        answersData={answers}
        onAnswerChange={handleAnswerChange}
      />
      <IndicatorsProfessionalism
        answersData={answers}
        onAnswerChange={handleAnswerChange}
      />
      <PrintSaveBtns
        btnColor={"compVI"}
        onSave={handleSave}
        onDraft={handleSaveDraft}
        onPreview={handlePDF}
        Progress={userProgress}
        {...summaryCompleteProps}
      />
      <CongratsPopUp
        handlePopUp={handlePopUp}
        isPopUp={popUp}
        btnColor={"compVI"}
        background={"compVI.light"}
        description={getTranslation("compVIDescription")}
      />
    </Grid>
  );
}
