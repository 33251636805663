import { Grid } from "@mui/material";
import Header from "./Header";
import Prepare from "./Prepare";
import Review from "./Review";
import SubmitIt from "./SubmitIt";

export default function PuttingItAllTogether() {
  //user files are not being passed to the final pdf anymore

  return (
    <Grid container p={2} m={1}>
      <Header />
      <Prepare />
      <Review />
      <SubmitIt />
    </Grid>
  );
}
