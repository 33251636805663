import CheckIcon from "@mui/icons-material/Check";
import {
  Checkbox,
  Grid,
  TextField,
  Typography,
  Theme,
  useMediaQuery,
} from "@mui/material";
import ChecklistData from "../PortfolioChecklist/ChecklistData.json";
import HVChecklistData from "../PortfolioChecklist/HVChecklist.json";
import { useAppSelector } from "../../app/store/configureStore";
import { AnswersProps } from "../../app/models/Answers";
import { useForm } from "react-hook-form";
import { useTranslation } from "../../translation/translate";
import { get } from "http";

const ChecklistForm: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();
  const thisDate = new Date();
  const cData = ChecklistData.ChecklistData;
  const hvData = HVChecklistData.HVChecklistData;
  console.log(cData);
  const isHomeVisitor = user?.credentialTypeId === 4;
  const defaultValues = {
    check0: "",
    checkA: "",
    checkB: "",
    checkC: "",
    checkD: "",
    checkDb: "",
    checkE: "",
    checkEb: "",
    checkF: "",
    checkFb: "",
    checkG: "",
    checkGb: "",
    checkH: "",
    checkHb: "",
    checkI: "",
    checkIb: "",
    checkJ: "",
    checkJb: "",
    checkK: "",
    signature:
      answersData.find((answer) => answer.systemCodeId === 354)?.answer || "",
    date:
      answersData.find((answer) => answer.systemCodeId === 355)?.answer || "",
  };

  if (isHomeVisitor) {
    defaultValues.check0 =
      answersData.find((answer) => answer.systemCodeId === 356)?.answer || "";
    defaultValues.checkA =
      answersData.find((answer) => answer.systemCodeId === 357)?.answer || "";
    defaultValues.checkB =
      answersData.find((answer) => answer.systemCodeId === 358)?.answer || "";
    defaultValues.checkC =
      answersData.find((answer) => answer.systemCodeId === 359)?.answer || "";
    defaultValues.checkD =
      answersData.find((answer) => answer.systemCodeId === 360)?.answer || "";
    defaultValues.checkE =
      answersData.find((answer) => answer.systemCodeId === 361)?.answer || "";
    defaultValues.checkEb =
      answersData.find((answer) => answer.systemCodeId === 362)?.answer || "";
    defaultValues.checkF =
      answersData.find((answer) => answer.systemCodeId === 363)?.answer || "";
    defaultValues.checkFb =
      answersData.find((answer) => answer.systemCodeId === 364)?.answer || "";
    defaultValues.checkG =
      answersData.find((answer) => answer.systemCodeId === 365)?.answer || "";
    defaultValues.checkGb =
      answersData.find((answer) => answer.systemCodeId === 366)?.answer || "";
    defaultValues.checkH =
      answersData.find((answer) => answer.systemCodeId === 367)?.answer || "";
    defaultValues.checkHb =
      answersData.find((answer) => answer.systemCodeId === 368)?.answer || "";
    defaultValues.checkI =
      answersData.find((answer) => answer.systemCodeId === 369)?.answer || "";
    defaultValues.checkIb =
      answersData.find((answer) => answer.systemCodeId === 370)?.answer || "";
    defaultValues.checkJ =
      answersData.find((answer) => answer.systemCodeId === 371)?.answer || "";
    defaultValues.checkJb =
      answersData.find((answer) => answer.systemCodeId === 372)?.answer || "";
    defaultValues.checkK =
      answersData.find((answer) => answer.systemCodeId === 373)?.answer || "";
  } else {
    defaultValues.check0 =
      answersData.find((answer) => answer.systemCodeId === 337)?.answer || "";
    defaultValues.checkA =
      answersData.find((answer) => answer.systemCodeId === 338)?.answer || "";
    defaultValues.checkB =
      answersData.find((answer) => answer.systemCodeId === 339)?.answer || "";
    defaultValues.checkC =
      answersData.find((answer) => answer.systemCodeId === 340)?.answer || "";
    defaultValues.checkD =
      answersData.find((answer) => answer.systemCodeId === 341)?.answer || "";
    defaultValues.checkDb =
      answersData.find((answer) => answer.systemCodeId === 342)?.answer || "";
    defaultValues.checkE =
      answersData.find((answer) => answer.systemCodeId === 343)?.answer || "";
    defaultValues.checkEb =
      answersData.find((answer) => answer.systemCodeId === 344)?.answer || "";
    defaultValues.checkF =
      answersData.find((answer) => answer.systemCodeId === 345)?.answer || "";
    defaultValues.checkFb =
      answersData.find((answer) => answer.systemCodeId === 346)?.answer || "";
    defaultValues.checkG =
      answersData.find((answer) => answer.systemCodeId === 347)?.answer || "";
    defaultValues.checkGb =
      answersData.find((answer) => answer.systemCodeId === 348)?.answer || "";
    defaultValues.checkH =
      answersData.find((answer) => answer.systemCodeId === 349)?.answer || "";
    defaultValues.checkHb =
      answersData.find((answer) => answer.systemCodeId === 350)?.answer || "";
    defaultValues.checkI =
      answersData.find((answer) => answer.systemCodeId === 351)?.answer || "";
    defaultValues.checkIb =
      answersData.find((answer) => answer.systemCodeId === 352)?.answer || "";
    defaultValues.checkJ =
      answersData.find((answer) => answer.systemCodeId === 353)?.answer || "";
  }

  const { register } = useForm({
    defaultValues,
    mode: "onTouched",
  });

  return (
    <Grid container p={5}>
      <Grid item container spacing={2} mb={2}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            {getTranslation("MYCDAEducationTitle1")}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            variant="filled"
            label={getTranslation("FirstName")}
            defaultValue={user?.firstName}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            variant="filled"
            defaultValue={user?.lastName}
            label={getTranslation("LastName")}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            variant="filled"
            defaultValue={user?.cdaCouncilCandidateId}
            label={getTranslation("CandidateIDNumber")}
            fullWidth
            disabled
          />
        </Grid>
      </Grid>
      <Grid item mt={4} mb={2}>
        <Typography fontWeight={700} fontSize={20}>
          {getTranslation("CDAPortfolioChecklist")}
        </Typography>
      </Grid>
      <Grid
        item
        container
        bgcolor="myVerify.main"
        color="white"
        border="1px black solid"
      >
        <Grid item xs={1} textAlign="center">
          <Typography>{getTranslation("TAB")}</Typography>
        </Grid>
        <Grid item xs={10} textAlign="center">
          <Typography>{getTranslation("REQUIREDPORTFOLIOITEM")}</Typography>
        </Grid>
        <Grid item xs={1} textAlign="center">
          <CheckIcon />
        </Grid>
      </Grid>
      {(user?.credentialTypeId === 4 ? hvData : cData).map(
        (comp: any, index: number) => (
          <Grid
            item
            xs={12}
            container
            border="1px black solid"
            bgcolor={index % 2 !== 0 ? "myVerify.light" : "white"}
            maxHeight={isMobile ? 200 : 120}
            minWidth={400}
          >
            <Grid
              item
              xs={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              border="1px black solid"
            >
              <Typography fontSize={18} fontWeight={700}>
                {comp.id}
              </Typography>
            </Grid>
            <Grid item xs={10} border="1px black solid">
              <Typography sx={{ m: 1 }} fontSize={isMobile ? "small" : "large"}>
                <span style={{ fontWeight: 700 }}>
                  {user?.isSpanish ? comp.spanishTitle : comp.title}
                </span>{" "}
                {user?.isSpanish ? comp.spanishDetails : comp.details}
              </Typography>
              {comp.sTitle && (
                <hr style={{ borderWidth: 3, borderColor: "black" }} />
              )}
              <Typography sx={{ m: 1 }} fontSize={isMobile ? "small" : "large"}>
                <span style={{ fontWeight: 700 }}>
                  {user?.isSpanish ? comp.sspanishTiele : comp.sTitle}
                </span>{" "}
                {user?.isSpanish ? comp.spanishDetailsB : comp.detailsB}
              </Typography>
            </Grid>
            <Grid
              item
              xs={1}
              textAlign="center"
              alignContent="center"
              alignSelf="flex-end"
              borderBottom="2px black solid"
              maxHeight={isMobile ? 200 : 120}
            >
              <Checkbox
                size={isMobile ? "small" : "medium"}
                {...register(comp.registerCheck)}
                checked={
                  defaultValues[
                    comp.registerCheck as keyof typeof defaultValues
                  ]?.toLowerCase() === "true"
                }
                onChange={(e) => {
                  const newValue = e.target.checked.toString();
                  const systemCodeId = comp.systemCodeId;
                  onAnswerChange(systemCodeId, newValue);
                }}
              />
              {comp.sTitle && (
                <hr
                  style={{
                    borderWidth: 3,
                    borderColor: "black",
                    marginTop: 9.5,
                  }}
                />
              )}
              {comp.sTitle && (
                <Checkbox
                  size={isMobile ? "small" : "medium"}
                  {...register(comp.sregisterCheck)}
                  checked={
                    defaultValues[
                      comp.sregisterCheck as keyof typeof defaultValues
                    ]?.toLowerCase() === "true"
                  }
                  onChange={(e) => {
                    const newValue = e.target.checked.toString();
                    const systemCodeId = comp.sSystemCodeId;
                    onAnswerChange(systemCodeId, newValue);
                  }}
                />
              )}
            </Grid>
          </Grid>
        )
      )}

      <Grid container p={2} mt={2} spacing={4}>
        <Grid item xs={12}>
          <Typography>{getTranslation("ChecklistAttest")}</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label={getTranslation("CandidateSignature")}
            variant="standard"
            {...register("signature")}
            fullWidth
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 354;

              onAnswerChange(systemCodeId, newValue);
              const systemCodeIdDate = 355;

              /*  Adds signature date once the form is signed */
              onAnswerChange(systemCodeIdDate, thisDate.toDateString());
            }}
            InputProps={{
              inputProps: {
                style: {
                  textAlign: "center",
                  fontSize: 20,
                  fontFamily: "Cedarville Cursive, cursive",
                },
              },
            }}
          />
          <Typography fontSize={11}>{getTranslation("DISCLAIMER")}</Typography>
        </Grid>
        <Grid item xs={6} textAlign="center">
          <TextField
            disabled
            variant="filled"
            label={getTranslation("PortfolioChecklistDate")}
            {...register("date")}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChecklistForm;
