import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Email, Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { LoginFormInputs } from "../../app/models/LoginForm";
import { useAppDispatch } from "../../app/store/configureStore";
import LoginValidationSchema from "../../app/validations/LoginValidation";
import { signInUser } from "../account/accountSlice";
import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import Copyright from "../../app/common/Copyright";
import { useState } from "react";
import { InputAdornment, IconButton } from "@mui/material";
import logo from "../../assets/images/CDALogo.png";
import { fetchTranslations } from "../../translation/translationSlice";

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event: any) => event.preventDefault();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    setError,
    formState: { isSubmitting, errors, isValid },
  } = useForm<LoginFormInputs>({
    resolver: yupResolver(LoginValidationSchema),
    mode: "onTouched",
  });

  function handleApiErrors(errors: any) {
    if (errors) {
      if (errors.error.message.includes("not Authorized")) {
        setError("password", { message: "Invalid Login" });
      } else {
        setError("password", { message: errors.error.message });
      }
    }
  }

  const onSubmit = async (data: LoginFormInputs) => {
    await dispatch(signInUser(data))
      .then((user) => {
        const u: any = user.payload;
        const admin = u.role === "CSI Admin" || u.role === "Council Admin";
        const trainer = u.role === "Trainer";
        const otherViewer = u.role === "Other Viewer";

        if (admin) {
          navigate(location.state?.from || "/admin/dashboard");
        } else if (
          !admin &&
          !trainer &&
          !otherViewer &&
          u.welcomeComplete === true
        ) {
          navigate(location.state?.from || "/myprogresssummary");
        } else if (trainer) {
          navigate(location.state?.from || "/trainer/students");
        } else if (otherViewer) {
          navigate(location.state?.from || "/editprofile");
        } else if (
          !admin &&
          !trainer &&
          !otherViewer &&
          !otherViewer &&
          u.welcomeComplete === false
        ) {
          navigate(location.state?.from || "/welcome");
        } else if (otherViewer) {
          navigate(location.state?.from || "/trainer/manageusers");
        }
      })
      .catch((error) => handleApiErrors(error));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="CDA LOGO" style={{ width: "60%" }} />

        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <TextField
            label="Email"
            margin="normal"
            fullWidth
            InputProps={{
              startAdornment: <Email />,
            }}
            {...register("email")}
            error={!!errors.email?.message}
            helperText={errors.email?.message}
            onBlur={handleSubmit(onSubmit)}
            autoComplete="username"
            inputProps={{ autoComplete: "username" }}
          />
          <TextField
            label="Password"
            type={showPassword ? "text" : "password"}
            margin="normal"
            fullWidth
            InputProps={{
              startAdornment: <Lock />,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...register("password")}
            autoComplete="current-password"
            inputProps={{ autoComplete: "current-password" }}
            error={!!errors.password?.message}
            helperText={errors.password?.message}
            onBlur={handleSubmit(onSubmit)}
          />
          <LoadingButton
            loading={isSubmitting}
            disabled={!isValid}
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <Link href="/account/resetpassword" variant="body2">
                Reset Password
              </Link>
            </Grid>
            <Grid item>
              <Link href="/account/register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
