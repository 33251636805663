import React from "react";
import { Grid, TextField, Typography } from "@mui/material";
import ReadMoreReadLess from "../../app/common/ReadMoreReadLess";
import { useAppSelector } from "../../app/store/configureStore";
import { AnswersProps } from "../../app/models/Answers";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const CommunicationDevelopment: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();

  const schema = yup.object().shape({
    textbox: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });

  const isHomeVisitor = user?.credentialTypeId === 4;
  const systemCodeId = isHomeVisitor ? 62 : 61;

  const defaultValues = {
    textbox: answersData.find((answer) => answer.systemCodeId === systemCodeId)
      ?.answer,
  };
  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const renderCommunicationCSIIdCompetence = () => {
    return (
      <>
        <li>
          {getTranslation("StatementIICommunicationDevelopmentBulletList1")}
        </li>
        <li>
          {getTranslation("StatementIICommunicationDevelopmentBulletList2")}
        </li>
        <li>
          {getTranslation("StatementIICommunicationDevelopmentBulletList3")}
        </li>
        <li>
          {getTranslation("StatementIICommunicationDevelopmentBulletList4")}
        </li>
      </>
    );
  };

  return (
    <Grid container pb={3}>
      <Grid item xs={12} px={3} pt={3}>
        <Typography variant="body1" fontWeight="bold" paragraph>
          {user?.credentialTypeId !== 4
            ? getTranslation("StatementIICommunicationDevelopmentLine1")
            : getTranslation("StatementIICommunicationDevelopmentLine2")}
        </Typography>
        <Typography variant="body1" paragraph>
          {user?.credentialTypeId !== 4
            ? getTranslation("StatementIICommunicationDevelopmentQuestion1")
            : getTranslation("StatementIICommunicationDevelopmentQuestion2")}
        </Typography>
        <ReadMoreReadLess>
          <>{renderCommunicationCSIIdCompetence()}</>
        </ReadMoreReadLess>
      </Grid>
      <Grid item xs={12} px={3} pt={3}>
        <TextField
          label={
            user?.credentialTypeId !== 4
              ? getTranslation("StatementIICommunicationDevelopmentLine3")
              : getTranslation("StatementIICommunicationDevelopmentLine4")
          }
          multiline
          rows={2}
          fullWidth
          {...register("textbox")}
          name="textbox"
          value={
            answersData.find((answer) => answer.systemCodeId === systemCodeId)
              ?.answer || ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.textbox?.message}
          helperText={errors.textbox?.message}
        />
        <Typography fontSize={13}>
          {defaultValues.textbox?.length}/500
        </Typography>
      </Grid>
    </Grid>
  );
};
export default CommunicationDevelopment;
