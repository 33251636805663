import { Grid, TextField, Typography } from "@mui/material";
import ReadMoreReadLess from "../../app/common/ReadMoreReadLess";
import { AnswersProps } from "../../app/models/Answers";
import { useForm } from "react-hook-form";
import { useAppSelector } from "../../app/store/configureStore";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const SocialDevelopmentList: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const defaultValues = {
    social: answersData.find((answer) => answer.systemCodeId === 171)?.answer,
    emotional: answersData.find((answer) => answer.systemCodeId === 172)
      ?.answer,
    positive: answersData.find((answer) => answer.systemCodeId === 173)?.answer,
  };
  const { getTranslation } = useTranslation();

  const schema = yup.object().shape({
    social: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    emotional: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    positive: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });
  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const { user } = useAppSelector((state) => state.account);

  const renderSocialDevelopmentCompetence = () => {
    return (
      <>
        {user?.credentialTypeId === 4 ? (
          <ul>
            <li>
              {getTranslation("StatementIIISocialDevelopmentBulletList1")}
            </li>
            <li>
              {getTranslation("StatementIIISocialDevelopmentBulletList2")}
            </li>
            <li>
              {getTranslation("StatementIIISocialDevelopmentBulletList3")}
            </li>
            <li>
              {getTranslation("StatementIIISocialDevelopmentBulletList4")}
            </li>
          </ul>
        ) : (
          <ul>
            <li>
              {getTranslation("StatementIIISocialDevelopmentBulletList5")}
            </li>
            <li>
              {getTranslation("StatementIIISocialDevelopmentBulletList6")}
            </li>
            <li>
              {getTranslation("StatementIIISocialDevelopmentBulletList7")}
            </li>
            <li>
              {getTranslation("StatementIIISocialDevelopmentBulletList8")}
            </li>
          </ul>
        )}
      </>
    );
  };

  const renderEmotionalDevelopmentCompetence = () => {
    return (
      <>
        {user?.credentialTypeId === 4 ? (
          <ul>
            <li>
              {getTranslation("StatementIIISocialDevelopmentBulletList9")}
            </li>
            <li>
              {getTranslation("StatementIIISocialDevelopmentBulletList10")}
            </li>
            <li>
              {getTranslation("StatementIIISocialDevelopmentBulletList11")}
            </li>
          </ul>
        ) : (
          <ul>
            <li>
              {getTranslation("StatementIIISocialDevelopmentBulletList12")}
            </li>
            <li>
              {getTranslation("StatementIIISocialDevelopmentBulletList13")}
            </li>
            <li>
              {getTranslation("StatementIIISocialDevelopmentBulletList14")}
            </li>
            <li>
              {getTranslation("StatementIIISocialDevelopmentBulletList15")}
            </li>
          </ul>
        )}
      </>
    );
  };

  const renderPostiiveGuidanceCompetence = () => {
    return (
      <>
        {user?.credentialTypeId === 4 ? (
          <ul>
            <li>
              {getTranslation("StatementIIISocialDevelopmentBulletList16")}
            </li>
            <li>
              {getTranslation("StatementIIISocialDevelopmentBulletList17")}
            </li>
            <li>
              {getTranslation("StatementIIISocialDevelopmentBulletList18")}
            </li>
            <li>
              {getTranslation("StatementIIISocialDevelopmentBulletList19")}
            </li>
          </ul>
        ) : (
          <ul>
            <li>
              {getTranslation("StatementIIISocialDevelopmentBulletList20")}
            </li>
            <li>
              {getTranslation("StatementIIISocialDevelopmentBulletList21")}
            </li>
            <li>
              {getTranslation("StatementIIISocialDevelopmentBulletList22")}
            </li>
            <li>
              {getTranslation("StatementIIISocialDevelopmentBulletList23")}
            </li>
          </ul>
        )}
      </>
    );
  };

  return (
    <>
      <Grid container pb={3}>
        <Grid item xs={12} px={3} pt={3}>
          <Typography variant="body1" fontWeight="bold" paragraph>
            {getTranslation("SocialDevelopment")}
          </Typography>
          <Typography variant="body1" paragraph>
            {getTranslation("StatementIIISocialDevelopmentQuestion1")}
          </Typography>
          <ReadMoreReadLess>
            <>{renderSocialDevelopmentCompetence()}</>
          </ReadMoreReadLess>
        </Grid>
        <Grid item xs={12} px={3} pt={3}>
          <TextField
            label={getTranslation("StatementIIISocialDevelopmentList1")}
            multiline
            rows={2}
            fullWidth
            {...register("social")}
            name="social"
            sx={{ bgcolor: "white" }}
            value={
              answersData.find((answer) => answer.systemCodeId === 171)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 171;

              newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.social?.message}
            helperText={errors.social?.message}
          />
          <Typography fontSize={13}>
            {defaultValues.social?.length}/500
          </Typography>
        </Grid>
        <Grid item xs={12} px={3} pt={3}>
          <Typography variant="body1" fontWeight="bold" paragraph>
            {getTranslation("StatementIIISocialDevelopmentLine1")}
          </Typography>
          <Typography variant="body1" paragraph>
            {getTranslation("StatementIIIEmotionalDevelopmentQuestion1")}
          </Typography>
          <ReadMoreReadLess>
            <>{renderEmotionalDevelopmentCompetence()}</>
          </ReadMoreReadLess>
        </Grid>
        <Grid item xs={12} px={3} pt={3}>
          <TextField
            label={getTranslation("StatementIIISocialDevelopmentList2")}
            multiline
            rows={2}
            fullWidth
            {...register("emotional")}
            name="emotional"
            sx={{ bgcolor: "white" }}
            value={
              answersData.find((answer) => answer.systemCodeId === 172)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 172;
              newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.emotional?.message}
            helperText={errors.emotional?.message}
          />
          <Typography fontSize={13}>
            {defaultValues.emotional?.length}/500
          </Typography>
        </Grid>
        <Grid item xs={12} px={3} pt={3}>
          <Typography variant="body1" fontWeight="bold" paragraph>
            {getTranslation("StatementIIISocialDevelopmentList3")}
          </Typography>
          <Typography variant="body1" paragraph>
            {user?.credentialTypeId === 4
              ? getTranslation("StatementIIISocialDevelopmentList4")
              : getTranslation("StatementIIIEmotionalDevelopmentQuestion2")}
          </Typography>
          <ReadMoreReadLess>
            <>{renderPostiiveGuidanceCompetence()}</>
          </ReadMoreReadLess>
        </Grid>
        <Grid item xs={12} px={3} pt={3}>
          <TextField
            label={getTranslation("StatementIIISocialDevelopmentList5")}
            multiline
            rows={2}
            fullWidth
            {...register("positive")}
            name="positive"
            sx={{ bgcolor: "white" }}
            value={
              answersData.find((answer) => answer.systemCodeId === 173)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 173;

              newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.positive?.message}
            helperText={errors.positive?.message}
          />
          <Typography fontSize={13}>
            {defaultValues.positive?.length}/500
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default SocialDevelopmentList;
