import React from "react";
import { Modal, Button, Typography, Box } from "@mui/material";
import { useTranslation } from "../../translation/translate";

interface ConfirmModalProps {
  isOpen: boolean;
  credMessage: string;
  biMessage: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "16px",
};

const CredentialConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  credMessage,
  biMessage,
  onConfirm,
  onCancel,
}) => {
  const { getTranslation } = useTranslation();
  return (
    <Modal open={isOpen} onClose={onCancel}>
      <Box sx={style}>
        <Typography variant="h6" component="div">
          {getTranslation("CredentialConfirmModalLine1")}
        </Typography>

        <Typography variant="body1" sx={{ mb: 2 }}>
          {getTranslation("CredentialConfirmModalLine2")} {credMessage}.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {getTranslation("CredentialConfirmModalLine2")}
          {getTranslation("You")} {biMessage}{" "}
          {getTranslation("CredentialConfirmModalLine3")}
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {getTranslation("CredentialConfirmModalLine4")}
        </Typography>
        <Box sx={buttonContainerStyle}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onCancel}
            sx={{ marginRight: "8px" }}
          >
            {getTranslation("No")}
          </Button>
          <Button variant="contained" color="secondary" onClick={onConfirm}>
            {getTranslation("Yes")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CredentialConfirmModal;
