import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { IconButton } from "rsuite";
import CloseIcon from "@mui/icons-material/Close";
import { TranslationItem } from "./translationType";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useEffect } from "react";

export const translationItemSchema = Yup.object().shape({
  placeholder: Yup.string().required("Placeholder is required"),
  en: Yup.string().required("English type is required"),
  es: Yup.string().required("Spanish is required"),
});

interface DisplayMessageBoxProps {
  isOpen: boolean;
  handleClose: () => void;
  translationItemToEdit: TranslationItem;
  upsert: (translationItem: TranslationItem) => void;
}

export default function EditTranslationPopUp({
  isOpen,
  translationItemToEdit,
  upsert,
  handleClose,
}: DisplayMessageBoxProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<TranslationItem>({
    resolver: yupResolver(translationItemSchema),
    defaultValues: translationItemToEdit,
  });

  const onSubmit = async (values: TranslationItem) => {
    if (!isValid) return;
    try {
      await upsert({ ...values });
      reset();
      handleClose();
    } catch (error) {}
  };

  useEffect(() => {
    console.log("translationItemToEdit: ", translationItemToEdit);
  }, []);
  return (
    <>
      <Dialog
        fullWidth
        maxWidth={"md"}
        aria-labelledby="dialog-title"
        open={isOpen}
      >
        <Box sx={{ textAlign: "right", backgroundColor: "white" }}>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogTitle sx={{ m: 0, p: 2, fontWeight: 600 }} id="dialog-title">
          Edit Translation
        </DialogTitle>
        <Divider sx={{ width: "100%", color: "lightgrey" }} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container>
            <Grid item container p={2} spacing={2}>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label="Placeholder"
                  disabled={true}
                  fullWidth
                  id="placeholder"
                  {...register("placeholder")}
                  error={!!errors.placeholder}
                  helperText={errors.placeholder?.message as string}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label="English"
                  fullWidth
                  id="English"
                  {...register("en")}
                  error={!!errors.en}
                  helperText={errors.en?.message as string}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  size="small"
                  label="Spanish"
                  fullWidth
                  id="phone"
                  {...register("es")}
                  error={!!errors.es}
                  helperText={errors.es?.message as string}
                />
              </Grid>
            </Grid>
            <Divider sx={{ width: "100%" }} />
            <Grid
              item
              xs={12}
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                variant="outlined"
                sx={{ mr: 2 }}
                onClick={() => handleClose()}
              >
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Dialog>
    </>
  );
}
