import { Button, Grid, Typography } from "@mui/material";
import UploadedFiles from "../FileUpload/UploadedFiles";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { FileInfo } from "../../app/models/Fileinfo";
import {
  Anchor,
  OpenDrawerPayload,
  Section,
} from "../../app/models/FileUpload";
import { openDrawer } from "../FileUpload/drawerSlice";
import agent from "../../app/api/agent";
import { FileUpload } from "@mui/icons-material";
import { useTranslation } from "../../translation/translate";

export default function FamilyQts2() {
  const [data, setData] = useState<FileInfo[]>([]);
  const { isOpen } = useAppSelector((state) => state.drawer);
  const { user } = useAppSelector((state) => state.account);
  const dispatch = useAppDispatch();
  const { getTranslation } = useTranslation();

  const handleOpenDrawer = (anchor: Anchor, section: Section) => {
    const payload: OpenDrawerPayload = {
      anchor,
      section,
    };
    dispatch(openDrawer(payload));
  };

  const fetchData = useCallback(async () => {
    try {
      const data = await agent.FileUpload.GetFileInfo(
        "SummarySheet",
        user?.portfolioId ?? 0
      );
      setData(data);
    } catch (error) {
      console.error();
    }
  }, [user?.portfolioId]);

  useEffect(() => {
    fetchData();
  }, [isOpen, fetchData]);

  function handleDeleteFile(): void {
    fetchData();
  }
  return (
    <Grid container item xs={12} p={2} bgcolor={"myVerify.light"}>
      <Grid item xs={12} md={6} marginBottom={2}>
        <Typography mb={1} fontWeight={700}>
          {getTranslation("QuestionnariesLine2")}
        </Typography>

        <Grid item mt={2}>
          <Button
            variant="outlined"
            startIcon={<FileUpload />}
            sx={{
              textTransform: "none",
              marginTop: "10px",
            }}
            color="success"
            onClick={() => handleOpenDrawer("right", "SummarySheet")}
          >
            {getTranslation("UploadDocumentation")}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body1" fontWeight="bold">
          {getTranslation("UploadedDocuments")}
        </Typography>
        <Typography variant="subtitle2">
          {data.length}{" "}
          {data.length === 1 ? getTranslation("file") : getTranslation("files")}
          {"  "}
          {getTranslation("uploaded")}
        </Typography>
        {data.map((item: any) => (
          <UploadedFiles
            fileOrder={data.indexOf(item) + 1}
            key={item.id}
            fileInfo={item}
            onDeleteFile={() => handleDeleteFile()}
          />
        ))}
      </Grid>
    </Grid>
  );
}
