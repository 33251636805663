import CreativeArts from "./RCIICreativeArts";
import EmotionalSkills from "./RCIIEmotionalSkills";
import FineMotor from "./RCIIFineMotor";
import GrossMotor from "./RCIIGrossMotor";
import LearningExperience from "./RCIILearningExperience";
import Mathematics from "./RCIIMathematics";
import SelfConcept from "./RCIISelfConcept";
import SocialSkills from "./RCIISocialSkills";
import ScienceSensory from "./StatementScienceSensory";
import LanguageAndLiteracy from "./RCIILanguageAndLIteracy";
import { useAppSelector } from "../../app/store/configureStore";
import ResourceIIStatus from "./ResourceIIStatus";
import { SummaryCompleteProps } from "../../app/models/SummaryComplete";
import { Grid, Typography } from "@mui/material";
import LoadingSpinner from "../../app/common/LoadingSpinner";
import agent from "../../app/api/agent";
import { useCallback, useEffect, useState } from "react";
import { Answers } from "../../app/models/Answers";
import CongratsPopUp from "../../app/common/CongratsPopUp";
import { useTranslation } from "../../translation/translate";

export default function ResourceCollectionII() {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();
  const [summaryDescription] = useState<any>(
    (user?.credentialTypeId === 1 && (
      <Typography component="div">
        {getTranslation("ResourceIIStatementTitle")}
        <ol>
          <li>{getTranslation("ResourceIIStatementDescription1a")}</li>
          <li>{getTranslation("ResourceIIStatementDescription1b")}</li>
          <li>{getTranslation("ResourceIIStatementDescription1c")}</li>
        </ol>
        {getTranslation("ResourceIIStatementDescription1d")}
      </Typography>
    )) ||
      (user?.credentialTypeId === 2 && (
        <Typography>
          {getTranslation("ResourceIIStatementDescription2")}
        </Typography>
      )) ||
      (user?.credentialTypeId === 3 && (
        <Typography>
          {getTranslation("ResourceIIStatementDescription3")}
        </Typography>
      )) ||
      (user?.credentialTypeId === 4 && (
        <Typography>
          {getTranslation("ResourceIIStatementDescription4")}
        </Typography>
      ))
  );

  const [summaryCompleteProps, setSummaryCompleteProps] =
    useState<SummaryCompleteProps>({
      title: getTranslation("ResourceIIStatementTitle"),
      percent: "",
      header: getTranslation("CompetencyIIStatementHeader"),
      description: summaryDescription,
    });

  const [answers, setAnswers] = useState<Answers[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [compComplete, setCompComplete] = useState(0);
  const [popUp, setPopUp] = useState(false);
  const handlePopUp = () => {
    setPopUp(false);
  };

  const fetchData = useCallback(async () => {
    try {
      if (user?.credentialName && user?.portfolioId) {
        const response = await agent.Answers.GetAnswers(
          user.credentialName,
          user.portfolioId,
          "RCII"
        );
        const compData = await agent.Progress.GetCompentencyResultsByType(
          user.portfolioId,
          "RCII"
        );

        setCompComplete(compData.resourceProgress); //for congrats popup

        setSummaryCompleteProps((prevSummaryCompleteProps) => ({
          ...prevSummaryCompleteProps,
          percent: compData.resourceProgress + "%",
        }));
        setAnswers(response);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [user?.credentialName, user?.portfolioId]);

  const handleComplete = () => {
    if (compComplete >= 100) {
      setPopUp(true);
    }
  };
  useEffect(() => {
    fetchData();
    handleComplete();
  }, [fetchData]);

  const handleAnswerChange = (systemCodeId: number, newValue: string) => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((answer) =>
        answer.systemCodeId === systemCodeId
          ? { ...answer, answer: newValue }
          : answer
      )
    );
  };

  const handleSave = async () => {
    setIsLoading(true);
    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );
    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    fetchData();
    setIsLoading(false);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container p={2} mb={2}>
      <CongratsPopUp
        handlePopUp={handlePopUp}
        isPopUp={popUp}
        btnColor={"info"}
        background={"#E9F6FC"}
        description={getTranslation("CompstatementIIPopup")}
      />

      <ResourceIIStatus {...summaryCompleteProps} />
      <LearningExperience />
      {user?.credentialTypeId === 4 ? null : (
        <ScienceSensory
          answersData={answers}
          onAnswerChange={handleAnswerChange}
          onSave={handleSave}
        />
      )}
      <LanguageAndLiteracy
        answersData={answers}
        onAnswerChange={handleAnswerChange}
        onSave={handleSave}
      />
      <CreativeArts
        answersData={answers}
        onAnswerChange={handleAnswerChange}
        onSave={handleSave}
      />
      <FineMotor
        answersData={answers}
        onAnswerChange={handleAnswerChange}
        onSave={handleSave}
      />
      <GrossMotor
        answersData={answers}
        onAnswerChange={handleAnswerChange}
        onSave={handleSave}
      />
      <SelfConcept
        answersData={answers}
        onAnswerChange={handleAnswerChange}
        onSave={handleSave}
      />
      <EmotionalSkills
        answersData={answers}
        onAnswerChange={handleAnswerChange}
        onSave={handleSave}
      />
      <SocialSkills
        answersData={answers}
        onAnswerChange={handleAnswerChange}
        onSave={handleSave}
      />
      <Mathematics
        answersData={answers}
        onAnswerChange={handleAnswerChange}
        onSave={handleSave}
      />
    </Grid>
  );
}
