import { Button, Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import {
  Anchor,
  OpenDrawerPayload,
  Section,
} from "../../app/models/FileUpload";
import { openDrawer } from "../FileUpload/drawerSlice";
import SaveStatementForm from "../../app/common/SaveStatementBtn";
import { useCallback, useEffect, useState } from "react";
import agent from "../../app/api/agent";
import { FileInfo } from "../../app/models/Fileinfo";
import UploadedFiles from "../FileUpload/UploadedFiles";
import { useTranslation } from "../../translation/translate";

const UploadDocumentsRSV = ({ onSave, userProgress, RecallData }: any) => {
  const { getTranslation } = useTranslation();
  const [data, setData] = useState<FileInfo[]>([]);
  const { isOpen } = useAppSelector((state) => state.drawer);

  const { user } = useAppSelector((state) => state.account);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const dispatch = useAppDispatch();

  const handleOpenDrawer = (anchor: Anchor, section: Section) => {
    const payload: OpenDrawerPayload = {
      anchor,
      section,
    };
    dispatch(openDrawer(payload));
  };

  const fetchData = useCallback(async () => {
    try {
      const data = await agent.FileUpload.GetFileInfo(
        "RCV",
        user?.portfolioId ?? 0
      );
      setData(data);
    } catch (error) {
      // Handle errors if necessary.
    }
  }, [user?.portfolioId]);

  //1st group files
  const groupedFiles: Record<any, any[]> = {};
  data.forEach((item) => {
    const certType = item.certificateType;
    const itemFile = item;
    // If the property doesn't exist in the groupedFiles object, initialize it as an empty array.
    if (!groupedFiles[certType]) {
      groupedFiles[certType] = [];
    }
    // Push the file to the corresponding property group.
    groupedFiles[certType].push(itemFile);
  });

  //2nd Render grouped files
  const newArr = Object.entries(groupedFiles);
  const fileDataArray = () => {
    return newArr.map((innerArray) => {
      return innerArray[1].map((file: any) => (
        <UploadedFiles
          fileOrder={innerArray[1].indexOf(file) + 1}
          key={file.id}
          fileInfo={file}
          onDeleteFile={() => handleDeleteFile()}
        />
      ));
    });
  };

  useEffect(() => {
    fetchData();
  }, [isOpen, fetchData]);

  useEffect(() => {
    if (!isOpen) {
      RecallData();
    }
  }, [isOpen]);

  function handleDeleteFile(): void {
    RecallData();
    fetchData();
  }

  return (
    <Grid container m={2} p={2}>
      <Grid item container spacing={3} xs={isMobile ? 12 : 6}>
        <Grid item xs={12}>
          <Typography fontWeight={700}>
            {getTranslation("UploadDocuments")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            color="compV"
            variant="outlined"
            onClick={() => handleOpenDrawer("right", "RCV")}
          >
            <FileUploadIcon />
            {getTranslation("UploadForms")}
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        xs={isMobile ? 12 : 6}
        mt={isMobile ? 2 : 0}
        mb={isMobile ? 2 : 0}
      >
        <Grid item>
          <Typography variant="body1" fontWeight="bold">
            {getTranslation("UploadDocuments")}
          </Typography>
          <Typography variant="subtitle2">
            {data.length}{" "}
            {data.length === 1
              ? getTranslation("file")
              : getTranslation("files")}{" "}
            {getTranslation("uploaded")}
          </Typography>
          {fileDataArray()}
        </Grid>
      </Grid>
      <Grid item xs={12} textAlign="right" mt={2}>
        {userProgress >= 100 && (
          <SaveStatementForm btnColor={"compV"} onSave={onSave} />
        )}
      </Grid>
    </Grid>
  );
};
export default UploadDocumentsRSV;
