import * as yup from "yup";
import { useTranslation } from "../../translation/translate";

interface RC4ValidationSchemaProps {
  languageSpecialization: boolean;
}

const RC4WebValidationSchema = ({
  languageSpecialization,
}: RC4ValidationSchemaProps) => {
  const { getTranslation } = useTranslation();
  let schema = yup.object().shape({
    website: yup
      .string()
      .max(150, getTranslation("ValidationWebsiteMaxChar"))
      .url(getTranslation("ValidationInvalidWebsite"))
      .required(getTranslation("ValidationWebsiteRequired")),
    description: yup
      .string()
      .required(getTranslation("ValidationDescriptionRequired")),
    description2ndLanguage: yup.string(),
  });

  const secondLanguageFields = {
    description2ndLanguage: yup
      .string()
      .required(getTranslation("ValidationDescriptionSecondLanguageRequired")),
  };

  if (languageSpecialization) {
    schema = schema.shape(secondLanguageFields);
  }

  return schema;
};

export default RC4WebValidationSchema;
