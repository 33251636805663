import * as yup from "yup";
import { useTranslation } from "../../translation/translate";

interface RCIIFormValidationSchemaProps {
  languageSpecialization: boolean;
}

const RCIIFormValidationSchema = ({languageSpecialization}: RCIIFormValidationSchemaProps) => {
  const {getTranslation} = useTranslation();
let schema = yup.object().shape({
    title: yup.string().max(60, getTranslation("ValidationTitleMax60Chars")).required(getTranslation("ValidationTitleRequired")),
    ageGroup: yup.string().required(getTranslation("AgeGroupRequired")),
    intendedGoals: yup.string().required(getTranslation("ValidationIntendedGoalRequired")),
    materials: yup.string().required(getTranslation("ValidationMaterialRequired")),
    process: yup.string().required(getTranslation("ValidationTeachingStrategiesRequired")),
    development: yup.string().required(getTranslation("ValidationDevelopmentExperienceRequired")),
    titleBilingual: yup.string(),
    intendedGoalsBiligual: yup.string(),
    materialsBiligual: yup.string(),
    processBiligual: yup.string(),
    developmentBiligual: yup.string()
});
const secondLanguageFields = {
    titleBilingual: yup.string().max(60, getTranslation("ValidationSecondLanguageTitleMax")).required(getTranslation("ValidationSecondLanguageTitleRequired")),
    intendedGoalsBiligual: yup.string().required(getTranslation("ValidationIntendedGoalSecondLanguageRequired")),
    materialsBiligual: yup.string().required(getTranslation("ValidationMaterialDetailsRequiredSecondLanguage")),
    processBiligual: yup.string().required(getTranslation("ValidationStrategiesSecondLanguageRequired")),
    developmentBiligual: yup.string().required(getTranslation("ValidationDevelopmentExperienceSecondLanguageRequired"))
}


if (languageSpecialization) {
    schema = schema.shape(secondLanguageFields);
  }

  return schema;
}
export default RCIIFormValidationSchema;
