import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { forwardRef, useState } from "react";
import { useTranslation } from "../../../translation/translate";

type RowSelected = {
  portfoliId: number;
  firstName: string;
  lastName: string;
  email: string;
};
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};
const defaultValues = { portfoliId: 0, firstName: "", lastName: "", email: "" };
const AddStudentToClassRoster = forwardRef(function AddToClassRosterModal(
  props: any,
  ref: any
) {
  const { setIsOpen, handleOnSubmit, searchStudents } = props;
  const [selected, setSelected] = useState<RowSelected>(defaultValues);
  const { getTranslation } = useTranslation();
  const handleClick = () => {
    handleOnSubmit(selected.portfoliId);
    setIsOpen(false);
  };

  return (
    <Grid container>
      <Box sx={style}>
        <Grid item xs={12} textAlign="center">
          <Typography fontWeight={600}>
            {getTranslation("AddStudentToClass")}
          </Typography>
          <Typography>{getTranslation("SearchStudent")}</Typography>
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="studentEmail">
              {getTranslation("StudentEmail")}
            </InputLabel>
            <Select
              labelId="studentEmail"
              id="demo-simple-select"
              value={selected}
              label={getTranslation("StudentEmail")}
              onChange={(props, option: any) => {
                props.preventDefault();
                setSelected(option.props.value);
              }}
            >
              {searchStudents.map((item: any) => (
                <MenuItem key={item.email} value={item}>
                  {item.email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} display="flex" flexDirection="row" mt={2}>
          <TextField
            placeholder={getTranslation("FirstName")}
            value={selected?.firstName}
            fullWidth
            sx={{ marginRight: 2 }}
            disabled
          />
          <TextField
            placeholder={getTranslation("LastName")}
            value={selected?.lastName}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            variant="contained"
            sx={{ marginTop: 2 }}
            onClick={() => handleClick()}
          >
            Add Student to Class
          </Button>
        </Grid>
      </Box>
    </Grid>
  );
});
export default AddStudentToClassRoster;
