import { Button } from "@mui/material";
import { useTranslation } from "../../translation/translate";

export default function SaveStatementBtn(props: any) {
  const { onSave, btnColor } = props;
  const { getTranslation } = useTranslation();
  return (
    <Button
      variant="contained"
      sx={{ textTransform: "none" }}
      color={btnColor}
      onClick={onSave}
    >
      {getTranslation("ButtonSave&Submit")}
    </Button>
  );
}
