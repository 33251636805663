import { Card, CardContent, Grid, Typography } from "@mui/material";
import { useTranslation } from "../../../translation/translate";

export default function OnlineCoverSheetHeader() {
  const { getTranslation } = useTranslation();
  return (
    <Card
      sx={{
        width: "100%",
        backgroundColor: "myVerify.light",
        boxShadow: "0 16px #3E7787",
        marginBottom: 4,
      }}
    >
      <CardContent>
        <Grid
          item
          xs={10}
          sm={10}
          md={11}
          display="block"
          flexDirection="column"
          paddingLeft={3}
        >
          <Typography variant="h6" fontWeight={700} marginBottom={1}>
            {getTranslation("CoverSheetTitle")}
          </Typography>
          <Typography marginBottom={1}>
            {getTranslation("CheckListDescription")}
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
}
