import { Grid } from "@mui/material";
import ProfessionalStatus from "./ProfessionalStatus";
import { SummaryCompleteProps } from "../../app/models/SummaryComplete";
import UploadProfessionalChecklist from "./UploadProfessionalChecklist";
import { useAppSelector } from "../../app/store/configureStore";
import { useCallback, useEffect, useState } from "react";
import agent from "../../app/api/agent";
import LoadingSpinner from "../../app/common/LoadingSpinner";
import PortfolioCoverSheet from "./PortfolioCoverSheet";
import { useTranslation } from "../../translation/translate";

export default function ProfessionalPortfolio() {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();
  const [summaryCompleteProps, setSummaryCompleteProps] =
    useState<SummaryCompleteProps>({
      title: getTranslation("MyCDAProfessionalPortfolioCoverSheetAndChecklist"),
      percent: "",
      header: "",
      description: getTranslation("VerificationVisitWorksheetLine1"),
    });

  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      if (user?.credentialName && user?.portfolioId) {
        const compData = await agent.Progress.GetCompentencyResultsByType(
          user.portfolioId,
          "PortfolioChecklist"
        );

        setSummaryCompleteProps((prevSummaryCompleteProps) => ({
          ...prevSummaryCompleteProps,
          percent: compData.resourceProgress + "%",
        }));

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [user?.credentialName, user?.portfolioId]);

  const RecallData = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container p={2} mb={2}>
      <ProfessionalStatus {...summaryCompleteProps} />
      <PortfolioCoverSheet RecallData={RecallData} />
      <UploadProfessionalChecklist RecallData={RecallData} />
    </Grid>
  );
}
