import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { AnswersProps } from "../../app/models/Answers";
import { useState } from "react";
import { useAppSelector } from "../../app/store/configureStore";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "../../translation/translate";

const StatementClassroomDesign: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();

  const schema = yup.object().shape({
    firstTextBox: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    secondTextBox: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    thirdTextBox: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    reflectTextBox: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });

  const isInfantToddler = user?.credentialTypeId === 1;

  const mainSystemCodeId = isInfantToddler ? 3622 : 30;
  const firstSystemCodeId = isInfantToddler ? 33 : 31;
  const secondSystemCodeId = isInfantToddler ? 34 : 32;
  const thirdSystemCodeId = isInfantToddler ? 3604 : 3634;

  const defaultValues = {
    option:
      answersData.find((answer) => answer.systemCodeId === mainSystemCodeId)
        ?.answer || null,
    firstTextBox:
      answersData.find((answer) => answer.systemCodeId === firstSystemCodeId)
        ?.answer || null,
    secondTextBox:
      answersData.find((answer) => answer.systemCodeId === secondSystemCodeId)
        ?.answer || null,
    thirdTextBox_Yes:
      answersData.find((answer) => answer.systemCodeId === 3605)?.answer ||
      null,
    thirdTextBox_No:
      answersData.find((answer) => answer.systemCodeId === 3621)?.answer ||
      null,
    oneTextBox:
      answersData.find((answer) => answer.systemCodeId === thirdSystemCodeId)
        ?.answer || null,
  };

  const {
    control,
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const [selectedOption, setSelectedOption] = useState(
    control._defaultValues.option === "true" ? true : false
  );

  return (
    <Grid item xs={12} px={3} py={3}>
      <Typography variant="body1" fontWeight="bold" paragraph>
        CS I-b - {getTranslation("CompetencyIStatementClassroomDesign")}
      </Typography>
      <Typography variant="body1" paragraph>
        {getTranslation("StatementClassroomLine1")}
      </Typography>
      <Box component="form">
        <Typography variant="body1">
          {getTranslation("CompetencyIStatementClassroomDesignLine1")}
        </Typography>
        <Controller
          name="option"
          control={control}
          render={({ field }) => (
            <RadioGroup
              name="option"
              value={field.value}
              onChange={(e) => {
                field.onChange(e);
                const newValue = e.target.value;
                const systemCodeId = mainSystemCodeId;

                onAnswerChange(systemCodeId, newValue);
              }}
            >
              <FormControlLabel
                value={true}
                onClick={() => setSelectedOption(true)}
                control={<Radio />}
                label={getTranslation("Yes")}
                labelPlacement="end"
              />
              <FormControlLabel
                value={false}
                onClick={() => setSelectedOption(false)}
                control={<Radio />}
                label={getTranslation("No")}
                labelPlacement="end"
              />
            </RadioGroup>
          )}
        />
        {selectedOption === true &&
          (isInfantToddler ? (
            <Box>
              <Grid item xs={12} display="flex" flexDirection="column">
                <Typography>
                  {getTranslation("CompetencyIStatementClassroomDesignLine2")}
                </Typography>
                <TextField
                  multiline
                  rows={2}
                  fullWidth
                  required
                  value={
                    answersData.find(
                      (answer) => answer.systemCodeId === thirdSystemCodeId
                    )?.answer || ""
                  }
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const systemCodeId = thirdSystemCodeId;

                    newValue.length <= 500 &&
                      onAnswerChange(systemCodeId, newValue);
                  }}
                  error={!!errors.firstTextBox?.message}
                  helperText={errors.firstTextBox?.message}
                />
                <Typography fontSize={13}>
                  {defaultValues.firstTextBox?.length}/500
                </Typography>

                <Typography mt={2}>
                  {getTranslation("CompetencyIStatementClassroomDesignLine3")}
                </Typography>
                <TextField
                  multiline
                  rows={2}
                  fullWidth
                  required
                  value={
                    answersData.find((answer) => answer.systemCodeId === 3605) // Same as 3621 however when No is selected and 3605 is for Yes is selected.
                      ?.answer || ""
                  }
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const systemCodeId = 3605;

                    newValue.length <= 500 &&
                      onAnswerChange(systemCodeId, newValue);
                  }}
                  error={!!errors.thirdTextBox_Yes?.message}
                  helperText={errors.thirdTextBox_Yes?.message}
                />
                <Typography fontSize={13}>
                  {defaultValues.firstTextBox?.length}/500
                </Typography>
              </Grid>
            </Box>
          ) : (
            <Box>
              <Grid item xs={12} pt={3}>
                <Typography variant="body1" paragraph mt={2}>
                  {getTranslation("CompetencyIStatementClassroomDesignLine4")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={getTranslation(
                    "CompetencyIStatementClassroomDesignLine5"
                  )}
                  {...register("oneTextBox")}
                  sx={{ bgcolor: "#FFF" }}
                  value={
                    answersData.find(
                      (answer) => answer.systemCodeId === thirdSystemCodeId
                    )?.answer || ""
                  }
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const systemCodeId = thirdSystemCodeId;
                    newValue.length <= 500 &&
                      onAnswerChange(systemCodeId, newValue);
                  }}
                  multiline
                  rows={2}
                  fullWidth
                  required
                />
                <Typography fontSize={13}>
                  {defaultValues.oneTextBox?.length}/500
                </Typography>
              </Grid>
            </Box>
          ))}
        {selectedOption === false && (
          <>
            {isInfantToddler ? (
              <Box>
                <Grid item xs={12} display="flex" flexDirection="column">
                  <Typography mt={2}>
                    {getTranslation("CompetencyIStatementClassroomDesignLine6")}
                  </Typography>
                  <TextField
                    value={
                      answersData.find(
                        (answer) => answer.systemCodeId === firstSystemCodeId
                      )?.answer || ""
                    }
                    onChange={(e) => {
                      const newValue = e.target.value;
                      const systemCodeId = firstSystemCodeId;

                      newValue.length <= 500 &&
                        onAnswerChange(systemCodeId, newValue);
                    }}
                    error={!!errors.firstTextBox?.message}
                    helperText={errors.firstTextBox?.message}
                    multiline
                    rows={2}
                    fullWidth
                    required
                  />

                  <Typography mt={2}>
                    {getTranslation("CompetencyIStatementClassroomDesignLine7")}
                  </Typography>
                  <TextField
                    value={
                      answersData.find(
                        (answer) => answer.systemCodeId === secondSystemCodeId
                      )?.answer || ""
                    }
                    onChange={(e) => {
                      const newValue = e.target.value;
                      const systemCodeId = secondSystemCodeId;

                      newValue.length <= 500 &&
                        onAnswerChange(systemCodeId, newValue);
                    }}
                    error={!!errors.secondTextBox?.message}
                    helperText={errors.secondTextBox?.message}
                    multiline
                    rows={2}
                    fullWidth
                    required
                  />

                  <Typography mt={2}>
                    {getTranslation("CompetencyIStatementClassroomDesignLine8")}
                  </Typography>
                  <TextField
                    value={
                      answersData.find((answer) => answer.systemCodeId === 3621) // Same as 3605 however when Yes is selected and 3621 is for No is selected.
                        ?.answer || ""
                    }
                    onChange={(e) => {
                      const newValue = e.target.value;
                      const systemCodeId = 3621;

                      newValue.length <= 500 &&
                        onAnswerChange(systemCodeId, newValue);
                    }}
                    error={!!errors.thirdTextBox_No?.message}
                    helperText={errors.thirdTextBox_No?.message}
                    multiline
                    rows={2}
                    fullWidth
                    required
                  />
                </Grid>
              </Box>
            ) : (
              <Box>
                <Grid item xs={12} display="flex" flexDirection="column">
                  <Typography mt={2}>
                    {getTranslation("CompetencyIStatementClassroomDesignLine9")}
                  </Typography>
                  <TextField
                    value={
                      answersData.find(
                        (answer) => answer.systemCodeId === firstSystemCodeId
                      )?.answer || ""
                    }
                    onChange={(e) => {
                      const newValue = e.target.value;
                      const systemCodeId = firstSystemCodeId;

                      newValue.length <= 500 &&
                        onAnswerChange(systemCodeId, newValue);
                    }}
                    error={!!errors.firstTextBox?.message}
                    helperText={errors.firstTextBox?.message}
                    multiline
                    rows={2}
                    fullWidth
                    required
                  />
                  <Typography mt={2}>
                    {getTranslation(
                      "CompetencyIStatementClassroomDesignLine10"
                    )}
                  </Typography>
                  <TextField
                    value={
                      answersData.find(
                        (answer) => answer.systemCodeId === secondSystemCodeId
                      )?.answer || ""
                    }
                    onChange={(e) => {
                      const newValue = e.target.value;
                      const systemCodeId = secondSystemCodeId;

                      newValue.length <= 500 &&
                        onAnswerChange(systemCodeId, newValue);
                    }}
                    error={!!errors.secondTextBox?.message}
                    helperText={errors.secondTextBox?.message}
                    multiline
                    rows={2}
                    fullWidth
                    required
                  />
                </Grid>
              </Box>
            )}
          </>
        )}
      </Box>
    </Grid>
  );
};
export default StatementClassroomDesign;
