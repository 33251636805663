import { ExpandCircleDown } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "../../translation/translate";
export default function ChildrenBookDevelopement() {
  const { getTranslation } = useTranslation();
  return (
    <Grid container m={2}>
      <Grid item>
        <Typography fontWeight={700}>
          {getTranslation("ResourceIIIChildrenBooksLine1")}
        </Typography>
        <Typography>
          {getTranslation("ResourceIIIChildrenBooksLine2")}
        </Typography>
      </Grid>

      <Grid item marginLeft={2}>
        <Typography component="span">
          {getTranslation("ResourceIIIChildrenBooksLine3")}
        </Typography>
        <Typography component="div">
          <ul>
            <li>{getTranslation("ResourceIIIChildrenBooksLine4")}</li>
            <li>{getTranslation("ResourceIIIChildrenBooksLine5")}</li>

            <li>{getTranslation("ResourceIIIChildrenBooksLine6")} </li>
            <li>{getTranslation("ResourceIIIChildrenBooksLine7")} </li>
            <li>{getTranslation("ResourceIIIChildrenBooksLine8")}</li>
            <li>{getTranslation("ResourceIIIChildrenBooksLine9")}</li>
          </ul>
        </Typography>
      </Grid>

      <Grid item mb={2}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandCircleDown sx={{ color: "#CC581F" }} />}
          >
            <Typography fontWeight={700}>
              {getTranslation("ResourceIIIChildrenBooksLine10")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container p={2} spacing={2}>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={6}>
                  <Typography fontWeight={700}>
                    *{getTranslation("ResourceIIIBookTitle")}
                  </Typography>
                  <Typography>
                    {getTranslation("ResourceIIIChildrenBooksLine11")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight={700}>
                    *{getTranslation("ResourceIIIBookAuthor")}
                  </Typography>
                  <Typography>
                    {getTranslation("ResourceIIIChildrenBooksLine12")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight={700}>
                    *{getTranslation("ResourceIIIBookPublisher")}
                  </Typography>
                  <Typography>
                    {getTranslation("ResourceIIIChildrenBooksLine13")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight={700}>
                    *{getTranslation("ResourceIIIBookCopyright")}
                  </Typography>
                  <Typography>1947</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography fontWeight={700}>
                    *{getTranslation("ResourceIIIBookDescription")}
                  </Typography>
                  <Typography>
                    {getTranslation("ResourceIIIChildrenBooksLine14")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}
