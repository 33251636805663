import { Grid } from "@mui/material";
import EFormStatus from "./EFormStatus";
import EFormTableQuestionnaire from "./EFormTableQuestionnaire";
import PrintSaveBtns from "../../app/common/PrintSaveBtn";
import { Answers } from "../../app/models/Answers";
import { useAppSelector } from "../../app/store/configureStore";
import { useState, useEffect, useCallback } from "react";
import agent from "../../app/api/agent";
import LoadingSpinner from "../../app/common/LoadingSpinner";

export default function EFormQuestionnaire() {
  const { user } = useAppSelector((state) => state.account);
  const [answers, setAnswers] = useState<Answers[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(async () => {
    try {
      if (user?.credentialName && user?.portfolioId) {
        const response = await agent.Answers.GetAnswers(
          user.credentialName,
          user.portfolioId,
          "FamilyQuestionnairesSummarySheet"
        );

        setAnswers(response);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [user?.credentialName, user?.portfolioId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAnswerChange = (systemCodeId: number, newValue: string) => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((answer) =>
        answer.systemCodeId === systemCodeId
          ? { ...answer, answer: newValue }
          : answer
      )
    );
  };

  const handleSaveDraft = async () => {
    setIsLoading(true);
    // This is just a sample function for demonstration
    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );
    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    fetchData();
    setIsLoading(false);
  };
  const handleSave = async () => {
    setIsLoading(true);
    // This is just a sample function for demonstration
    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );
    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    fetchData();
    setIsLoading(false);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container p={2} m={1}>
      <EFormStatus />
      <EFormTableQuestionnaire
        answersData={answers}
        onAnswerChange={handleAnswerChange}
      />
      <PrintSaveBtns
        btnColor={"myVerify"}
        onSave={handleSave}
        onDraft={handleSaveDraft}
        title={"Eform"}
      />
    </Grid>
  );
}
