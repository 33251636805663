import { Button, Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { FileDownload } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import pdfSample from "../../assets/images/hvlist-1.png";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../app/store/configureStore";
import {
  Anchor,
  Section,
  OpenDrawerPayload,
} from "../../app/models/FileUpload";
import { openDrawer } from "../FileUpload/drawerSlice";
import agent from "../../app/api/agent";
import { FileInfo } from "../../app/models/Fileinfo";
import UploadedFiles from "../FileUpload/UploadedFiles";
import { FilesProps } from "../../app/models/Answers";
import { useTranslation } from "../../translation/translate";

const UploadProfessionalChecklist: React.FC<FilesProps> = ({ RecallData }) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { getTranslation } = useTranslation();
  const [data, setData] = useState<FileInfo[]>([]);
  const { isOpen } = useAppSelector((state: RootState) => state.drawer);

  const { user } = useAppSelector((state) => state.account);

  const dispatch = useAppDispatch();

  const handleOpenDrawer = (anchor: Anchor, section: Section) => {
    const payload: OpenDrawerPayload = {
      anchor,
      section,
    };
    dispatch(openDrawer(payload));
  };

  const fetchData = useCallback(async () => {
    try {
      const data = await agent.FileUpload.GetFileInfo(
        "PortfolioChecklist",
        user?.portfolioId ?? 0
      );

      setData(data);
    } catch (error) {
      // Handle errors if necessary.
    }
  }, [user?.portfolioId]);
  //1st group files
  const groupedFiles: Record<any, any[]> = {};
  data.forEach((item) => {
    const certType = item.certificateType;
    const itemFile = item;
    // If the property doesn't exist in the groupedFiles object, initialize it as an empty array.
    if (!groupedFiles[certType]) {
      groupedFiles[certType] = [];
    }
    // Push the file to the corresponding property group.
    groupedFiles[certType].push(itemFile);
  });

  //2nd Render grouped files
  const newArr = Object.entries(groupedFiles);
  const fileDataArray = () => {
    return newArr.map((innerArray) => {
      return innerArray[1].map((file: any) => (
        <UploadedFiles
          fileOrder={innerArray[1].indexOf(file) + 1}
          key={file.id}
          fileInfo={file}
          onDeleteFile={() => handleDeleteFile()}
        />
      ));
    });
  };

  useEffect(() => {
    fetchData();
    RecallData();
  }, [isOpen, fetchData]);

  function handleDeleteFile(): void {
    fetchData();
    RecallData();
  }

  const downloadChecklisEForm = () => {
    const link = document.createElement("a");
    link.download = `MyCDAProfessionalPortfolio.pdf`;
    link.href = "../docs/MyCDAProfessionalPortfolioForm.pdf";
    link.click();
  };
  const downloadHomeVisitorChecklist = () => {
    const link = document.createElement("a");
    link.download = `MyCDAProfessionalPortfolioChecklist.pdf`;
    link.href = "../docs/HVCoverChecklist.pdf";
    link.click();
  };

  return (
    <Grid container bgcolor="myVerify.light" p={2} m={1}>
      <Grid item container xs={isMobile ? 12 : 8} spacing={2}>
        <Grid item xs={10}>
          <Typography fontWeight={700}>
            2. {getTranslation("UploadProfessionalChecklistLine1")}
          </Typography>
          <Typography>
            {getTranslation("UploadProfessionalChecklistLine2")}
          </Typography>
          <Typography mb={1}>
            {getTranslation("UploadProfessionalChecklistLine3")}
          </Typography>
          <Button
            href="/portfoliochecklist/onlineform"
            sx={{ textTransform: "initial" }}
            variant="outlined"
            color="myVerify"
          >
            {getTranslation("UploadProfessionalChecklistLine4")}
          </Button>
        </Grid>
        <Grid item xs={2}>
          <img src={pdfSample} width="100%" alt="PDF Sample" />
        </Grid>
      </Grid>

      <Grid item xs={8} mt={2}>
        <Typography mb={1}>
          {getTranslation("UploadProfessionalChecklistLine5")}{" "}
        </Typography>
        <Typography mb={1}>
          {getTranslation("UploadProfessionalChecklistLine6")}
        </Typography>
        <Button
          onClick={
            user?.credentialTypeId === 4
              ? downloadHomeVisitorChecklist
              : downloadChecklisEForm
          }
          startIcon={<FileDownload />}
          sx={{ textTransform: "initial" }}
          variant="outlined"
          color="myVerify"
        >
          {getTranslation("DownloadForm")}
        </Button>
      </Grid>
      <Grid item container mt={2} xs={12}>
        <Grid item md={6}>
          <Grid item xs={12}>
            <Typography fontWeight={700}>
              {getTranslation("UploadDocuments")}
            </Typography>

            <Button
              variant="outlined"
              color="myVerify"
              startIcon={<FileUploadIcon />}
              sx={{
                textTransform: "initial",
                lineHeight: 1,
              }}
              onClick={() => handleOpenDrawer("right", "PortfolioChecklist")}
            >
              {getTranslation("UploadProfessionalChecklistLine7")}
            </Button>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <Typography variant="body1" fontWeight="bold">
            {getTranslation("UploadedDocuments")}
          </Typography>
          <Typography variant="subtitle2">
            {data.length}{" "}
            {data.length === 1
              ? getTranslation("file")
              : getTranslation("files")}
            {"  "}
            {getTranslation("uploaded")}
          </Typography>
          {fileDataArray()}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default UploadProfessionalChecklist;
