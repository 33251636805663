import {
  Typography,
  Grid,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Avatar,
  useMediaQuery,
  Theme,
  Switch,
  Stack,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import util from "../../app/utils/utils";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../app/store/configureStore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Anchor,
  OpenDrawerPayload,
  Section,
} from "../../app/models/FileUpload";
import { openDrawer } from "../FileUpload/drawerSlice";
import agent from "../../app/api/agent";
import { User } from "../../app/models/user";
import { fetchCurrentUser } from "./accountSlice";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "../../translation/translate";

export default function EditUserInfo() {
  const { candidateRoles, states } = util;
  const { user } = useAppSelector((state) => state.account);
  const [isSpanish, setIsSpanish] = useState(user?.isSpanish || false);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { getTranslation } = useTranslation();
  const [profilePicture, setProfilePicture] = useState<string | null>("");
  const { isOpen } = useAppSelector((state: RootState) => state.drawer);
  const dispatch = useAppDispatch();
  const gridStyle = {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
  };
  const textStyle = { marginRight: 2, marginTop: 2 };
  const textStyle2 = { marginTop: 2 };

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<User>({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await agent.FileUpload.GetFileInfo(
          "Profile",
          user?.portfolioId ?? 0
        );
        if (data.length !== 0) {
          const response = await agent.FileUpload.GetFiles(data[0].id);
          const blob = new Blob([response], {
            type: "application/octet-stream",
          });

          const imageUrl = URL.createObjectURL(blob);
          setProfilePicture(imageUrl);
        } else {
          setProfilePicture("");
        }
      } catch (error) {
        console.error("Error fetching and processing the file:", error);
      }
    };

    fetchData();
  }, [isOpen, user?.id, isSpanish]);

  const handleDelete = async () => {
    try {
      const data = await agent.FileUpload.GetFileInfo(
        "Profile",
        user?.portfolioId ?? 0
      );
      await agent.FileUpload.DeleteFile(data[0].id);
      setProfilePicture("");
    } catch (error) {
      // Handle error here, if needed
      console.error(error);
    }
  };

  const handleOpenDrawer = (anchor: Anchor, section: Section) => {
    const payload: OpenDrawerPayload = {
      anchor,
      section,
    };
    dispatch(openDrawer(payload));
  };

  const onSaveClick = async (data: User) => {
    data.id = user?.id || 0;
    data.isSpanish = isSpanish;
    await agent.Account.updateAccount(data);
    await dispatch(fetchCurrentUser());

    reset(data);
  };

  return (
    <Grid
      container
      component="form"
      p={2}
      m={2}
      mt={5}
      spacing={isMobile ? 2 : 4}
      onSubmit={handleSubmit(onSaveClick)}
    >
      <Grid
        item
        container
        xs={12}
        md={3}
        p={2}
        display="flex"
        flexDirection="column"
      >
        <Avatar
          alt={user?.firstName}
          src={profilePicture ?? ""}
          variant="circular"
          sx={{ width: 124, height: 124, alignSelf: "center" }}
        />
        <Typography fontSize={24} marginTop={2} textAlign="center">
          {user?.firstName} {user?.lastName}
        </Typography>

        <Button
          variant="outlined"
          color="secondary"
          sx={{ marginBottom: 2 }}
          onClick={() => handleOpenDrawer("right", "Profile")}
        >
          <EditIcon />

          {getTranslation("Change")}
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          sx={{ marginBottom: 2 }}
          onClick={() => handleDelete()}
        >
          <DeleteIcon />
          {getTranslation("Remove")}
        </Button>
        <FormGroup>
          <FormControlLabel
            value="end"
            control={
              <Switch
                color="success"
                checked={isSpanish}
                onChange={(e) => setIsSpanish(e.target.checked)}
              />
            }
            label={getTranslation("ViewsiteinSpanish")}
            labelPlacement="end"
          />
        </FormGroup>
      </Grid>
      <Grid item container xs={12} md={9}>
        <Grid item xs={12} sx={gridStyle}>
          <TextField
            fullWidth
            sx={textStyle}
            label={getTranslation("FirstName")}
            {...register("firstName")}
            defaultValue={user?.firstName}
          />
          <TextField
            fullWidth
            sx={textStyle2}
            label={getTranslation("LastName")}
            defaultValue={user?.lastName}
            {...register("lastName")}
          />
        </Grid>
        <Grid item xs={12} sx={gridStyle}>
          <TextField
            fullWidth
            sx={textStyle}
            label={getTranslation("Email")}
            {...register("email")}
            defaultValue={user?.email}
          />
          <TextField
            fullWidth
            sx={textStyle2}
            label={getTranslation("PhoneNumber")}
            {...register("phoneNumber")}
            defaultValue={user?.phoneNumber}
          />
        </Grid>
        <Grid item xs={12} sx={gridStyle}>
          <TextField
            fullWidth
            sx={textStyle}
            label={getTranslation("HomeZipCode")}
            {...register("homeZipCode")}
            defaultValue={user?.homeZipCode}
          />
          <FormControl fullWidth sx={textStyle2}>
            <InputLabel>{getTranslation("HomeState")}</InputLabel>
            <Select
              label={getTranslation("HomeState")}
              {...register("homeState")}
              defaultValue={user?.homeState}
            >
              {states.map((state) => (
                <MenuItem key={state.abbreviation} value={state.abbreviation}>
                  {state.abbreviation}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {user?.role !== "Other Viewer" && (
          <Grid item xs={12} sx={gridStyle}>
            <TextField
              fullWidth
              sx={textStyle}
              label={getTranslation("EditUserInfoLine1")}
              {...register("cdaCouncilCandidateId")}
              defaultValue={user?.cdaCouncilCandidateId}
            />
            <Typography
              display={isMobile ? "none" : "true"}
              sx={{ width: "100%" }}
              fontSize="small"
              mt={1}
            >
              {getTranslation("EditUserInfoLine2")}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={5.9} sx={gridStyle}>
          <FormControl sx={{ marginTop: 2 }} fullWidth required>
            <InputLabel>{getTranslation("Role")}</InputLabel>
            <Select
              label={getTranslation("Role")}
              {...register("role")}
              defaultValue={user?.role}
              disabled={
                user?.role === "CDA Candidate" || user?.role === "Other Viewer"
                  ? true
                  : false
              }
            >
              {candidateRoles.map((role) => (
                <MenuItem key={role.role} value={role.role}>
                  {role.role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} mt={2} display="flex" justifyContent="flex-end">
          <LoadingButton
            loading={isSubmitting}
            type="submit"
            variant="contained"
            color="secondary"
            fullWidth={isMobile ? true : false}
          >
            {getTranslation("Save")}
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
