import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import EventIcon from "@mui/icons-material/Event";
import { Controller, useForm } from "react-hook-form";
import { MuiFileInput } from "mui-file-input";
import { useAppSelector } from "../../app/store/configureStore";
import {
  Anchor,
  FileUploadForm,
  RCIFileUploadProps,
} from "../../app/models/FileUpload";
import agent from "../../app/api/agent";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import { yupResolver } from "@hookform/resolvers/yup";
import RCIFileUploadValidation from "../../app/validations/RCIFileUploadValidation";
import { useDispatch } from "react-redux";
import { closeDrawer } from "./drawerSlice";

const translations = {
  "Type of Document": "Tipo de Documento",
  "Date certificate expires": "Fecha de vencimiento del certificado",
  Date: "Fecha",
  "certificate expires": "fecha de vencimiento del certificado",
  "Name of Trainer / Training Organization":
    "Nombre del Entrenador / Organización de Entrenamiento",
  "Choose file": "Elegir archivo",
  "JPG, GIF, DOC/DOCX, PNG and PDF formats are acceptable":
    "Se aceptan formatos JPG, GIF, DOC/DOCX, PNG y PDF",
  "JPG, GIF and PNG formats are acceptable":
    "Se aceptan formatos JPG, GIF y PNG",
  Cancel: "Cancelar",
  "Save Upload": "Guardar Carga",
  "First Aid Training": "Entrenamiento de Primeros Auxilios",
  "CPR Training": "Entrenamiento de RCP",
  "First Aid Training + CPR Training":
    "Entrenamiento de Primeros Auxilios + RCP",
  Menu: "Menú",
  "Feeding Schedule": "Horario de Alimentación",
  "Weekly Plan": "Plan Semanal",
  Article: "Artículo",
  "My Policy": "Mi Política",
  "Personnel Qualifications": "Calificaciones del Personal",
  "Group Size and Ratio": "Tamaño del Grupo y Proporción",
  "Educator Resources": "Recursos para Educadores",
  "Partially Completed WorkSheet": "Hoja de Trabajo Parcialmente Completada",
  "Completed and Co-Signed Worksheet": "Hoja de Trabajo Completada y Cofirmada",
  "Questionnaires Summary": "Resumen de Cuestionarios",
  "Family Questionnaires": "Cuestionarios Familiares",
  "Profile Photo": "Foto de Perfil",
  "Cover Sheet Photo": "Foto de Portada",
  "My CDA Education Summary": "Resumen de mi Educación CDA",
  "Training Documentation": "Documentación de Entrenamiento",
  "Parent Observation Permission Form":
    "Formulario de Permiso de Observación de Padres",
  "Professional Portfolio Checklist":
    "Lista de Verificación del Portafolio Profesional",
  "Professional Portfolio Cover Sheet": "Portada del Portafolio Profesional",
  "Filename must be less than 150 characters":
    "El nombre del archivo debe tener menos de 150 caracteres",
  "Trainer is required": "Se requiere el nombre del entrenador",
  "Invalid Date, please use MM/DD/YYYY formatting":
    "Fecha inválida, por favor use el formato MM/DD/AAAA",
  "Date is required": "Se requiere la fecha",
  "Certificate must be good for at least six months":
    "El certificado debe ser válido por al menos seis meses",
  "Family Plan": "Plan Familiar",
  "Family Needs Assessment": "Evaluación de Necesidades Familiares",
  "Individualized Service Plan or Action Plan":
    "Plan de Servicio Individualizado o Plan de Acción",
};

const translate = (text: string, isSpanish: boolean) =>
  isSpanish ? (translations as Record<string, string>)[text] || text : text;
const RCIFileUpload: React.FC<RCIFileUploadProps> = ({ section }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState<Dayjs | null>(null);
  const {
    control,
    register,
    handleSubmit,
    setValue: setFormValue,
    formState: { isSubmitting, errors, isValid },
  } = useForm<FileUploadForm>({
    resolver: yupResolver(RCIFileUploadValidation(section)),
    mode: "onTouched",
  });
  const [file, setFile] = useState<File | null>(null);
  const [selectedCertificateType, setSelectedCertificateType] =
    useState<string>(section === "RCI1" ? "First Aid Training" : "Menu");
  const [typeOfCertificateOptions, setTypeOfCertificateOptions] = useState<
    string[]
  >([
    "First Aid Training",
    "CPR Training",
    "First Aid Training + CPR Training",
  ]);
  const [filenameError, setFilenameError] = useState<string | null>(null); // State for filename error

  const { user } = useAppSelector((state) => state.account);

  const isSpanish = user?.isSpanish || false;

  let RCVselectedType: string = "";
  if (user?.credentialTypeId === 4) {
    RCVselectedType = "Family Plan";
  } else if (user?.credentialTypeId !== 4) {
    RCVselectedType = "Accident Report Form";
  }

  const RCVtypeOptions = useMemo(() => {
    let typeOptions: any[] = [];
    if (user?.credentialTypeId === 4) {
      typeOptions = [
        "Family Plan",
        "Family Needs Assessment",
        "Individualized Service Plan or Action Plan",
      ];
    } else if (user?.credentialTypeId !== 4) {
      typeOptions = [
        "Accident Report Form",
        "Emergency Form",
        "Observation Form",
      ];
    }
    return typeOptions;
  }, [user?.credentialTypeId, user?.languageSpecialization]);

  const handleChange = (newFile: File | null) => {
    setFile(newFile);
  };
  const isRCI1Section = section === "RCI1";
  const indexOfDash = section.indexOf("-");

  const characterAfterDash =
    indexOfDash !== -1 && indexOfDash < section.length - 1
      ? section.charAt(indexOfDash + 1)
      : "";

  const resetForm = () => {
    setFormValue("certificateType", ""); // Reset form value manually
    setFormValue("trainer", ""); // Reset form value manually
    setValue(null); // Reset DatePicker value
    setFile(null); // Reset file value
  };

  const handleFormSubmit = async (formData: FileUploadForm) => {
    if (file) {
      if (file.name.length > 150) {
        setFilenameError(
          translate("Filename must be less than 150 characters", isSpanish)
        ); // Set the error message
        return;
      } else {
        setFilenameError(null); // Clear the error message if filename is within limits
      }
      const formDataToSend = new FormData();
      formDataToSend.set("file", file);
      formDataToSend.set(
        "section",
        section.trim() === "Questionnaires Summary"
          ? "FamilyQuestionnaire"
          : section
      );
      formDataToSend.set("certificateType", formData.certificateType);
      formDataToSend.set("trainer", formData.trainer ?? "");
      if (isRCI1Section) {
        formDataToSend.set(
          "certificateExpirationDate",
          dayjs(value).format("YYYY-MM-DD")
        );
      } else {
        formDataToSend.set("certificateExpirationDate", ""); // Set to empty string or any other suitable value
      }

      formDataToSend.set("emailAddress", user?.email || "");

      let sec = formDataToSend.get("section") as string;

      let sectionWithoutDash: string;
      let certType: string;

      if (sec.includes("RCVI2-") || sec.includes("RCIV4-")) {
        sectionWithoutDash = sec.slice(0, sec.indexOf("-"));
        certType = selectedCertificateType + characterAfterDash;
      } else {
        sectionWithoutDash = sec;
        certType = selectedCertificateType;
      }
      const convertedFormData: FileUploadForm = {
        certificateType: certType,
        emailAddress: formDataToSend.get("emailAddress") as string,
        certificateExpirationDate: formDataToSend.get(
          "certificateExpirationDate"
        ) as string,
        section: sectionWithoutDash,
        trainer: formDataToSend.get("trainer") as string,
        file: formDataToSend.get("file") as File,
        portfolioId: user?.portfolioId as number,
      };

      try {
        await agent.FileUpload.UploadFile(convertedFormData);
        resetForm();
        dispatch(closeDrawer("right"));
      } catch (error) {
        console.error("Error uploading file", error);
      }
    }
  };

  const handleCancelClick = (anchor: Anchor) => {
    dispatch(closeDrawer(anchor));
    resetForm();
  };

  useEffect(() => {
    let selectedType;
    let typeOptions;

    if (section.includes("RCIV4")) {
      selectedType = "Article";
      typeOptions = ["Article"];
    } else {
      switch (section) {
        case "RCI1":
          selectedType = "First Aid Training";
          typeOptions = [
            "First Aid Training",
            "CPR Training",
            "First Aid Training + CPR Training",
          ];
          break;
        case "RCI2":
          selectedType = "Menu";
          typeOptions = ["Menu", "Feeding Schedule"];
          break;
        case "RCI24":
        case "RCI3":
          selectedType = "Weekly Plan";
          typeOptions = ["Weekly Plan"];
          break;
        case "RCIV4":
          selectedType = "Article";
          typeOptions = ["Article"];
          break;
        case "RCV":
          selectedType = RCVselectedType;
          typeOptions = RCVtypeOptions;

          break;
        case "RCVI14":
          selectedType = "My Policy";
          typeOptions = ["My Policy"];
          break;
        case "RCVI1a":
          selectedType = "Personnel Qualifications";
          typeOptions = ["Personnel Qualifications", "Group Size and Ratio"];
          break;
        case "RCVI2-1":
        case "RCVI2-2":
        case "RCVI2-3":
          selectedType = "Educator Resources";
          typeOptions = ["Educator Resources"];
          break;
        case "myVerify":
          selectedType = "Partially Completed WorkSheet";
          typeOptions = [
            "Partially Completed WorkSheet",
            "Completed and Co-Signed Worksheet",
          ];
          break;
        case "SummarySheet":
          selectedType = "Questionnaires Summary";
          typeOptions = ["Questionnaires Summary"];
          break;
        case "FamilyQuestionnaire":
          selectedType = "Family Questionnaires";
          typeOptions = ["Family Questionnaires"];
          break;
        case "Profile":
          selectedType = "Profile Photo";
          typeOptions = ["Profile Photo"];
          break;
        case "CoverSheet":
          selectedType = "Cover Sheet Photo";
          typeOptions = ["Cover Sheet Photo"];
          break;
        case "EducationSummary":
          selectedType = "My CDA Education Summary";
          typeOptions = ["My CDA Education Summary", "Training Documentation"];
          break;
        case "ParentObservation":
          selectedType = "Parent Observation Permission Form";
          typeOptions = ["Parent Observation Permission Form"];
          break;
        case "PortfolioChecklist":
          selectedType = "Professional Portfolio Checklist";
          typeOptions = ["Professional Portfolio Checklist"];
          break;
        case "PortfolioCoverSheet":
          selectedType = "Professional Portfolio Cover Sheet";
          typeOptions = ["Professional Portfolio Cover Sheet"];
          break;
        default:
          selectedType = "Menu";
          typeOptions = ["Menu", "Feeding Schedule"];
          break;
      }
    }

    setSelectedCertificateType(selectedType);
    setTypeOfCertificateOptions(typeOptions);
  }, [section, RCVselectedType, RCVtypeOptions]);

  return (
    <Container sx={{ width: 400 }}>
      <Box
        component="form"
        onSubmit={handleSubmit(handleFormSubmit)}
        noValidate
      >
        <Box marginTop={2}>
          <Controller
            control={control}
            name="certificateType"
            render={({ field }) => (
              <TextField
                {...field}
                label={
                  <Typography variant="subtitle2">
                    {translate("Type of Document", isSpanish)}
                  </Typography>
                }
                select
                fullWidth
                value={selectedCertificateType}
                onChange={(e) => setSelectedCertificateType(e.target.value)}
              >
                {typeOfCertificateOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {translate(option, isSpanish)}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Box>
        {isRCI1Section ? (
          <>
            <Box marginTop={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  control={control}
                  name="certificateExpirationDate"
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      className={
                        errors.certificateExpirationDate ? "error" : ""
                      }
                      label={
                        <Typography variant="subtitle2">
                          {translate("Date", isSpanish)}{" "}
                          {translate(selectedCertificateType, isSpanish)}{" "}
                          {translate("certificate", isSpanish)}
                          <Typography component="span" style={{ color: "red" }}>
                            *
                          </Typography>
                        </Typography>
                      }
                      value={value}
                      onChange={(newValue) => {
                        setValue(newValue);
                        field.onChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={!!errors.certificateExpirationDate?.message}
                          helperText={translate(
                            errors.certificateExpirationDate?.message || "",
                            isSpanish
                          )}
                          InputProps={{
                            endAdornment: <EventIcon />,
                          }}
                        />
                      )}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box marginTop={2}>
              <TextField
                label={
                  <Typography variant="subtitle2">
                    {translate(
                      "Name of Trainer / Training Organization",
                      isSpanish
                    )}
                    <Typography component="span" style={{ color: "red" }}>
                      *
                    </Typography>
                  </Typography>
                }
                fullWidth
                {...register("trainer")}
                error={!!errors.trainer?.message}
                helperText={translate(errors.trainer?.message || "", isSpanish)}
                onBlur={handleSubmit(handleFormSubmit)}
              />
            </Box>
          </>
        ) : null}
        <Box marginTop={2}>
          <MuiFileInput
            value={file}
            onChange={handleChange}
            placeholder={translate("Choose file", isSpanish)}
            error={!!filenameError} // Add error prop to MuiFileInput
            helperText={filenameError}
          />
        </Box>
        <Box marginTop={1}>
          <Typography variant="body2">
            {translate(
              section === "Profile"
                ? "JPG, GIF and PNG formats are acceptable"
                : "JPG, GIF, DOC/DOCX, PNG and PDF formats are acceptable",
              isSpanish
            )}
          </Typography>
        </Box>
        <Box marginTop={2}>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                sx={{ textTransform: "none" }}
                color="secondary"
                onClick={() => handleCancelClick("right")}
              >
                {translate("Cancel", isSpanish)}
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                loading={isSubmitting}
                disabled={!isValid}
                type="submit"
                variant="contained"
                sx={{ textTransform: "none" }}
                color="secondary"
              >
                {translate("Save Upload", isSpanish)}
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default RCIFileUpload;
