import * as yup from "yup";
import { useTranslation } from "../../translation/translate";

export const CredentialSchema = (getTranslation: (key: string) => string) =>
  yup.object().shape({
    credentialTypeId: yup
      .number()
      .required(getTranslation("SelectCredentialType")),
  });

export const useGetTranslation = () => {
  const { getTranslation } = useTranslation();
  return getTranslation;
};
