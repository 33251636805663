import { Grid, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";

export default function RegistrationSuccess() {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh" }}
    >
      <Grid item xs={12} sm={8} md={6} lg={4}>
        <Typography variant="h4" align="center">
          Verify your email
        </Typography>
        <Typography variant="subtitle1" align="center">
          We've sent you an email, please check your email and click the link
          to activate your account.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/account/login"
          fullWidth
        >
          Navigate to Login
        </Button>
      </Grid>
    </Grid>
  );
}
