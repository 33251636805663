import {
  Button,
  Card,
  CardContent,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { useTranslation } from "../../translation/translate";

export default function EFormStatus() {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { getTranslation } = useTranslation();

  return (
    <Grid item container>
      <Button href="/familyquestionnairessummary" startIcon={<ArrowBackIos />}>
        {getTranslation("BackFamilyQuestionnaires")}
      </Button>
      <Card
        sx={{
          width: "100%",
          backgroundColor: "myVerify.light",
          boxShadow: "0 16px #3E7787",
          marginBottom: 4,
          marginTop: 2,
        }}
      >
        <CardContent>
          <Grid
            item
            xs={10}
            sm={10}
            md={11}
            display="block"
            flexDirection="column"
            paddingLeft={3}
          >
            <Typography variant="h6" fontWeight={700} marginBottom={1}>
              {getTranslation("OnlineForm")}
            </Typography>
            <Typography display={isMobile ? "none" : "show"} marginBottom={1}>
              <span style={{ fontWeight: 700 }}>
                {getTranslation("NotetoCandidate")}:{" "}
              </span>
              {getTranslation("FamilyQuestionnairesDescription")}
            </Typography>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
