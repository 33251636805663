import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useAppSelector } from "../../app/store/configureStore";
import { AnswersProps } from "../../app/models/Answers";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "../../translation/translate";

const StatementWeeklyPlan: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const { user } = useAppSelector((state) => state.account);
  const isInfantToddler = user?.credentialTypeId === 1;

  const mainSystemCodeId = isInfantToddler ? 3627 : 35;
  const strengthSystemCodeId = isInfantToddler ? 3625 : 37;
  const changePlanSystemCodeId = isInfantToddler ? 3626 : 38;
  const yourWeeklyPlanPhilosophy = isInfantToddler ? 3624 : 36;
  const describePlanYes = 3607;
  const describePlanNo = 3620;
  const { getTranslation } = useTranslation();
  const schema = yup.object().shape({
    weeklyPlanPhilosophy: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    strengths: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    change: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    describePlan: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });

  const defaultValues = {
    option:
      answersData.find((answer) => answer.systemCodeId === mainSystemCodeId)
        ?.answer || null,
    weeklyPlanPhilosophy: answersData.find(
      (answer) => answer.systemCodeId === yourWeeklyPlanPhilosophy
    )?.answer,
    strengths: answersData.find(
      (answer) => answer.systemCodeId === strengthSystemCodeId
    )?.answer,
    change: answersData.find(
      (answer) => answer.systemCodeId === changePlanSystemCodeId
    )?.answer,
    describePlan_Yes: answersData.find(
      (answer) => answer.systemCodeId === describePlanYes
    )?.answer,
    describePlan_No: answersData.find(
      (answer) => answer.systemCodeId === describePlanNo
    )?.answer,
  };

  const {
    control,
    watch,
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });
  const [selectedOption, setSelectedOption] = useState<boolean | null>(null);
  const Option = watch("option");
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  useEffect(() => {
    if (Option) {
      setSelectedOption(Option === "true");
    }
  }, [Option, selectedOption]);

  return (
    <Grid item xs={12} px={3} py={3} sx={{ bgcolor: "#EBFCF2" }}>
      <Typography variant="body1" fontWeight="bold" paragraph>
        CS I-a - {getTranslation("CompetencyIStatementPlanReflection")}
      </Typography>
      <Typography variant="body1" paragraph>
        {getTranslation("CompetencyIStatementPlanReflectionLine1")}
      </Typography>
      <Box component="form">
        <Typography variant="body1">
          {getTranslation("CompetencyIStatementPlanReflectionLine2")}
        </Typography>
        <Controller
          name="option"
          control={control}
          render={({ field }) => (
            <RadioGroup
              name="option"
              value={field.value}
              onChange={(e) => {
                field.onChange(e);
                const newValue = e.target.value;
                const systemCodeId = mainSystemCodeId;

                onAnswerChange(systemCodeId, newValue);
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={getTranslation("Yes")}
                labelPlacement="end"
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={getTranslation("No")}
                labelPlacement="end"
              />
            </RadioGroup>
          )}
        />

        {selectedOption != null && (
          <>
            {selectedOption === true &&
              (isInfantToddler ? (
                <Box>
                  <Grid item xs={12}>
                    <Typography mt={2}>
                      {getTranslation(
                        "CompetencyIStatementPlanReflectionLine3"
                      )}
                    </Typography>
                    <TextField
                      value={
                        answersData.find(
                          (answer) =>
                            answer.systemCodeId === yourWeeklyPlanPhilosophy
                        )?.answer || ""
                      }
                      onChange={(e) => {
                        const newValue = e.target.value;
                        const systemCodeId = yourWeeklyPlanPhilosophy;
                        newValue.length <= 500 &&
                          onAnswerChange(systemCodeId, newValue);
                      }}
                      error={!!errors.weeklyPlanPhilosophy?.message}
                      helperText={errors.weeklyPlanPhilosophy?.message}
                      multiline
                      rows={2}
                      sx={{ bgcolor: "#FFF" }}
                      fullWidth
                    />

                    <Typography mt={2}>
                      {getTranslation(
                        "CompetencyIStatementPlanReflectionLine4"
                      )}
                    </Typography>
                    <TextField
                      value={
                        answersData.find(
                          (answer) => answer.systemCodeId === describePlanYes
                        )?.answer || ""
                      }
                      onChange={(e) => {
                        const newValue = e.target.value;
                        const systemCodeId = describePlanYes;
                        newValue.length <= 500 &&
                          onAnswerChange(systemCodeId, newValue);
                      }}
                      error={!!errors.describePlan_Yes?.message}
                      helperText={errors.describePlan_Yes?.message}
                      multiline
                      rows={2}
                      sx={{ bgcolor: "#FFF" }}
                      fullWidth
                    />
                  </Grid>
                </Box>
              ) : (
                <Box>
                  <Grid item xs={12} pt={3}>
                    <Typography variant="body1" paragraph>
                      {getTranslation(
                        "CompetencyIStatementPlanReflectionLine5"
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} pt={3} pb={isMobile ? 3 : 0}>
                    <TextField
                      label={getTranslation(
                        "CompetencyIStatementPlanReflectionLine6"
                      )}
                      {...register("weeklyPlanPhilosophy")}
                      sx={{ bgcolor: "#FFF" }}
                      value={
                        answersData.find(
                          (answer) =>
                            answer.systemCodeId === yourWeeklyPlanPhilosophy
                        )?.answer || ""
                      }
                      onChange={(e) => {
                        const newValue = e.target.value;
                        const systemCodeId = yourWeeklyPlanPhilosophy;
                        newValue.length <= 500 &&
                          onAnswerChange(systemCodeId, newValue);
                      }}
                      multiline
                      rows={2}
                      fullWidth
                      error={!!errors.weeklyPlanPhilosophy?.message}
                      helperText={errors.weeklyPlanPhilosophy?.message}
                    />
                    <Typography fontSize={13}>
                      {defaultValues.weeklyPlanPhilosophy?.length}/500
                    </Typography>
                  </Grid>
                </Box>
              ))}
            {selectedOption === false &&
              (isInfantToddler ? (
                <Box>
                  <Grid item xs={12} display="flex" flexDirection="column">
                    <Typography mt={2}>
                      {getTranslation(
                        "CompetencyIStatementPlanReflectionLine7"
                      )}
                    </Typography>
                    <TextField
                      value={
                        answersData.find(
                          (answer) =>
                            answer.systemCodeId === strengthSystemCodeId
                        )?.answer || ""
                      }
                      onChange={(e) => {
                        const newValue = e.target.value;
                        const systemCodeId = strengthSystemCodeId;
                        newValue.length <= 500 &&
                          onAnswerChange(systemCodeId, newValue);
                      }}
                      error={!!errors.strengths?.message}
                      helperText={errors.strengths?.message}
                      multiline
                      rows={2}
                      sx={{ bgcolor: "#FFF" }}
                      fullWidth
                    />

                    <Typography mt={2}>
                      {getTranslation(
                        "CompetencyIStatementPlanReflectionLine8"
                      )}
                    </Typography>
                    <TextField
                      value={
                        answersData.find(
                          (answer) =>
                            answer.systemCodeId === changePlanSystemCodeId
                        )?.answer || ""
                      }
                      onChange={(e) => {
                        const newValue = e.target.value;
                        const systemCodeId = changePlanSystemCodeId;
                        newValue.length <= 500 &&
                          onAnswerChange(systemCodeId, newValue);
                      }}
                      error={!!errors.change?.message}
                      helperText={errors.change?.message}
                      multiline
                      rows={2}
                      sx={{ bgcolor: "#FFF" }}
                      fullWidth
                    />

                    <Typography mt={2}>
                      {getTranslation(
                        "CompetencyIStatementPlanReflectionLine9"
                      )}
                    </Typography>
                    <TextField
                      value={
                        answersData.find(
                          (answer) => answer.systemCodeId === describePlanNo
                        )?.answer || ""
                      }
                      onChange={(e) => {
                        const newValue = e.target.value;
                        const systemCodeId = describePlanNo;
                        newValue.length <= 500 &&
                          onAnswerChange(systemCodeId, newValue);
                      }}
                      error={!!errors.describePlan_No?.message}
                      helperText={errors.describePlan_No?.message}
                      multiline
                      rows={2}
                      sx={{ bgcolor: "#FFF" }}
                      fullWidth
                    />
                  </Grid>
                </Box>
              ) : (
                <Box>
                  <Grid item xs={12} display="flex" flexDirection="column">
                    <Typography mt={2}>
                      {getTranslation(
                        "CompetencyIStatementPlanReflectionLine10"
                      )}
                    </Typography>
                    <TextField
                      value={
                        answersData.find(
                          (answer) =>
                            answer.systemCodeId === strengthSystemCodeId
                        )?.answer || ""
                      }
                      onChange={(e) => {
                        const newValue = e.target.value;
                        const systemCodeId = strengthSystemCodeId;
                        newValue.length <= 500 &&
                          onAnswerChange(systemCodeId, newValue);
                      }}
                      error={!!errors.strengths?.message}
                      helperText={errors.strengths?.message}
                      multiline
                      rows={2}
                      sx={{ bgcolor: "#FFF" }}
                      fullWidth
                    />

                    <Typography mt={2}>
                      {getTranslation(
                        "CompetencyIStatementPlanReflectionLine8"
                      )}
                    </Typography>
                    <TextField
                      value={
                        answersData.find(
                          (answer) =>
                            answer.systemCodeId === changePlanSystemCodeId
                        )?.answer || ""
                      }
                      onChange={(e) => {
                        const newValue = e.target.value;
                        const systemCodeId = changePlanSystemCodeId;
                        newValue.length <= 500 &&
                          onAnswerChange(systemCodeId, newValue);
                      }}
                      error={!!errors.change?.message}
                      helperText={errors.change?.message}
                      multiline
                      rows={2}
                      sx={{ bgcolor: "#FFF" }}
                      fullWidth
                    />
                  </Grid>
                </Box>
              ))}
          </>
        )}
      </Box>
    </Grid>
  );
};
export default StatementWeeklyPlan;
