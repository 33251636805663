import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import agent from "../../../app/api/agent";
import { useAppSelector } from "../../../app/store/configureStore";
import { useTranslation } from "../../../translation/translate";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  height: "vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const AddStudentModal = forwardRef(function AddStudentModal(
  props: any,
  ref: any
) {
  const { user } = useAppSelector((state) => state.account);
  const { setIsOpen, handleOnSubmit } = props;

  const [addStudent, setAddStudent] = useState<any | null>({
    firstName: "",
    lastName: "",
    email: "",
    portfolioId: 0,
  });
  const [studentEmail, setStudentEmail] = useState<any[]>([]);
  const { getTranslation } = useTranslation();

  const fetchData = async () => {
    if (user) {
      try {
        const response = await agent.SharedAccess.ListOfUsersByType(
          "CDA Candidate"
        );
        const newArr = response.filter((item: any) => item.portfolioId != 0);
        setStudentEmail(newArr);
      } catch {
        console.error();
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleClick = () => {
    setIsOpen(false);
    handleOnSubmit(addStudent);
  };

  const onSelect = (data: any) => {
    const newStudent = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      portfolioId: data.portfolioId,
    };
    if (data === null) {
      return null;
    } else {
      setAddStudent(newStudent);
      console.log(data);
    }
  };
  return (
    <Grid container>
      <Box sx={style}>
        <Grid item xs={12} textAlign="center">
          <Typography>{getTranslation("AddStudentModalLine1")}</Typography>
          <Typography>{getTranslation("AddStudentModalLine2")}</Typography>
        </Grid>

        <Grid item xs={12} display="flex" flexDirection="row">
          <TextField
            sx={{ marginRight: 2 }}
            label={getTranslation("FirstName")}
            value={addStudent.firstName}
            onChange={(e) =>
              setAddStudent({ ...addStudent, firstName: e.target.value })
            }
          />
          <TextField
            label={getTranslation("LastName")}
            value={addStudent.lastName}
            onChange={(e) =>
              setAddStudent({ ...addStudent, lastName: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} mt={2}>
          <Autocomplete
            id="free-solo-demo"
            freeSolo
            disableClearable
            options={studentEmail}
            onChange={(props, option: any) => {
              props.preventDefault();
              onSelect(option);
            }}
            getOptionLabel={(option) => option.email}
            renderInput={(params) => (
              <TextField {...params} label={getTranslation("StudentEmail")} />
            )}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} display="flex" flexDirection="column">
          <Button
            variant="contained"
            sx={{ marginTop: 2 }}
            disabled={!addStudent.email ? true : false}
            onClick={() => handleClick()}
          >
            {getTranslation("AddStudent")}
          </Button>
          <Button
            variant="outlined"
            sx={{ marginTop: 2 }}
            onClick={() => setIsOpen(false)}
          >
            {getTranslation("Cancel")}
          </Button>
        </Grid>
      </Box>
    </Grid>
  );
});

export default AddStudentModal;
