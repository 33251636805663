import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { RCVIUploadForm } from "../../app/models/RCVIUploadForm";
import { SubmitHandler } from "react-hook-form";
import MentalHealthAgency from "./MentalHealthAgency";
import { ExpandCircleDown } from "@mui/icons-material";
import agent from "../../app/api/agent";
import { useAppSelector } from "../../app/store/configureStore";
import { FilesProps } from "../../app/models/Answers";
import { useTranslation } from "../../translation/translate";

const MentalHealthServices: React.FC<FilesProps> = ({ RecallData }) => {
  const { user } = useAppSelector((state) => state.account);
  const [portfolioId, setPortfoliId] = useState<number>(0);
  const [newEntry, setNewEntry] = useState<RCVIUploadForm[]>([]);
  const { getTranslation } = useTranslation();

  const [showDiv, setShowDiv] = useState(false);

  const [open, setOpen] = useState({ show: false, id: "" });

  const handleClickOpen = (id: any) => {
    setOpen({ show: true, id: id });
  };

  const handleClose = () => {
    setOpen({ show: false, id: "" });
  };
  useEffect(() => {
    const fetchData = async () => {
      if (user && user.portfolioId) {
        const data = await agent.MentalHealthServices.GetMentalHealthServices(
          user.portfolioId
        );
        setPortfoliId(user.portfolioId);
        setNewEntry(data);
      }
    };

    fetchData();
  }, [user]);

  const handleToggle = () => {
    setShowDiv((prev) => !prev);
  };
  const onSubmit: SubmitHandler<RCVIUploadForm> = async (data) => {
    const newData = { ...data, portfolioId: portfolioId };
    if (user && user.portfolioId) {
      await agent.MentalHealthServices.PostMentalHealthServices(
        user?.portfolioId,
        newData
      );
    }
    setNewEntry([...newEntry, data]);
    handleToggle();
    RecallData();
  };
  const counter = (id: string) => {
    const arr = newEntry
      .map((item: any) => {
        return item.id;
      })
      .indexOf(id);
    return arr + 1;
  };
  const handleRemove = (id: string) => {
    const newList = newEntry.filter((item: any) => item.id !== id);
    agent.MentalHealthServices.DeleteMentalHealthServices(id);
    setNewEntry(newList);
    handleClose();
    RecallData();
  };
  return (
    <Grid container p={2} m={1}>
      <Grid item>
        <Typography fontSize={18} fontWeight={700} mb={2}>
          {getTranslation("RCVI3Title")}
        </Typography>
        <Typography mb={2}>{getTranslation("RCVI3Detail")}</Typography>
      </Grid>
      {newEntry.map((entry: any) => (
        <Grid item key={entry.id} xs={12} mb={2}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandCircleDown sx={{ color: "compVI.main" }} />}
            >
              <Typography fontSize={18} fontWeight={700} mr={2} color="primary">
                {counter(entry.id)}
              </Typography>
              <Typography fontWeight={700}>{entry.agency}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} p={2}>
                <Grid item xs={6}>
                  <Typography fontWeight={600}>
                    {getTranslation("RCVIChildhoodAssociationsLine7")}
                  </Typography>
                  <Typography>{entry.agency}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight={600}>
                    {getTranslation("RCVI_Telephone")}
                  </Typography>
                  <Typography>{entry.telephone}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight={600}>
                    {getTranslation("RCVI_Website")}
                  </Typography>
                  <Typography
                    textOverflow={"ellipsis"}
                    maxWidth={500}
                    overflow={"hidden"}
                  >
                    {entry.website}
                  </Typography>
                </Grid>
                <Grid item display="flex" justifyContent="flex-end" xs={12}>
                  <Button
                    variant="outlined"
                    color="compVI"
                    onClick={() => handleClickOpen(entry.id)}
                  >
                    {getTranslation("RCVIChildhoodAssociationsLine10")}
                  </Button>
                  <Dialog
                    open={open.show}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Delete Entry?"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        {getTranslation("RCVIChildhoodAssociationsLine12")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => handleRemove(open.id)}>
                        {getTranslation("Delete")}
                      </Button>
                      <Button onClick={handleClose} autoFocus>
                        {getTranslation("Cancel")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
      {showDiv && (
        <MentalHealthAgency toggle={handleToggle} onsubmit={onSubmit} />
      )}
      <Grid item xs={12} display="flex" justifyContent="center">
        <Button color="compVI" onClick={handleToggle}>
          {getTranslation("AddMentalHealthButton")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default MentalHealthServices;
