import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { forwardRef, useState } from "react";
import { useForm } from "react-hook-form";
import { CreateNewClassSchema } from "../../../app/validations/CreateNewClassValidations";
import { ClassContent } from "../../../app/models/AgentParamTypes";
import { useTranslation } from "../../../translation/translate";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};
const AddNewClassModal = forwardRef(function AddStudentModal(
  props: any,
  ref: any
) {
  const { setIsOpen, handleOnSubmit } = props;
  const [newClass, setNewClass] = useState<ClassContent>({
    name: "",
    drescription: "",
    instructorId: 0,
    startDate: "",
    endDate: "",
    active: true,
    createDate: new Date(),
  });
  const handleClick = () => {
    handleOnSubmit(newClass);
    setIsOpen(false);
  };
  const { getTranslation } = useTranslation();

  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues: newClass,
    mode: "onTouched",
    resolver: yupResolver(CreateNewClassSchema(getTranslation)),
  });

  return (
    <Grid container>
      <Box sx={style}>
        <Grid item xs={12} textAlign="center">
          <Typography>{getTranslation("AddNewClass")}</Typography>
          <Typography>{getTranslation("AddNewClassLine1")}</Typography>
        </Grid>

        <Grid item xs={12} display="flex" flexDirection="column">
          <TextField
            label={getTranslation("ClassName")}
            {...register("name")}
            fullWidth
            sx={{ marginTop: 2 }}
            onChange={(e) => setNewClass({ ...newClass, name: e.target.value })}
            error={!!errors.name?.message}
            helperText={errors.name?.message}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              key={"start"}
              label={getTranslation("StartDate")}
              {...register("startDate")}
              value={newClass.startDate || null}
              onChange={(e: any) => {
                setNewClass({
                  ...newClass,
                  startDate: e.$d,
                });
              }}
              disablePast
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  sx={{ mt: 2 }}
                  error={!!errors.startDate?.message}
                  helperText={errors.startDate?.message}
                />
              )}
            />
          </LocalizationProvider>
          <Grid item mt={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                key={"end"}
                label={getTranslation("EndDate")}
                minDate={newClass.startDate}
                {...register("endDate")}
                value={newClass.endDate || newClass.startDate || null}
                views={["year", "month", "day"]}
                onChange={(e: any) => {
                  setNewClass({
                    ...newClass,
                    endDate: e.$d,
                  });
                }}
                disablePast
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    error={!!errors.endDate?.message}
                    helperText={errors.endDate?.message}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <TextField
            label={getTranslation("ClassDescription")}
            sx={{ marginTop: 2 }}
            {...register("drescription")}
            multiline
            rows={3}
            onChange={(e) =>
              setNewClass({ ...newClass, drescription: e.target.value })
            }
            fullWidth
            error={!!errors.drescription?.message}
            helperText={errors.drescription?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            disabled={
              newClass.drescription === null || newClass.endDate === ""
                ? true
                : false
            }
            sx={{ marginTop: 2 }}
            onClick={() => handleClick()}
          >
            {getTranslation("AddClass")}
          </Button>
        </Grid>
      </Box>
    </Grid>
  );
});
export default AddNewClassModal;
