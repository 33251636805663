import { useForm } from "react-hook-form";
import { Grid, TextField, Typography } from "@mui/material";
import ReadMoreReadLess from "../../app/common/ReadMoreReadLess";
import { AnswersProps } from "../../app/models/Answers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const ObservationMethod: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const defaultValues = {
    observing: answersData.find((answer) => answer.systemCodeId === 252)
      ?.answer,
  };
  const { getTranslation } = useTranslation();
  const schema = yup.object().shape({
    observing: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });
  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const renderObservationMethodCompetence = () => {
    return (
      <>
        <li>{getTranslation("ObservationMethodCompetenceBullet1")}</li>
        <li>{getTranslation("ObservationMethodCompetenceBullet2")}</li>
        <li>{getTranslation("ObservationMethodCompetenceBullet3")}</li>
      </>
    );
  };

  return (
    <Grid container p={2} m={1} mb={2} bgcolor="compV.light">
      <Grid item xs={12}>
        <Typography fontSize={18} fontWeight={700} mb={2}>
          {getTranslation("SVObservationMethodLine1")}
        </Typography>
        <Typography mb={2}>
          {getTranslation("SVObservationMethodLine2")}
        </Typography>
        <ReadMoreReadLess>
          <>{renderObservationMethodCompetence()}</>
        </ReadMoreReadLess>
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...register("observing")}
          name="observing"
          label={getTranslation("SVObservationMethodLine3")}
          fullWidth
          multiline
          sx={{ backgroundColor: "white" }}
          rows={2}
          value={
            answersData.find((answer) => answer.systemCodeId === 252)?.answer ||
            ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = 252;

            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.observing?.message}
          helperText={errors.observing?.message}
        />
        <Typography fontSize={13}>
          {defaultValues.observing?.length}/500
        </Typography>
      </Grid>
    </Grid>
  );
};
export default ObservationMethod;
