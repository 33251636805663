import { Grid, TextField, Typography } from "@mui/material";
import { useAppSelector } from "../../app/store/configureStore";
import { AnswersProps } from "../../app/models/Answers";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  Candidate: yup
    .string()
    .required("Must give an answer")
    .max(500, "Max characters reached"),
  Specialist: yup
    .string()
    .required("Must give an answer")
    .max(500, "Max characters reached"),
});
export default function EFormStepThree({
  answersData,
  onAnswerChange,
}: AnswersProps) {
  const { user } = useAppSelector((state) => state.account);

  const defaultValues = {
    Candidate: answersData.find((answer) => answer.systemCodeId === 2195)
      ?.answer,
    Specialist: answersData.find((answer) => answer.systemCodeId === 2196)
      ?.answer,
  };

  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });
  return (
    <Grid item container m={2} p={2}>
      <Grid item>
        <Typography
          fontWeight={700}
          fontSize={20}
          fontStyle="italic"
          textAlign="center"
          color={"myVerify.main"}
        >
          Step 3: Commit to Achieving Your Goal(s)
        </Typography>
        <Typography textAlign="center">
          I,{" "}
          <span style={{ textDecoration: "underline", fontSize: 18 }}>
            {user?.firstName} {user?.lastName}
          </span>{" "}
          hereby commit to achieving my goal(s) in order to further develop as a
          professional and to become even more effective at serving the needs of
          the children and families in my care.
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={3}>
        <Grid item xs={6}>
          <TextField
            {...register("Candidate")}
            value={
              answersData.find((answer) => answer.systemCodeId === 2195)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 2195;

              newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.Candidate?.message}
            helperText={errors.Candidate?.message}
            inputProps={{ style: { textAlign: "center" } }}
            variant="standard"
            fullWidth
          />
          <Typography textAlign="center" fontSize={14}>
            CDA Candidate (sign here)
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...register("Specialist")}
            value={
              answersData.find((answer) => answer.systemCodeId === 2196)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 2196;

              newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.Specialist?.message}
            helperText={errors.Specialist?.message}
            inputProps={{ style: { textAlign: "center" } }}
            variant="standard"
            fullWidth
          />
          <Typography textAlign="center" fontSize={14}>
            as witnessed by my CDA PD Specialist (sign here)
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} m={2}>
        <Typography fontSize={14}>
          This worksheet is now yours to keep. Being a reflective practitioner
          and meeting the goals you've set for yourself is up to you, regardless
          of whether you are awarded your CDA Credential. The Council encourages
          you to share your goals with a mentor, colleague or supervisor who
          will support you, hold you accountable for meeting your goals and
          celebrate with you when you have met them.
        </Typography>
      </Grid>
    </Grid>
  );
}
