import { Grid, TextField, Typography } from "@mui/material";
import ReadMoreReadLess from "../../app/common/ReadMoreReadLess";
import { AnswersProps } from "../../app/models/Answers";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const StatementChildAbuse: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const { getTranslation } = useTranslation();
  const defaultValues = {
    policyChallenges: answersData.find((answer) => answer.systemCodeId === 25)
      ?.answer,
    policyAlign: answersData.find((answer) => answer.systemCodeId === 24)
      ?.answer,
    policyRequirements: answersData.find(
      (answer) => answer.systemCodeId === 279
    )?.answer,
    encountered: answersData.find((answer) => answer.systemCodeId === 3608)
      ?.answer,
  };

  const schema = yup.object().shape({
    policyChallenges: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    policyAlign: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    policyRequirements: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    encountered: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });

  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const renderChildAbuseNeglectPolicyReflectionCompetence = () => {
    return (
      <>
        <li>{getTranslation("WhatareSimilarites")} </li>
        <li>{getTranslation("WhatareDifferences")}</li>
      </>
    );
  };

  return (
    <Grid container p={3} sx={{ bgcolor: "#EBFCF2" }}>
      <Grid item xs={12}>
        <Typography variant="body1" fontWeight={700} paragraph>
          {getTranslation("StatementCHildAbuseTitle")}
        </Typography>
      </Grid>
      <Grid item xs={12} mb={4}>
        <Typography variant="body1" paragraph>
          {getTranslation("StatementCHildAbuseLine1")}
        </Typography>
        <ReadMoreReadLess>
          <>{renderChildAbuseNeglectPolicyReflectionCompetence()}</>
        </ReadMoreReadLess>

        <TextField
          label={getTranslation("policyAlignLine1")}
          multiline
          rows={2}
          fullWidth
          {...register("policyAlign")}
          name="policyAlign"
          sx={{ bgcolor: "#FFF" }}
          value={
            answersData.find((answer) => answer.systemCodeId === 24)?.answer ||
            ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = 24;

            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.policyAlign?.message}
          helperText={errors.policyAlign?.message}
        />
        <Typography fontSize={13}>
          {defaultValues.policyAlign?.length}/500
        </Typography>
      </Grid>
      <Grid item xs={12} mb={4}>
        <Typography>{getTranslation("policyAlignLine2")}</Typography>

        <TextField
          label={getTranslation("StatementCHildAbuseLine3")}
          multiline
          rows={2}
          fullWidth
          {...register("policyChallenges")}
          name="policyChallenges"
          sx={{ bgcolor: "#FFF" }}
          value={
            answersData.find((answer) => answer.systemCodeId === 25)?.answer ||
            ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = 25;

            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.policyChallenges?.message}
          helperText={errors.policyChallenges?.message}
        />
        <Typography fontSize={13}>
          {defaultValues.policyChallenges?.length}/500
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>{getTranslation("StatementCHildAbuseLine4")}</Typography>
        <TextField
          value={
            answersData.find((answer) => answer.systemCodeId === 3608)
              ?.answer || ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = 3608;

            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.encountered?.message}
          helperText={errors.encountered?.message}
          multiline
          rows={2}
          fullWidth
          sx={{ bgcolor: "#FFF" }}
        />
      </Grid>
    </Grid>
  );
};
export default StatementChildAbuse;
