import { ExpandCircleDown } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { RCVIAnswersProps } from "../../app/models/Answers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const ChildAbuseReporting: React.FC<RCVIAnswersProps> = ({
  answersData,
  onAnswerChange,
  onSave,
}) => {
  const defaultValues = {
    name: answersData.find((answer) => answer.systemCodeId === 263)?.answer,
    telephone: answersData.find((answer) => answer.systemCodeId === 264)
      ?.answer,
    website: answersData.find((answer) => answer.systemCodeId === 265)?.answer,
    childAbuse: answersData.find((answer) => answer.systemCodeId === 266)
      ?.answer,
    report: answersData.find((answer) => answer.systemCodeId === 267)?.answer,
    required: answersData.find((answer) => answer.systemCodeId === 268)?.answer,
  };
  const { getTranslation } = useTranslation();
  const schema = yup.object().shape({
    name: yup
      .string()
      .max(60, getTranslation("ValidationNameMaxChar"))
      .required(getTranslation("ValidationNameRequired")),
    telephone: yup
      .string()
      .required(getTranslation("ValidationPhoneNumberRequired"))
      .min(10, getTranslation("ValidationInvalidNumber"))
      .max(11, getTranslation("ValidationInvalidNumber")),
    website: yup
      .string()
      .url(getTranslation("ChildhoodAssociationEntryValidationWebsite"))
      .required(getTranslation("ValidationWebsiteRequired")),
    childAbuse: yup
      .string()
      .max(
        1000,
        getTranslation("ChildhoodAssociationEntryValidationDescriptionMax")
      )
      .required(getTranslation("ValidationDescriptionRequired")),
    report: yup
      .string()
      .required(getTranslation("ValidationReportDetailsRequired")),
    required: yup
      .string()
      .required(getTranslation("ValidationReportDetailsRequired")),
  });

  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const handlePhoneInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const numericInput = input.replace(/\D/g, ""); // Remove non-numeric characters
    event.target.value = numericInput;
    const newValue = event.target.value;
    const systemCodeId = 264;
    onAnswerChange(systemCodeId, newValue);
  };
  return (
    <Grid container p={2} m={1}>
      <Grid item mb={2}>
        <Typography fontSize={18} fontWeight={700}>
          {getTranslation("RCVIChildAbuseLine1")}
        </Typography>
        <Typography>{getTranslation("RCVIChildAbuseLine2")}</Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandCircleDown sx={{ color: "compVI.main" }} />}
          >
            <Typography fontSize={18} fontWeight={700}>
              {getTranslation("RCVIChildAbuseLine3")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography fontWeight={700}>
                  {getTranslation("RCVIChildAbuseLine5")}
                </Typography>
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <TextField
                  label={getTranslation("RCVI_Name")}
                  {...register("name")}
                  value={
                    answersData.find((answer) => answer.systemCodeId === 263)
                      ?.answer || ""
                  }
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const systemCodeId = 263;

                    onAnswerChange(systemCodeId, newValue);
                  }}
                  fullWidth
                  error={!!errors.name?.message}
                  helperText={errors.name?.message}
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <TextField
                  label={getTranslation("RCVI_Telephone")}
                  {...register("telephone")}
                  value={
                    answersData.find((answer) => answer.systemCodeId === 264)
                      ?.answer || ""
                  }
                  onChange={handlePhoneInput}
                  fullWidth
                  error={!!errors.telephone?.message}
                  helperText={errors.telephone?.message}
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <TextField
                  label={getTranslation("RCVI_Website")}
                  {...register("website")}
                  placeholder="https://www.website.com/"
                  value={
                    answersData.find((answer) => answer.systemCodeId === 265)
                      ?.answer || ""
                  }
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const systemCodeId = 265;

                    onAnswerChange(systemCodeId, newValue);
                  }}
                  fullWidth
                  error={!!errors.website?.message}
                  helperText={errors.website?.message}
                />
              </Grid>
            </Grid>
            <Grid container mt={4} mb={4}>
              <Grid item mb={2} xs={12}>
                <Typography fontWeight={700}>
                  {getTranslation("RCVIChildAbuseLine6")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography mb={1}>
                  {getTranslation("RCVIChildAbuseLine7")}
                </Typography>
                <TextField
                  label={getTranslation("RCVIChildAbuseLine8")}
                  {...register("childAbuse")}
                  value={
                    answersData.find((answer) => answer.systemCodeId === 266)
                      ?.answer || ""
                  }
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const systemCodeId = 266;
                    newValue.length <= 1000 &&
                      onAnswerChange(systemCodeId, newValue);
                  }}
                  multiline
                  rows={4}
                  fullWidth
                  error={!!errors.childAbuse?.message}
                  helperText={errors.childAbuse?.message}
                />
                <Typography fontSize={13}>
                  {defaultValues.childAbuse?.length}/1000
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography fontWeight={700}>
                  {getTranslation("WhenWheretoReport")}
                </Typography>
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <TextField
                  label={getTranslation("RCVIChildAbuseLine9")}
                  {...register("report")}
                  value={
                    answersData.find((answer) => answer.systemCodeId === 267)
                      ?.answer || ""
                  }
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const systemCodeId = 267;

                    onAnswerChange(systemCodeId, newValue);
                  }}
                  fullWidth
                  error={!!errors.report?.message}
                  helperText={errors.report?.message}
                />
              </Grid>
              <Grid item xs={isMobile ? 12 : 6}>
                <TextField
                  label={getTranslation("RCVIChildAbuseLine10")}
                  {...register("required")}
                  value={
                    answersData.find((answer) => answer.systemCodeId === 268)
                      ?.answer || ""
                  }
                  onChange={(e) => {
                    const newValue = e.target.value;
                    const systemCodeId = 268;

                    onAnswerChange(systemCodeId, newValue);
                  }}
                  fullWidth
                  error={!!errors.required?.message}
                  helperText={errors.required?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="compVI" onClick={onSave}>
                  {getTranslation("Save")}
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};
export default ChildAbuseReporting;
