import * as React from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material"; // Make sure SelectChangeEvent is imported

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

interface SelectLanguageModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedLanguage: string;
  handleLanguageChange: (event: SelectChangeEvent) => void; // Use SelectChangeEvent type
  handleSave: () => void;
}

export default function SelectLanguageModal({
  isOpen,
  onClose,
  selectedLanguage,
  handleLanguageChange,
  handleSave,
}: SelectLanguageModalProps) {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-title" variant="h6" component="h2">
          Welcome to the CDA ePortfolio!
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2 }}>
          Please select the language in which you would like to view your
          ePortfolio. Your selection will apply throughout your experience on
          this platform, including all screens and the generated PDF of your
          portfolio. You can change your language preference at any time by
          visiting your profile page.
        </Typography>

        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="language-select-label">Select Language</InputLabel>
          <Select
            labelId="language-select-label"
            id="language-select"
            label="Language"
            value={selectedLanguage}
            onChange={handleLanguageChange} // Correct handler type
          >
            <MenuItem value="false">English</MenuItem>
            <MenuItem value="true">Spanish</MenuItem>
          </Select>
        </FormControl>

        <Typography id="modal-instructions" sx={{ mt: 2 }}>
          <b>Instructions:</b> Select your preferred ePortfolio display language
          by clicking one of the options above.{" "}
          <span style={{ color: "red" }}>
            Once selected, you will be directed to the Welcome Page to continue
            setting up your ePortfolio.
          </span>
        </Typography>

        <Button variant="contained" sx={{ mt: 3 }} onClick={handleSave}>
          Save
        </Button>
      </Box>
    </Modal>
  );
}
