import * as yup from "yup";
import { useTranslation } from "../../translation/translate";

const RCVIValidationSchema = () => {
  const { getTranslation } = useTranslation();
  let schema = yup.object().shape({
    agency: yup
      .string()
      .max(250, getTranslation("ValidationNameofAgencyMax"))
      .required(getTranslation("ValidationNameofAgencyRequired")),
    telephone: yup
      .string()
      .required(getTranslation("ValidationPhoneNumberRequired"))
      .min(10, getTranslation("ValidationInvalidNumber"))
      .max(11, getTranslation("ValidationInvalidNumber")),
    website: yup
      .string()
      .max(150, getTranslation("ValidationWebsiteMaxChar"))
      .url(getTranslation("ValidationInvalidWebsite"))
      .required(getTranslation("ValidationWebsiteRequired")),
  });
  return schema;
};
export default RCVIValidationSchema;
