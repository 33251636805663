import { Button, Grid, Typography, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../../app/common/LoadingSpinner";
import { useAppSelector } from "../../../app/store/configureStore";
import agent from "../../../app/api/agent";
import StudentTableData from "./studentTable";
import AddStudentModal from "./addStudentModal";
import { useTranslation } from "../../../translation/translate";

type RowDataType = {
  portfolioId: number;
  portfoliId: number;
  firstName: string;
  lastName: string;
  email: string;
  classNames: string;
  status: string;
};

export default function ManageStudents() {
  const [studentRowData, setStudentRowData] = useState<RowDataType[]>([
    {
      portfolioId: 0,
      portfoliId: 0,
      firstName: "",
      lastName: "",
      email: "",
      classNames: "",
      status: "",
    },
  ]);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();
  const fetchData = async () => {
    if (user) {
      try {
        const studentList = await agent.SharedAccess.GetSharedListAccessUserId(
          user.id
        );
        setStudentRowData(studentList);
      } catch {
        console.error();
      }
      setIsLoading(false);
    }
  };

  const handleOnSubmit = async (data: RowDataType) => {
    if (user) {
      const constructedData = {
        portfolioId: data.portfolioId,
        portfoliId: data.portfolioId,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        classNames: data.classNames,
        status: "Pending",
      };

      const requestStudent = {
        portfolioId: data.portfolioId,
        userId: user.id,
        sendEmail: true,
      };
      try {
        await agent.SharedAccess.RequestSharedAccess(requestStudent);
        const pendingAccess = {
          portfoliId: data.portfolioId,
          userId: user.id,
          status: "Pending",
          isTrainer: true,
        };
        await agent.SharedAccess.ApproveSharedAccess(pendingAccess);
      } catch {
        console.error();
      }
      setStudentRowData([...studentRowData, constructedData]);
    }
    setIsLoading(true);
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRemoveUser = async (id: any) => {
    if (user) {
      setIsLoading(true);
      const removeData = {
        portfoliId: id,
        userId: user.id,
        status: "Denied",
        isTrainer: true,
      };
      await agent.SharedAccess.ApproveSharedAccess(removeData);
      fetchData();
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container p={2} m={2}>
      <Grid
        item
        xs={12}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography fontSize={24} fontWeight={700}>
            {user?.role === "Trainer"
              ? getTranslation("ManageStudents")
              : getTranslation("AllStudents")}
          </Typography>
          <Typography>
            {getTranslation("ManageStudentsLine1")}:{" "}
            {studentRowData.length ?? 0} {getTranslation("Students")}
          </Typography>
          <Typography>{getTranslation("ManageStudentsLine2")}</Typography>
        </Grid>
        <Grid item m={2} mr={3}>
          <Button
            variant="outlined"
            sx={{ textTransform: "initial" }}
            onClick={() => setIsOpen(true)}
          >
            {getTranslation("AddStudent")}
          </Button>
        </Grid>
      </Grid>
      <StudentTableData
        handleRemoveUser={handleRemoveUser}
        selectedRow={selectedRow}
        studentRowData={studentRowData}
        setSelectedRow={setSelectedRow}
      />
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="add-user-modal-title"
        aria-describedby="add-user-modal-description"
      >
        <AddStudentModal
          setIsOpen={setIsOpen}
          handleOnSubmit={handleOnSubmit}
        />
      </Modal>
    </Grid>
  );
}
