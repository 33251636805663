import { Card, CardContent, Typography, Box, Grid } from "@mui/material";
import Status from "../../assets/images/symbol-icon-blue.png";
import EditUserInfo from "./EditUserInfo";
import SharedAccessComp from "./SharedAccess/SharedAccess";
import { useAppSelector } from "../../app/store/configureStore";
import { useEffect, useState } from "react";
import { AdminSharedAccType } from "../../app/models/AdminSharedAcc";
import agent from "../../app/api/agent";
import LoadingSpinner from "../../app/common/LoadingSpinner";
import { useTranslation } from "../../translation/translate";

export default function EditProfile() {
  const { user } = useAppSelector((state) => state.account);
  const [adminData, setAdminData] = useState<AdminSharedAccType[]>([]);
  const [listOfAdmin, setListOfAdmin] = useState<AdminSharedAccType[]>([]);
  const [addNew, setAddNew] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const userAccess =
    user?.role === "CDA Candidate" || user?.role === "Other Viewer";
  const { getTranslation } = useTranslation();


  const fecthAdminListData = async (userType: any) => {
    if (user?.role === "CDA Candidate") {
      try {
        const listData = await agent.SharedAccess.ListOfUsersByType(userType);

        setListOfAdmin(listData);
      } catch {
        console.error();
      }
    }
    setIsLoading(false);
  };

  const fetchData = async () => {
    if (user) {
      if (user?.role === "CDA Candidate") {
        try {
          const listOfSharedUsers = await agent.SharedAccess.ListOfAccessShared(
            user.portfolioId
          );
          setAdminData(listOfSharedUsers);
        } catch {
          console.error();
        }
        setIsLoading(false);
      } else {
        try {
          const listData = await agent.SharedAccess.GetSharedListAccessUserId(
            user.id
          );
          setAdminData(listData);
        } catch {
          console.error();
        }
        setIsLoading(false);
      }
    }
  };

  const onSubmit = async (data: AdminSharedAccType) => {
    setIsLoading(true);
    if (user) {
      const postData = {
        portfolioId: user.portfolioId,
        userId: data.userId,
        sendEmail: false,
      };
      const grantAccess = {
        portfoliId: user?.portfolioId,
        userId: data.userId,
        status: "Approved",
        isTrainer: false,
      };

      try {
        await agent.SharedAccess.RequestSharedAccess(postData);

        await agent.SharedAccess.ApproveSharedAccess(grantAccess);
      } catch {
        console.error();
      }
      setIsLoading(false);
      fetchData();
    }
  };
  const checkUser = () => {
    if (!userAccess) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    checkUser();
  }, []);

  const handleRemove = async (userId: number, portfoliId: number) => {
    if (user && user.role === "CDA Candidate") {
      const removeAdmin = {
        portfoliId: user.portfolioId,
        userId: userId,
        status: "Denied",
        isTrainer: false,
      };

      await agent.SharedAccess.ApproveSharedAccess(removeAdmin);
    } else if (user && user.role === "Other Viewer") {
      const removeStudent = {
        portfoliId: portfoliId,
        userId: user.id,
        status: "Denied",
        isTrainer: false,
      };
      await agent.SharedAccess.ApproveSharedAccess(removeStudent);
    }
    fetchData();
  };

  const handleGrantPermission = async (userId: number) => {
    if (user) {
      const approve = {
        portfoliId: user.portfolioId,
        userId: userId,
        status: "Approved",
        isTrainer: false,
      };
      await agent.SharedAccess.ApproveSharedAccess(approve);
      fetchData();
    }
  };

  const headerBox = () => {
    return (
      <Card
        sx={{
          width: "100%",
          backgroundColor: "#EEFCFB",
          boxShadow: " 0 16px #3E7787",
        }}
      >
        <CardContent>
          <Grid container mt={1} display="flex" flexDirection="row">
            <Grid item xs={2} sm={2} md={1}>
              <Box sx={{ display: { xs: "none", sm: "block", md: "block" } }}>
                <img src={Status} alt="status" style={{ width: "70%" }} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography fontWeight={700} fontSize={24}>
                {getTranslation("EditUserProfile")}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container m={0.5} mb={2} p={2}>
      {headerBox()}

      <EditUserInfo />

      {userAccess && (
        <SharedAccessComp
          adminData={adminData}
          onSubmit={onSubmit}
          addNew={addNew}
          setAddNew={setAddNew}
          handleRemove={handleRemove}
          listOfAdmin={listOfAdmin}
          fecthListData={fecthAdminListData}
          otherUser={user.role === "Other Viewer"}
          handleGrantPermission={handleGrantPermission}
        />
      )}
    </Grid>
  );
}
