import { Button, Grid, Typography } from "@mui/material";
import UploadedFiles from "../FileUpload/UploadedFiles";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { FileInfo } from "../../app/models/Fileinfo";
import {
  Anchor,
  OpenDrawerPayload,
  Section,
} from "../../app/models/FileUpload";
import { openDrawer } from "../FileUpload/drawerSlice";
import agent from "../../app/api/agent";
import { FileUpload } from "@mui/icons-material";
import { useTranslation } from "../../translation/translate";

export default function AddForm2() {
  const [data, setData] = useState<FileInfo[]>([]);
  const { isOpen } = useAppSelector((state) => state.drawer);
  const { user } = useAppSelector((state) => state.account);
  const dispatch = useAppDispatch();
  const { getTranslation } = useTranslation();

  const handleOpenDrawer = (anchor: Anchor, section: Section) => {
    const payload: OpenDrawerPayload = {
      anchor,
      section,
    };
    dispatch(openDrawer(payload));
  };

  const fetchData = useCallback(async () => {
    try {
      const data = await agent.FileUpload.GetFileInfo(
        "PortfolioChecklist",
        user?.portfolioId ?? 0
      );
      setData(data);
    } catch (error) {
      console.error();
    }
  }, [user?.portfolioId]);
  //1st group files
  const groupedFiles: Record<any, any[]> = {};
  data.forEach((item) => {
    const certType = item.certificateType;
    const itemFile = item;
    // If the property doesn't exist in the groupedFiles object, initialize it as an empty array.
    if (!groupedFiles[certType]) {
      groupedFiles[certType] = [];
    }
    // Push the file to the corresponding property group.
    groupedFiles[certType].push(itemFile);
  });

  //2nd Render grouped files
  const newArr = Object.entries(groupedFiles);
  const fileDataArray = () => {
    return newArr.map((innerArray) => {
      return innerArray[1].map((file: any) => (
        <UploadedFiles
          fileOrder={innerArray[1].indexOf(file) + 1}
          key={file.id}
          fileInfo={file}
          onDeleteFile={() => handleDeleteFile()}
        />
      ));
    });
  };

  useEffect(() => {
    fetchData();
  }, [isOpen, fetchData]);

  function handleDeleteFile(): void {
    fetchData();
  }
  return (
    <Grid container item xs={12} p={2} bgcolor={"myVerify.light"}>
      <Grid item xs={12} md={6} marginBottom={2}>
        <Typography mb={1} fontWeight={700}>
          {getTranslation("AddForm2Line1")}
        </Typography>

        <Grid item mt={2}>
          <Button
            variant="outlined"
            startIcon={<FileUpload />}
            sx={{
              textTransform: "none",
              marginTop: "10px",
            }}
            color="success"
            onClick={() => handleOpenDrawer("right", "PortfolioChecklist")}
          >
            {getTranslation("UploadDocumentation")}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body1" fontWeight="bold">
          {getTranslation("UploadedDocuments")}
        </Typography>
        <Typography variant="subtitle2">
          {data.length}{" "}
          {data.length === 1 ? getTranslation("file") : getTranslation("files")}
          {"  "}
          {getTranslation("uploaded")}
        </Typography>
        {fileDataArray()}
      </Grid>
    </Grid>
  );
}
