import { Button, Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import teamIcon from "../../assets/images/team-waving.png";
import { FileUpload } from "@mui/icons-material";
import { useCallback, useEffect, useState } from "react";
import { AnswersProps } from "../../app/models/Answers";
import { useForm } from "react-hook-form";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../app/store/configureStore";
import {
  Anchor,
  Section,
  OpenDrawerPayload,
} from "../../app/models/FileUpload";
import { openDrawer } from "../FileUpload/drawerSlice";
import agent from "../../app/api/agent";
import { FileInfo } from "../../app/models/Fileinfo";
import UploadedFiles from "../FileUpload/UploadedFiles";
import { useTranslation } from "../../translation/translate";

const SummaryEducationForm: React.FC<AnswersProps> = ({ answersData }) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  const { getTranslation } = useTranslation();

  const [data, setData] = useState<FileInfo[]>([]);
  const { isOpen } = useAppSelector((state: RootState) => state.drawer);

  const { user } = useAppSelector((state) => state.account);

  const dispatch = useAppDispatch();

  const handleOpenDrawer = (anchor: Anchor, section: Section) => {
    const payload: OpenDrawerPayload = {
      anchor,
      section,
    };
    dispatch(openDrawer(payload));
  };

  const { control } = useForm({
    defaultValues: {
      uploadDecision:
        answersData.find((answer) => answer.systemCodeId === 278)?.answer ||
        null,
    },
  });

  const fetchData = useCallback(async () => {
    try {
      const data = await agent.FileUpload.GetFileInfo(
        "EducationSummary",
        user?.portfolioId ?? 0
      );
      setData(data);
    } catch (error) {
      // Handle errors if necessary.
    }
  }, [user?.portfolioId]);
  //1st group files
  const groupedFiles: Record<any, any[]> = {};
  data.forEach((item) => {
    const certType = item.certificateType;
    const itemFile = item;
    // If the property doesn't exist in the groupedFiles object, initialize it as an empty array.
    if (!groupedFiles[certType]) {
      groupedFiles[certType] = [];
    }
    // Push the file to the corresponding property group.
    groupedFiles[certType].push(itemFile);
  });

  //2nd Render grouped files
  const newArr = Object.entries(groupedFiles);
  const fileDataArray = () => {
    return newArr.map((innerArray) => {
      return innerArray[1].map((file: any) => (
        <UploadedFiles
          fileOrder={innerArray[1].indexOf(file) + 1}
          key={file.id}
          fileInfo={file}
          onDeleteFile={() => handleDeleteFile()}
        />
      ));
    });
  };

  useEffect(() => {
    fetchData();
  }, [answersData, control, isOpen, fetchData]);

  function handleDeleteFile(): void {
    fetchData();
    window.location.reload();
  }

  const downloadEForm = () => {
    const link = document.createElement("a");
    link.download = `SummaryofMyCDAEducationForm.pdf`;
    link.href = "./docs/SummaryofMyCDAEducationForm.pdf";
    link.click();
  };

  return (
    <Grid container m={2} spacing={3}>
      <Grid item xs={isMobile ? 12 : 7} p={2}>
        <Grid item mb={1}>
          <Typography fontWeight={700}>
            {getTranslation("CDAEducationDocumentationAndSummary")}
          </Typography>
        </Grid>
        <Grid item mb={2}>
          <Typography>
            {getTranslation("CDAEducationDocumentationAndSummaryDescription")}
            <br />
            <br />
            {getTranslation("CDAEducationDocumentationAndSummaryListHeader")}
            <ol>
              <li>
                <strong>
                  {getTranslation(
                    "CDAEducationDocumentationAndSummaryList1Bold"
                  )}
                  :
                </strong>{" "}
                {getTranslation("CDAEducationDocumentationAndSummaryList1")}
              </li>
              <li>
                <strong>
                  {getTranslation(
                    "CDAEducationDocumentationAndSummaryList2Bold"
                  )}
                  :
                </strong>{" "}
                {getTranslation("CDAEducationDocumentationAndSummaryList2")}
              </li>
              <li>
                <strong>
                  {getTranslation(
                    "CDAEducationDocumentationAndSummaryList3Bold"
                  )}
                  :
                </strong>{" "}
                {getTranslation("CDAEducationDocumentationAndSummaryList3")}
              </li>
            </ol>
          </Typography>
        </Grid>

        <Grid item mt={1}>
          <Button
            variant="outlined"
            color="cdaEDU"
            onClick={() => handleOpenDrawer("right", "EducationSummary")}
            sx={{ marginRight: 2, textTransform: "initial" }}
          >
            <FileUpload />
            {getTranslation("Upload")}
          </Button>

          <Button
            variant="outlined"
            color="cdaEDU"
            onClick={downloadEForm}
            sx={{ marginRight: 2, textTransform: "initial" }}
          >
            {getTranslation("DownloadForm")}
          </Button>
          <Button
            variant="outlined"
            color="cdaEDU"
            href="/cdaeducaion/edocument"
            sx={{ textTransform: "initial" }}
          >
            {getTranslation("CompleteEForm")}
          </Button>
        </Grid>
        <Grid item mt={3}>
          <Typography variant="body1" fontWeight="bold">
            {getTranslation("UploadedDocuments")}
          </Typography>
          <Typography variant="subtitle2">
            {data.length}{" "}
            {data.length === 1
              ? getTranslation("file")
              : getTranslation("files")}{" "}
            {getTranslation("uploaded")}
          </Typography>
          {fileDataArray()}
        </Grid>
      </Grid>
      <Grid item xs={5} display={isMobile ? "none" : "show"}>
        <img src={teamIcon} width="100%" alt="Team Icon" />
      </Grid>
    </Grid>
  );
};
export default SummaryEducationForm;
