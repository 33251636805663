import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DOMPurify from "dompurify";

const borderStyle = {
  border: "1px lightgray solid",
  borderRadius: 2,
  p: 1,
};
export default function EmailTemplateArray({
  emailArr,
  removeEmail,
  editEmailTemplate,
}: any) {
  //the content may possibly be an array where each object has its text inlineStyles keys and style types
  function ConvertedHtml(html: any) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }
  return (
    <>
      {emailArr.map((item: any) => (
        <Grid item xs={12} mb={2} key={item.id}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={700} fontSize={20}>
                {item.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12}>
                <Typography fontWeight={700}>Subject:</Typography>
                <Typography sx={borderStyle}>{item.subject}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography fontWeight={700}>Body:</Typography>
                <Typography
                  component="p"
                  sx={borderStyle}
                  dangerouslySetInnerHTML={ConvertedHtml(item.message)}
                ></Typography>
              </Grid>
              <Grid
                item
                mt={2}
                xs={12}
                display="flex"
                justifyContent="flex-end"
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => removeEmail(item.id)}
                  sx={{ textTransform: "initial", mr: 2 }}
                >
                  Delete
                </Button>
                <Button
                  variant="contained"
                  onClick={() => editEmailTemplate(item)}
                  sx={{ textTransform: "initial" }}
                >
                  Edit
                </Button>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </>
  );
}
