import * as React from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Divider from "@mui/material/Divider";
import { Typography } from "@mui/material";
import { Clear } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../app/store/configureStore";
import { closeDrawer, openDrawer } from "./drawerSlice";
import RCI1FileUpload from "./RcIFileUpload";
import {
  FileUploadProps,
  OpenDrawerPayload,
} from "../../app/models/FileUpload";
import { useTranslation } from "../../translation/translate";

export default function FileUpload(props: FileUploadProps) {
  const { anchor } = props;
  const { getTranslation } = useTranslation();
  const isOpen = useSelector((state: RootState) => state.drawer[anchor]);
  const section = useSelector((state: RootState) => state.drawer.section);

  const dispatch = useDispatch();

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      if (open) {
        const payload: OpenDrawerPayload = {
          anchor,
          section,
        };
        dispatch(openDrawer(payload));
      } else {
        dispatch(closeDrawer(anchor));
      }
    };

  const list = () => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      margin={2}
      display="flex"
      alignItems="center"
    >
      <Typography variant="h6" flexGrow={1}>
        {section === "Profile"
          ? getTranslation("UploadProfilePhoto")
          : getTranslation("UploadDocumentation")}
      </Typography>
      <Clear onClick={() => dispatch(closeDrawer(anchor))} />
      <Divider />
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <SwipeableDrawer
          anchor={anchor}
          open={isOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          {list()}
          <RCI1FileUpload section={section} />
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
