import { FileUpload } from "@mui/icons-material";
import { Avatar, Button, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import {
  Anchor,
  OpenDrawerPayload,
  Section,
} from "../../../app/models/FileUpload";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../app/store/configureStore";
import { openDrawer } from "../../FileUpload/drawerSlice";
import agent from "../../../app/api/agent";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "../../../translation/translate";

export default function CoverSheetIMG({ coverPicture, setCoverPicture }: any) {
  const { getTranslation } = useTranslation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.account);
  const { isOpen } = useAppSelector((state: RootState) => state.drawer);

  const handleDelete = async () => {
    try {
      const data = await agent.FileUpload.GetFileInfo(
        "CoverSheet",
        user?.portfolioId ?? 0
      );
      await agent.FileUpload.DeleteFile(data[0].id);
      setCoverPicture("");
    } catch (error) {
      // Handle error here, if needed
      console.error(error);
    }
  };

  const handleOpenDrawer = (anchor: Anchor, section: Section) => {
    const payload: OpenDrawerPayload = {
      anchor,
      section,
    };
    dispatch(openDrawer(payload));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await agent.FileUpload.GetFileInfo(
          "CoverSheet",
          user?.portfolioId ?? 0
        );
        if (data.length !== 0) {
          const response = await agent.FileUpload.GetFiles(data[0].id);
          const blob = new Blob([response], {
            type: "application/octet-stream",
          });

          const imageUrl = URL.createObjectURL(blob);
          setCoverPicture(imageUrl);
        } else {
          setCoverPicture("");
        }
      } catch (error) {
        console.error("Error fetching and processing the file:", error);
      }
    };

    fetchData();
  }, [user?.portfolioId, isOpen]);

  return (
    <Grid container p={2} bgcolor={"myVerify.light"}>
      <Grid item xs={12}>
        <Typography fontSize={26} fontWeight={700}>
          {getTranslation("UploadImageOptional")}
        </Typography>
        <Typography fontSize={18} fontWeight={500}>
          {getTranslation("AcceptableFormats")}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Button
          variant="outlined"
          color="myVerify"
          onClick={() => handleOpenDrawer("right", "CoverSheet")}
        >
          <FileUpload /> {getTranslation("Upload")}
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Avatar
          alt={user?.firstName}
          src={coverPicture ?? ""}
          variant="square"
          sx={{ width: 124, height: 124 }}
        />
      </Grid>
      {coverPicture && (
        <Grid
          item
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
        >
          <Button
            variant="outlined"
            color="secondary"
            sx={{ marginBottom: 2 }}
            onClick={() => handleDelete()}
          >
            <DeleteIcon />
            {getTranslation("Remove")}
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
