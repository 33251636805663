import { PrintSharp } from "@mui/icons-material";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import agent from "../../../app/api/agent";
import { useAppSelector } from "../../../app/store/configureStore";
import LoadingSpinner from "../../../app/common/LoadingSpinner";
import StudentBarGraph from "./studentGraph";
import { useTranslation } from "../../../translation/translate";

export default function TrainerDashboard() {
  const [newDateValue, setNewDateValue] = useState<Date | null>(new Date());
  const { user } = useAppSelector((state) => state.account);
  const [isLoading, setIsLoading] = useState(true);
  const [barData, setBarData] = useState<any>([]);
  const { getTranslation } = useTranslation();

  const fetchData = async () => {
    if (user && newDateValue) {
      const graphData = {
        endDate: newDateValue,
        trainerId: user.id,
      };
      try {
        const data = await agent.Progress.GetUserProgressAssignedToTrainer(
          graphData
        );
        setBarData(data);
      } catch (error) {
        // Handle errors if necessary.
        console.error();
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [newDateValue]);

  const handlePrintData = () => {
    window.print();
  };

  const handleApplyButtonClick = () => {
    setIsLoading(true);
    fetchData();
  };
  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container p={4}>
      <Grid item container>
        <Grid
          item
          xs={12}
          component="div"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography fontSize={24} fontWeight={700}>
            {getTranslation("Dashboard")}
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => handlePrintData()}
          >
            <PrintSharp /> {getTranslation("Print")}
          </Button>
        </Grid>
        <Grid item xs={6} md={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disableFuture
              label={getTranslation("PortfolioChecklistDate")}
              value={newDateValue}
              views={["year", "month", "day"]}
              onChange={(e) => setNewDateValue(e)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2} md={1} ml={2}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleApplyButtonClick}
          >
            {getTranslation("Apply")}
          </Button>
        </Grid>
      </Grid>
      <Grid container p={2} spacing={5}>
        <StudentBarGraph barData={barData} />
      </Grid>
    </Grid>
  );
}
