import { Typography, Grid, Button } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  Anchor,
  Section,
  OpenDrawerPayload,
} from "../../app/models/FileUpload";
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../app/store/configureStore";
import { useCallback, useEffect, useState } from "react";
import { FileInfo } from "../../app/models/Fileinfo";
import UploadedFiles from "../FileUpload/UploadedFiles";
import agent from "../../app/api/agent";
import { openDrawer } from "../FileUpload/drawerSlice";
import { FilesProps } from "../../app/models/Answers";
import { useTranslation } from "../../translation/translate";

const PortfolioCoverSheet: React.FC<FilesProps> = ({ RecallData }) => {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();
  const [data, setData] = useState<FileInfo[]>([]);
  const { isOpen } = useAppSelector((state: RootState) => state.drawer);

  const fetchData = useCallback(async () => {
    try {
      const data = await agent.FileUpload.GetFileInfo(
        "PortfolioCoverSheet",
        user?.portfolioId ?? 0
      );

      setData(data);
    } catch (error) {
      // Handle errors if necessary.
    }
  }, [user?.portfolioId]);

  useEffect(() => {
    fetchData();
    RecallData();
  }, [isOpen, fetchData]);
  //1st group files
  const groupedFiles: Record<any, any[]> = {};
  data.forEach((item) => {
    const certType = item.certificateType;
    const itemFile = item;
    // If the property doesn't exist in the groupedFiles object, initialize it as an empty array.
    if (!groupedFiles[certType]) {
      groupedFiles[certType] = [];
    }
    // Push the file to the corresponding property group.
    groupedFiles[certType].push(itemFile);
  });

  //2nd Render grouped files
  const newArr = Object.entries(groupedFiles);
  const fileDataArray = () => {
    return newArr.map((innerArray) => {
      return innerArray[1].map((file: any) => (
        <UploadedFiles
          fileOrder={innerArray[1].indexOf(file) + 1}
          key={file.id}
          fileInfo={file}
          onDeleteFile={() => handleDeleteFile()}
        />
      ));
    });
  };

  const dispatch = useAppDispatch();

  const handleOpenDrawer = (anchor: Anchor, section: Section) => {
    const payload: OpenDrawerPayload = {
      anchor,
      section,
    };
    dispatch(openDrawer(payload));
  };

  function handleDeleteFile(): void {
    fetchData();
    RecallData();
  }

  return (
    <Grid item container p={2} m={1}>
      <Typography fontWeight={700}>
        1. {getTranslation("PortfolioCoverSheetLine1")}
      </Typography>
      <Typography>{getTranslation("PortfolioCoverSheetLine2")}</Typography>
      <Typography>{getTranslation("PortfolioCoverSheetLine3")}</Typography>
      <Grid item xs={12} marginBottom={2}>
        <Button
          href="/portfoliochecklist/OnlineCoverSheet"
          variant="outlined"
          style={{ textTransform: "initial" }}
          color="myVerify"
        >
          {getTranslation("PortfolioCoverSheetLine4")}
        </Button>
      </Grid>

      <Grid item container mt={2} xs={12}>
        <Grid item md={6}>
          <Grid item xs={12}>
            <Typography fontWeight={700}>
              {getTranslation("UploadDocuments")}
            </Typography>

            <Button
              variant="outlined"
              color="myVerify"
              startIcon={<FileUploadIcon />}
              sx={{
                textTransform: "initial",
                lineHeight: 1,
              }}
              onClick={() => handleOpenDrawer("right", "PortfolioCoverSheet")}
            >
              {getTranslation("PortfolioCoverSheetLine5")}
            </Button>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <Typography variant="body1" fontWeight="bold">
            {getTranslation("UploadedDocuments")}
          </Typography>
          <Typography variant="subtitle2">
            {data.length}{" "}
            {data.length === 1
              ? getTranslation("file")
              : getTranslation("files")}
            {"  "}
            {getTranslation("uploaded")}
          </Typography>
          {fileDataArray()}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default PortfolioCoverSheet;
