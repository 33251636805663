import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Divider, Grid, Popover } from "@mui/material";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { useTranslation } from "../../../translation/translate";

export default function ClassListTable({ rows, handleRemoveClass }: any) {
  const [selectedRow, setSelectedRow] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const navigate = useNavigate();
  const [thisClass, setThisClass] = useState<any>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const { getTranslation } = useTranslation();
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: getTranslation("ClassName"),
      sortable: false,
      flex: 1,
    },
    {
      field: "startDate",
      headerName: getTranslation("ClassStartDate"),
      sortable: false,
      flex: 1,
      valueFormatter: (params) => {
        return dayjs(params.value).format("L");
      },
    },
    {
      field: "studentAmount",
      headerName: getTranslation("NumberOfStudents"),
      sortable: false,
      flex: 1,
    },
    {
      field: "actions",
      headerName: getTranslation("Actions"),
      cellClassName: "actions",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Grid item>
            <Button
              onClick={(e) => {
                setIsOpen(true);
                setAnchorEl(e.currentTarget);
                setThisClass(params.row);
                params.row.studentAmount === 0
                  ? setIsDisabled(false)
                  : setIsDisabled(true);
              }}
            >
              <EditIcon sx={{ color: "darkslategray" }} />
            </Button>
          </Grid>
        );
      },
    },
  ];

  return (
    <Grid item container>
      <Grid item xs={12} width={900}>
        <DataGrid
          slots={{ toolbar: GridToolbar }}
          rows={rows}
          columns={columns}
          autoHeight
          disableColumnMenu
          rowHeight={35}
          initialState={{
            pagination: {
              paginationModel: { page: 1, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          editMode="row"
          onCellClick={(e) => setSelectedRow(e.row.id)}
          rowSelectionModel={selectedRow ? [selectedRow] : []}
        />
      </Grid>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Button
          onClick={() => {
            localStorage.setItem("ClassData", JSON.stringify(thisClass));
            navigate("/trainer/class-student-list");
          }}
        >
          {getTranslation("ManageClassRoster")}
        </Button>
        <Divider />
        <Button
          disabled={isDisabled}
          onClick={() => {
            handleRemoveClass(thisClass.id);
            setIsOpen(false);
          }}
        >
          {getTranslation("RemoveClass")}
        </Button>
      </Popover>
    </Grid>
  );
}
