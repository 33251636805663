import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { AnswersProps } from "../../app/models/Answers";

const schema = yup.object().shape({
  StrenghtA: yup
    .string()
    .required("Must give an answer")
    .max(1000, "Max characters reached"),
  StrenghtB: yup
    .string()
    .required("Must give an answer")
    .max(1000, "Max characters reached"),
  StrenghtC: yup
    .string()
    .required("Must give an answer")
    .max(1000, "Max characters reached"),
  GrowthD: yup
    .string()
    .required("Must give an answer")
    .max(1000, "Max characters reached"),
  GrowthE: yup
    .string()
    .required("Must give an answer")
    .max(1000, "Max characters reached"),
  GrowthF: yup
    .string()
    .required("Must give an answer")
    .max(1000, "Max characters reached"),
});

export default function EFormStepOne({
  answersData,
  onAnswerChange,
}: AnswersProps) {
  const titleStyle = { color: "myVerify.main", fontWeight: 700 };
  const containerStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  };
  const containerStyle2 = {
    display: "flex",
    flexDirection: "row",
  };
  const borderStyle = { border: "2px black solid", p: 1 };

  const defaultValues = {
    StrenghtA: answersData.find((answer) => answer.systemCodeId === 2183)
      ?.answer,
    StrenghtB: answersData.find((answer) => answer.systemCodeId === 2184)
      ?.answer,
    StrenghtC: answersData.find((answer) => answer.systemCodeId === 2185)
      ?.answer,
    GrowthD: answersData.find((answer) => answer.systemCodeId === 2186)?.answer,
    GrowthE: answersData.find((answer) => answer.systemCodeId === 2187)?.answer,
    GrowthF: answersData.find((answer) => answer.systemCodeId === 2188)?.answer,
  };

  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  return (
    <Grid item container m={2} p={2}>
      <Grid item>
        <Typography
          fontWeight={700}
          fontSize={20}
          fontStyle="italic"
          textAlign="center"
          color={"myVerify.main"}
        >
          Step 1: Identify Areas of Strength and Areas for Future Professional
          Growth
        </Typography>
        <Typography>
          In order to identify the best goals for yourself, it may be helpful to
          first explore different perspectives - (1) opinions from the families
          you serve, (2) your own thoughts and (3) feedback from your PD
          Specialist, who has just read your{" "}
          <span style={{ fontStyle: "italic" }}>Professional Portfolio</span>{" "}
          and observed you working with children. Before your CDA Verification
          Visit, please read the{" "}
          <span style={{ fontStyle: "italic" }}>Family Questionnaires</span> you
          received. Look for trends or patterns of responses and write down, in
          boxes A and B below, as many areas of strength and areas for
          professional growth that you would like. You will complete the second
          and third columns during the reflective dialogue.
        </Typography>
      </Grid>
      <Grid item container sx={containerStyle}>
        <Grid item xs={3.5} sx={borderStyle}>
          <Typography sx={titleStyle}>1. Family Questionnaires</Typography>
          <Typography>
            (to be completed by the candidate prior to the CDA Verification
            Visit)
          </Typography>
        </Grid>
        <Grid item xs={3.5} sx={borderStyle}>
          <Typography sx={titleStyle}>2. Candidate Self-Reflection</Typography>
          <Typography>
            (to be completed by the candidate during the reflective dialogue)
          </Typography>
        </Grid>
        <Grid item xs={3.5} sx={borderStyle}>
          <Typography sx={titleStyle}>
            3. Feedback From My PD Specialist
          </Typography>
          <Typography>
            (to be completed by the candidate during the reflective dialogue)
          </Typography>
        </Grid>
      </Grid>
      <Grid item container sx={containerStyle2}>
        <Grid item xs={1.5} sx={borderStyle}>
          <Typography sx={titleStyle}>
            What are Your Area(s) of Professional Strength?
          </Typography>
        </Grid>
        <Grid item xs={3.5} sx={borderStyle}>
          <Typography sx={titleStyle}>A</Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            {...register("StrenghtA")}
            value={
              answersData.find((answer) => answer.systemCodeId === 2183)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 2183;

              newValue.length <= 1000 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.StrenghtA?.message}
            helperText={errors.StrenghtA?.message}
          />
        </Grid>
        <Grid item xs={3.5} sx={borderStyle}>
          <Typography sx={titleStyle}>B</Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            {...register("StrenghtB")}
            value={
              answersData.find((answer) => answer.systemCodeId === 2184)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 2184;

              newValue.length <= 1000 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.StrenghtB?.message}
            helperText={errors.StrenghtB?.message}
          />
        </Grid>
        <Grid item xs={3.5} sx={borderStyle}>
          <Typography sx={titleStyle}>C</Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            {...register("StrenghtC")}
            value={
              answersData.find((answer) => answer.systemCodeId === 2185)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 2185;

              newValue.length <= 1000 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.StrenghtC?.message}
            helperText={errors.StrenghtC?.message}
          />
        </Grid>
      </Grid>
      <Grid item container sx={containerStyle2}>
        <Grid item xs={1.5} sx={borderStyle}>
          <Typography sx={titleStyle}>
            What are your Area(s) for Future Professional Growth?
          </Typography>
        </Grid>
        <Grid item xs={3.5} sx={borderStyle}>
          <Typography sx={titleStyle}>D</Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            {...register("GrowthD")}
            value={
              answersData.find((answer) => answer.systemCodeId === 2186)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 2186;

              newValue.length <= 1000 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.GrowthD?.message}
            helperText={errors.GrowthD?.message}
          />
        </Grid>
        <Grid item xs={3.5} sx={borderStyle}>
          <Typography sx={titleStyle}>E</Typography>
          <TextField
            fullWidth
            multiline
            {...register("GrowthE")}
            rows={4}
            value={
              answersData.find((answer) => answer.systemCodeId === 2187)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 2187;

              newValue.length <= 1000 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.GrowthE?.message}
            helperText={errors.GrowthE?.message}
          />
        </Grid>
        <Grid item xs={3.5} sx={borderStyle}>
          <Typography sx={titleStyle}>F</Typography>
          <TextField
            fullWidth
            multiline
            rows={4}
            {...register("GrowthF")}
            value={
              answersData.find((answer) => answer.systemCodeId === 2188)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 2188;

              newValue.length <= 1000 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.GrowthF?.message}
            helperText={errors.GrowthF?.message}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
