import { Grid, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import { useEffect } from "react";

interface Props {
  barData: any;
}

export default function ProgressBarData({ barData }: Props) {

  useEffect(() => {
  }, [barData]);

  return (
    <Grid item xs={12} mb={2}>
      <Grid item xs={12}>
        <Typography>Student Users by Setting and Progress</Typography>
      </Grid>
      {barData && barData.length > 0 && (
        <BarChart
          dataset={barData}
          xAxis={[
            {
              scaleType: "band",
              dataKey: "credentialType",
            },
          ]}
          series={[
            {
              dataKey: "notStarted",
              label: "Not Started",
            },
            {
              dataKey: "inProgress",
              label: "In Progress",
            },
            {
              dataKey: "completed",
              label: "Completed",
            },
          ]}
          colors={["#a6d4fa", "#2c387e", "#a5d6a7"]}
          slotProps={{
            legend: {
              direction: "row",
              position: { vertical: "bottom", horizontal: "middle" },
              padding: 0,
            },
          }}
          width={800}
          height={400}
        />
      )}
    </Grid>
  );
}
