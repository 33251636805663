import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'

const createUserProgressReport = ({ title, report,pdfData } : any): jsPDF=> { 
    // Create a new jsPDF instance.
    const doc = new jsPDF('l');
  
    // Calculate double-spacing (line height factor) for 12-point font.
    const lineHeightFactor = 2;
    doc.setLineHeightFactor(lineHeightFactor);
    //get page size
    const pageWidth = doc.internal.pageSize.getWidth()
    const pageHeight = doc.internal.pageSize.getHeight()
    //doc margins
    let yOffset = 35;
    let marginBottom = doc.internal.pageSize.height -50;
    const answers = doc.splitTextToSize(report, 200, 'center')
    doc.setFontSize(14)
    doc.setFont('times','bold')
    doc.text(answers, 15,20)
    yOffset +=10

    const infantToddler1 = pdfData[2].notStarted; 
    const preschool1 = pdfData[3].notStarted; 
    const familyChildCare1 = pdfData[0].notStarted; 
    const homeVisitor1 = pdfData[1].notStarted;

    const infantToddler2 = pdfData[2].inProgress; 
    const preschool2 = pdfData[3].inProgress; 
    const familyChildCare2 = pdfData[0].inProgress; 
    const homeVisitor2 = pdfData[1].inProgress;

    const infantToddler3 = pdfData[2].completed; 
    const preschool3 = pdfData[3].completed; 
    const familyChildCare3 = pdfData[0].completed; 
    const homeVisitor3 = pdfData[1].completed;

    const footData = {
      credentialType: 'Total', 
      notStarted: infantToddler1 + preschool1 + familyChildCare1 + homeVisitor1, 
      inProgress: infantToddler2 + preschool2 + familyChildCare2 + homeVisitor2,
      completed: infantToddler3 + preschool3 + familyChildCare3 + homeVisitor3,
     }

    autoTable(doc, {
      theme: 'grid',
      margin: {top: 30},
      headStyles: {fillColor: 255, textColor: 0, lineWidth: .1 },
      footStyles: {fillColor: 255, textColor: 0, lineWidth: .1 },

      body: pdfData,
      columns: [
          {header: 'Credential Type', dataKey: 'credentialType'},
          {header: 'Not Started', dataKey: 'notStarted'}, 
          {header: 'In Progress', dataKey: 'inProgress'}, 
          {header: 'Completed', dataKey: 'completed'},
      ],
      foot: [footData]
    })
    


      // Generate a data URL for the PDF.
  const pdfDataUri = doc.output('datauristring'); // Specify the filename here

  // Open the PDF in a new tab.
  const newTab = window.open();


  if (newTab) {
    newTab.document.write(`
      <html>
      <head>
        <title>${title}.pdf</title>
      </head>
      <body>
        <embed width='100%' height='100%' src='${pdfDataUri}' type='application/pdf'>
        
      </body>
      </html>
    `);
  } else {
    // Handle the case where the new tab couldn't be opened.
    alert('Popup blocker prevented opening a new tab.');
  }

    return doc;

}
export default createUserProgressReport;
