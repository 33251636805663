import { Grid, Typography } from "@mui/material";
import SummaryHeader from "./SummaryHeader";
import Instructions from "./Instructions";
import Questionnaires from "./Questionnaires";
import { SummaryCompleteProps } from "../../app/models/SummaryComplete";
import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../app/store/configureStore";
import agent from "../../app/api/agent";
import LoadingSpinner from "../../app/common/LoadingSpinner";
import { useTranslation } from "../../translation/translate";

export default function FamilyQuestionnairesSummary() {
  const { user } = useAppSelector((state) => state.account);
  const [isLoading, setIsLoading] = useState(true);
  const { getTranslation } = useTranslation();

  const [summaryCompleteProps, setSummaryCompleteProps] =
    useState<SummaryCompleteProps>({
      title: getTranslation("FamilyQuestionnairesSummaryTitle"),
      percent: "",
      header: "",
      description:
        getTranslation("FamilyQuestionnairesSummaryDescription") +
        "<b>" +
        getTranslation("FamilyQuestionnairesSummaryDescriptionBold") +
        "</b>",
    });

  const fetchData = useCallback(async () => {
    try {
      if (user?.portfolioId) {
        const compData = await agent.Progress.GetCompentencyResultsByType(
          user.portfolioId,
          "FamilyQuestionnaire"
        );

        setSummaryCompleteProps((prevSummaryCompleteProps) => ({
          ...prevSummaryCompleteProps,
          percent: compData.resourceProgress + "%",
        }));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [user?.portfolioId]);

  const RecallData = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Grid container p={2} mb={2}>
      <SummaryHeader {...summaryCompleteProps} />
      <Questionnaires RecallData={RecallData} />
      <Instructions RecallData={RecallData} />
    </Grid>
  );
}
