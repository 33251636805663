import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'

const createUserBilingualPDF = ({ title, report,pdfData } : any): jsPDF=> { 
    // Create a new jsPDF instance.
    const doc = new jsPDF('l');
  
    // Calculate double-spacing (line height factor) for 12-point font.
    const lineHeightFactor = 2;
    doc.setLineHeightFactor(lineHeightFactor);
    //get page size
    const pageWidth = doc.internal.pageSize.getWidth()
    const pageHeight = doc.internal.pageSize.getHeight()
    //doc margins
    let yOffset = 35;
    let marginBottom = doc.internal.pageSize.height -50;

    doc.setFontSize(14)
    doc.setFont('times','bold')

    const answers = doc.splitTextToSize(report, 200, 'center')
    doc.setFontSize(16)
    doc.setFont('times','bold')
    doc.text(answers, 15,20)
    yOffset +=10
    
    const infantToddler = pdfData[2].isBilingual; 
    const preschool = pdfData[3].isBilingual; 
    const familyChildCare = pdfData[0].isBilingual; 
    const homeVisitor = pdfData[1].isBilingual;

    const infantToddler2 = pdfData[2].nonBilingual; 
    const preschool2 = pdfData[3].nonBilingual; 
    const familyChildCare2 = pdfData[0].nonBilingual; 
    const homeVisitor2 = pdfData[1].nonBilingual;

  const footData = {
    credentialType: 'Total', 
    isBilingual: infantToddler + preschool + familyChildCare + homeVisitor, 
    nonBilingual: infantToddler2 + preschool2 + familyChildCare2 + homeVisitor2
   }
    

    autoTable(doc, {
      theme: 'grid',
      margin: {top: 30},
      headStyles: {fillColor: 255, textColor: 0, lineWidth: .1 },
      footStyles: {fillColor: 255, textColor: 0, lineWidth: .1 },
      body: pdfData,
      columns: [
          {header: 'Credential Type', dataKey: 'credentialType'},
          {header: 'Bilingual', dataKey: 'isBilingual'}, 
          {header: 'Non-bilingual', dataKey: 'nonBilingual'}, 
      ],
      foot: [footData]
    })
    


      // Generate a data URL for the PDF.
  const pdfDataUri = doc.output('datauristring'); // Specify the filename here

  // Open the PDF in a new tab.
  const newTab = window.open();


  if (newTab) {
    newTab.document.write(`
      <html>
      <head>
        <title>${title}.pdf</title>
      </head>
      <body>
        <embed width='100%' height='100%' src='${pdfDataUri}' type='application/pdf'>
        
      </body>
      </html>
    `);
  } else {
    // Handle the case where the new tab couldn't be opened.
    alert('Popup blocker prevented opening a new tab.');
  }

    return doc;

}
export default createUserBilingualPDF;
