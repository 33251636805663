import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../store/configureStore";

import { signOut } from "../../features/account/accountSlice";
import React, { useEffect, useState } from "react";
import { Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import agent from "../api/agent";
import { useTranslation } from "../../translation/translate";

const ProfilePicture = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.account);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { isOpen } = useAppSelector((state: RootState) => state.drawer);
  const open = Boolean(anchorEl);
  const [profilePicture, setProfilePicture] = useState<string | null>("");
  const { getTranslation } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogOut = () => {
    dispatch(signOut());
    setAnchorEl(null);
  };

  const handleEditProfile = () => {
    navigate("/editprofile");
    setAnchorEl(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await agent.FileUpload.GetFileInfo(
          "Profile",
          user?.portfolioId ?? 0
        );
        if (data.length !== 0) {
          const response = await agent.FileUpload.GetFiles(data[0].id);
          const blob = new Blob([response], {
            type: "application/octet-stream",
          });

          const imageUrl = URL.createObjectURL(blob);
          setProfilePicture(imageUrl);
        } else {
          setProfilePicture("");
        }
      } catch (error) {
        console.error("Error fetching and processing the file:", error);
      }
    };

    fetchData();
  }, [isOpen]);

  return (
    <Box sx={{ display: "flex", alignItems: "center", textAlign: "right" }}>
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "flex" },
          flexDirection: "column",
        }}
      >
        <Typography variant="caption" display="block">
          {getTranslation("Name")}:{" "}
          <Typography variant="caption" sx={{ fontWeight: "bold" }}>
            {user?.firstName} {user?.lastName}
          </Typography>
        </Typography>
        {user?.role !== "Other Viewer" && (
          <>
            <Typography variant="caption" display="block">
              {getTranslation("LanguageSpecialization")}:{" "}
              <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                {user?.languageSpecialization ? "Bilingual" : "None"}
              </Typography>
            </Typography>
            <Typography variant="caption" display="block">
              {getTranslation("CouncilCustomerID")}:{" "}
              <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                {user?.cdaCouncilCandidateId}
              </Typography>
            </Typography>
          </>
        )}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              alt={user?.firstName}
              src={profilePicture ?? ""}
              variant="circular"
              sx={{ width: 60, height: 60 }}
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleEditProfile}>
          <Avatar />
          {getTranslation("EditMyProfile")}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogOut}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          {getTranslation("Logout")}
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ProfilePicture;
