import { useForm } from "react-hook-form";
import { Grid, TextField, Typography } from "@mui/material";
import { AnswersProps } from "../../app/models/Answers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const ImportanceObservation: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const defaultValues = {
    importanceOfObservation: answersData.find(
      (answer) => answer.systemCodeId === 251
    )?.answer,
  };
  const { getTranslation } = useTranslation();

  const schema = yup.object().shape({
    textbox: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });
  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={12}>
        <Typography fontSize={18} fontWeight={700} mb={2}>
          {getTranslation("SVImportanceObservationLine1")}
        </Typography>
        <Typography mb={2}>
          {getTranslation("SVImportanceObservationLine2")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...register("importanceOfObservation")}
          name="importanceOfObservation"
          label={getTranslation("SVImportanceObservationLine3")}
          fullWidth
          multiline
          rows={2}
          value={
            answersData.find((answer) => answer.systemCodeId === 251)?.answer ||
            ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = 251;

            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.importanceOfObservation?.message}
          helperText={errors.importanceOfObservation?.message}
        />
        <Typography fontSize={13}>
          {defaultValues.importanceOfObservation?.length}/500
        </Typography>
      </Grid>
    </Grid>
  );
};
export default ImportanceObservation;
