import { FileUpload } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { useEffect, useState } from "react";
import { FileInfo } from "../../app/models/Fileinfo";
import {
  Anchor,
  OpenDrawerPayload,
  Section,
} from "../../app/models/FileUpload";
import { openDrawer } from "../FileUpload/drawerSlice";
import agent from "../../app/api/agent";
import UploadedFiles from "../FileUpload/UploadedFiles";
import { FilesProps } from "../../app/models/Answers";
import { useTranslation } from "../../translation/translate";

const Resource1MenuFeedingSchedule: React.FC<FilesProps> = ({ RecallData }) => {
  const [data, setData] = useState<FileInfo[]>([]);
  const { isOpen } = useAppSelector((state) => state.drawer);
  const { getTranslation } = useTranslation();

  const dispatch = useAppDispatch();

  const handleOpenDrawer = (anchor: Anchor, section: Section) => {
    const payload: OpenDrawerPayload = {
      anchor,
      section,
    };
    dispatch(openDrawer(payload));
  };
  const { user } = useAppSelector((state) => state.account);

  const fetchData = async () => {
    try {
      const data = await agent.FileUpload.GetFileInfo(
        "RCI2",
        user?.portfolioId ?? 0
      );
      setData(data);
    } catch (error) {
      // Handle errors if necessary.
    }
  };

  //1st group files
  const groupedFiles: Record<any, any[]> = {};
  data.forEach((item) => {
    const certType = item.certificateType;
    const itemFile = item;
    // If the property doesn't exist in the groupedFiles object, initialize it as an empty array.
    if (!groupedFiles[certType]) {
      groupedFiles[certType] = [];
    }
    // Push the file to the corresponding property group.
    groupedFiles[certType].push(itemFile);
  });
  //2nd Render grouped files
  const newArr = Object.entries(groupedFiles);
  const fileDataArray = () => {
    return newArr.map((innerArray) => {
      return innerArray[1].map((file: any) => (
        <UploadedFiles
          fileOrder={innerArray[1].indexOf(file) + 1}
          key={file.id}
          fileInfo={file}
          onDeleteFile={() => handleDeleteFile()}
        />
      ));
    });
  };

  useEffect(() => {
    fetchData();
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      RecallData();
    }
  }, [isOpen]);

  function handleDeleteFile(): void {
    fetchData();
    RecallData();
  }

  return (
    <Grid container bgcolor="#EBFCF2">
      <Grid
        container
        item
        xs={12}
        px={3}
        pt={3}
        display="flex"
        flexDirection="column"
      >
        <Typography variant="body1" fontWeight="bold" paragraph>
          RC I-2{" "}
          {user?.credentialTypeId === 1 &&
            getTranslation("ResourceCollectionI2Line1")}
          {(user?.credentialTypeId === 2 || user?.credentialTypeId === 3) &&
            getTranslation("ResourceCollectionI2Line1A")}
        </Typography>
        <Typography variant="body1" paragraph>
          {user?.credentialTypeId === 1 &&
            getTranslation("ResourceCollectionI2Line2")}
          {(user?.credentialTypeId === 2 || user?.credentialTypeId === 3) &&
            getTranslation("ResourceCollectionI2Line3")}
        </Typography>
      </Grid>
      <Grid container item p={2} xs={12} ml={2}>
        <Grid item xs={12} md={6}>
          <Grid item>
            <Typography fontWeight={700}>
              {getTranslation("UploadMenu")}

              {user?.credentialTypeId === 1 &&
                ` ${getTranslation("Resource1part2Line2")}`}
            </Typography>
            <Button
              variant="outlined"
              startIcon={<FileUpload />}
              sx={{
                textTransform: "none",
              }}
              color="success"
              onClick={() => handleOpenDrawer("right", "RCI2")}
            >
              {getTranslation("UploadDocumentation")}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" fontWeight="bold">
            {getTranslation("UploadedDocuments")}
          </Typography>
          <Typography variant="subtitle2">
            {data.length}{" "}
            {data.length === 1
              ? getTranslation("file")
              : getTranslation("files")}{" "}
            {getTranslation("uploaded")}
          </Typography>
          {fileDataArray()}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Resource1MenuFeedingSchedule;
