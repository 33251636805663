import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Grid,
  TextField,
  Typography,
  FormHelperText,
  FormControl,
} from "@mui/material";
import { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const schema = yup.object().shape({
  title: yup
    .string()
    .required("Title of Email Template is Required")
    .max(500, "Maximum Characters Reached"),
  subject: yup.string().required("Email Subject is Required"),
  body: yup.object().required("Email Details are Required"),
});

const InitialValues = {
  title: "",
  subject: "",
  body: "",
};
export default function EmailForm({
  onsubmit,
  handleAddNew,
  setBodyHTML,
  resetEmailInputs,
  emailTitles,
  setEmailTitles,
}: any) {
  const editorRef = useRef<any | null>(null);

  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues: InitialValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  return (
    <Grid item container xs={12} p={2} m={2}>
      <Grid item xs={12} display="flex" flexDirection="column" component="form">
        <Typography fontWeight={700}>Template Title</Typography>
        <TextField
          placeholder="Title"
          {...register("title")}
          fullWidth
          value={emailTitles ? emailTitles.title : ""}
          onChange={(e) =>
            setEmailTitles({ ...emailTitles, title: e.target.value })
          }
          error={!!errors.title?.message}
          helperText={errors.title?.message}
        />
        <Typography mt={3} fontWeight={700}>
          Email Subject
        </Typography>
        <TextField
          placeholder="Subject"
          {...register("subject")}
          fullWidth
          value={emailTitles ? emailTitles.subject : ""}
          onChange={(e) =>
            setEmailTitles({ ...emailTitles, subject: e.target.value })
          }
          error={!!errors.subject?.message}
          helperText={errors.subject?.message}
        />

        <Typography mt={3} fontWeight={700}>
          Email Body
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl
          error={!!errors.subject?.message}
          {...register("body")}
          fullWidth
        >
          <Editor
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            licenseKey="gpl"
            onInit={(_evt, editor) => (editorRef.current = editor)}
            onEditorChange={(a) => setBodyHTML(a)}
            initialValue={
              emailTitles ? emailTitles.message : "<p>Your message here.</p>"
            }
            init={{
              height: 350,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "anchor",
                "searchreplace",
                "visualblocks",
                "code",
                "fullscreen",
                "insertdatetime",
                "media",
                "table",
                "preview",
                "help",
              ],
              statusbar: false,
              toolbar:
                "undo redo | blocks | " +
                "bold italic forecolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | link",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
          <FormHelperText>{errors.body?.message}</FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12} textAlign="center" marginTop={3}>
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            onsubmit(emailTitles);
          }}
          disabled={errors.title || errors.body ? true : false}
        >
          Save & Submit
        </Button>
        <Button
          variant="outlined"
          fullWidth
          sx={{ mt: 2 }}
          onClick={() => {
            resetEmailInputs();
            handleAddNew();
          }}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
}
