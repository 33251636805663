import { FileUpload, FileDownload } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import agent from "../../app/api/agent";
import {
  Anchor,
  Section,
  OpenDrawerPayload,
} from "../../app/models/FileUpload";
import { FileInfo } from "../../app/models/Fileinfo";
import {
  useAppSelector,
  RootState,
  useAppDispatch,
} from "../../app/store/configureStore";
import { openDrawer } from "../FileUpload/drawerSlice";
import UploadedFiles from "../FileUpload/UploadedFiles";
import { FilesProps } from "../../app/models/Answers";
import { useTranslation } from "../../translation/translate";

function toTitleCase(str: string) {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

const Questionnaires: React.FC<FilesProps> = ({ RecallData }) => {
  const [data, setData] = useState<FileInfo[]>([]);
  const { isOpen } = useAppSelector((state: RootState) => state.drawer);
  const { getTranslation } = useTranslation();

  const { user } = useAppSelector((state) => state.account);

  const dispatch = useAppDispatch();

  const handleOpenDrawer = (anchor: Anchor, section: Section) => {
    const payload: OpenDrawerPayload = {
      anchor,
      section,
    };
    dispatch(openDrawer(payload));
  };

  const fetchData = useCallback(async () => {
    try {
      const data = await agent.FileUpload.GetFileInfoByCertType(
        "Family Questionnaires",
        user?.portfolioId ?? 0
      );
      setData(data);
    } catch (error) {
      // Handle errors if necessary.
    }
  }, [user]); // Include user as a dependency for useCallback

  useEffect(() => {
    fetchData();
    RecallData();
  }, [isOpen, fetchData]);

  function handleDeleteFile(): void {
    fetchData();
    RecallData();
  }

  const downloadTemplate = () => {
    const link = document.createElement("a");
    link.download = `FamilyQuestionnaireTemplate.pdf`;
    link.href = "../docs/Family_Questionnaire.pdf";
    link.click();
  };
  return (
    <Grid container p={3} mt={2}>
      <Grid item xs={12} md={6} mb={2}>
        <Typography variant="body1" fontWeight="bold">
          {getTranslation("QuestionnariesLine1")}
        </Typography>
        <Button
          variant="outlined"
          startIcon={<FileUpload />}
          sx={{
            textTransform: "none",
            marginTop: "10px",
          }}
          color="myVerify"
          onClick={() => handleOpenDrawer("right", "FamilyQuestionnaire")}
        >
          {getTranslation("QuestionnariesLine2")}
        </Button>
        <Typography
          variant="body1"
          fontWeight="bold"
          sx={{
            textTransform: "none",
            marginTop: "10px",
          }}
        >
          {toTitleCase(getTranslation("DownloadForm"))}
        </Typography>
        <Button
          onClick={downloadTemplate}
          variant="outlined"
          startIcon={<FileDownload />}
          color="myVerify"
          sx={{
            textTransform: "none",
          }}
        >
          {getTranslation("QuestionnariesLine4")}
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="body1" fontWeight={700} sx={{ mb: 2 }}>
          {getTranslation("UploadedDocuments")}
        </Typography>
        <Typography variant="subtitle2">
          {data.length}{" "}
          {data.length === 1 ? getTranslation("file") : getTranslation("files")}{" "}
          {getTranslation("uploaded")}
        </Typography>
        {data.map((item) => (
          <UploadedFiles
            fileOrder={data.indexOf(item) + 1}
            key={item.id}
            fileInfo={item}
            onDeleteFile={() => handleDeleteFile()}
          />
        ))}
      </Grid>
    </Grid>
  );
};
export default Questionnaires;
