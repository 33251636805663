import {
  Button,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { RCVIUploadForm } from "../../app/models/RCVIUploadForm";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { useCallback, useEffect, useState } from "react";
import { FileUpload } from "@mui/icons-material";
import {
  Anchor,
  Section,
  OpenDrawerPayload,
} from "../../app/models/FileUpload";
import { openDrawer } from "../FileUpload/drawerSlice";
import agent from "../../app/api/agent";
import { FileInfo } from "../../app/models/Fileinfo";
import UploadedFiles from "../FileUpload/UploadedFiles";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

export default function ChildhoodAssociationEntry({
  onsubmit,
  toggle,
  idValue,
  associationCount,
  answersData,
  descriptionValue,
  setDescriptionValue,
  associationValue,
  isEdit,
  websiteValue,
  setWebsiteValue,
  setAssociationValue,
}: any) {
  const { user } = useAppSelector((state) => state.account);
  const [data, setData] = useState<FileInfo[]>([]);
  const { isOpen } = useAppSelector<{ isOpen: boolean }>(
    (state) => state.drawer
  );
  const { getTranslation } = useTranslation();

  const dispatch = useAppDispatch();

  const handleOpenDrawer = (anchor: Anchor, section: Section) => {
    const payload: OpenDrawerPayload = {
      anchor,
      section,
    };
    dispatch(openDrawer(payload));
  };

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  let section: Section = "RCVI2-1";

  switch (associationCount) {
    case 0:
      section = "RCVI2-1";
      break;
    case 1:
      section = "RCVI2-2";
      break;
    case 2:
      section = "RCVI2-3";
      break;
  }

  const fetchData = useCallback(async () => {
    try {
      const data = await agent.FileUpload.GetFileInfo(
        "RCVI2",
        user?.portfolioId ?? 0
      );
      const filterByTypeEndingWithOne = true; // Set to true to filter by types ending with "1"

      const filteredData = data.filter((item: { certificateType: string }) => {
        if (filterByTypeEndingWithOne) {
          return item.certificateType.endsWith(associationCount + 1);
        } else {
          return true; // Include all records if filterByTypeEndingWithOne is false
        }
      });
      setData(filteredData);
    } catch (error) {
      // Handle errors if necessary.
    }
  }, [user?.portfolioId, associationCount]);

  const schema = yup.object().shape({
    association: yup
      .string()
      .max(
        250,
        getTranslation("ChildhoodAssociationEntryValidationAssociation")
      )
      .required(
        getTranslation("ChildhoodAssociationEntryValidationAssociationRequired")
      ),
    website: yup
      .string()
      .url(getTranslation("ChildhoodAssociationEntryValidationWebsite"))
      .max(500, getTranslation("ChildhoodAssociationEntryValidationWebsiteMax"))
      .required(getTranslation("ValidationWebsiteRequired")),

    description: yup
      .string()
      .max(
        1000,
        getTranslation("ChildhoodAssociationEntryValidationDescriptionMax")
      )
      .required(
        getTranslation("ChildhoodAssociationEntryValidationDescriptionRequired")
      ),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<RCVIUploadForm>({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  useEffect(() => {
    fetchData();
  }, [answersData, isOpen, fetchData]);

  const handleCancelEntry = () => {
    reset();
    toggle();
  };

  function handleDeleteFile(): void {
    fetchData();
  }

  return (
    <Grid
      container
      bgcolor={"white"}
      p={2}
      component="form"
      onSubmit={handleSubmit(onsubmit)}
      noValidate
    >
      <Grid item container spacing={3} mb={1} xs={12}>
        <Grid item xs={isMobile ? 12 : 6}>
          {isEdit && (
            <TextField
              value={idValue}
              {...register("id")}
              sx={{ display: "none" }}
            />
          )}
          <TextField
            label={getTranslation("ChildhoodAssociationEntry1")}
            {...register("association")}
            value={associationValue}
            onChange={(e) => {
              const newValue = e.target.value;
              setAssociationValue(newValue);
            }}
            fullWidth
            error={!!errors.association?.message}
            helperText={errors.association?.message}
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label={getTranslation("Website")}
            {...register("website")}
            placeholder="https://www.website.com/"
            value={websiteValue}
            onChange={(e) => {
              const newValue = e.target.value;
              setWebsiteValue(newValue);
            }}
            error={!!errors.website?.message}
            helperText={errors.website?.message}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={getTranslation("ChildhoodAssociationEntry2")}
            {...register("description")}
            value={descriptionValue}
            onChange={(e) => {
              const newValue = e.target.value;
              newValue.length <= 1000 && setDescriptionValue(newValue);
            }}
            fullWidth
            multiline
            rows={4}
            error={!!errors.description?.message}
            helperText={errors.description?.message}
            required
          />
          <Typography fontSize={13}>{descriptionValue.length}/1000</Typography>
        </Grid>
      </Grid>
      {user?.credentialTypeId === 4 && (
        <Grid container p={2} m={1}>
          <Grid item xs={10}>
            <Typography mb={2} pr={3}>
              {getTranslation("ChildhoodAssociationEntry3")}
            </Typography>
          </Grid>

          <Grid
            item
            container
            xs={12}
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
          >
            <Grid item xs={12} md={6}>
              <Typography fontWeight={700}>
                {getTranslation("ChildhoodAssociationEntry4")}
              </Typography>
              <Button
                variant="outlined"
                color="compVI"
                onClick={() => handleOpenDrawer("right", section)}
              >
                <FileUpload /> {getTranslation("Upload")}
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography fontWeight={700}>
                {getTranslation("UploadedDocuments")}
              </Typography>
              <Typography variant="subtitle2">
                {data.length}{" "}
                {data.length === 1
                  ? getTranslation("file")
                  : getTranslation("files")}{" "}
                {getTranslation("uploaded")}
              </Typography>
              {data.map((item) => (
                <UploadedFiles
                  fileOrder={data.indexOf(item) + 1}
                  key={item.id}
                  fileInfo={item}
                  onDeleteFile={() => handleDeleteFile()}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent={
          user?.credentialTypeId === 4 ? "flex-end" : "flex-start"
        }
      >
        <Button
          variant="contained"
          color="compVI"
          sx={{ marginRight: 4 }}
          onClick={handleSubmit(onsubmit)}
        >
          {getTranslation("ChildhoodAssociationEntry5")}
        </Button>
        {!isEdit && (
          <Button variant="outlined" color="compVI" onClick={handleCancelEntry}>
            {getTranslation("ChildhoodAssociationEntry6")}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
