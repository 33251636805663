import { useForm } from "react-hook-form";
import { Grid, TextField, Typography } from "@mui/material";
import ReadMoreReadLess from "../../app/common/ReadMoreReadLess";
import { AnswersProps } from "../../app/models/Answers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const WellRun: React.FC<AnswersProps> = ({ answersData, onAnswerChange }) => {
  const { getTranslation } = useTranslation();
  const schema = yup.object().shape({
    teachingPractices: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });
  const defaultValues = {
    teachingPractices: answersData.find((answer) => answer.systemCodeId === 250)
      ?.answer,
  };

  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={12}>
        <Typography fontSize={18} fontWeight={700} mb={2}>
          {getTranslation("SVWellRunLine1")}
        </Typography>
        <Typography mb={2}>{getTranslation("SVWellRunLine2")}</Typography>
        <ReadMoreReadLess>
          <ul>
            <li>{getTranslation("SVWellRunBulletList1")}</li>
            <li>{getTranslation("SVWellRunBulletList2")}</li>
            <li>{getTranslation("SVWellRunBulletList3")}</li>
            <li>{getTranslation("SVWellRunBulletList4")}</li>
          </ul>
        </ReadMoreReadLess>
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...register("teachingPractices")}
          name="teachingPractices"
          label={getTranslation("SVWellRunLine3")}
          fullWidth
          multiline
          rows={2}
          value={
            answersData.find((answer) => answer.systemCodeId === 250)?.answer ||
            ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = 250;

            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.teachingPractices?.message}
          helperText={errors.teachingPractices?.message}
        />
        <Typography fontSize={13}>
          {defaultValues.teachingPractices?.length}/500
        </Typography>
      </Grid>
    </Grid>
  );
};
export default WellRun;
