import { useForm } from "react-hook-form";
import { Grid, TextField, Typography } from "@mui/material";
import ReadMoreReadLess from "../../app/common/ReadMoreReadLess";
import { useAppSelector } from "../../app/store/configureStore";
import { AnswersProps } from "../../app/models/Answers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const ObservationForm: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();
  const schema = yup.object().shape({
    observationForm1: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    observationForm2: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
    observationForm3: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });

  const defaultValues = {
    observationForm1: answersData.find(
      (answer) =>
        answer.systemCodeId === (user?.credentialTypeId === 4 ? 246 : 249)
    )?.answer,
    observationForm2: answersData.find((answer) => answer.systemCodeId === 247)
      ?.answer,
    observationForm3: answersData.find((answer) => answer.systemCodeId === 248)
      ?.answer,
  };

  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const renderAObservationFormCompetence = () => {
    return (
      <>
        <li>{getTranslation("SVObservationFormBulletList1")}</li>
        <li>{getTranslation("SVObservationFormBulletList2")}</li>
        <li>{getTranslation("SVObservationFormBulletList3")}</li>
        <li>{getTranslation("SVObservationFormBulletList4")}</li>
      </>
    );
  };

  return (
    <Grid container p={2} m={1} bgcolor="compV.light" mb={2}>
      {user?.credentialTypeId === 4 ? (
        <>
          <Grid item xs={12}>
            <Typography fontSize={18} fontWeight={700} mb={2}>
              {getTranslation("SVObservationFormLine1")}
            </Typography>
          </Grid>
          <Grid item xs={12} mb={3}>
            <Typography mb={1}>
              {getTranslation("SVObservationFormLine2")}
            </Typography>
            <ReadMoreReadLess>
              <>{renderAObservationFormCompetence()}</>
            </ReadMoreReadLess>
            <TextField
              sx={{ backgroundColor: "white" }}
              {...register("observationForm1")}
              name="observationForm1"
              label={getTranslation("SVObservationFormLine3")}
              fullWidth
              multiline
              rows={2}
              value={
                answersData.find((answer) => answer.systemCodeId === 246)
                  ?.answer || ""
              }
              onChange={(e) => {
                const newValue = e.target.value;
                const systemCodeId = 246;

                newValue.length <= 500 &&
                  onAnswerChange(systemCodeId, newValue);
              }}
              error={!!errors.observationForm1?.message}
              helperText={errors.observationForm1?.message}
            />
            <Typography fontSize={13}>
              {defaultValues.observationForm1?.length}/500
            </Typography>
          </Grid>
          <Grid item xs={12} mb={3}>
            <Typography mb={1}>
              {getTranslation("SVObservationFormLine4")}
            </Typography>

            <TextField
              sx={{ backgroundColor: "white" }}
              {...register("observationForm2")}
              name="observationForm2"
              label={getTranslation("SVObservationFormLine5")}
              fullWidth
              multiline
              rows={2}
              value={
                answersData.find((answer) => answer.systemCodeId === 247)
                  ?.answer || ""
              }
              onChange={(e) => {
                const newValue = e.target.value;
                const systemCodeId = 247;

                newValue.length <= 500 &&
                  onAnswerChange(systemCodeId, newValue);
              }}
              error={!!errors.observationForm2?.message}
              helperText={errors.observationForm2?.message}
            />
            <Typography fontSize={13}>
              {defaultValues.observationForm2?.length}/500
            </Typography>
          </Grid>
          <Grid item xs={12} mb={3}>
            <Typography mb={1}>
              {getTranslation("SVObservationFormLine6")}
            </Typography>

            <TextField
              sx={{ backgroundColor: "white" }}
              {...register("observationForm3")}
              name="observationForm3"
              label={getTranslation("SVObservationFormLine7")}
              fullWidth
              multiline
              rows={2}
              value={
                answersData.find((answer) => answer.systemCodeId === 248)
                  ?.answer || ""
              }
              onChange={(e) => {
                const newValue = e.target.value;
                const systemCodeId = 248;

                newValue.length <= 500 &&
                  onAnswerChange(systemCodeId, newValue);
              }}
              error={!!errors.observationForm3?.message}
              helperText={errors.observationForm3?.message}
            />
            <Typography fontSize={13}>
              {defaultValues.observationForm3?.length}/500
            </Typography>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography fontSize={18} fontWeight={700}>
              {getTranslation("SVObservationFormLine8")}{" "}
            </Typography>
            <Typography>{getTranslation("SVObservationFormLine9")}</Typography>
            <TextField
              sx={{ backgroundColor: "white" }}
              {...register("observationForm1")}
              name="observationForm1"
              label={getTranslation("SVObservationFormLine10")}
              fullWidth
              multiline
              rows={2}
              value={
                answersData.find((answer) => answer.systemCodeId === 249)
                  ?.answer || ""
              }
              onChange={(e) => {
                const newValue = e.target.value;
                const systemCodeId = 249;

                newValue.length <= 500 &&
                  onAnswerChange(systemCodeId, newValue);
              }}
              error={!!errors.observationForm1?.message}
              helperText={errors.observationForm1?.message}
            />
            <Typography fontSize={13}>
              {defaultValues.observationForm1?.length}/500
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default ObservationForm;
