import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const createUserDetailedReport = ({ title, pdfData, report }: any): jsPDF => {
  // Create a new jsPDF instance.
  const doc = new jsPDF("l");

  // Calculate double-spacing (line height factor) for 12-point font.
  const lineHeightFactor = 2;
  doc.setLineHeightFactor(lineHeightFactor);
  //get page size
  //doc margins
  let yOffset = 35;

  const answers = doc.splitTextToSize(report, 200, "center");
  doc.setFontSize(16);
  doc.setFont("times", "bold");
  doc.text(answers, 15, 20);
  yOffset += 10;

  const mappedPdfData = pdfData.map((row: { languageSpecialization: any }) => ({
    ...row,
    languageSpecialization: row.languageSpecialization ? "Yes" : "No",
  }));

  autoTable(doc, {
    theme: "grid",
    margin: { top: 30 },
    headStyles: { fillColor: 255, textColor: 0, lineWidth: 0.1 },
    body: mappedPdfData,
    columnStyles: {
      0: { minCellWidth: 15 },
      1: { minCellWidth: 20 },
      2: { minCellWidth: 20 },
      3: { minCellWidth: 40 },
      4: { minCellWidth: 15 },
      5: { minCellWidth: 20 },
      6: { minCellWidth: 20 },
      7: { minCellWidth: 20 },
      8: { minCellWidth: 20 },
    },
    columns: [
      { header: "User ID", dataKey: "id" },
      { header: "Last Name", dataKey: "lastName" },
      { header: "First Name", dataKey: "firstName" },
      { header: "Email", dataKey: "email" },
      { header: "State", dataKey: "state" },
      { header: "Language Specialization", dataKey: "languageSpecialization" },
      // { header: "Language Version", dataKey: "" },
      { header: "Progress", dataKey: "progress" },
      { header: "Percent Completed", dataKey: "completed" },
    ],
  });

  // Generate a data URL for the PDF.
  const pdfDataUri = doc.output("datauristring"); // Specify the filename here

  // Open the PDF in a new tab.
  const newTab = window.open();

  if (newTab) {
    newTab.document.write(`
      <html>
      <head>
        <title>${title}.pdf</title>
      </head>
      <body>
        <embed width='100%' height='100%' src='${pdfDataUri}' type='application/pdf'>
        
      </body>
      </html>
    `);
  } else {
    // Handle the case where the new tab couldn't be opened.
    alert("Popup blocker prevented opening a new tab.");
  }

  return doc;
};
export default createUserDetailedReport;
