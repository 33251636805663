import ResourceIVStatus from "./ResourceIVStatus";
import { SummaryCompleteProps } from "../../app/models/SummaryComplete";
import { Grid } from "@mui/material";
import LocalCounseling from "./RCIVLocalCounseling";
import TranslationServices from "./RCIVTranslationServics";
import AgenciesServingChild from "./RCIVAgenciesServingChild";
import WebsitesProvidingInfo from "./RCIVWebsitesProvidingInfo";
import { useAppSelector } from "../../app/store/configureStore";
import { useState, useEffect, useCallback } from "react";
import agent from "../../app/api/agent";
import LoadingSpinner from "../../app/common/LoadingSpinner";
import { Answers } from "../../app/models/Answers";
import CongratsPopUp from "../../app/common/CongratsPopUp";
import { useTranslation } from "../../translation/translate";

export default function CompetencyIVResourceCollection() {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();

  const [summaryCompleteProps, setSummaryCompleteProps] =
    useState<SummaryCompleteProps>({
      title: getTranslation("CompetencyIVResourceCollectionTitle"),
      percent: "10%",
      header: getTranslation("CompetencyIVResourceCollectionHeader"),
      description: getTranslation("CompetencyIVResourceCollectionDescription"),
    });

  const [answers, setAnswers] = useState<Answers[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [popUp, setPopUp] = useState(false);
  const handlePopUp = () => {
    setPopUp((prev) => !prev);
  };

  const fetchData = useCallback(async () => {
    try {
      if (user?.credentialName && user?.portfolioId) {
        const response = await agent.Answers.GetAnswers(
          user.credentialName,
          user.portfolioId,
          "RCIV"
        );
        const compData = await agent.Progress.GetCompentencyResultsByType(
          user.portfolioId,
          "RCIV"
        );
        setSummaryCompleteProps((prevSummaryCompleteProps) => ({
          ...prevSummaryCompleteProps,
          percent: compData.resourceProgress + "%",
        }));
        setAnswers(response);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [user?.credentialName, user?.portfolioId]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleAnswerChange = (systemCodeId: number, newValue: string) => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((answer) =>
        answer.systemCodeId === systemCodeId
          ? { ...answer, answer: newValue }
          : answer
      )
    );
  };

  const handleSaveDraft = async () => {
    setIsLoading(true);
    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );
    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    fetchData();
    setIsLoading(false);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Grid container p={2} mb={2}>
        <ResourceIVStatus {...summaryCompleteProps} />
        <LocalCounseling
          answersData={answers}
          onAnswerChange={handleAnswerChange}
          onSave={handleSaveDraft}
        />
        <TranslationServices
          answersData={answers}
          onAnswerChange={handleAnswerChange}
          onSave={handleSaveDraft}
        />
        <AgenciesServingChild
          answersData={answers}
          onAnswerChange={handleAnswerChange}
          onSave={handleSaveDraft}
        />
        <WebsitesProvidingInfo />
      </Grid>
      <CongratsPopUp
        handlePopUp={handlePopUp}
        isPopUp={popUp}
        btnColor={"warning"}
        background={"warning.light"}
        description={getTranslation("CompstatementIVPopup")}
      />
    </>
  );
}
