import * as yup from "yup";
import { useTranslation } from "../../translation/translate";
interface RC4ValidationSchemaProps {
  languageSpecialization: boolean;
}

const RC4ValidationSchema = ({
  languageSpecialization,
}: RC4ValidationSchemaProps) => {
  const { getTranslation } = useTranslation();
  let schema = yup.object().shape({
    name: yup
      .string()
      .max(60, getTranslation("ValidationNameMaxChar"))
      .required(getTranslation("ValidationNameRequired")),
    address: yup
      .string()
      .max(100, getTranslation("ValidationAddressMaxChar"))
      .required(getTranslation("ValidationAddressRequired")),
    phone: yup
      .string()
      .required(getTranslation("ValidationPhoneNumberRequired"))
      .min(10, getTranslation("ValidationInvalidNumber"))
      .max(11, getTranslation("ValidationInvalidNumber")),
    email: yup
      .string()
      .max(50, getTranslation("ValidationEmailMaxChar"))
      .required(getTranslation("ValidationEmailRequired"))
      .email(getTranslation("ValidationInvalidEmail")),

    website: yup
      .string()
      .max(150, getTranslation("ValidationWebsiteMaxChar"))
      .url(getTranslation("ValidationInvalidWebsite"))
      .required(getTranslation("ValidationWebsiteRequired")),

    description: yup
      .string()
      .max(1000, getTranslation("ValidationMaximumCharacterReached"))
      .required(getTranslation("ValidationDescriptionRequired")),

    description2ngLanguage: yup.string(),
  });

  const secondLanguageFields = {
    description2ngLanguage: yup
      .string()
      .max(1000, getTranslation("ValidationMaximumCharacterReached"))
      .required(getTranslation("ValidationDescriptionSecondLanguageRequired")),
  };

  if (languageSpecialization) {
    schema = schema.shape(secondLanguageFields);
  }

  return schema;
};


const RCIVSchema = () => {
  const { getTranslation } = useTranslation();

  return yup.object().shape({
    name: yup
      .string()
      .max(60, getTranslation("ValidationNameMaxChar"))
      .required(getTranslation("ValidationNameRequired")),
    address: yup
      .string()
      .max(100, getTranslation("ValidationAddressMaxChar"))
      .required(getTranslation("ValidationAddressRequired")),
    phone: yup
      .string()
      .required(getTranslation("ValidationPhoneNumberRequired"))
      .min(10, getTranslation("ValidationInvalidNumber"))
      .max(11, getTranslation("ValidationInvalidNumber")),
    email: yup
      .string()
      .max(50, getTranslation("ValidationEmailMaxChar"))
      .required(getTranslation("ValidationEmailRequired"))
      .email(getTranslation("ValidationInvalidEmail")),
    website: yup
      .string()
      .max(150, getTranslation("ValidationWebsiteMaxChar"))
      .url(getTranslation("ValidationInvalidWebsite"))
      .required(getTranslation("ValidationWebsiteRequired")),
  });
};

const schemaRCIV = { RC4ValidationSchema, RCIVSchema };
export default schemaRCIV;
