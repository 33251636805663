import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TeacherandKids from "../../assets/images/teacher-and-kids.png";
import { CredentialFormData } from "../../app/models/Credentials";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import agent from "../../app/api/agent";
import CredentialConfirmModal from "./CredentialConfirmModal";
import { useState } from "react";
import { useTranslation } from "../../translation/translate";
import { CredentialSchema } from "../../app/validations/CredentialFormValidation";

export default function CredentialForm({
  preloadedValues,
}: {
  preloadedValues: CredentialFormData;
}) {
  const { getTranslation } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm<CredentialFormData>({
    resolver: yupResolver(CredentialSchema(getTranslation)),
    defaultValues: preloadedValues,
  });

  const [formData, setFormData] = useState<CredentialFormData | null>(null);

  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmModal = async (data: CredentialFormData) => {
    await agent.Credentials.UpdateCredentials(data).then(() => {
      navigate("/welcome/How-To-Video");
    });
    setShowModal(false);
  };

  const onSubmit = async (data: CredentialFormData) => {
    setFormData(data);
    setShowModal(true);
  };

  const handleCredential = () => {
    // if(formData?.credentialTypeId === 1){
    // return "Infant-Toddler"}else{
    //   if(formData?.credentialTypeId === 2){
    //   return "Preschool"}
    // }

    switch (formData?.credentialTypeId) {
      case 1:
        return getTranslation("CredentialFormLine1");
      case 2:
        return getTranslation("CredentialFormLine2");
      case 3:
        return getTranslation("CredentialFormLine3");
      case 4:
        return getTranslation("CredentialFormLine4");
      default:
        return "";
    }
  };

  return (
    <Box>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container>
          <Grid item md={7}>
            <Typography sx={{ fontWeight: "bold" }}>
              {getTranslation("CredentialFormLine5")}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  className={errors.credentialTypeId ? "error" : ""}
                >
                  <Controller
                    control={control}
                    name="credentialTypeId"
                    render={({ field }) => (
                      <RadioGroup
                        aria-label="CDA Credential"
                        {...field}
                        row
                        className={errors.credentialTypeId ? "error" : ""}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <FormControlLabel
                              value={1}
                              control={<Radio />}
                              labelPlacement="end"
                              label={
                                <Box>
                                  <Typography mt={3}>
                                    {getTranslation("CredentialFormLine6")}
                                  </Typography>
                                  <Typography>
                                    {getTranslation("CredentialFormLine7")}
                                  </Typography>
                                </Box>
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FormControlLabel
                              value={3}
                              control={<Radio />}
                              labelPlacement="end"
                              label={
                                <Box>
                                  <Typography mt={3}>
                                    {getTranslation("CredentialFormLine8")}
                                  </Typography>
                                  <Typography>
                                    {getTranslation("CredentialFormLine9")}
                                  </Typography>
                                </Box>
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FormControlLabel
                              value={2}
                              control={<Radio />}
                              labelPlacement="end"
                              label={
                                <Box>
                                  <Typography mt={3}>
                                    {getTranslation("CredentialFormLine10")}
                                  </Typography>
                                  <Typography>
                                    {getTranslation("CredentialFormLine11")}
                                  </Typography>
                                </Box>
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FormControlLabel
                              value={4}
                              control={<Radio />}
                              labelPlacement="end"
                              label={
                                <Box>
                                  <Typography mt={3}>
                                    {getTranslation("CredentialFormLine12")}
                                  </Typography>
                                  <Typography>
                                    {getTranslation("CredentialFormLine13")}
                                  </Typography>
                                </Box>
                              }
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Typography sx={{ fontWeight: "bold" }} mt={4}>
              {getTranslation("CredentialFormLine14")}
            </Typography>
            <Typography mt={2}>
              {getTranslation("CredentialFormLine15")}
            </Typography>
            <Controller
              control={control}
              name="languageSpecialization"
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value} // Set the checked state based on the field value
                      onChange={(e) => field.onChange(e.target.checked)} // Update the field value when the checkbox is changed
                    />
                  }
                  labelPlacement="end"
                  label={
                    <Box>
                      <Typography mt={3}>
                        {getTranslation("CredentialFormLine16")}
                      </Typography>
                      <Typography>
                        {getTranslation("CredentialFormLine17")}
                      </Typography>
                    </Box>
                  }
                />
              )}
            />
            <Typography mt={2}>
              {getTranslation("CredentialFormLine18")}
            </Typography>
            <LoadingButton
              type="submit"
              loading={isSubmitting}
              disabled={!isValid}
              variant="contained"
              sx={{ textTransform: "none", mb: 3, mt: 4 }}
            >
              {getTranslation("CDABookButton2")}
            </LoadingButton>
          </Grid>
          <Grid item md={5} xs={12} sx={{ textAlign: "left" }}>
            <img
              src={TeacherandKids}
              alt="teacher-and-kids"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Grid>
        </Grid>
      </Box>
      <CredentialConfirmModal
        isOpen={showModal}
        credMessage={handleCredential()}
        biMessage={
          formData?.languageSpecialization
            ? getTranslation("Selected")
            : getTranslation("didnotselect")
        }
        onConfirm={() => handleConfirmModal(formData as CredentialFormData)}
        onCancel={handleCloseModal}
      />
    </Box>
  );
}
