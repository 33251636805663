import { FileDownload } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { getTrailingCommentRanges } from "typescript";
import { useTranslation } from "../../translation/translate";

const HomeVisitorAssessor = () => {
  const { getTranslation } = useTranslation();
  const downloadWorksheet = () => {
    const link = document.createElement("a");
    link.download = `HomeVisitorCDAAssessorRecommendationForm.pdf`;
    link.href = "./docs/HomeVisitorCDAAssessorRecommendationForm.pdf";
    link.click();
  };

  return (
    <Grid container mb={2} p={3}>
      <Grid item xs={12} mb={2}>
        <Typography fontWeight={700} fontSize={18}>
          {getTranslation("HVAssesor")}
        </Typography>
        <Typography>{getTranslation("HVAssesorDetails")}</Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Button
          variant="outlined"
          color="myVerify"
          sx={{ textTransform: "initial" }}
          onClick={downloadWorksheet}
        >
          <FileDownload />
          {getTranslation("DownloadForm")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography>{getTranslation("HVAssesorDetails2")}</Typography>
      </Grid>
    </Grid>
  );
};
export default HomeVisitorAssessor;
