import { Grid } from "@mui/material";
import HowToUpload from "./Uploading";
import HowToDownload from "./Downloading";
import UploadDownloadSatus from "./UploadDownloadStatus";

export default function UploadDownloadCollection() {
  return (
    <Grid container p={1} m={1}>
      <UploadDownloadSatus />
      <HowToUpload />
      <HowToDownload />
    </Grid>
  );
}
