import { Grid, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useAppSelector } from "../../app/store/configureStore";
import { AnswersProps } from "../../app/models/Answers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const EarlyChildhood: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();
  const isHomeVisitor = user?.credentialTypeId === 4;
  const earlyChildhoodCodeId = isHomeVisitor ? 256 : 254;

  const defaultValues = {
    earlyChildhood: answersData.find(
      (answer) => answer.systemCodeId === earlyChildhoodCodeId
    )?.answer,
  };

  const schema = yup.object().shape({
    earlyChildhood: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });
  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  return (
    <Grid container p={2} m={1} mb={2} bgcolor="compVI.light">
      <Grid item xs={12}>
        <Typography fontSize={18} fontWeight={700} mb={2}>
          {user?.credentialTypeId === 4
            ? getTranslation("SVIEarlyChildhoodLine1")
            : getTranslation("SVIEarlyChildhoodLine2")}
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2}>
        <Typography>
          {user?.credentialTypeId === 4
            ? getTranslation("SVIEarlyChildhoodLine3")
            : getTranslation("SVIEarlyChildhoodLine4")}{" "}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={
            user?.credentialTypeId === 4
              ? getTranslation("SVIEarlyChildhoodLine5")
              : getTranslation("SVIEarlyChildhoodLine6")
          }
          {...register("earlyChildhood")}
          name="earlyChildhood"
          sx={{ backgroundColor: "white" }}
          fullWidth
          multiline
          rows={2}
          value={
            answersData.find(
              (answer) => answer.systemCodeId === earlyChildhoodCodeId
            )?.answer || ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = earlyChildhoodCodeId;

            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.earlyChildhood?.message}
          helperText={errors.earlyChildhood?.message}
        />
        <Typography fontSize={13}>
          {defaultValues.earlyChildhood?.length}/500
        </Typography>
      </Grid>
    </Grid>
  );
};
export default EarlyChildhood;
