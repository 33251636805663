import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import visitIcon from "../../assets/images/verifyVisit.png";
import { SummaryCompleteProps } from "../../app/models/SummaryComplete";

export default function MyUploadStatus({
  title,
  description,
}: SummaryCompleteProps) {
  return (
    <Card
      sx={{
        width: "100%",
        backgroundColor: "myVerify.light",
        boxShadow: "0 16px #3E7787",
        marginBottom: 4,
      }}
    >
      <CardContent>
        <Grid container mt={1}>
          <Grid item xs={2} sm={2} md={1} sx={{ textAlign: "center" }}>
            <Grid item sx={{ textAlign: "center" }}>
              <Box
                sx={{
                  marginTop: 1,
                  display: { xs: "none", sm: "none", md: "block" },
                }}
              >
                <img src={visitIcon} alt="status" style={{ width: "100%" }} />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={10} p={1} ml={2}>
            <Typography variant="h6" fontWeight={700} marginBottom={1}>
              {title}
            </Typography>
            <Typography marginBottom={1}>{description}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
