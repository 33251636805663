import { useForm } from "react-hook-form";
import { Grid, TextField, Typography } from "@mui/material";
import ReadMoreReadLess from "../../app/common/ReadMoreReadLess";
import { AnswersProps } from "../../app/models/Answers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const PlansForFamilies: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const { getTranslation } = useTranslation();
  const defaultValues = {
    plansForFamilies: answersData.find((answer) => answer.systemCodeId === 245)
      ?.answer,
  };
  const schema = yup.object().shape({
    plansForFamilies: yup
      .string()
      .required(getTranslation("ValidationMustGiveAnAnswer"))
      .max(500, getTranslation("ValidationMaximumCharacterReached")),
  });
  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  const renderPlansforFamilies = () => {
    return (
      <>
        <li>{getTranslation("PlansForFamilyBullet1")}</li>
        <li>{getTranslation("PlansForFamilyBullet2")}</li>
        <li>{getTranslation("SVWellRunBulletList3")}</li>
        <li>{getTranslation("SVWellRunBulletList4")}</li>
      </>
    );
  };

  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={12}>
        <Typography fontSize={18} fontWeight={700} mb={2}>
          {getTranslation("PlansForFamilyBullet3")}
        </Typography>
        <Typography mb={2}>
          {getTranslation("PlansForFamilyBullet4")}
        </Typography>
        <ReadMoreReadLess>
          <>{renderPlansforFamilies()}</>
        </ReadMoreReadLess>
      </Grid>
      <Grid item xs={12}>
        <TextField
          {...register("plansForFamilies")}
          name="plansForFamilies"
          label={getTranslation("PlansForFamilyBullet5")}
          fullWidth
          multiline
          rows={2}
          value={
            answersData.find((answer) => answer.systemCodeId === 245)?.answer ||
            ""
          }
          onChange={(e) => {
            const newValue = e.target.value;
            const systemCodeId = 245;

            newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
          }}
          error={!!errors.plansForFamilies?.message}
          helperText={errors.plansForFamilies?.message}
        />
        <Typography fontSize={13}>
          {defaultValues.plansForFamilies?.length}/500
        </Typography>
      </Grid>
    </Grid>
  );
};
export default PlansForFamilies;
