import SocialDevelopmentList from "./StatementIIISocialDevelopmentList";
import ChallengingBehaviors from "./StatementIIIChallengingBehaviors";
import PositiveBehaviors from "./StatementIIIPositiveBehaviors";
import PositiveSelf from "./StatementIIIPositiveSelf";
import ResourceIIIStatus from "./ResourceIIIStatus";
import { SummaryCompleteProps } from "../../app/models/SummaryComplete";
import { Grid } from "@mui/material";
import { useAppSelector } from "../../app/store/configureStore";
import PrintSaveBtns from "../../app/common/PrintSaveBtn";
import { useState, useEffect, useCallback } from "react";
import agent from "../../app/api/agent";
import LoadingSpinner from "../../app/common/LoadingSpinner";
import { Answers } from "../../app/models/Answers";
import CongratsPopUp from "../../app/common/CongratsPopUp";
import BilingualPopUp from "../../app/common/BilingualPopUp";
import { PDF } from "../../app/models/Pdf";
import createAndOpenPDF from "../../app/utils/pdfutils";
import { useTranslation } from "../../translation/translate";

export default function CompetencyIIIStatement() {
  const { getTranslation } = useTranslation();
  const { user } = useAppSelector((state) => state.account);
  const [pdf, setPdf] = useState<PDF[]>([]);

  const [summaryCompleteProps, setSummaryCompleteProps] =
    useState<SummaryCompleteProps>({
      title: getTranslation("CompetencyIIIStatementTitle"),
      percent: "",
      header: getTranslation("CompetencyIIIStatementHeader"),
      description: getTranslation("CompetencyIIIStatementDescription"),
    });

  const [answers, setAnswers] = useState<Answers[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userProgress, setUserProgress] = useState(0);

  const [bilingualModal, setBilingualModal] = useState(true);
  const handleBilingual = () => {
    setBilingualModal(false);
  };

  const [popUp, setPopUp] = useState(false);
  const handlePopUp = () => {
    setPopUp((prev) => !prev);
  };

  const fetchData = useCallback(async () => {
    try {
      if (user?.credentialName && user?.portfolioId) {
        const response = await agent.Answers.GetAnswers(
          user.credentialName,
          user.portfolioId,
          "CSIII"
        );
        const compData = await agent.Progress.GetCompentencyResultsByType(
          user.portfolioId,
          "CSIII"
        );
        const pdf = await agent.PDFViewer.GetPDFByType(
          user.credentialName,
          user.portfolioId,
          "CSIII"
        );

        setUserProgress(compData.statementProgress);
        setSummaryCompleteProps((prevSummaryCompleteProps) => ({
          ...prevSummaryCompleteProps,
          percent: compData.statementProgress + "%",
        }));

        setPdf(pdf);
        setAnswers(response);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }, [user?.credentialName, user?.portfolioId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAnswerChange = (systemCodeId: number, newValue: string) => {
    setAnswers((prevAnswers) =>
      prevAnswers.map((answer) =>
        answer.systemCodeId === systemCodeId
          ? { ...answer, answer: newValue }
          : answer
      )
    );
  };

  const handleSaveDraft = async () => {
    setIsLoading(true);
    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );
    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    fetchData();
    setIsLoading(false);
  };
  const handleSave = async () => {
    setIsLoading(true);
    const filteredAnswers = answers.filter(
      (answer) => answer.id !== 0 || answer.answer !== ""
    );
    await agent.Answers.InsertorUpdateAnswers(filteredAnswers);
    fetchData();
    setIsLoading(false);
    handlePopUp();
  };
  const handlePDF = async () => {
    createAndOpenPDF({
      pdfData: pdf,
      title: getTranslation("CompetencyIIIStatementTitle"),
      user: [
        user?.firstName,
        user?.lastName,
        user?.credentialName,
        user?.cdaCouncilCandidateId,
      ],
    });
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Grid container p={2} mb={2}>
        {user?.languageSpecialization && (
          <BilingualPopUp
            isPopUp={bilingualModal}
            btnColor={"comp3"}
            handlePopUp={handleBilingual}
          />
        )}
        <ResourceIIIStatus {...summaryCompleteProps} />
        <SocialDevelopmentList
          answersData={answers}
          onAnswerChange={handleAnswerChange}
        />
        <PositiveSelf
          answersData={answers}
          onAnswerChange={handleAnswerChange}
        />
        <PositiveBehaviors
          answersData={answers}
          onAnswerChange={handleAnswerChange}
        />
        <ChallengingBehaviors
          answersData={answers}
          onAnswerChange={handleAnswerChange}
        />
      </Grid>

      <PrintSaveBtns
        btnColor={"comp3"}
        onSave={handleSave}
        onDraft={handleSaveDraft}
        onPreview={handlePDF}
        Progress={userProgress}
        {...summaryCompleteProps}
      />
      <CongratsPopUp
        handlePopUp={handlePopUp}
        isPopUp={popUp}
        btnColor={"comp3"}
        background={"comp3.light"}
        description={getTranslation("CompstatementIIIPopup")}
      />
    </>
  );
}
