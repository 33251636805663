import { ExpandCircleDown } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
} from "@mui/material";
import { useTranslation } from "../../translation/translate";

export default function FaqSection() {
  const { getTranslation } = useTranslation();
  return (
    <Grid container mt={3}>
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandCircleDown sx={{ color: "comp3.main" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontSize={20} fontWeight={700}>
              {getTranslation("FaqSectionLine1")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{
                paddingLeft: "25px",
                paddingRight: "25px",
                marginTop: "-10px",
              }}
            >
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{ marginBottom: "5px" }}
              >
                {getTranslation("FaqSectionLine2")}
              </Typography>
              {getTranslation("FaqSectionLine3")}
            </Typography>
            <Typography
              mt={3}
              sx={{
                backgroundColor: "#FFF4F0",
                paddingTop: "25px",
                paddingBottom: "25px",
                paddingLeft: "25px",
                paddingRight: "25px",
              }}
            >
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{ marginBottom: "5px" }}
              >
                {getTranslation("FaqSectionLine4")}
              </Typography>
              {getTranslation("FaqSectionLine5")}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
}
