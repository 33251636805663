import {
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useAppSelector } from "../../app/store/configureStore";
import { useForm } from "react-hook-form";
import { AnswersProps } from "../../app/models/Answers";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "../../translation/translate";

const EFormTableQuestionnaire: React.FC<AnswersProps> = ({
  answersData,
  onAnswerChange,
}) => {
  const { user } = useAppSelector((state) => state.account);
  const { getTranslation } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const defaultValues = {
    distributed: answersData.find((answer) => answer.systemCodeId === 323)
      ?.answer,
    collected: answersData.find((answer) => answer.systemCodeId === 324)
      ?.answer,
    summaryA: answersData.find((answer) => answer.systemCodeId === 325)?.answer,
    summaryB: answersData.find((answer) => answer.systemCodeId === 326)?.answer,
  };

  const schema = yup.object().shape({
    distributed: yup
      .number()
      .typeError(getTranslation("ValidationAmountRequired"))
      .required(getTranslation("ValidationAmountRequired")),
    collected: yup
      .number()
      .typeError(getTranslation("ValidationAmountRequired"))
      .required(getTranslation("ValidationAmountRequired")),
    summaryA: yup
      .string()
      .required(getTranslation("ValidationSummaryRequired")),
    summaryB: yup
      .string()
      .required(getTranslation("ValidationSummaryRequired")),
  });

  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onTouched",
  });

  return (
    <Grid container p={3}>
      <Grid item m={1}>
        <Typography fontSize={20} fontWeight={700}>
          {getTranslation("TitleFamilyQuestionnaires")}
        </Typography>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={4}>
          <TextField
            defaultValue={user?.firstName}
            label={getTranslation("FirstName")}
            variant="filled"
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            defaultValue={user?.lastName}
            label={getTranslation("LastName")}
            variant="filled"
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            defaultValue={user?.cdaCouncilCandidateId}
            label={getTranslation("CandidateIDNumber")}
            variant="filled"
            disabled
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid item container p={1} m={1} spacing={3}>
        <Grid item mt={2}>
          <Typography fontSize={20} fontWeight={700}>
            {getTranslation("SummaryFamilyQuestionnaires")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <span style={{ fontWeight: 700 }}>1.</span>{" "}
            {getTranslation("Idistributed")}{" "}
            <TextField
              variant="standard"
              sx={{ width: 50 }}
              placeholder=" #"
              type="number"
              {...register("distributed")}
              value={
                answersData.find((answer) => answer.systemCodeId === 323)
                  ?.answer || ""
              }
              onChange={(e) => {
                const newValue = e.target.value;
                const systemCodeId = 323;
                newValue.length <= 10 && onAnswerChange(systemCodeId, newValue);
              }}
              error={!!errors.distributed?.message}
              helperText={errors.distributed?.message}
            />
            {getTranslation("FamilyQuestionnaires")}.
          </Typography>
        </Grid>
        <Grid item xs={12} p={2} mt={1} bgcolor="myVerify.light">
          <Typography>
            <span style={{ fontWeight: 700 }}>2. </span>
            {getTranslation("Icollected")}{" "}
            <TextField
              variant="standard"
              sx={{ width: 50 }}
              type="number"
              placeholder=" #"
              {...register("collected")}
              value={
                answersData.find((answer) => answer.systemCodeId === 324)
                  ?.answer || ""
              }
              onChange={(e) => {
                const newValue = e.target.value;
                const systemCodeId = 324;
                newValue.length <= 10 && onAnswerChange(systemCodeId, newValue);
              }}
              error={!!errors.collected?.message}
              helperText={errors.collected?.message}
            />{" "}
            {getTranslation("FamilyQuestionnaireNumber3")}.
          </Typography>
        </Grid>
        <Grid item xs={isMobile ? 12 : 8}>
          <Typography>
            <span style={{ fontWeight: 700 }}>3.</span>{" "}
            {getTranslation("FamilyQuestionnaireNumber3a")}
          </Typography>
        </Grid>
        <Grid item xs={isMobile ? 12 : 7} ml={isMobile ? 0 : 6}>
          <TextField
            label={getTranslation("AreasofStrength")}
            multiline
            rows={3}
            fullWidth
            inputProps={{ maxLength: 500 }}
            {...register("summaryA")}
            value={
              answersData.find((answer) => answer.systemCodeId === 325)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 325;
              newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.summaryA?.message}
            helperText={errors.summaryA?.message}
          />
          <Typography fontSize={13}>
            {defaultValues.summaryA?.length}/500
          </Typography>
        </Grid>
        <Grid item xs={isMobile ? 12 : 7} ml={isMobile ? 0 : 6}>
          <TextField
            label={getTranslation("AreasProfessionalGrowth")}
            inputProps={{ maxLength: 500 }}
            multiline
            rows={3}
            fullWidth
            {...register("summaryB")}
            value={
              answersData.find((answer) => answer.systemCodeId === 326)
                ?.answer || ""
            }
            onChange={(e) => {
              const newValue = e.target.value;
              const systemCodeId = 326;
              newValue.length <= 500 && onAnswerChange(systemCodeId, newValue);
            }}
            error={!!errors.summaryB?.message}
            helperText={errors.summaryB?.message}
          />
          <Typography fontSize={13}>
            {defaultValues.summaryB?.length}/500
          </Typography>
        </Grid>
        <Grid item xs={12} bgcolor="myVerify.light" p={2} mt={2}>
          <Typography>
            <span style={{ fontWeight: 700 }}>4.</span>{" "}
            {getTranslation("familyquestionnairessummary4")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            {getTranslation("NoteCDAPDSpecialist")}
          </Typography>
          <Typography>
            {getTranslation("NoteCDAPDSpecialistDescription")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EFormTableQuestionnaire;
