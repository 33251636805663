import * as yup from "yup";
import { useTranslation } from "../../translation/translate";

const emailREGX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const FormValidationSAcc = (getTranslation: (key: string) => string) =>
  yup.object().shape({
    firstName: yup.string().required(getTranslation("SelectionRequired")),
    lastName: yup
      .string()
      .max(60, getTranslation("ValidationNameMaxChar"))
      .required(getTranslation("ValidationNameRequired")),
    email: yup
      .string()
      .max(50, getTranslation("ValidationEmailMaxChar"))
      .required(getTranslation("ValidationEmailRequired"))
      .matches(emailREGX, getTranslation("ValidationInvalidEmail")),
    relation: yup.string().required(getTranslation("RelationshipnRequired")),
  });

export const useGetTranslation = () => {
  const { getTranslation } = useTranslation();
  return getTranslation;
};
