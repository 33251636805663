import { CloseOutlined } from "@mui/icons-material";
import { Grid, IconButton, Link, Popover, Typography } from "@mui/material";
import { useAppSelector } from "../store/configureStore";

export default function HelpSupport(props: any) {
  const { openPopover, anchorEl, handleClose } = props;
  const { user } = useAppSelector((state) => state.account);

  return (
    <Popover
      open={openPopover}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      <Grid p={2}>
        <Grid item display="flex" justifyContent="space-between" mb={2}>
          <Typography mt={1} fontWeight={700} fontSize={20} color="GrayText">
            Support
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseOutlined fontSize="small" />
          </IconButton>
        </Grid>
        {user && (
          <Grid item mb={2}>
            <Typography fontWeight={700}>Self Help</Typography>
            <ul>
              <li>
                <Link href="/downloadupload">Review Demo Video</Link>
              </li>
              <li>
                <Link href="/help/helpandfaqs">Help & FAQs Here</Link>
              </li>
            </ul>
          </Grid>
        )}
        <Grid item>
          <Typography fontWeight={700} fontSize={20}>
            Contact Us
          </Typography>
          <Typography>
            <span style={{ fontWeight: 700 }}>Email</span>{" "}
            <Link href="mailto:support@caresolutions.com" type="email">
              support@caresolutions.com
            </Link>
          </Typography>
          <Typography>
            <span style={{ fontWeight: 700 }}>Phone</span> (800)227-3410
          </Typography>
          <Typography>
            <span style={{ fontWeight: 700 }}>Hours</span> Monday-Friday 8:30 AM
            - 5:30 PM EST
          </Typography>
        </Grid>
      </Grid>
    </Popover>
  );
}
