import { Button, Grid, Theme, useMediaQuery } from "@mui/material";
import { useTranslation } from "../../translation/translate";

export default function DraftAndSubmitBtns(props: any) {
  const { onSave } = props;
  const { getTranslation } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Grid item xs={12} mr={2}>
      <Button
        onClick={onSave}
        color="warning"
        variant="outlined"
        sx={{ marginRight: 3, marginBottom: isMobile ? 2 : 0 }}
      >
        {getTranslation("Save")}{" "}
      </Button>
    </Grid>
  );
}
